import {
  BENEFIT,
  BENEFIT_CLEAR_DATA,
  BENEFIT_ERROR,
  BENEFIT_SUCCESS,
  GET_BENEFICIARY,
  GET_BENEFICIARY_CLEAR_DATA,
  GET_BENEFICIARY_ERROR,
  GET_BENEFICIARY_SUCCESS,
  BENEFICIARY_PATCH,
  BENEFICIARY_PATCH_SUCCESS,
  BENEFICIARY_PATCH_ERROR,
  BENEFICIARY_PATCH_CLEAR_DATA,
} from "./actionTypes";

export const benefit = (beneficiary, history) => {
  return {
    type: BENEFIT,
    payload: { beneficiary, history },
  };
};

export const benefitSuccess = (success) => {
  return {
    type: BENEFIT_SUCCESS,
    payload: success,
  };
};

export const benefitError = (error) => {
  return {
    type: BENEFIT_ERROR,
    payload: error,
  };
};

export const benefitClearData = () => {
  return {
    type: BENEFIT_CLEAR_DATA,
    payload: {},
  };
};

export const getBeneficiary = (beneficiary, history) => {
  return {
    type: GET_BENEFICIARY,
    payload: { beneficiary, history },
  };
};

export const getBeneficiarySuccess = (success) => {
  return {
    type: GET_BENEFICIARY_SUCCESS,
    payload: success,
  };
};

export const getBeneficiaryError = (error) => {
  return {
    type: GET_BENEFICIARY_ERROR,
    payload: error,
  };
};

export const getBeneficiryClearData = () => {
  return {
    type: GET_BENEFICIARY_CLEAR_DATA,
    payload: {},
  };
};

export const patchBeneficiary = (beneficiary, history) => {
  return {
    type: BENEFICIARY_PATCH,
    payload: { beneficiary, history },
  };
}

export const patchBeneficiarySuccess = (success) => {
  return {
    type: BENEFICIARY_PATCH_SUCCESS,
    payload: success,
  };
}

export const patchBeneficiaryError = (error) => {
  return {
    type: BENEFICIARY_PATCH_ERROR,
    payload: error,
  };
}

export const patchBeneficiaryClearData = () => {
  return {
    type: BENEFICIARY_PATCH_CLEAR_DATA,
    payload: {},
  };
}
import {
  BRANCHES,
  BRANCHES_CLEAR_DATA,
  BRANCHES_ERROR,
  BRANCHES_PATCH,
  BRANCHES_PATCH_CLEAR_DATA,
  BRANCHES_PATCH_ERROR,
  BRANCHES_PATCH_SUCCESS,
  BRANCHES_POST,
  BRANCHES_POST_CLEAR_DATA,
  BRANCHES_POST_ERROR,
  BRANCHES_POST_SUCCESS,
  BRANCHES_SUCCESS,
} from "./actionTypes";

export const Branches = (branch, history) => {
  return {
    type: BRANCHES,
    payload: { branch, history },
  };
};

export const branchesSuccess = (success) => {
  return {
    type: BRANCHES_SUCCESS,
    payload: success,
  };
};

export const branchesError = (error) => {
  return {
    type: BRANCHES_ERROR,
    payload: error,
  };
};

export const branchesClearData = () => {
  return {
    type: BRANCHES_CLEAR_DATA,
    payload: {},
  };
};

export const branchesPost = (branchesBody, history) => {
  return {
    type: BRANCHES_POST,
    payload: { branchesBody, history },
  };
};

export const branchesPostSuccess = (success) => {
  return {
    type: BRANCHES_POST_SUCCESS,
    payload: success,
  };
};

export const branchesPostError = (error) => {
  return {
    type: BRANCHES_POST_ERROR,
    payload: error,
  };
};

export const branchesPostClearData = () => {
  return {
    type: BRANCHES_POST_CLEAR_DATA,
    payload: {},
  };
};

export const branchesPatch = (branchesPatchBody, id) => {
  return {
    type: BRANCHES_PATCH,
    payload: { branchesPatchBody, id },
  };
};

export const branchesPatchSuccess = (success) => {
  return {
    type: BRANCHES_PATCH_SUCCESS,
    payload: success,
  };
};

export const branchesPatchError = (error) => {
  return {
    type: BRANCHES_PATCH_ERROR,
    payload: error,
  };
};

export const branchesPatchClearData = () => {
  return {
    type: BRANCHES_PATCH_CLEAR_DATA,
    payload: {},
  };
};

// import {
//   BRANCHES_GET_ONE,
//   BRANCHES_GET_ONE_CLEAR_DATA,
//   BRANCHES_GET_ONE_ERROR,
//   BRANCHES_GET_ONE_SUCCESS,
//   BRANCHES_PATCH,
//   BRANCHES_PATCH_CLEAR_DATA,
//   BRANCHES_PATCH_ERROR,
//   BRANCHES_PATCH_SUCCESS,
//   BRANCHES_POST,
//   BRANCHES_POST_CLEAR_DATA,
//   BRANCHES_POST_ERROR,
//   BRANCHES_POST_SUCCESS,
//   GET_BRANCHES,
//   GET_BRANCHES_CLEAR_DATA,
//   GET_BRANCHES_ERROR,
//   GET_BRANCHES_SUCCESS,
// } from "./actionTypes";
//
// export const branchesPost = (branches, history) => {
//   return {
//     type: BRANCHES_POST,
//     payload: { branches, history },
//   };
// };
//
// export const branchesPostSuccess = (success) => {
//   return {
//     type: BRANCHES_POST_SUCCESS,
//     payload: { ...success },
//   };
// };
//
// export const branchesPostError = (error) => {
//   return {
//     type: BRANCHES_POST_ERROR,
//     payload: error,
//   };
// };
//
// export const branchesPostClearData = () => {
//   return {
//     type: BRANCHES_POST_CLEAR_DATA,
//     payload: {},
//   };
// };
//
// //aqui inicia el patch
// export const branchesPatch = (branchesBody, id) => {
//   return {
//     type: BRANCHES_PATCH,
//     payload: { branchesBody, id },
//   };
// };
//
// export const branchesPatchSuccess = (success) => {
//   return {
//     type: BRANCHES_PATCH_CLEAR_DATA,
//     payload: success,
//   };
// };
//
// export const branchesPatchError = (error) => {
//   return {
//     type: BRANCHES_PATCH_ERROR,
//     payload: error,
//   };
// };
//
// export const branchesPatchClearData = (branchesBody) => {
//   return {
//     type: BRANCHES_PATCH_SUCCESS,
//     payload: {},
//   };
// };
//
// export const getBranches = (branch, history) => {
//   return {
//     type: GET_BRANCHES,
//     payload: { branch, history },
//   };
// };
//
// export const getBranchesSuccess = (success) => {
//   return {
//     type: GET_BRANCHES_SUCCESS,
//     payload: success,
//   };
// };
//
// export const getBranchesError = (error) => {
//   return {
//     type: GET_BRANCHES_ERROR,
//     payload: error,
//   };
// };
//
// export const getBranchesClearData = () => {
//   return {
//     type: GET_BRANCHES_CLEAR_DATA,
//     payload: {},
//   };
// };
//
// export const branchesGetOne = (id) => {
//   return {
//     type: BRANCHES_GET_ONE,
//     payload: id,
//   };
// };
//
// export const branchesGetOneSuccess = (success) => {
//   return {
//     type: BRANCHES_GET_ONE_SUCCESS,
//     payload: success,
//   };
// };
//
// export const branchesGetOneError = (error) => {
//   return {
//     type: BRANCHES_GET_ONE_ERROR,
//     payload: error,
//   };
// };
//
// export const branchesGetOneClearData = () => {
//   return {
//     type: BRANCHES_GET_ONE_CLEAR_DATA,
//     payload: {},
//   };
// };

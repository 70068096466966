import {
  PUT_AVATAR,
  PUT_AVATAR_SUCCESS,
  PUT_AVATAR_ERROR,
  PUT_AVATAR_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  loadingAvatar: false,
  errorPutAvatar: null,
  responsePutAvatar: null,
  loadingPutAvatar: false,
};

const AvatarApi = (state = initialState, action) => {
  switch (action.type) {
    case PUT_AVATAR:
      state = {
        ...state,
        loadingPutAvatar: true,
        errorPutAvatar: null,
        responsePutAvatar: true,
      };
      break;
    case PUT_AVATAR_SUCCESS:
      state = {
        ...state,
        errorPutAvatar: null,
        loadingPutAvatar: false,
        responsePutAvatar: action.payload,
      };
      break;
    case PUT_AVATAR_ERROR:
      state = {
        ...state,
        errorPutAvatar: action.payload,
        loadingPutAvatar: false,
        responsePutAvatar: null,
      };
      break;
    case PUT_AVATAR_CLEAR_DATA:
      state = {
        ...state,
        errorPutAvatar: null,
        loadingPutAvatar: null,
        responsePutAvatar: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AvatarApi;

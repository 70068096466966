import { call, put, takeEvery } from "redux-saga/effects";
import {
  branchesError,
  branchesPatchError,
  branchesPatchSuccess,
  branchesPostError,
  branchesPostSuccess,
  branchesSuccess,
} from "./actions";
import { BRANCHES, BRANCHES_PATCH, BRANCHES_POST } from "./actionTypes";
import {
  getBranches,
  patchBranches,
  postBranches,
} from "../../resources/helper/branches";

function* branches({ payload: { branch } }) {
  try {
    const response = yield call(
      getBranches,
      branch.clientId,
      branch.enable,
      branch.page,
      branch.limit,
      branch.name,
      branch.code,
    );
    yield put(branchesSuccess(response));
  } catch (error) {
    yield put(branchesError(error));
  }
}

function* branchesPost({ payload: { branchesBody } }) {
  try {
    const response = yield call(postBranches, branchesBody);
    yield put(branchesPostSuccess(response));
  } catch (error) {
    yield put(branchesPostError(error));
  }
}

function* branchesPatch({ payload: { branchesPatchBody, id } }) {
  try {
    const response = yield call(patchBranches, branchesPatchBody, id);
    yield put(branchesPatchSuccess(response));
  } catch (error) {
    yield put(branchesPatchError(error));
  }
}

function* branchesSaga() {
  yield takeEvery(BRANCHES, branches);
  yield takeEvery(BRANCHES_POST, branchesPost);
  yield takeEvery(BRANCHES_PATCH, branchesPatch);
}

export default branchesSaga;

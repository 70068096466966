import React from "react";
import {
  breadcrumb,
  BreadcrumbAFY,
  FormApplicantSecuredDeclarationAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";

interface ClientsViewProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
  showInfo?: boolean;
  tableHeight?: string;
  filters?: any[];
  stepProgress?: any;
  resumeDescription?: string;
  resumeValue?: string;
  nextLabel?: string;
  previousLabel?: string;
  onClickPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (data: any) => void;
  nextDisabled?: boolean;
  showPrevious?: boolean;
  hideFilter?: boolean;
  steps?: any;
}

export const ApplicantDeclarationView = (props: ClientsViewProps) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadcrumbs}
        titlePage={props.title}
        steps={props.steps}
        hideStep={false}
        stepsTop={"-20px"}
      />
      
      <FormApplicantSecuredDeclarationAFY
        title={""}
        showNext={true}
        nextLabel={"Siguiente"}
        buttonPosition={"top"}
        isCollapsible={false}
        minHeightGrid={"calc(100vh - 73px)"}

        previousLabel={"Anterior"}
        showInfo={true}
        hideFilter={props.hideFilter}
        nextDisabled={props.nextDisabled}
        resumeDescription={props.resumeDescription}
        resumeValue={props.resumeValue}
        titleQuestionnaire={""}
        showNextQuestionnaire={true}
        nextText={"Guardar"}
        collapsed={false}
        onClickNext={props.onClickNext}
        data={[
          {
            "label": "¿Ha sufrido alguna vez de desordenes nerviosos, enfermedades del corazón, del sistema respitatorio, diabetes, cáncer, enfermedades de riñones, enfermedades o transtornos del hígado, úlceras, presión alta o baja? ",
            "type": "toggle",
            "name": "chronic_diseases"
          },
          {
            "label": "¿Está usted actualmente embarazada (mujer)?",
            "type": "toggle",
            "name": "pregnant",
            "childrenDescription": "",
            "children": [
              {
                "label": "Tiempo de gestación",
                "type": "number",
                "description": "Meses",
                "name": "pregnant_time",
               
              },{
                "label": "Su embarazo transcurre normalmente",
                "type": "toggle",
                "name": "pregnant_normal",
              }
            ]

          },
          {
            "label": "¿Usa usted o ha usado drogas ilegales o sustancias controladas?",
            "type": "toggle",
            "name": "drug_use",
            "childrenDescription": "Especifique el tiempo de consumo",
            "children": [
              {
                "label": "Mucho tiempo:",
                "type": "radio",
                "name": "drug_use_time",
                "radioOptions": [{
                  "id": "1",
                  "name": "Less than 1 year",
                }, {
                  "id": "2",
                  "name": "1-5 years",
                }]
              },
            ]
          },
          {
            "label": "¿Ha tenido resultados positivos de una prueba del HIV? ¿Padece o ha recibido tratamiento o consejo para SIDA, complejo relacionado al SIDA o cualquier otro desorden inmunológico?",
            "type": "toggle",
            "name": "hiv"
          },
          {
            "label": "¿Fuma?",
            "type": "toggle",
            "name": "smoking",
            "childrenDescription": "En caso afirmativo, ¿Cuántos cigarrillos fuma al día?",
            "childrenRows": 3,
            "children": [
              {
                "label": "",
                "type": "radio",
                "description": "",
                "name": "smoking_time",
                radioOptions: [
                  {
                      id: "smoke_less_11",
                      name: "< 11"
                  },
                  {
                      id: "smoke_11_20",
                      name: "11-20"
                  },
                  {
                      id: "smoke_21_40",
                      name: "21-40"
                  },
                  {
                      id: "smoke_more_40",
                      name: "> 40"
                  }
              ]
              },
            ]
          },
          {
            "label": "¿Ha tenido que recibir tratamiento médico en hospitalización o unidad de cuidados intensivos por el COVID? En caso afirmativo, ¿Requirió la ventilación mecánica?",
            "type": "toggle",
            "name": "covid_treatment"
          },
          {
            "label": "¿A la fecha, mantiene alguna enfermedad producto del COVID?",
            "type": "toggle",
            "name": "covid_disease"
          }
        ]}
        filters={props.filters}
      />
    </React.Fragment>
  );
};

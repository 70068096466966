import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { BranchesRequest } from "../../model/branches";
import LocalStorageService from "../../services/localStorage";
//import { useBranchesPatch  } from "../../fnx/branches/branchesPatch";
//import { useBranchesPost  } from "../../fnx/branches/branchesPost";
import {
  deleteEntity,
  deleteEntityClearData,
} from "../../store/delete/actions";

import {
  CardProps,
  MenuItem,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { useCataloguesGetAll } from "../../fnx/catalogue/catalogueGetAll";
import { Catalogue } from "../../model/catalogue";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const CataloguesAdmin = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState<CardProps[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [typeCatalog, setTypeCatalog] = useState("");
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [bodyRequest, setBodyRequest] = useState<BranchesRequest>({
    data: { clientId: "" },
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    Name: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseCatalogues, errorCatalogues, loadingCatalogues } =
    useCataloguesGetAll(trigger, "CATALOG_SEXO", clientId);

  // const { responsePostBranches, errorPostBranches, loadingPostBranches } = useBranchesPost(triggerPost,bodyRequest);
  //const { responsePatchBranches, errorPatchBranches, loadingPatchBranches }  = useBranchesPatch (triggerPatch,bodyRequest);

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const callCatalogues = () => {
    setTrigger(!trigger);
  };

  useEffect(() => {
    callCatalogues();
  }, []);

  useEffect(() => {
    if (responseCatalogues && responseCatalogues?.length > 0) {
      const listCatalogues: CardProps[] = responseCatalogues.map(
        (item: Catalogue) => ({
          id: item.id,
          title: item.description,
          code: item.type,
          clientId: item.clientId,
        }),
      );
      setList(listCatalogues);
    }
  }, [responseCatalogues]);

  //  useEffect(() => {
  //  setTriggerPost(false);
  //if (responsePostBranches && responsePostBranches?.data) {
  // setSeeToast({
  //   show: true,
  //    message: "Creado con éxito",
  //    type: typeAlert.success,
  //  });
  //  setTimeout(() => {
  //    setSeeToast({
  //      show: false,
  //         message: "",
  //         type: typeAlert.success,
  //       });
  //     }, 3000);
  //     setOpenModal(false);
  //   } else if (errorPostBranches) {
  //     setSeeToast({
  //       show: true,
  //       message: errorPostBranches?.message.message,
  //       type: typeAlert.error,
  //     });
  //     setTimeout(() => {
  //       setSeeToast({
  //         show: false,
  //         message: "",
  //         type: typeAlert.error,
  //       });
  //     }, 3000);
  //   }
  // }, [responsePostBranches, errorPostBranches]);

  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  // useEffect(() => {
  //   setTriggerPatch(false);
  //   if (responsePatchBranches && responsePatchBranches.data) {
  //     setSeeToast({
  //       show: true,
  //       message: "Actualizado con éxito",
  //       type: typeAlert.success,
  //     });
  //     setTimeout(() => {
  //       setSeeToast({
  //         show: false,
  //         message: "",
  //         type: typeAlert.success,
  //       });
  //     }, 3000);
  //     setOpenModal(false);
  //     setTrigger(true);
  //     //dispatch(patchSponsorClearData());
  //   } else if (errorPatchBranches) {
  //     setSeeToast({
  //       show: true,
  //       message: errorPatchBranches?.message,
  //       type: typeAlert.error,
  //     });
  //     setTimeout(() => {
  //       setSeeToast({
  //         show: false,
  //         message: "",
  //         type: typeAlert.error,
  //       });
  //     }, 3000);
  //   }
  // }, [responsePatchBranches, errorPatchBranches]);

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      Id: row.id,
      Name: row.title,
      clientId: row.clientId,
    });
  };

  const handleSubmit = (dataItem: any) => {
    // if (isUpdate) {
    //   let id = dataForm.Id;
    //   setBodyRequest({
    //     data: {
    //       clientId: dataItem.clientId,
    //       name: dataItem.Name,
    //       description
    //
    //     },
    //   });
    //   setTriggerPatch(true);
    // } else {
    //   let body = {
    //     clientId: dataItem.clientId,
    //     name: dataItem.Name,
    //   };
    //   setBodyRequest({
    //     data: {
    //       clientId: clientId,
    //       description: dataItem.Name,
    //       code: dataItem.Name,
    //       maxCharacter: 100,
    //       minCharacter: 10,
    //       isNumeric: true,
    //     },
    //   });
    //   setTriggerPost(true);
    // }
    // setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      Id: "",
      Name: "",
      clientId: "",
      Image: "",
    });
  };

  const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(item);
    } else if (opt.id === "2") {
      setitemModal(item);
      setOpenModalConfirm(true);
    } else if (opt.id === "3") {
      setitemModal(item);
      setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  const handleDelete = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
    dispatch(
      deleteEntity({
        id: itemModal.id,
        entity: "BranchEntity",
        idUser: "d2bd577e-c8c6-4519-b30a-b8e674c42952",
      }),
    );
  };

  const handleClone = () => {};

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }

      <View
        title="Catalogos Generales"
        list={list}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onSaveData={handleSubmit}
        onClickAdd={handleOnClickAdd}
        onClickButtonOptionsDropdown={(opt?: any, item?: any): void => {
          handleOnClickButtonOptionsDropdown(opt, item);
        }}
      />
    </React.Fragment>
  );
};

export default CataloguesAdmin;

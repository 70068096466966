import { call, put, takeEvery } from "redux-saga/effects";
import { getPlanBenefit, postPlanBenefit, patchPlanBenefit } from "../../resources/helper/benefit";
import { PLAN_BENEFIT, PLAN_BENEFIT_CREATE, PLAN_BENEFIT_DELETE, PLAN_BENEFIT_UPDATE } from "./actionTypes";
import { 
  planBenefitError, 
  planBenefitSuccess, 
  planBenefitCreateError,
  planBenefitCreateSuccess,
  planBenefitDeleteError,
  planBenefitDeleteSuccess,
  planBenefitUpdateSuccess,
  planBenefitUpdateError } from "./actions";

function* planBenefit({ payload: { planBenefit } }) {
  try {
    const response = yield call(
      getPlanBenefit,
      planBenefit.enable,
      planBenefit.page,
      planBenefit.limit,
      planBenefit.planId,
    );
    yield put(planBenefitSuccess(response));
  } catch (error) {
    yield put(planBenefitError(error));
  }
}
/* Add the function to create the benefit */
function* createPlanBenefit({ payload: { planBenefit } }) {
  try {
    const response = yield call(postPlanBenefit, planBenefit);
    yield put(planBenefitCreateSuccess(response));
  } catch (error) {
    yield put(planBenefitCreateError(error));
  }
}

/* Add the function to delete the benefit */
function* deletePlanBenefit({ payload: { id } }) {
  try {
    const response = yield call(patchPlanBenefit, id);
    yield put(planBenefitDeleteSuccess(response));
  } catch (error) {
    yield put(planBenefitDeleteError(error));
  }
}

/* Add the function to update the benefit */
function* updatePlanBenefit({ payload: { planBenefit, id } }) {
  try {
    const response = yield call(patchPlanBenefit, planBenefit, id);
    yield put(planBenefitUpdateSuccess(response));
  } catch (error) {
    yield put(planBenefitUpdateError(error));
  }
}




  

function* planBenefitSaga() {
  yield takeEvery(PLAN_BENEFIT, planBenefit);
  yield takeEvery(PLAN_BENEFIT_CREATE, createPlanBenefit);
  yield takeEvery(PLAN_BENEFIT_DELETE, deletePlanBenefit);
  yield takeEvery(PLAN_BENEFIT_UPDATE, updatePlanBenefit);
}

export default planBenefitSaga;

export const BRANCHES = "BRANCHES";
export const BRANCHES_SUCCESS = "BRANCHES_SUCCESS";
export const BRANCHES_ERROR = "BRANCHES_ERROR";
export const BRANCHES_CLEAR_DATA = "BRANCHES_CLEAR_DATA";

export const BRANCHES_POST = "BRANCHES_POST";
export const BRANCHES_POST_SUCCESS = "BRANCHES_POST_SUCCESS";
export const BRANCHES_POST_ERROR = "BRANCHES_POST_ERROR";
export const BRANCHES_POST_CLEAR_DATA = "BRANCHES_POST_CLEAR_DATA";

export const BRANCHES_PATCH = "BRANCHES_PATCH";
export const BRANCHES_PATCH_SUCCESS = "BRANCHES_PATCH_SUCCESS";
export const BRANCHES_PATCH_ERROR = "BRANCHES_PATCH_ERROR";
export const BRANCHES_PATCH_CLEAR_DATA = "BRANCHES_PATCH_CLEAR_DATA";

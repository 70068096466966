import {
  GET_CATALOGUES,
  GET_CATALOGUES_CLEAR_DATA,
  GET_CATALOGUES_ERROR,
  GET_CATALOGUES_SUCCESS,
} from "./actionType";

const initialState = {
  errorCatalogues: null,
  responseCatalogues: null,
  loadingCatalogues: false,
};

const catalogues = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOGUES:
      state = {
        ...state,
        loadingCatalogues: true,
        errorCatalogues: null,
        responseCatalogues: true,
      };
      break;
    case GET_CATALOGUES_SUCCESS:
      state = {
        ...state,
        errorCatalogues: null,
        loadingCatalogues: false,
        responseCatalogues: action.payload,
      };
      break;
    case GET_CATALOGUES_ERROR:
      state = {
        ...state,
        errorCatalogues: action.payload,
        loadingCatalogues: false,
        responseCatalogues: null,
      };
      break;
    case GET_CATALOGUES_CLEAR_DATA:
      state = {
        ...state,
        errorCatalogues: null,
        loadingCatalogues: null,
        responseCatalogues: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default catalogues;

import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../salesCore/loginPage/login";
import PasswordReset from "../salesCore/passwordReset/passwordReset";
import ConfirmEmail from "../salesCore/confirmEmail/confirmEmail";
import ConfirmPasswordReset from "../salesCore/confirmPasswordReset/confirmPasswordReset";


export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="passwordReset" element={<PasswordReset />} />
      <Route path="confirmEmail" element={<ConfirmEmail />} />
      <Route path="confirmPasswordReset" element={<ConfirmPasswordReset />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

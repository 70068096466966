import { call, put, takeEvery } from "redux-saga/effects";
import { insuranceError, insuranceSuccess } from "./actions";
import { getInsurance } from "../../resources/helper/helper";
import { INSURANCE } from "./actionTypes";

function* insurance({ payload: { insurance } }) {
  try {
    const response = yield call(
      getInsurance,
      insurance.enable,
      insurance.page,
      insurance.limit,
      insurance.sponsorId,
      insurance.descriptionInsurance,
      insurance.clientId,
      insurance.projectType,
    );
    yield put(insuranceSuccess(response));
  } catch (error) {
    yield put(insuranceError(error));
  }
}

function* insuranceSaga() {
  yield takeEvery(INSURANCE, insurance);
}

export default insuranceSaga;

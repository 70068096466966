import {
  POLICY_CANCEL,
  POLICY_CANCEL_CLEAR_DATA,
  POLICY_CANCEL_ERROR,
  POLICY_CANCEL_SUCCESS,
  POLICY_CLEAR_DATA,
  POLICY_CONFIRM,
  POLICY_CONFIRM_CLEAR_DATA,
  POLICY_CONFIRM_ERROR,
  POLICY_CONFIRM_SUCCESS,
  POLICY_CREATE,
  POLICY_CREATE_CLEAR_DATA,
  POLICY_CREATE_ERROR,
  POLICY_CREATE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPolicy: null,
  responsePolicy: null,
  loadingPolicy: false,
  errorCreatePolicy: null,
  responseCreatePolicy: null,
  loadingCreatePolicy: false,
  errorPolicyConfirm: null,
  responsePolicyConfirm: null,
  loadingPolicyConfirm: false,
  errorCancelPolicy: null,
  responseCancelPolicy: null,
  loadingCancelPolicy: false,
};

const Policy = (state = initialState, action) => {
  switch (action.type) {
    case POLICY_CREATE:
      state = {
        ...state,
        loadingCreatePolicy: true,
        errorCreatePolicy: null,
        responseCreatePolicy: true,
      };
      break;
    case POLICY_CREATE_SUCCESS:
      state = {
        ...state,
        errorCreatePolicy: null,
        loadingCreatePolicy: false,
        responseCreatePolicy: action.payload,
      };
      break;
    case POLICY_CREATE_ERROR:
      state = {
        ...state,
        errorCreatePolicy: action.payload,
        loadingCreatePolicy: false,
        responseCreatePolicy: null,
      };
      break;
    case POLICY_CREATE_CLEAR_DATA:
      state = {
        ...state,
        errorCreatePolicy: null,
        loadingCreatePolicy: null,
        responseCreatePolicy: false,
      };
      break;
    case POLICY_CANCEL:
      state = {
        ...state,
        loadingCancelPolicy: true,
        errorCancelPolicy: null,
        responseCancelPolicy: true,
      };
      break;
    case POLICY_CANCEL_SUCCESS:
      state = {
        ...state,
        errorCancelPolicy: null,
        loadingCancelPolicy: false,
        responseCancelPolicy: action.payload,
      };
      break;
    case POLICY_CANCEL_ERROR:
      state = {
        ...state,
        errorCancelPolicy: action.payload,
        loadingCancelPolicy: false,
        responseCancelPolicy: null,
      };
      break;
    case POLICY_CANCEL_CLEAR_DATA:
      state = {
        ...state,
        errorCancelPolicy: null,
        loadingCancelPolicy: null,
        responseCancelPolicy: false,
      };
      break;
    case POLICY_CLEAR_DATA:
      state = {
        ...state,
        errorPolicy: null,
        loadingPolicy: null,
        responsePolicy: false,
      };
      break;
    case POLICY_CONFIRM:
      state = {
        ...state,
        loadingPolicyConfirm: true,
        errorPolicyConfirm: null,
        responsePolicyConfirm: true,
      };
      break;
    case POLICY_CONFIRM_SUCCESS:
      state = {
        ...state,
        errorPolicyConfirm: null,
        loadingPolicyConfirm: false,
        responsePolicyConfirm: action.payload,
      };
      break;
    case POLICY_CONFIRM_ERROR:
      state = {
        ...state,
        errorPolicyConfirm: action.payload,
        loadingPolicyConfirm: false,
        responsePolicyConfirm: null,
      };
      break;
    case POLICY_CONFIRM_CLEAR_DATA:
      state = {
        ...state,
        errorPolicyConfirm: null,
        loadingPolicyConfirm: null,
        responsePolicyConfirm: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Policy;

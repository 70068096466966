import React from "react";
import "./style.css";
import insurance from "../../assets/images/insurance.png";
import {
  AdminProductsAFY,
  CardProps,
  icons,
  itemPlanProps,
  ListDropdownMultipleProps,
  MenuItem,
  sizesIcon,
  sizesSelect,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { insurersView, optionsSelect } from "./data";
import { ModalDelete } from "./resources/modalDelete";
import { Project } from "../../model/project";
import { ModalClone } from "./resources/modalClone";

export const ViewInsurances = (props: {
  permissions: any;
  insurancesList: CardProps[];
  insuranceTypeList: any;
  sponsorList: any;
  titlePage: string;
  openModal: boolean;
  openModalConfirmDelete: boolean;
  dataForm?: any;
  modalTitle: string;
  clickView: (view: MenuItem) => void;
  onSubmitClone: () => void;
  onClose: () => void;
  handleOpenModal: () => void;
  handleOpenModalConfirm: () => void;
  onUpload: () => void;
  ///
  onClickDetails?: (event: itemPlanProps) => void;
  onClickAdd: () => void;
  insuranceTypeOptions: ListDropdownMultipleProps[];
  sponsorOptions: ListDropdownMultipleProps[];
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;
  optionProduct?: (event: MenuItem, card: CardProps) => void;
  itemCard?: CardProps;
  setOpenModalConfirmDelete?: (value: boolean | (() => boolean)) => void;
  openModalConfirmClone?: boolean;
  setOpenModalConfirmClone?: (value: boolean | (() => boolean)) => void;
  onClickDelete?: (item: Project) => void;
  insurancePage: number;
  insuranceLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
}) => {
  const menuOptions = [
    {
      disabled: props.permissions?.detail ? false : true,
      id: "1",
      name: "Planes",
      divider: true,
      avatar: {
        image: insurance,
      },
    },
    {
      disabled: props.permissions?.update ? false : true,
      id: "2",
      name: "Modificar",
      divider: false,
      icon: {
        color: "#006ac6",
        icon: icons.Edit,
        size: sizesIcon.standard,
      },
    },
    {
      disabled: props.permissions?.clone ? false : true,
      id: "3",
      name: "Clonar",
      divider: false,
      icon: {
        color: "#006ac6",
        icon: icons.FileCopy,
        size: sizesIcon.standard,
      },
    },
    {
      disabled: props.permissions?.delete ? false : true,
      id: "4",
      name: "Eliminar",
      divider: false,
      icon: {
        color: "#006ac6",
        icon: icons.DeleteForever,
        size: sizesIcon.standard,
      },
    },
  ];
  return (
    <>
      <ModalClone
        openModalConfirmClone={props.openModalConfirmClone}
        setOpenModalConfirmClone={props.setOpenModalConfirmClone}
      />
      <ModalDelete
        openModalConfirmDelete={props.openModalConfirmDelete}
        setOpenModalConfirmDelete={props.setOpenModalConfirmDelete}
        itemCard={props.itemCard}
        onClickDelete={props.onClickDelete}
      />

      <AdminProductsAFY
        title="Producto"
        permissions={props.permissions}
        titleSelectSponsor="Sponsor"
        titleSelectInsurance="Tipo de producto"
        titleClearButton="Limpiar filtros"
        clientLabel="Cliente"
        textFilterSearch="Buscar"
        textFilterSearchDrop="Buscar"
        textBackSearch="Volver"
        backgroundColorSearch="#F6F7FB"
        titleColor="#3A56A1"
        colorClientLabel="#3A56A1"
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        seeDetailColor={"#3A56A1"}
        heightAddNew="113px"
        heightAddNewList="68px"
        heightCard="113px"
        heightCardList="68px"
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        selectSize={sizesSelect.small}
        avatarVariant={variantsAvatar.rounded}
        selectVariant={variantsTextField.outlined}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        disabledSelect={true}
        onClickButtonOptionsDropdown={function noRefCheck() {}}
        onClickButtonView={function noRefCheck() {}}
        onClickContextMenu={props.optionProduct}
        onClickSeeDetails={props.onClickDetails}
        onCloseSearch={props.onCloseSearch}
        onChangeData={props.filterStatus}
        onChangeSearch={props.searchFilter}
        onChangeSelect={function noRefCheck() {}}
        onBlurSearch={() => {}}
        onClickAdd={props.onClickAdd}
        insurersView={insurersView}
        menuItem={menuOptions}
        data={props.insurancesList}
        sponsorOptions={props.sponsorOptions}
        insuranceOptions={props.insuranceTypeOptions}
        optionsSelect={optionsSelect}
        buttonActionOptions={[]}
        iconBackgroundColor={""}
        textColor={""}
        titlePagination={"Registros por página"}
        countPagination={props.countPagination}
        rowsPerPage={props.insuranceLimit}
        onChangePage={props.onChangePage}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        pagePagination={props.insurancePage}
      />
    </>
  );
};

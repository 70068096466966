export const formattedDate = (data: string | undefined): string => {
  if (data === undefined) return "undefined";
  if (Number.isNaN(Date.parse(data))) return "NA";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return new Date(data).toLocaleDateString("es-ES", options);
};

export const formattedDateWithTime = (data: string | undefined): string => {
  if (data === undefined) return "undefined";
  if (Number.isNaN(Date.parse(data))) return "NA";

  const date = new Date(data);
  return date.toISOString().split("T")[0] + "T00:00:00.000Z";
};

export const newFormattedDate = (data: string | undefined): string => {
  if (data) {
    const partes = data.split("-");
    const year = parseInt(partes[0], 10);
    const month = parseInt(partes[1], 10) - 1;
    const day = parseInt(partes[2], 10);

    const date = new Date(Date.UTC(year, month, day));

    const formattedDay = ("0" + date.getUTCDate()).slice(-2);
    const formattedMonth = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const formattedYear = date.getUTCFullYear();

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  } else {
    return "";
  }
};

export const newFormattedDateYYMMDD = (data: string | undefined): string => {
  if (data) {
    const parts = data.split("-");
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      const isoFormattedDate = `${year}-${month}-${day}`;
      const date = new Date(isoFormattedDate);
      if (!isNaN(date.getTime())) {
        return isoFormattedDate;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const formattedDateYYMMDD = (data: string | undefined): string => {
  if (data === undefined) return "undefined";
  if (Number.isNaN(Date.parse(data))) return "NA";

  // Convertir la fecha a formato ISO y extraer solo la parte de la fecha
  return new Date(data).toISOString().split("T")[0];
};

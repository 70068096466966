import {
  POLICY_ERROR,
  POLICY,
  POLICY_CREATE,
  POLICY_SUCCESS,
  POLICY_CREATE_SUCCESS,
  POLICY_CREATE_ERROR,
  POLICY_CLEAR_DATA,
  POLICY_CREATE_CLEAR_DATA,
  POLICY_CONFIRM,
  POLICY_CONFIRM_SUCCESS,
  POLICY_CONFIRM_ERROR,
  POLICY_CONFIRM_CLEAR_DATA,
  POLICY_CANCEL,
  POLICY_CANCEL_SUCCESS,
  POLICY_CANCEL_ERROR,
  POLICY_CANCEL_CLEAR_DATA,
} from "./actionTypes";

export const policyCreate = (policy, history) => {
  return {
    type: POLICY_CREATE,
    payload: { policy, history },
  };
};

export const policyCreateSuccess = (success) => {
  return {
    type: POLICY_CREATE_SUCCESS,
    payload: success,
  };
};

export const policyCreateError = (error) => {
  return {
    type: POLICY_CREATE_ERROR,
    payload: error,
  };
};

export const policyClearData = () => {
  return {
    type: POLICY_CLEAR_DATA,
    payload: {},
  };
};

export const policyConfirm = (policy, history) => {
  return {
    type: POLICY_CONFIRM,
    payload: { policy, history },
  };
};

export const policyConfirmSuccess = (success) => {
  return {
    type: POLICY_CONFIRM_SUCCESS,
    payload: success,
  };
}

export const policyConfirmError = (error) => {
  return {
    type: POLICY_CONFIRM_ERROR,
    payload: error,
  };
}

export const policyConfirmClearData = () => {
  return {
    type: POLICY_CONFIRM_CLEAR_DATA,
    payload: {},
  };
}



export const policyCreateClearData = () => {
  return {
    type: POLICY_CREATE_CLEAR_DATA,
    payload: {},
  };
};

export const policyCancel = (policy, history) => {
  return {
    type: POLICY_CANCEL,
    payload: { policy, history },
  };
}

export const policyCancelSuccess = (success) => {
  return {
    type: POLICY_CANCEL_SUCCESS,
    payload: success,
  };
}

export const policyCancelError = (error) => {
  return {
    type: POLICY_CANCEL_ERROR,
    payload: error,
  };
}

export const policyCancelClearData = () => {
  return {
    type: POLICY_CANCEL_CLEAR_DATA,
    payload: {},
  };
}


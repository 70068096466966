import { TFunction } from "i18next";

import LocalStorageService from "../../../services/localStorage";
import { Steps } from "../../../enum/steps";
export const DataForm = (tl: TFunction) => {

  return [
    {
      children: [
        {
          label: tl("financialInformation.dataForm.Option_1"),
          name: "economic_activity",
          selectOptions: [
            {
              id: "Empleado-Asalariado",
              name: "Empleado/Asalariado",
            },
            {
              id: "Propietario-Socio",
              name: "Propietario/Socio",
            },
            {
              id: "Jubilado-Pensionado",
              name: "Jubilado/Pensionado",
            },
            {
              id: "Inversionista-Prestamista",
              name: "Inversionista/Prestamista",
            },
            {
              id: "Estudiante",
              name: "Estudiante",
            },
            {
              id: "Independiente",
              name: "Independiente",
            },
            {
              id: "Ama-de-casa",
              name: "Ama de casa",
            },
            {
              id: "otro",
              name: "Otro",
            },
          ],
          type: "select",
        },
        {
          label: tl("financialInformation.dataForm.Option_2"),
          name: "monthly_income_range",
          selectOptions: [
            {
              id: "20",
              name: "Menos de RD $20 mil",
            },
            {
              id: "20-50",
              name: "RD $20 mil - RD $50 mil",
            },
            {
              id: "50-100",
              name: "RD $50 mil - RD $100 mil",
            },
            {
              id: "100",
              name: "Más de RD $100 mil",
            },
          ],
          type: "select",
        },
        {
          label: tl("financialInformation.dataForm.Option_3"),
          name: "monthly_income",
          type: "number",
        },
      ],
      childrenRows: 3,
      label: "",
      name: "",
      type: "multi-column",
    },
    {
      name: "public-resources",
      label: tl("financialInformation.dataForm.Question_1"),
      type: "toggle",
      children: [
        {
          name: "public-resources-text",
          helperText: tl("financialInformation.dataForm.helperText"),
          label: "",
          rows: 12,
          type: "text",
        },
      ],
    },
    {
      name: "public-power",
      label: tl("financialInformation.dataForm.Question_2"),
      type: "toggle",
      children: [
        {
          name: "public-power-text",
          helperText: tl("financialInformation.dataForm.helperText"),
          label: "",
          rows: 12,
          type: "text",
        },
      ],
    },
    {
      name: "public-influence",
      label: tl("financialInformation.dataForm.Question_3"),
      type: "toggle",
      children: [
        {
          name: "public-influence-text",
          helperText: tl("financialInformation.dataForm.helperText"),
          label: "",
          type: "text",
        },
      ],
    },
    {
      name: "affirmative-questions",
      label: tl("financialInformation.dataForm.Question_4"),
      type: "toggle",
      children: [
        {
          name: "affirmative-questions-text",
          helperText: tl("financialInformation.dataForm.helperText"),
          label: "",
          type: "text",
        },
      ],
    },
  ];
};

export const filtersData = (tl: TFunction) => {
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  return [
    {
      name: tl("financialInformation.dataFilter.Product"),
      label: localStorageService.getStepStored().steps[Steps.insurance].name,
      type: "label",
    },
    /* {
      name: tl("financialInformation.dataFilter.TypeOfPlan"),
      label: "Vida",
      type: "label",
    },*/
    {
      name: tl("financialInformation.dataFilter.Birthdate"),
      label: new Date(localStorageService.getStepStored().steps[Steps.initialInfo].initialInfo.dateBirth).toLocaleDateString('es-GT'),
      type: "label",
    },
    {
      name: tl("financialInformation.dataFilter.Gender"),
      label: localStorageService.getStepStored().steps[Steps.initialInfo].initialInfo.sexType.name,
      type: "label",
    },
    {
      name: tl("financialInformation.dataFilter.PolicyDuration"),
      label: localStorageService.getStepStored().steps[Steps.initialInfo].initialInfo.policyDuration+" años",
      type: "label",
    },
    {
      name: tl("financialInformation.dataFilter.SumInsured"),
      label: `RD$ ${localStorageService.getStepStored().steps[Steps.initialInfo].initialInfo.premium}`,
      type: "label",
    },
  ];
};

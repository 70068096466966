import styled from "styled-components";

export const ContainerAlert = styled.div`
  margin-bottom: 15px;
`;

export const Container = styled.div`
  width: 100%;
  margin-left: -15px;
  margin-bottom: -13px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  gap: 7px;
  margin: 0;
`;

export const ContainerIcon = styled.div`
  margin-top: 7px;
`;

import React from "react";
import {
  LoadingAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { BranchDashboardView } from "./branchDashboardView";

export const BranchDashboard = () => {
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <LoadingAFY
        loading={loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />

      <BranchDashboardView />
    </>
  );
};
import {
  POST_COLLECTION,
  POST_COLLECTION_SUCCESS,
  POST_COLLECTION_ERROR,
  POST_COLLECTION_CLEAR_DATA,
} from "./actionTypes";



export const postCollection = (data, history) => {
  return {
    type: POST_COLLECTION,
    payload: { data, history },
  };
};

export const postCollectionSuccess = (success) => {
  return {
    type: POST_COLLECTION_SUCCESS,
    payload: success,
  };
};

export const postCollectionError = (error) => {
  return {
    type: POST_COLLECTION_ERROR,
    payload: error,
  };
};

export const postCollectionClearData = () => {
  return {
    type: POST_COLLECTION_CLEAR_DATA,
    payload: {},
  };
};
import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  AdminSponsorAFY,
  ButtonAFY,
  CardProps,
  FormSponsorAFY,
  MenuItem,
  ModalComponentAFY,
  sizes,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions, identificationTypeView } from "./data";

export const View = (props: {
  title: string;
  accountTypeTitle: string;
  list: CardProps[];
  openModal: boolean;
  openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  onSaveData: any;
  loadingPost: any;
  dataForm: any;
  modalTitle: string;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitClone: () => void;

  onClose: () => void;
  onCloseConfirm: () => void;
  onCloseCloneModal: () => void;

  handleOpenModal: () => void;
  handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;

  onClickAdd: () => void;
  onClickButtonOptionsDropdown: () => void;
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        modalWidth={490}
        border={0}
      >
        <FormSponsorAFY
          sponsorTitle="Agregar"
          dataForm={props.dataForm}
          onBlurInput={() => {}}
          onChangeInput={function noRefCheck() {}}
          onChangeSelect={function noRefCheck() {}}
          onChangeUpload={function noRefCheck() {}}
          onImage={function noRefCheck() {}}
          onSaveData={props.onSaveData}
          onUpload={() => {}}
        />
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseConfirm}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Eliminar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Eliminar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={props.onCloseConfirm}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, cancelar"}
                backgroundColor="red"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalCloneConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseCloneModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Clonar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Clonar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={props.onCloseCloneModal}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, Clonar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmitClone();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <AdminSponsorAFY
        title={props.title}
        insurersView={identificationTypeView}
        buttonActionOptions={buttonActionOptions}
        data={props.list}
        iconBackgroundColor="#ffffff"
        textColor="#3A56A1"
        onClickAdd={props.onClickAdd}
        onClickButtonOptionsDropdown={props.onClickButtonOptionsDropdown}
        onClickButtonView={(option: MenuItem) => {
          props.clickView && props.clickView(option);
        }}
      />
    </>
  );
};

import { call, put, takeEvery } from "redux-saga/effects";
import { countriesSuccess, countriesError } from "./actions";
import { getCountries } from "../../resources/helper/helper";
import { COUNTRIES } from "./actionTypes";

function* countries({ payload: { countries } }) {
  try {
    const response = yield call(
      getCountries,
      countries.enable,
      countries.page,
      countries.limit,
    );
    yield put(countriesSuccess(response));
  } catch (error) {
    yield put(countriesError(error));
  }
}

function* countriesSaga() {
  yield takeEvery(COUNTRIES, countries);
}

export default countriesSaga;

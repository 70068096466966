import {
  REGION,
  REGION_SUCCESS,
  REGION_ERROR,
  REGION_CLEAR_DATA,
  REGION_GET_ONE,
  REGION_GET_ONE_SUCCESS,
  REGION_GET_ONE_ERROR,
  REGION_GET_ONE_CLEAR_DATA,
  REGION_POST ,
  REGION_POST_SUCCESS,
  REGION_POST_ERROR,
  REGION_POST_CLEAR_DATA,
  REGION_PATCH,
  REGION_PATCH_SUCCESS,
  REGION_PATCH_ERROR,
  REGION_PATCH_CLEAR_DATA,
  } from "./actionTypes";

export const region = (region, history) => {
  return {
    type: REGION,
    payload: { region, history },
  };
};

export const regionSuccess = (success, level) => {
  return {
    type: REGION_SUCCESS,
    payload: { ...success, level },
  };
};

export const regionError = (error) => {
  return {
    type: REGION_ERROR,
    payload: error,
  };
};

export const regionClearData = () => {
  return {
    type: REGION_CLEAR_DATA,
    payload: {},
  };
};

export const regionGetOne = (id) => {
  return {
    type: REGION_GET_ONE,
    payload: id,
  };
};

export const regionGetOneSuccess = (success) => {
  return {
    type: REGION_GET_ONE_SUCCESS,
    payload: success,
  };
};

export const regionGetOneError = (error) => {
  return {
    type: REGION_GET_ONE_ERROR,
    payload: error,
  };
};

export const regionGetOneClearData = () => {
  return {
    type: REGION_GET_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const regionPost = (data) => {
  return {
    type: REGION_POST,
    payload: data,
  };
};

export const regionPostSuccess = (success) => {
  return {
    type: REGION_POST_SUCCESS,
    payload: success,
  };
};

export const regionPostError = (error) => {
  return {
    type: REGION_POST_ERROR,
    payload: error,
  };
};

export const regionPostClearData = () => {
  return {
    type: REGION_POST_CLEAR_DATA,
    payload: {},
  };
};

export const regionPatch = (data) => {
  return {
    type: REGION_PATCH,
    payload:  data ,
  };
};

export const regionPatchSuccess = (success) => {
  return { 
    type: REGION_PATCH_SUCCESS,
    payload: success,
  };
};

export const regionPatchError = (error) => {
  return {
    type: REGION_PATCH_ERROR,
    payload: error,
  };
};

export const regionPatchClearData = () => {
  return {
    type: REGION_PATCH_CLEAR_DATA,
    payload: {},
  };
};
import {
  PREMIUM_EVAL_OPTION,
  PREMIUM_EVAL_OPTION_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_ERROR,
  PREMIUM_EVAL_OPTION_SUCCESS,
  PREMIUM_EVAL_OPTION_GET_ONE,
  PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS,
  PREMIUM_EVAL_OPTION_GET_ONE_ERROR,
  PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_POST,
  PREMIUM_EVAL_OPTION_POST_SUCCESS,
  PREMIUM_EVAL_OPTION_POST_ERROR,
  PREMIUM_EVAL_OPTION_POST_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_PATCH,
  PREMIUM_EVAL_OPTION_PATCH_SUCCESS,
  PREMIUM_EVAL_OPTION_PATCH_ERROR,
  PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA,
} from "./actionTypes";

export const premiumEvalOption = (premiumEvalOption, history) => {
  return {
    type: PREMIUM_EVAL_OPTION,
    payload: { premiumEvalOption, history },
  };
};

export const premiumEvalOptionSuccess = (success) => {
  return {
    type: PREMIUM_EVAL_OPTION_SUCCESS,
    payload: success,
  };
};

export const premiumEvalOptionError = (error) => {
  return {
    type: PREMIUM_EVAL_OPTION_ERROR,
    payload: error,
  };
};

export const premiumEvalOptionClearData = () => {
  return {
    type: PREMIUM_EVAL_OPTION_CLEAR_DATA,
    payload: {},
  };
};

export const premiumEvalOptionGetOne = (id) => {
  return {
    type: PREMIUM_EVAL_OPTION_GET_ONE,
    payload: id,
  };
};

export const premiumEvalOptionGetOneSuccess = (success) => {
  return {
    type: PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS,
    payload: success,
  };
};

export const premiumEvalOptionGetOneError = (error) => {
  return {
    type: PREMIUM_EVAL_OPTION_GET_ONE_ERROR,
    payload: error,
  };
};

export const premiumEvalOptionGetOneClearData = () => {
  return {
    type: PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const premiumEvalOptionPost = (data) => {
  return {
    type: PREMIUM_EVAL_OPTION_POST,
    payload: data,
  };
};

export const premiumEvalOptionPostSuccess = (success) => {
  return {
    type: PREMIUM_EVAL_OPTION_POST_SUCCESS,
    payload: success,
  };
};

export const premiumEvalOptionPostError = (error) => {
  return {
    type: PREMIUM_EVAL_OPTION_POST_ERROR,
    payload: error,
  };
};

export const premiumEvalOptionPostClearData = () => {
  return {
    type: PREMIUM_EVAL_OPTION_POST_CLEAR_DATA,
    payload: {},
  };
};

export const premiumEvalOptionPatch = (id, data) => {
  return {
    type: PREMIUM_EVAL_OPTION_PATCH,
    payload: { id, data },
  };
};

export const premiumEvalOptionPatchSuccess = (success) => {
  return {
    type: PREMIUM_EVAL_OPTION_PATCH_SUCCESS,
    payload: success,
  };
};

export const premiumEvalOptionPatchError = (error) => {
  return {
    type: PREMIUM_EVAL_OPTION_PATCH_ERROR,
    payload: error,
  };
};

export const premiumEvalOptionPatchClearData = () => {
  return {
    type: PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA,
    payload: {},
  };
};
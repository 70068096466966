import {
  MenuItem,
  state,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import LocalStorageService from "../services/localStorage";

export const navigateNext = (step: any, navigate: any) => {
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  let flow = localStorageService.getStepStored().steps[0].flow;
  if (!flow) {
    return;
  }
  let flowData = flow.metadata?.navigation.flowStep.steps;

  const nextStep = flowData.find((item: any) => item.name === step);
  let nextIndex;
  let nextElement;
  let route = "/";
  if (nextStep) {
    nextIndex = flowData.indexOf(nextStep);
    nextElement = flowData[nextIndex + 1];
    route = nextElement.route;
  }
  navigate(route);
};

export const navigateBack = (step: any, navigate: any) => {
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  let flow = localStorageService.getStepStored().steps[0].flow;
  if (!flow) {
    return;
  }
  let flowData = flow.metadata?.navigation.flowStep.steps;

  const nextStep = flowData.find((item: any) => item.name === step);
  let nextIndex;
  let nextElement;
  let route = "/";
  if (nextStep) {
    nextIndex = flowData.indexOf(nextStep);
    nextElement = flowData[nextIndex - 1];
    route = nextElement.route;
  }
  navigate(route);
};

/**
 * @param step - current step
 * @param breadcrumb - boolean to determine if breadcrumb or steps
 * @returns - array of steps or breadcrumbs
 */
export const stepProgress = (step: any, breadcrumb?: boolean): any[] => {
  let steps: MenuItem[] = [];
  let crumbs: any[] = [];
  let foundStep = false;
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  let flow = localStorageService.getStepStored().steps[0].flow;
  if (!flow) {
    return [];
  }
  let flowData = flow.metadata?.navigation.flowStep.steps;

  const currentIndex = flowData?.findIndex((p: any) => p.name == step);
  //currentStep?.stepId;

  let index = 0;
  flowData?.forEach((item: any) => {
    index++;
    if (breadcrumb) {
      foundStep = item.name === step ? true : foundStep;

      if (foundStep === false) {
        crumbs.push({
          key: "",
          name: item.label,
          ariaCurrent: "",
        });
      }
    } else {
      steps.push({
        id: (index - 1).toString(),
        name: item.label,
        code: item.name,
        state: state.initialState,
      });
    }
  });

  steps.forEach((item) => {
    if (Number(item.id) < (currentIndex ?? 0)) {
      item.state = state.complete;
    }

    if (Number(item.id) == currentIndex) {
      item.state = state.inProgress;
    }
    if (Number(item.id) > (currentIndex ?? 0)) {
      item.state = state.initialState;
    }
  });

  return breadcrumb ? crumbs : steps;
};

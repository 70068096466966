import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postPlan } from "../../store/plans/actions";
import {  PlanRequest } from "../../model/plan";

export const usePlanPost = (
  trigger: boolean,
  bodyRequest: PlanRequest,
) => {
  const dispatch = useDispatch();

  const { responsePostPlans, errorPostPlans, loadingPostPlans } = useSelector(
    (state: any) => (
      
      {
      responsePostPlans: state.Plans.responsePostPlans?.data,
      errorPostPlans: state.Plans.errorPostPlans,
      loadingPostPlans: state.Plans.loadingPostPlans,
    }
    
    ),
    
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        postPlan(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePostPlans,
    errorPostPlans,
    loadingPostPlans,
  };
};

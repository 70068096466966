import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import {
  PremiumEvalOptionRequest,
  PremiumEvalOptionResponse,
} from "../../model/premiumEvalOption";
import { getHeader } from "../headers";

const premiumEvalOptionGet = async (
  enable: boolean,
  page: number,
  limit: number,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.premiumEvalOption +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as PremiumEvalOptionResponse;
  } catch (error: any) {
    throw error;
  }
};

const premiumEvalOptionGetOne = async (id: string) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.premiumEvalOption + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const premiumEvalOptionPost = async (body: PremiumEvalOptionRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.premiumEvalOption;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as PremiumEvalOptionResponse;
  } catch (error: any) {
    throw error;
  }
};

const premiumEvalOptionPatch = async (
  body: PremiumEvalOptionRequest,
  id: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.premiumEvalOption + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as PremiumEvalOptionResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  premiumEvalOptionGet,
  premiumEvalOptionGetOne,
  premiumEvalOptionPost,
  premiumEvalOptionPatch,
};

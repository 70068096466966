import {
  COVER_TYPE,
  COVER_TYPE_SUCCESS,
  COVER_TYPE_ERROR,
  COVER_TYPE_CLEAR_DATA,
  COVER_TYPE_GET_ONE,
  COVER_TYPE_GET_ONE_SUCCESS,
  COVER_TYPE_GET_ONE_ERROR,
  COVER_TYPE_GET_ONE_CLEAR_DATA,
  COVER_TYPE_POST,
  COVER_TYPE_POST_SUCCESS,
  COVER_TYPE_POST_ERROR,
  COVER_TYPE_POST_CLEAR_DATA,
  COVER_TYPE_PATCH,
  COVER_TYPE_PATCH_SUCCESS,
  COVER_TYPE_PATCH_ERROR,
  COVER_TYPE_PATCH_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorCoverType: null,
  responseCoverType: null,
  loadingCoverType: false,
  errorPostCoverType: null,
  responsePostCoverType: null,
  loadingPostCoverType: false,
  errorPatchCoverType: null,
  loadingPatchCoverType: null,
  responsePatchCoverType: false,
};

const coverType = (state = initialState, action) => {
  switch (action.type) {
    case COVER_TYPE:
      state = {
        ...state,
        loadingCoverType: true,
        errorCoverType: null,
        responseCoverType: true,
      };
      break;
    case COVER_TYPE_SUCCESS:
      state = {
        ...state,
        errorCoverType: null,
        loadingCoverType: false,
        responseCoverType: action.payload,
      };
      break;
    case COVER_TYPE_ERROR:
      state = {
        ...state,
        errorCoverType: action.payload,
        loadingCoverType: false,
        responseCoverType: null,
      };
      break;
    case COVER_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorCoverType: null,
        loadingCoverType: null,
        responseCoverType: false,
      };
      break;
      case COVER_TYPE_GET_ONE:
        state = {
          ...state,
          loadingCoverType: true,
          errorCoverType: null,
          responseCoverType: null,
        };
        break;
      case COVER_TYPE_GET_ONE_SUCCESS:
        state = {
          ...state,
          errorCoverType: null,
          loadingCoverType: false,
          responseCoverType: action.payload,
        };
        break;
      case COVER_TYPE_GET_ONE_ERROR:
        state = {
          ...state,
          errorCoverType: action.payload,
          loadingCoverType: false,
          responseCoverType: null,
        };
        break;
      case COVER_TYPE_GET_ONE_CLEAR_DATA:
        state = {
          ...state,
          errorCoverType: null,
          loadingCoverType: null,
          responseCoverType: null,
        };
        break;      
    case COVER_TYPE_POST:
      state = {
        ...state,
        loadingPostCoverType: true,
        errorPostCoverType: null,
        responsePostCoverType: true,
      };
      break;
    case COVER_TYPE_POST_SUCCESS:
      state = {
        ...state,
        errorPostCoverType: null,
        loadingPostCoverType: false,
        responsePostCoverType: action.payload,
      };
      break;
    case COVER_TYPE_POST_ERROR:
      state = {
        ...state,
        errorPostCoverType: action.payload,
        loadingPostCoverType: false,
        responsePostCoverType: null,
      };
      break;
    case COVER_TYPE_POST_CLEAR_DATA:
      state = {
        ...state,
        errorPostCoverType: null,
        loadingPostCoverType: null,
        responsePostCoverType: false,
      };
      break;
    case COVER_TYPE_PATCH:
      state = {
        ...state,
        loadingPatchCoverType: true,
        errorPatchCoverType: null,
        responsePatchCoverType: null,
      };
      break;
    case COVER_TYPE_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchCoverType: null,
        loadingPatchCoverType: false,
        responsePatchCoverType: action.payload,
      };
      break;
    case COVER_TYPE_PATCH_ERROR:
      state = {
        ...state,
        errorPatchCoverType: action.payload,
        loadingPatchCoverType: false,
        responsePatchCoverType: null,
      };
      break;
    case COVER_TYPE_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchCoverType: null,
        loadingPatchCoverType: null,
        responsePatchCoverType: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default coverType;

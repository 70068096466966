import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { QuoteOneResponse } from "../../model/quote";
import { getHeader, getHeader2 } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const postGenerateQuote = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.quote;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getOneGenerateQuote = async (
  id: string,
  fullBody: boolean,
  log?: boolean
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    `/${id}/${log ? "Logs" : ""}?fullBody=${fullBody ? fullBody : false}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as QuoteOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllGenerateQuote = async (
  clientId: string,
  statuses: string,
  creationDateStart: string,
  creationDateEnd: string,
  projectIds: string,
  branches: string,
  enable: boolean,
  page: number,
  limit: number,
  numberId: number,
  fields: string,
  search: string,
  catalogStatusQuote: string,
  profileDocumentsStatus: string
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    "/All?" +
    (clientId ? `clientId=${clientId}` : "") +
    (statuses ? `&statuses=${statuses}` : "") +
    (creationDateStart ? `&creationDateStart=${creationDateStart}` : "") +
    (creationDateEnd ? `&creationDateEnd=${creationDateEnd}` : "") +
    (projectIds ? `&projectIds=${projectIds}` : "") +
    (branches ? `&branches=${branches}` : "") +
    (numberId ? `&numberId=${numberId}` : "") +
    (fields ? `&fields=${fields}` : "") +
    (search ? `&search=${search}` : "") +
    (catalogStatusQuote ? `&catalogStatusQuote=${catalogStatusQuote}` : "") +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (profileDocumentsStatus
      ? `&profileDocumentsStatus=${profileDocumentsStatus}`
      : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as QuoteOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const downloadGenerateQuoteAPI = async (
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
  catalogStatusQuote: string,
  creationDateStart: string,
  creationDateEnd: string,
  fields: string,
  labels: string,
  contentType: string
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    "/All" +
    `?clientId=${clientId}` +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (catalogStatusQuote ? `&catalogStatusQuote=${catalogStatusQuote}` : "") +
    (creationDateStart ? `&creationDateStart=${creationDateStart}` : "") +
    (creationDateEnd ? `&creationDateEnd=${creationDateEnd}` : "") +
    (fields ? `&fields=${fields}` : "") +
    (labels ? `&labels=${labels}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader2(contentType),
  };

  try {
    const responseAPI = await fetch(newURL, requestOptions);
    if (!responseAPI.ok) {
      throw await responseAPI.blob();
    }
    return await responseAPI.blob();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const putQuoteAddFieldsAPI = async (
  quoteId: string,
  key: string,
  body: any
) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.quote}/${quoteId}/AdditionalFields/${key}`;
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const attachedDocumentStatusUpdate = async (
  quoteId: string,
  documentId: string,
  status: string,
  body: any
) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.quote}/${quoteId}/Attachments/${documentId}/${status}`;
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    const contentLength = response.headers.get("content-length");
    if (!contentLength || contentLength === "0") {
      return `Error ${response.status}: La respuesta no contiene datos.`;
    } else {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  postGenerateQuote,
  getOneGenerateQuote,
  getAllGenerateQuote,
  downloadGenerateQuoteAPI,
  putQuoteAddFieldsAPI,
  attachedDocumentStatusUpdate,
};

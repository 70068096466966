import { call, put, takeEvery } from "redux-saga/effects";
import {
  personByDocumentError,
  personByDocumentSuccess,
  personByIdError,
  personByIdSuccess,
  personError,
  personSuccess,
  updatePersonError,
  updatePersonSuccess,
} from "./actions";
import { getPerson } from "../../resources/helper/helper";
import {
  PERSON,
  PERSON_BY_DOCUMENT,
  PERSON_BY_ID,
  UPDATE_PERSON,
} from "./actionTypes";
import {
  getPersonByDocumentAPI,
  getPersonByIdAPI,
  updatePersonAPI,
} from "../../resources/helper/person";

function* person({ payload: { person } }) {
  try {
    const response = yield call(getPerson, person);
    yield put(personSuccess(response));
  } catch (error) {
    yield put(personError(error));
  }
}

function* personById({ payload: { personId } }) {
  try {
    const response = yield call(getPersonByIdAPI, personId);
    yield put(personByIdSuccess(response));
  } catch (error) {
    yield put(personByIdError(error));
  }
}

function* updatePerson({ payload: { person } }) {
  try {
    const response = yield call(updatePersonAPI, person.body, person.id);
    yield put(updatePersonSuccess(response));
  } catch (error) {
    yield put(updatePersonError(error));
  }
}

function* personByDocument({ payload: { document } }) {
  try {
    const response = yield call(
      getPersonByDocumentAPI,
      document.document,
      document.enable,
      document.page,
      document.limit,
    );
    yield put(personByDocumentSuccess(response));
  } catch (error) {
    yield put(personByDocumentError(error));
  }
}

function* personSaga() {
  yield takeEvery(PERSON, person);
  yield takeEvery(PERSON_BY_ID, personById);
  yield takeEvery(UPDATE_PERSON, updatePerson);
  yield takeEvery(PERSON_BY_DOCUMENT, personByDocument);
}

export default personSaga;

export enum FuelType {
  GASOLINE = "GASOLINE",
  DIESEL = "DIESEL",
  ELECTRIC = "ELECTRIC",
  GAS = "GAS",
}

export enum CarCondition {
  NEW = "NEW",
  USED = "USED",
}

export enum NumberCylinders {
  DOS = "2",
  TRES = "3",
  CUATRO = "4",
  CINCO = "5",
  SEIS = "6",
  OCHO = "8",
  DIEZ = "10",
  DOCE = "12",
  DIECISEIS = "16",
}

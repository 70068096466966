import { call, put, takeEvery } from "redux-saga/effects";
import {
  postQuoteAttachError,
  postQuoteAttachSuccess,
  postQuoteUploadDocumentError,
  postQuoteUploadDocumentSuccess,
} from "./actions";
import { QUOTE_UPLOAD_ATTACH, QUOTE_UPLOAD_DOCUMENT } from "./actionTypes";
import { uploadQuoteAttachmentsAPI } from "../../resources/helper/uploadQuoteAttachments";
import { uploadQuoteDocumentsAPI } from "../../resources/helper/uploadQuoteDocuments";

function* uploadQuoteAttachments({ payload: { uploadAttachments } }) {
  try {
    const response = yield call(
      uploadQuoteAttachmentsAPI,
      uploadAttachments.id,
      uploadAttachments.fileName,
      uploadAttachments.pdfFile,
      uploadAttachments.code,
    );
    yield put(postQuoteAttachSuccess(response));
  } catch (error) {
    yield put(postQuoteAttachError(error));
  }
}

function* uploadQuoteDocuments({ payload: { uploadDocuments } }) {
  try {
    const response = yield call(
      uploadQuoteDocumentsAPI,
      uploadDocuments.id,
      uploadDocuments.fileName,
      uploadDocuments.pdfFile,
      uploadDocuments.documentProfileId,
    );
    yield put(postQuoteUploadDocumentSuccess(response));
  } catch (error) {
    yield put(postQuoteUploadDocumentError(error));
  }
}

function* uploadAttachSaga() {
  yield takeEvery(QUOTE_UPLOAD_ATTACH, uploadQuoteAttachments);
  yield takeEvery(QUOTE_UPLOAD_DOCUMENT, uploadQuoteDocuments);
}

export default uploadAttachSaga;

import {
  PERSON,
  PERSON_BY_DOCUMENT,
  PERSON_BY_DOCUMENT_CLEAR_DATA,
  PERSON_BY_DOCUMENT_ERROR,
  PERSON_BY_DOCUMENT_SUCCESS,
  PERSON_BY_ID,
  PERSON_BY_ID_CLEAR_DATA,
  PERSON_BY_ID_ERROR,
  PERSON_BY_ID_SUCCESS,
  PERSON_CLEAR_DATA,
  PERSON_ERROR,
  PERSON_SUCCESS,
  UPDATE_PERSON,
  UPDATE_PERSON_CLEAR_DATA,
  UPDATE_PERSON_ERROR,
  UPDATE_PERSON_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPerson: null,
  responsePerson: null,
  loadingPerson: false,

  errorPersonById: null,
  responsePersonById: null,
  loadingPersonById: false,

  errorUpdatePerson: null,
  responseUpdatePerson: null,
  loadingUpdatePerson: false,

  errorPersonByDocument: null,
  responsePersonByDocument: null,
  loadingPersonByDocument: false,
};

const Person = (state = initialState, action) => {
  switch (action.type) {
    case PERSON:
      state = {
        ...state,
        loadingPerson: true,
        errorPerson: null,
        responsePerson: true,
      };
      break;
    case PERSON_SUCCESS:
      state = {
        ...state,
        errorPerson: null,
        loadingPerson: false,
        responsePerson: action.payload,
      };
      break;
    case PERSON_ERROR:
      state = {
        ...state,
        errorPerson: action.payload,
        loadingPerson: false,
        responsePerson: null,
      };
      break;
    case PERSON_CLEAR_DATA:
      state = {
        ...state,
        errorPerson: null,
        loadingPerson: null,
        responsePerson: false,
      };
      break;
    case PERSON_BY_ID:
      state = {
        ...state,
        loadingPersonById: true,
        errorPersonById: null,
        responsePersonById: true,
      };
      break;
    case PERSON_BY_ID_SUCCESS:
      state = {
        ...state,
        errorPersonById: null,
        loadingPersonById: false,
        responsePersonById: action.payload,
      };
      break;
    case PERSON_BY_ID_ERROR:
      state = {
        ...state,
        errorPersonById: action.payload,
        loadingPersonById: false,
        responsePersonById: null,
      };
      break;
    case PERSON_BY_ID_CLEAR_DATA:
      state = {
        ...state,
        errorPersonById: null,
        loadingPersonById: null,
        responsePersonById: false,
      };
      break;
    case UPDATE_PERSON:
      state = {
        ...state,
        loadingUpdatePerson: true,
        errorUpdatePerson: null,
        responseUpdatePerson: true,
      };
      break;
    case UPDATE_PERSON_SUCCESS:
      state = {
        ...state,
        errorUpdatePerson: null,
        loadingUpdatePerson: false,
        responseUpdatePerson: action.payload,
      };
      break;
    case UPDATE_PERSON_ERROR:
      state = {
        ...state,
        errorUpdatePerson: action.payload,
        loadingUpdatePerson: false,
        responseUpdatePerson: null,
      };
      break;
    case UPDATE_PERSON_CLEAR_DATA:
      state = {
        ...state,
        errorUpdatePerson: null,
        loadingUpdatePerson: null,
        responseUpdatePerson: false,
      };
      break;
    case PERSON_BY_DOCUMENT:
      state = {
        ...state,
        loadingPersonByDocument: true,
        errorPersonByDocument: null,
        responsePersonByDocument: null,
      };
      break;
    case PERSON_BY_DOCUMENT_SUCCESS:
      state = {
        ...state,
        errorPersonByDocument: null,
        loadingPersonByDocument: false,
        responsePersonByDocument: action.payload,
      };
      break;
    case PERSON_BY_DOCUMENT_ERROR:
      state = {
        ...state,
        errorPersonByDocument: action.payload,
        loadingPersonByDocument: false,
        responsePersonByDocument: null,
      };
      break;
    case PERSON_BY_DOCUMENT_CLEAR_DATA:
      state = {
        ...state,
        errorPersonByDocument: null,
        loadingPersonByDocument: null,
        responsePersonByDocument: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Person;

import React from "react";
import {
  ButtonAFY,
  ModalComponentAFY,
  sizes,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useStyles } from "./style";

export const ModalConfirmationDelete = (props: any) => {
  const classes = useStyles();
  return (
    <ModalComponentAFY
      openModal={props.openModal}
      backgroundColor="#ffffff"
      onClose={() => {
        props.setOpenModal(false);
      }}
      border={0}
      minWidthModal={"500px"}
      padding={30}
    >
      <div style={{ marginTop: "10px" }}>
        <h2 className={classes.title}>{`Eliminar ${props.titleDelete}`}</h2>
        <p className={classes.description}>
          {`¿Está seguro que desea eliminar ${props.subTitleDelete}?`}
        </p>
        <div className={classes.buttonsContainer}>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"No"}
              backgroundColor="red"
              onClick={() => {
                props.setOpenModal(false);
              }}
            />
          </div>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"Si, eliminar"}
              onClick={() => {
                if (props.titleDelete === "recurrencia de pago") {
                  props.deleteRecurrence();
                } else {
                  props.deletePaymentMode();
                }
              }}
            />
          </div>
        </div>
      </div>
    </ModalComponentAFY>
  );
};

export const PREMIUM_EVAL_OPTION = "PREMIUM_EVAL_OPTION";
export const PREMIUM_EVAL_OPTION_SUCCESS = "PREMIUM_EVAL_OPTION_SUCCESS";
export const PREMIUM_EVAL_OPTION_ERROR = "PREMIUM_EVAL_OPTION_ERROR";
export const PREMIUM_EVAL_OPTION_CLEAR_DATA = "PREMIUM_EVAL_OPTION_CLEAR_DATA";

export const PREMIUM_EVAL_OPTION_GET_ONE = "PREMIUM_EVAL_OPTION_GET_ONE";
export const PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS = "PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS";
export const PREMIUM_EVAL_OPTION_GET_ONE_ERROR = "PREMIUM_EVAL_OPTION_GET_ONE_ERROR";
export const PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA = "PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA";

export const PREMIUM_EVAL_OPTION_POST = "PREMIUM_EVAL_OPTION_POST";
export const PREMIUM_EVAL_OPTION_POST_SUCCESS = "PREMIUM_EVAL_OPTION_POST_SUCCESS";
export const PREMIUM_EVAL_OPTION_POST_ERROR = "PREMIUM_EVAL_OPTION_POST_ERROR";
export const PREMIUM_EVAL_OPTION_POST_CLEAR_DATA = "PREMIUM_EVAL_OPTION_POST_CLEAR_DATA";

export const PREMIUM_EVAL_OPTION_PATCH = "PREMIUM_EVAL_OPTION_PATCH";
export const PREMIUM_EVAL_OPTION_PATCH_SUCCESS = "PREMIUM_EVAL_OPTION_PATCH_SUCCESS";
export const PREMIUM_EVAL_OPTION_PATCH_ERROR = "PREMIUM_EVAL_OPTION_PATCH_ERROR";
export const PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA = "PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA";
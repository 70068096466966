import {
  COVER_TYPE_PLAN,
  COVER_TYPE_PLAN_CLEAR_DATA,
  COVER_TYPE_PLAN_ERROR,
  COVER_TYPE_PLAN_SUCCESS,
  COVER_TYPE_PLAN_GET_ONE,
  COVER_TYPE_PLAN_GET_ONE_SUCCESS,
  COVER_TYPE_PLAN_GET_ONE_ERROR,
  COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA,
  COVER_TYPE_PLAN_POST,
  COVER_TYPE_PLAN_POST_SUCCESS,
  COVER_TYPE_PLAN_POST_ERROR,
  COVER_TYPE_PLAN_POST_CLEAR_DATA,
  COVER_TYPE_PLAN_PATCH,
  COVER_TYPE_PLAN_PATCH_SUCCESS,
  COVER_TYPE_PLAN_PATCH_ERROR,
  COVER_TYPE_PLAN_PATCH_CLEAR_DATA,
} from "./actionTypes";

export const coverTypePlan = (coverTypePlan, history) => {
  return {
    type: COVER_TYPE_PLAN,
    payload: { coverTypePlan, history },
  };
};

export const coverTypePlanSuccess = (success) => {
  return {
    type: COVER_TYPE_PLAN_SUCCESS,
    payload: success,
  };
};

export const coverTypePlanError = (error) => {
  return {
    type: COVER_TYPE_PLAN_ERROR,
    payload: error,
  };
};

export const coverTypePlanClearData = () => {
  return {
    type: COVER_TYPE_PLAN_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypePlanGetOne = (id) => {
  return {
    type: COVER_TYPE_PLAN_GET_ONE,
    payload: id,
  };
};

export const coverTypePlanGetOneSuccess = (success) => {
  return {
    type: COVER_TYPE_PLAN_GET_ONE_SUCCESS,
    payload: success,
  };
};

export const coverTypePlanGetOneError = (error) => {
  return {
    type: COVER_TYPE_PLAN_GET_ONE_ERROR,
    payload: error,
  };
};

export const coverTypePlanGetOneClearData = () => {
  return {
    type: COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypePlanPost = (data, history) => {
  return {
    type: COVER_TYPE_PLAN_POST,
    payload:{ data, history },
  };
};

export const coverTypePlanPostSuccess = (success) => {
  return {
    type: COVER_TYPE_PLAN_POST_SUCCESS,
    payload: success,
  };
};

export const coverTypePlanPostError = (error) => {
  return {
    type: COVER_TYPE_PLAN_POST_ERROR,
    payload: error,
  };
};

export const coverTypePlanPostClearData = () => {
  return {
    type: COVER_TYPE_PLAN_POST_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypePlanPatch = (id, data) => {
  return {
    type: COVER_TYPE_PLAN_PATCH,
    payload: { id, data },
  };
};

export const coverTypePlanPatchSuccess = (success) => {
  return {
    type: COVER_TYPE_PLAN_PATCH_SUCCESS,
    payload: success,
  };
};

export const coverTypePlanPatchError = (error) => {
  return {
    type: COVER_TYPE_PLAN_PATCH_ERROR,
    payload: error,
  };
};

export const coverTypePlanPatchClearData = () => {
  return {
    type: COVER_TYPE_PLAN_PATCH_CLEAR_DATA,
    payload: {},
  };
};
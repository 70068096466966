import React, { useEffect, useState } from "react";
import { PDFViewerView } from "./pdfViewerView";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateStatusAuditDoc,
  updateStatusAuditDocClearData,
} from "../../../store/generateQuote/actions";
import { showErrorToast } from "../../../fnx/showError";
import {
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useTranslation } from "react-i18next";
import showToast from "../../../services/toast";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";
import { StatusAuditDocument } from "../../../enum/statusAuditDocument";
import LocalStorageService from "../../../services/localStorage";
import { Steps } from "../../../enum/steps";

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

export const PdfViewerApap = () => {
  const navigate = useNavigate();
  const { idQuote } = useParams();
  const dispatch = useDispatch();
  const { idDocument } = useParams();
  const { t } = useTranslation();
  const documentUrl =
    localStorageService.getStepStored().steps[Steps.auditDocumentUrl]
      .auditDocumentUrl;
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const {
    responseUpdateStatusAuditDocs,
    errorUpdateStatusAuditDocs,
    loadingUpdateStatusAuditDocs,
  } = useSelector((state: any) => ({
    responseUpdateStatusAuditDocs: state.Quote.responseUpdateStatusAuditDocs,
    errorUpdateStatusAuditDocs: state.Quote.errorUpdateStatusAuditDocs,
    loadingUpdateStatusAuditDocs: state.Quote.loadingUpdateStatusAuditDocs,
  }));

  useEffect(() => {
    dispatch(updateStatusAuditDocClearData());
  }, []);

  useEffect(() => {
    if (errorUpdateStatusAuditDocs) {
      showErrorToast(errorUpdateStatusAuditDocs, setSeeToast, t);
    }
  }, [errorUpdateStatusAuditDocs]);

  useEffect(() => {
    if (responseUpdateStatusAuditDocs) {
      showToastSuccessMessage(
        t("pdfViewer.approveAuditDocument"),
        typeAlert.success,
        setSeeToast,
      );
      onBack();
      dispatch(updateStatusAuditDocClearData());
    }
  }, [responseUpdateStatusAuditDocs]);

  const onBack = () => {
    navigate(`/fiscalizacion-apap-auto/${idQuote}`);
  };

  const updateStatusAuditDocument = (
    status: StatusAuditDocument,
    comment?: string,
  ) => {
    const body = {
      comment: comment,
    };
    dispatch(
      updateStatusAuditDoc({
        quoteId: idQuote,
        documentId: idDocument,
        status: status,
        body: body,
      }),
    );
  };

  const onRejected = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const applyValue = (data: any) => {
    updateStatusAuditDocument(
      StatusAuditDocument.REJECTED,
      data.descriptionValue.value,
    );
    setOpenDrawer(false);
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <PDFViewerView
        onBack={onBack}
        approveDocument={updateStatusAuditDocument}
        onRejectedDocument={onRejected}
        url={documentUrl}
        t={t}
        loading={loadingUpdateStatusAuditDocs}
        titleDrawer={"Rechazar documento"}
        txtLegendDrawer={
          "Razón del rechazo: Es obligatorio proporcionar una explicación clara del motivo del rechazo del documento."
        }
        titleButtonDrawer={"Rechazar"}
        placeHolderDrawer={
          "Escriba una descripcion para sustentar el rechazo del documento"
        }
        subTitleDrawer={"Formulario conocimiento persona fisica"}
        openDrawer={openDrawer}
        onValueApply={applyValue}
        onCloseDrawer={onCloseDrawer}
      />
    </>
  );
};

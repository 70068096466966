import {
  CLIENT,
  CLIENT_SUCCESS,
  CLIENT_ONE,
  CLIENT_ERROR,
  CLIENT_CLEAR_DATA,
  CLIENT_ONE_SUCCESS,
  CLIENT_ONE_BY_PROJECT,
  CLIENT_ONE_BY_PROJECT_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorClient: null,
  responseClient: null,
  responseOneClient: null,
  responseOneByProjectClient: null,
  loadingClient: false,
};

const Client = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT:
      state = {
        ...state,
        loadingClient: true,
        errorClient: null,
        responseClient: true,
      };
      break;
    case CLIENT_ONE:
      state = {
        ...state,
        loadingClient: true,
        errorClient: null,
        responseOneClient: true,
      };
      break;
    case CLIENT_ONE_BY_PROJECT:
      state = {
        ...state,
        loadingClient: true,
        errorClient: null,
        responseOneClient: true,
        responseOneByProjectClient: true,
      };
      break;
    case CLIENT_SUCCESS:
      state = {
        ...state,
        errorClient: null,
        loadingClient: false,
        responseClient: action.payload,
      };
      break;
    case CLIENT_ONE_SUCCESS:
      state = {
        ...state,
        errorClient: null,
        loadingClient: false,
        responseOneClient: action.payload,
      };
      break;
    case CLIENT_ONE_BY_PROJECT_SUCCESS:
      state = {
        ...state,
        errorClient: null,
        loadingClient: false,
        responseOneByProjectClient: action.payload,
      };
      break;
    case CLIENT_ERROR:
      state = {
        ...state,
        errorClient: action.payload,
        loadingClient: false,
        responseClient: null,
      };
      break;
    case CLIENT_CLEAR_DATA:
      state = {
        ...state,
        errorClient: null,
        loadingClient: null,
        responseClient: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Client;

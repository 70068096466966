import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_CLEAR_DATA,
  ACCOUNT_TYPE_ERROR,
  ACCOUNT_TYPE_PATCH,
  ACCOUNT_TYPE_PATCH_CLEAR_DATA,
  ACCOUNT_TYPE_PATCH_ERROR,
  ACCOUNT_TYPE_PATCH_SUCCESS,
  ACCOUNT_TYPE_POST,
  ACCOUNT_TYPE_POST_CLEAR_DATA,
  ACCOUNT_TYPE_POST_ERROR,
  ACCOUNT_TYPE_POST_SUCCESS,
  ACCOUNT_TYPE_SUCCESS,
} from "./actionTypes";

export const accountType = (accountType, history) => {
  return {
    type: ACCOUNT_TYPE,
    payload: { accountType, history },
  };
};

export const accountTypeSuccess = (success) => {
  return {
    type: ACCOUNT_TYPE_SUCCESS,
    payload: success,
  };
};

export const accountTypeError = (error) => {
  return {
    type: ACCOUNT_TYPE_ERROR,
    payload: error,
  };
};

export const accountTypeClearData = () => {
  return {
    type: ACCOUNT_TYPE_CLEAR_DATA,
    payload: {},
  };
};

//aqui inicia el post
export const accountTypePost = (accountType, history) => {
  return {
    type: ACCOUNT_TYPE_POST,
    payload: { accountType, history },
  };
};

export const accountTypePostSuccess = (success) => {
  return {
    type: ACCOUNT_TYPE_POST_SUCCESS,
    payload: success,
  };
};

export const accountTypePostError = (error) => {
  return {
    type: ACCOUNT_TYPE_POST_ERROR,
    payload: error,
  };
};

export const accountTypePostClearData = () => {
  return {
    type: ACCOUNT_TYPE_POST_CLEAR_DATA,
    payload: {},
  };
};

//aqui inicia el patch

export const accountTypePatch = (accountTypePatchBody, id) => {
  return {
    type: ACCOUNT_TYPE_PATCH,
    payload: { accountTypePatchBody, id },
  };
};

export const accountTypePatchSuccess = (success) => {
  return {
    type: ACCOUNT_TYPE_PATCH_SUCCESS,
    payload: success,
  };
};

export const accountTypePatchError = (error) => {
  return {
    type: ACCOUNT_TYPE_PATCH_ERROR,
    payload: error,
  };
};

export const accountTypePatchClearData = () => {
  return {
    type: ACCOUNT_TYPE_PATCH_CLEAR_DATA,
    payload: {},
  };
};

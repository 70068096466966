import {
  CERTIFICATE_QUOTE,
  CERTIFICATE_QUOTE_CLEAR_DATA,
  CERTIFICATE_QUOTE_ERROR,
  CERTIFICATE_QUOTE_SUCCESS,
} from "./actionTypes";

export const certificateQuote = (certificateQuote, history) => {
  return {
    type: CERTIFICATE_QUOTE,
    payload: { certificateQuote, history },
  };
};

export const certificateQuoteSuccess = (success) => {
  return {
    type: CERTIFICATE_QUOTE_SUCCESS,
    payload: success,
  };
};

export const certificateQuoteError = (error) => {
  return {
    type: CERTIFICATE_QUOTE_ERROR,
    payload: error,
  };
};

export const certificateQuoteClearData = () => {
  return {
    type: CERTIFICATE_QUOTE_CLEAR_DATA,
    payload: {},
  };
};
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentsStatistics } from "../../store/documentStatistics/actions";

export const GetDocumentsStatistics = (trigger: boolean) => {
  const dispatch = useDispatch();

  const {
    loadingDocumentStatistics,
    responseDocumentStatistics,
    errorDocumentStatistics,
  } = useSelector((state: any) => ({
    loadingDocumentStatistics:
      state.DocumentStatistics.loadingDocumentStatistics,
    responseDocumentStatistics:
      state.DocumentStatistics.responseDocumentStatistics,
    errorDocumentStatistics: state.DocumentStatistics.errorDocumentStatistics,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(getDocumentsStatistics());
    }
  }, [trigger]);

  return {
    loadingDocumentStatistics,
    responseDocumentStatistics,
    errorDocumentStatistics,
  };
};

export const PERSON = "PERSON";
export const PERSON_SUCCESS = "PERSON_SUCCESS";
export const PERSON_ERROR = "PERSON_ERROR";
export const PERSON_CLEAR_DATA = "PERSON_CLEAR_DATA";
export const PERSON_BY_ID = "PERSON_BY_ID";
export const PERSON_BY_ID_SUCCESS = "PERSON_BY_ID_SUCCESS";
export const PERSON_BY_ID_ERROR = "PERSON_BY_ID_ERROR";
export const PERSON_BY_ID_CLEAR_DATA = "PERSON_BY_ID_CLEAR_DATA";
export const UPDATE_PERSON = "UPDATE_PERSON";
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const UPDATE_PERSON_ERROR = "UPDATE_PERSON_ERROR";
export const UPDATE_PERSON_CLEAR_DATA = "UPDATE_PERSON_CLEAR_DATA";

export const PERSON_BY_DOCUMENT = "PERSON_BY_DOCUMENT";
export const PERSON_BY_DOCUMENT_SUCCESS = "PERSON_BY_DOCUMENT_SUCCESS";
export const PERSON_BY_DOCUMENT_ERROR = "PERSON_BY_DOCUMENT_ERROR";
export const PERSON_BY_DOCUMENT_CLEAR_DATA = "PERSON_BY_DOCUMENT_CLEAR_DATA";

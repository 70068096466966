import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { I18nextProvider } from "react-i18next";
import i18Next from "i18next";
import reportWebVitals from "./reportWebVitals";

import vimenca_es from "./translations/locales/es/vimenca.json";
import vimenca_en from "./translations/locales/en/vimenca.json";
import banesco_es from "./translations/locales/es/banesco.json";
import banesco_en from "./translations/locales/en/banesco.json";
import apap_auto_es from "./translations/locales/es/apap-auto.json";
import apap_auto_en from "./translations/locales/en/apap-auto.json";
import default_en from "./translations/locales/en/default.json";
import default_es from "./translations/locales/es/default.json";

i18Next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  ns: ["default", "apapauto", "vimenca", "banesco"],
  defaultNS: "default",
  resources: {
    en: {
      default: default_en,
      apapauto: apap_auto_en,
      vimenca: vimenca_en,
      banesco: banesco_en,
      global: default_en,
    },
    es: {
      default: default_es,
      apapauto: apap_auto_es,
      vimenca: vimenca_es,
      banesco: banesco_es,
      global: default_es,
    },
  },
});

const app = (
  <Provider store={store}>
    <I18nextProvider i18n={i18Next}>
      <App />
    </I18nextProvider>
  </Provider>
);

const root = document.getElementById("root") as HTMLElement;
if (!root) {
  throw new Error("Root element not found");
}

const appRoot = ReactDOM.createRoot(root);
appRoot.render(app);

reportWebVitals();

import {
  GENERAL_CATALOG,
  GENERAL_CATALOG_CLEAR_DATA,
  GENERAL_CATALOG_ERROR,
  GENERAL_CATALOG_PATCH,
  GENERAL_CATALOG_PATCH_CLEAR_DATA,
  GENERAL_CATALOG_PATCH_ERROR,
  GENERAL_CATALOG_PATCH_SUCCESS,
  GENERAL_CATALOG_POST,
  GENERAL_CATALOG_POST_CLEAR_DATA,
  GENERAL_CATALOG_POST_ERROR,
  GENERAL_CATALOG_POST_SUCCESS,
  GENERAL_CATALOG_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorGeneralCatalog: null,
  responseGeneralCatalog: null,
  loadingGeneralCatalog: false,
  errorGeneralCatalogPost: null,
  responseGeneralCatalogPost: null,
  loadingGeneralCatalogPost: false,
  errorGeneralCatalogPatch: null,
  responseGeneralCatalogPatch: null,
  loadingGeneralCatalogPatch: false,
};

const GeneralCatalog = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_CATALOG:
      state = {
        ...state,
        loadingGeneralCatalog: true,
        errorGeneralCatalog: null,
        responseGeneralCatalog: null,
      };
      break;
    case GENERAL_CATALOG_SUCCESS:
      state = {
        ...state,
        errorGeneralCatalog: null,
        loadingGeneralCatalog: false,
        responseGeneralCatalog: action.payload,
      };
      break;
    case GENERAL_CATALOG_ERROR:
      state = {
        ...state,
        errorGeneralCatalog: action.payload,
        loadingGeneralCatalog: false,
        responseGeneralCatalog: null,
      };
      break;
    case GENERAL_CATALOG_CLEAR_DATA:
      state = {
        ...state,
        errorGeneralCatalog: null,
        loadingGeneralCatalog: null,
        responseGeneralCatalog: false,
      };
      break;
    case GENERAL_CATALOG_POST:
      state = {
        ...state,
        loadingGeneralCatalogPost: true,
        errorGeneralCatalogPost: null,
        responseGeneralCatalogPost: null,
      };
      break;
    case GENERAL_CATALOG_POST_SUCCESS:
      state = {
        ...state,
        errorGeneralCatalogPost: null,
        loadingGeneralCatalogPost: false,
        responseGeneralCatalogPost: action.payload,
      };
      break;
    case GENERAL_CATALOG_POST_ERROR:
      state = {
        ...state,
        errorGeneralCatalogPost: action.payload,
        loadingGeneralCatalogPost: false,
        responseGeneralCatalogPost: null,
      };
      break;
    case GENERAL_CATALOG_POST_CLEAR_DATA:
      state = {
        ...state,
        errorGeneralCatalogPost: null,
        loadingGeneralCatalogPost: null,
        responseGeneralCatalogPost: false,
      };
      break;
    case GENERAL_CATALOG_PATCH:
      state = {
        ...state,
        loadingGeneralCatalogPatch: true,
        errorGeneralCatalogPatch: null,
        responseGeneralCatalogPatch: null,
      };
      break;
    case GENERAL_CATALOG_PATCH_SUCCESS:
      state = {
        ...state,
        errorGeneralCatalogPatch: null,
        loadingGeneralCatalogPatch: false,
        responseGeneralCatalogPatch: action.payload,
      };
      break;
    case GENERAL_CATALOG_PATCH_ERROR:
      state = {
        ...state,
        errorGeneralCatalogPatch: action.payload,
        loadingGeneralCatalogPatch: false,
        responseGeneralCatalogPatch: null,
      };
      break;
    case GENERAL_CATALOG_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorGeneralCatalogPatch: null,
        loadingGeneralCatalogPatch: null,
        responseGeneralCatalogPatch: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GeneralCatalog;

import React from "react";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

interface ToastState {
  show: boolean;
  message: string;
  type: typeAlert;
}

type SetSeeToastFunction = React.Dispatch<React.SetStateAction<ToastState>>;

export const showErrorToast = (
  error: { errors: string | any[]; message: string },
  setSeeToastFunction: SetSeeToastFunction,
  tl: TFunction
) => {
  if (error && error.errors) {
    let message =
      "Hubo inconvenientes al procesar la solicitud, por favor contacte con la aseguradora";

    if (Array.isArray(error.errors)) {
      if (error.errors.length > 0 && error.errors[0].code != 400) {
        message = error.errors
          .map((error: { code: ""; message: "" }) => tl(` ${error.message}`))
          .join("\n");
      }
    } else if (error?.message) {
      //message = error?.message;
    }

    setSeeToastFunction({
      show: true,
      message: message,
      type: typeAlert.error,
    });
    error.errors = [];
  }
};

import LocalStorageService from "../services/localStorage";
import { Steps } from "../enum/steps";
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

export const removeNonNumeric = (value: string) => {
  return value.replace(/\D/g, '');
};

export const formatDocument = (value: string) => {
  let result = value;
  //let nameType = type.replace(/é/g, "e").toLowerCase()
  if (value.length === 11) {
    result = `${value.slice(0, 3)}-${value.slice(3, 10)}-${value.slice(10)}`;
  }
  return result;
}
export const filterData = (array: any, key: string, value: string) => {
  let temp: any = [];
  temp = array.filter(function (e: any) {
    return e[key] == value;
  });
  let result: any = [];
  temp.map((item: any, idx: any) => {
    result.push({ id: item.id, name: item.description, value: item.code });
  });
  return result;
};


export const transformToSelect = (array: any) => {

  let result: any = [];
  if (array)
  if (array.length > 0)
    array.map((item: any) => {
      result.push({ id: item.id, value: item.id, name: item.name, default: item.default ? item.default : false });
    });
  return result;
};

export const setCampaignData = (event: any) => {
  let cdata = [];
  cdata = localStorageService.getStepStored().steps[Steps.plan].campaign as any;
  let data = localStorageService.getStepStored().steps[Steps.plan]
  let selected;

  if (cdata)
    cdata.map((item: any) => {
      if (item.id === event) {
        item.default = true;
        selected = item;
      } else {
        item.default = false;
      }
    });
  localStorageService.setStepStored({
    id: data.id,
    name: data.name as string,
    step: data.step,
    completed: data.completed,
    campaign: cdata,
    campaignSelected: selected ? selected : data.campaignSelected,
    
  });
 }

 export const setPaymentModeData = (event: any,paymentMode: any) => {
  let cdata = [];
  cdata = paymentMode;
  let data = localStorageService.getStepStored().steps[Steps.plan]
  let selected;
if (event == null){
  console.log("Payment mode null, hence first element is selected",paymentMode[0])
  selected = paymentMode[0];

} else {
  if (cdata)
    cdata.map((item: any) => {
      if (item.id === event) {
        item.default = true;
        selected = item;
      } else {
        item.default = false;
      }
    });
  }
  localStorageService.setStepStored({
    id: data.id,
    name: data.name as string,
    step: data.step,
    completed: data.completed,
    campaign: data.campaign,
    campaignSelected: data.campaignSelected,
    paymentMode: cdata,
    paymentModeSelected: selected ? selected : data.paymentModeSelected,
    quoteId: data.quoteId,
    policyId: data.policyId,
  });
  
  return cdata;
 }
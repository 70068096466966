import React, { useEffect, useState } from "react"; // @ts-ignore
import { IdentificationType } from "../../model/identificationType";
import showToast from "../../services/toast";
import { setCampaignData, setPaymentModeData } from "../../services/functions";
import { InsurancePageProps } from "./planDto";
import { useDispatch, useSelector } from "react-redux";
import {
  breadcrumb,
  icons,
  itemPlanProps,
  LoadingAFY,
  MenuItem,
  position,
  select,
  sizesIcon,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";
import { PlanView } from "./view";
import { useNavigate } from "react-router-dom";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { plans, plansClearData } from "../../store/plans/actions";
import { getPlans } from "../../resources/helper/plans";
import { Steps } from "../../enum/steps";
import { PlanCoverTypes, PlanModel, PlanSelectOption } from "../../model/plan";
import { RowsModel } from "../../model/insurancePoliciesSummary";
import { paymentModeAction } from "../../store/paymentModes/action";
import { PaymentMode, PaymentModeResponse } from "../../model/paymentMode";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { STEP_PLAN } from "../../const/catalog";

const STEP = STEP_PLAN;

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const storageKeyVariables = "afy-variables";
const lsVariables = new LocalStorageService(storageKeyVariables);
const Plan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const storageKeyActionFlags = "afy-action-flags";
  // const storageKeyActionFlags = "afy-steps";
  const lsActionFlags = new LocalStorageService(storageKeyActionFlags);
  const navigate = useNavigate();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId
  );

  /*Adiciona flag para validar, si se muestran los rangos de edad
   * en el detalle de los planes*/
  const [flagRangeAge] = React.useState(
    lsActionFlags.getPageActionFlagsStored()
  );
  const [enableAgeRange, setEnabledAgeRange] = React.useState<boolean>(false);
  const [filtersQuotes, setFiltersQuotes] = React.useState<any>([]);
  const [rowsQuotes, setRowsQuotes] = React.useState<any>([]);
  const [selectedPlanId, setSelectedPlanId] = React.useState<string>("");
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [changedPlanId, setChangedPlanId] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [helperText, setHelperText] = React.useState<string>("");
  const [planPage, setPlanPage] = useState<number>(1);
  const [planLimit, setPlanLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [seeToastAlertNext, setSeeToastAlertNext] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [enable, setEnable] = React.useState<boolean>(true);
  /*Polizas o cotizaciones*/
  const [showModalQuotes, setShowModalQuotes] = React.useState<boolean>(false);
  const onCloseModalQuotes = () => {
    setShowModalQuotes(false);
  };
  const [extraInfoNames, setExtraInfoNames] = React.useState<itemPlanProps[]>(
    []
  );
  const [extraInfoNamesAut, setExtraInfoNamesAut] = React.useState<
    itemPlanProps[]
  >([]);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(100);
  const [isDetailClick, setIsDetailClick] = React.useState<boolean>(false);
  const [isSingleCompareClick, setIsSingleCompareClick] =
    React.useState<boolean>(false);
  const [paymentModelSelected, setPaymentModelSelected] =
    React.useState<select>({ id: "", name: "", value: "" });
  const [coverTypeSelected, setCoverTypeSelected] = React.useState<select>();
  const [planSelected, setPlanSelected] = React.useState<itemPlanProps>({
    id: "",
  });
  const [planSelectedNextStep, setPlanSelectedNextStep] =
    React.useState<itemPlanProps>({
      id: "",
    });
  const [planSelectedCompare, setPlanSelectedCompare] = React.useState<
    itemPlanProps[]
  >([]);
  const [showDetail, setShowDetail] = React.useState<boolean>(false);
  const [showCompareDetail, setShowCompareDetail] =
    React.useState<boolean>(false);

  const [projectId, setProjectId] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].id
  );
  const [namePlan, setNamePlan] = React.useState<string>("");
  const [paymentMode, setPaymentMode] = React.useState<any>([]);
  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign
  );
  const [insuranceType] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
  );
  const [insurance] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
  );
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [stepsData, setStepsData] = React.useState<MenuItem[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [plan, setPlan] = React.useState<InsurancePageProps>({
    titlePage: "Plan",
    viewType: "2",
    titleMetaTag: "Affinity Plan",
    informationPage: {
      backgroundColor: themeCore.colors.background,
      colorText: themeCore.colors.secondaryText,
      description: "Seleccion el plan que necesite el cliente",
      icon: icons.Help,
      iconSize: sizesIcon.standard,
      iconColor: themeCore.colors.primary,
    },
    filter: {
      backgroundColor: "#FFFFFF",
      bgChipInsurers: "#fff",
      bgChipInsurersSelected: "#EBEEF6",
      bgColorButton: "#fff",
      bgColorComparativeButton: "#3A56A1",
      bgColorIconButton: "#EBEEF6",
      bgColorIconButtonSelected: "#3A56A1",
      colorIconButton: "#3A56A1",
      colorIconButtonSelected: "#fff",
      colorIconChip: "#3A56A1",
      colorText: "#3A56A1",
      comparativeTextButton: "ver comparativa",
      comparativeTextColor: "#fff",
      insurers: [],
      title: "Patrocinador",
      titleFirstChip: "Todas",
      comparativeButtonDisabled: false,
    },
    data: [],
  });

  const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    })
  );

  // const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
  //   responsePlan: state.Plan.responsePlan,
  //   errorPlan: state.Plan.errorPlan,
  //   loading: state.Plan.loadingPlan,
  // }));

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    useSelector((state: any) => ({
      responsePaymentMode: state.PaymentMode.responsePaymentMode,
      errorPaymentMode: state.PaymentMode.errorPaymentMode,
      loadingPaymentMode: state.PaymentMode.loadingPaymentMode,
    }));

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    })
  );

  /* Mapeo de polizas y cotizaciones*/
  useEffect(() => {
    if (responseAllQuote) {
      /*Build Filter Quotes*/
      setFiltersQuotes([
        {
          label: insuranceType,
          name: "Tipo de Seguro",
          type: "label",
        },
        {
          label: insurance,
          name: "Seguro",
          type: "label",
        },
        {
          label: planSelectedNextStep.name,
          name: "Plan",
          type: "label",
        },
        {
          label: campaignInfo ? campaignInfo[0].name : "",
          name: "Campaña",
          type: "label",
        },
      ]);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      buildDataRowsQuotes(responseAllQuote);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    callDispatchPaymentMode();
    console.log("Plan::: ", stepProgress(STEP));
    setStepsData(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    // callDispatchCatalog();
  }, []);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorPaymentMode) {
      showErrorToast(errorPaymentMode, setSeeToast, t);
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (responsePlans && responsePlans.data) {
      //Vamos
      const dataST: itemPlanProps[] = [];
      responsePlans?.data.map((item: PlanModel) => {
        dataST.push({
          id: item.id,
          name: item.name ? item.name.replace(/"/g, "") : "",
          logo: item.sponsorLogoUrl && item.sponsorLogoUrl,
          backgroundColor: "#ffffff",
          currency: "RD$",
          itemsValue: [],
          option: setCoverTypeStorybook(item.coverTypes ? item.coverTypes : []),
          period: paymentModelSelected.name,
          benefits: item.benefits, // Added 'benefits' property
          scenaries: item.scenaries,
          //price: Array.isArray(item.scenaries) ? item.scenaries[0].price : "0",
          price: item?.price?.amount ? item.price.amount : "0",
          textColor: "#3A56A1",
        });
      });

      flagRangeAge?.forEach((actionFlag) => {
        if (actionFlag.name === "afy-plan-range-age") {
          setEnabledAgeRange(actionFlag.value === "true");
        }
      });

      setPlan({ ...plan, data: dataST });
    }
    if (responsePlans?.pagingInfo) {
      setCountPagination(responsePlans?.pagingInfo.totalItems);
    }
  }, [responsePlans]);

  useEffect(() => {
    if (responsePaymentMode && responsePaymentMode.data) {
      console.log("responsePaymentMode::: ", responsePaymentMode);
      const data = responsePaymentMode as PaymentModeResponse;
      let result = data.data?.map((x: PaymentMode) => {
        return {
          id: x.catalogPaymentMode.id,
          label: x.catalogPaymentMode.description,
          name: x.catalogPaymentMode.description,
          default:
            x.catalogPaymentMode.description?.toLocaleLowerCase() === "mensual"
              ? true
              : false,
        } as select;
      });

      if (result && result.length > 0) {
        let mensual = result.find((x: select) => x.default === true);
        if (mensual) {
          setPaymentModelSelected(mensual);
        } else {
          setPaymentModelSelected(result[0]);
        }
        let ms = setPaymentModeData(null, result);
        setPaymentMode(ms);
      }
    }
  }, [responsePaymentMode]);

  useEffect(() => {
    if (paymentModelSelected.id) {
      callDispatchPlan();
    }
  }, [paymentModelSelected]);

  useEffect(() => {
    if (coverTypeSelected && coverTypeSelected.id) {
      updateCoverPrice(planSelected, coverTypeSelected);
    }
  }, [coverTypeSelected]);

  useEffect(() => {
    if (namePlan) {
      callDispatchPlan();
    }
  }, [namePlan]);

  const buildDataRowsQuotes = (responseAllQuote: RowsModel[]) => {
    const dataR = responseAllQuote?.map((item: RowsModel) => ({
      id: item.id,
      storeChannel: "Sucursal 1",
      quote: item.policyId ? item.policyId?.code : item.code,
      policyId: item.policyId?.id,
      product: item.productId?.description,
      status: item.catalogStatusQuote?.description,
      name: `${item.firstNameA} ${item.firstNameB} ${item.lastNameA} ${item.lastNameB}`,
    }));

    setRowsQuotes(dataR);

    if (planSelectedNextStep.id) {
      if (dataR?.length > 0) {
        setShowModalQuotes(true);
      } else {
        navigate("/customerForm");
      }
    }
  };

  const setCoverTypeStorybook = (ct: PlanCoverTypes[]) => {
    const optionConverType: PlanSelectOption[] = [];
    ct.map((item: PlanCoverTypes) => {
      optionConverType.push({
        name: item.description ? item.description : "",
        value: item.id,
        id: item.id,
      });
    });
    return optionConverType;
  };

  const clickChip = (chips: MenuItem[]) => {
    console.log("clickChip::: ", chips);
  };

  const clickView = (view: MenuItem) => {
    let insuranceObj = { ...plan, viewType: view.id };
    setPlan(insuranceObj);
  };

  const searchFilter = (term: string) => {
    setNamePlan(term);
  };

  // const callDispatchCatalog = () => {
  //   if (!responsePlan) {
  //     dispatch(catalog({ enable: enable, page: page, limit: limit }));
  //   }
  // };

  const callDispatchPaymentMode = () => {
    if (projectId)
      dispatch(paymentModeAction({ enable: enable, page, limit, projectId }));
  };

  const callDispatchPlan = () => {
    // if (paymentMode[0].id !== "-1") {
    dispatch(
      plans({
        enable: enable,
        page: planPage,
        limit: planLimit,
        projectId: projectId,
        name: namePlan,
        paymentModeId: paymentModelSelected.id,
        coverTypeId: coverTypeSelected?.id,
        clientId: clientId,
      })
    );
    //}
  };

  const updateCoverPrice = (item: itemPlanProps, event: select) => {
    //vamos
    setUpdating(true);
    getPlans(
      enable,
      page,
      limit,
      projectId,
      namePlan,
      paymentModelSelected.id ?? "",
      event.id ?? "",
      clientId
    )
      .then((response: any) => {
        setUpdating(false);
        let result = response.data.find(
          (plan: any) => plan.id === changedPlanId
        );
        if (result) {
          let newPlan: InsurancePageProps = plan;

          newPlan.data?.forEach((plan) => {
            if (plan.id === result.id) {
              plan.price = result.price?.amount;
            }
          });
          setPlan({ ...newPlan });
        }
      })
      .catch((error: any) => {
        setUpdating(false);
      });
  };

  const clickChangePaymentMode = (
    event: select,
    item: itemPlanProps,
    row: any
  ) => {
    if (item?.id) {
      console.log(
        "Opcion de pago seleccionada: ",
        event,
        "Plan seleccionado: ",
        item.id,
        "Fila seleccionada: ",
        row
      );
      setChangedPlanId(item.id);
      setCoverTypeSelected(event);
      savePlan(null, event, item.id);
    } else {
      if (row) {
      } else {
        const t = paymentMode.find((p: any) => p.id === event);
        console.log("paymentModeSelected: ", t);
        if (t) {
          setPaymentModelSelected(t);
          let ms = setPaymentModeData(event, paymentMode);
          setPaymentMode(ms);
          if (coverTypeSelected && coverTypeSelected.id) {
            savePlan(null, coverTypeSelected, selectedPlanId);
          }
        } else {
          setCampaignData(event);
        }
        dispatch(plansClearData());
      }
    }
  };

  const clickDetails = (item: itemPlanProps) => {
    /*Valida si el plan a mostrar el detalle
     * ya tiene la respuesta del api solo muestra
     * el modal  si no la tiene llama al api para
     * recuperar los detalles */
    setShowDetails(true);
    /* if (planSelected.id === item.id) {
           console.log("--->",planSelected)
           setPlanSelected(planSelected);
           setShowDetail(true);
         }
     */
    // setIsDetailClick(true);
    setPlanSelected(item);
    setShowDetail(true);
  };

  const clickCompareDetails = (items: itemPlanProps[]) => {
    // setIsDetailClick(false);
    setShowCompareDetail(true);
  };

  const closeDetail = () => {
    setShowDetails(false);
    setShowDetail(false);
    setShowCompareDetail(false);
  };

  const onClickNewQuoteModal = () => {
    setShowModalQuotes(false);
    navigate("/customerForm");
  };

  const savePlan = (item: any, cv: any, cp: any) => {
    if (item) {
      localStorageService.setStepStored({
        id: item.id,
        name: item.name as string,
        step: Steps.plan,
        completed: true,
        paymentMode: paymentMode,
        paymentModeSelected: paymentModelSelected,
        coverType: cv ? cv : item.option ? item.option[0] : "",
        campaign: campaignInfo,
        campaignSelected: campaignInfo ? campaignInfo[0] : "",
      });
    } else {
      if (cp === selectedPlanId) {
        localStorageService.setStepStored({
          id: localStorageService.getStepStored().steps[Steps.plan].id,
          name: localStorageService.getStepStored().steps[Steps.plan]
            .name as string,
          step: Steps.plan,
          completed: true,
          paymentMode:
            localStorageService.getStepStored().steps[Steps.plan].paymentMode,
          paymentModeSelected:
            localStorageService.getStepStored().steps[Steps.plan]
              .paymentModeSelected,
          coverType: cv,
          campaign:
            localStorageService.getStepStored().steps[Steps.plan].campaign,
          campaignSelected:
            localStorageService.getStepStored().steps[Steps.plan]
              .campaignSelected,
        });
      }
    }
  };

  const onClickSelectRowQuote = (params: any) => {
    if (params.status.toLowerCase() === "cotizada") {
      navigate("/confirmationForm/" + params.id + "/true/true");
    } else {
      navigate("/confirmationForm/" + params.id + "/false/true");
    }
  };

  const clickCard = (card: itemPlanProps) => {
    console.log("clickCard::: ", card);
    let coverType = card.option?.find((i: any) => i.id === card.selected);
    setSelectedPlanId(card.id);
    setCoverTypeSelected(coverType);
    setPlanSelectedNextStep(card);
    savePlan(card, coverType, null);
    onClickNext(card);
  };

  const clickAcquire = (item: any) => {
    console.log("acquire::: ", item);
    let options: any = [];
    plan &&
      plan.data?.map((it: any) => {
        if (it.id === item.id) {
          options.push(it);
        }
      });
    item.option = options;
    savePlan(item, coverTypeSelected, null);
  };

  const onClickNext = (card: any) => {
    let sizeDoc = 0;

    if (card.id.length > 0) {
      /*
             let vars = [
               {
                 idType: idType,
                 docNumber: dns,
               },
             ];
     
             lsVariables.setVariablesStored(vars);
     
             dispatch(
               quoteAll({
                 clientId: clientId,
                 enable: enable,
                 page: page,
                 limit: limit,
                 numberId: dns,
                 fields: "abc",
               }),
             );
             */
      setFiltersQuotes([
        {
          label: insuranceType,
          name: "Tipo de Seguro",
          type: "label",
        },
        {
          label: insurance,
          name: "Seguro",
          type: "label",
        },
        {
          label: card.name,
          name: "Plan",
          type: "label",
        },
        {
          label: campaignInfo ? campaignInfo[0].name : "",
          name: "Campaña",
          type: "label",
        },
      ]);

      //navigate("/client-record");
      navigateNext(STEP, navigate);
    } else {
      /*Cuando no se tiene ningun plan selecionado */
      setSeeToastAlertNext({
        show: true,
        message: "¡Se debe de seleccionar un plan!",
        type: typeAlert.info,
      });
    }
  };

  const onClickBack = () => {
    navigateBack(STEP, navigate);
    //navigate("/insurance");
  };

  const handleCancelSearch = () => {
    setNamePlan("");
    dispatch(
      plans({
        enable: enable,
        page: page,
        limit: limit,
        projectId: projectId,
        name: "",
        paymentModeId: paymentModelSelected.id,
        coverTypeId: coverTypeSelected?.id,
        clientId: clientId,
      })
    );
  };

  const onClickCompareItem = (item: itemPlanProps) => {
    /* let planCompare = planSelectedCompare;
        let index = planCompare.findIndex((i) => i.id === item.id);
        if (index === -1) {
          planCompare.push(item);
        } else {
          planCompare.splice(index, 1);
        }
        setPlanSelectedCompare(planCompare); */
  };

  useEffect(() => {
    let isMounted = true;

    const callPlan = async () => {
      if (isMounted) {
        callDispatchPlan();
      }
    };

    callPlan();

    return () => {
      isMounted = false;
    };
  }, [planPage, planLimit]);

  const onChangePage = (e: number) => {
    setPlanPage(e);
  };

  const onChangeRowsPerPage = (e: number) => {
    setPlanLimit(e);
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      {seeToastAlertNext.show &&
        showToast(
          position.bottomRight,
          seeToastAlertNext.type,
          seeToastAlertNext.message,
          () => {
            setSeeToastAlertNext({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          }
        )}
      <LoadingAFY
        loading={loadingPlans || updating}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text=""
        bgColor={themeCore.colors.backgroundLoader}
      />

      {paymentMode && paymentMode.length > 0 && (
        <PlanView
          error={error}
          helperText={helperText}
          planSelected={planSelected}
          paymentMode={paymentMode}
          campaignInfo={campaignInfo}
          listIdentificationType={[]}
          showDetail={showDetail}
          showCompareDetail={showCompareDetail}
          closeDetail={closeDetail}
          plan={plan}
          breadCrumbs={breadCrumbs}
          clickDetails={clickDetails}
          clickCompareDetail={clickCompareDetails}
          onClickCompareItem={onClickCompareItem}
          clickChangePaymentMode={clickChangePaymentMode}
          searchFilter={searchFilter}
          clickView={clickView}
          clickCard={clickCard}
          steps={stepsData}
          onClickAcquire={clickAcquire}
          clickChip={clickChip}
          onClickNext={() => {}}
          onClickBack={onClickBack}
          extraInfoNames={extraInfoNames}
          insurance={insurance}
          insuranceType={insuranceType}
          showExtraInfo={enableAgeRange}
          showQuotes={showModalQuotes}
          onCloseModalQuote={onCloseModalQuotes}
          filtersQuote={filtersQuotes}
          rowsQuote={rowsQuotes}
          onClickSelectRowQuote={onClickSelectRowQuote}
          onClickNewQuote={onClickNewQuoteModal}
          filterTitleInsuranceInformation={"Información"}
          showInformation={false}
          onCancelSearch={handleCancelSearch}
          insurancePage={planPage}
          insuranceLimit={planLimit}
          countPagination={countPagination}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  );
};
export default Plan;

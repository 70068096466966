import {
  ROL,
  ROL_CLEAR_DATA,
  ROL_DISABLE,
  ROL_DISABLE_CLEAR_DATA,
  ROL_DISABLE_ERROR,
  ROL_DISABLE_SUCCESS,
  ROL_ERROR,
  ROL_PATCH,
  ROL_PATCH_CLEAR_DATA,
  ROL_PATCH_ERROR,
  ROL_PATCH_SUCCESS,
  ROL_POST,
  ROL_POST_CLEAR_DATA,
  ROL_POST_ERROR,
  ROL_POST_SUCCESS,
  ROL_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorRol: null,
  responseRol: null,
  loadingRol: false,
  errorRolPost: null,
  responseRolPost: null,
  loadingRolPost: false,
  errorRolPatch: null,
  responseRolPatch: null,
  loadingRolPatch: false,
  errorRolDisable: null,
  responseRolDisable: null,
  loadingRolDisable: false,
};

const Rol = (state = initialState, action) => {
  switch (action.type) {
    case ROL:
      state = {
        ...state,
        loadingRol: true,
        errorRol: null,
        responseRol: null,
      };
      break;
    case ROL_SUCCESS:
      state = {
        ...state,
        errorRol: null,
        loadingRol: false,
        responseRol: action.payload,
      };
      break;
    case ROL_ERROR:
      state = {
        ...state,
        errorRol: action.payload,
        loadingRol: false,
        responseRol: null,
      };
      break;
    case ROL_CLEAR_DATA:
      state = {
        ...state,
        errorRol: null,
        loadingRol: null,
        responseRol: false,
      };
      break;
    case ROL_POST:
      state = {
        ...state,
        loadingRolPost: true,
        errorRolPost: null,
        responseRolPost: null,
      };
      break;
    case ROL_POST_SUCCESS:
      state = {
        ...state,
        errorRolPost: null,
        loadingRolPost: false,
        responseRolPost: action.payload,
      };
      break;
    case ROL_POST_ERROR:
      state = {
        ...state,
        errorRolPost: action.payload,
        loadingRolPost: false,
        responseRolPost: null,
      };
      break;
    case ROL_POST_CLEAR_DATA:
      state = {
        ...state,
        errorRolPost: null,
        loadingRolPost: null,
        responseRolPost: false,
      };
      break;
    case ROL_PATCH:
      state = {
        ...state,
        loadingRolPatch: true,
        errorRolPatch: null,
        responseRolPatch: null,
      };
      break;
    case ROL_PATCH_SUCCESS:
      state = {
        ...state,
        errorRolPatch: null,
        loadingRolPatch: false,
        responseRolPatch: action.payload,
      };
      break;
    case ROL_PATCH_ERROR:
      state = {
        ...state,
        errorRolPatch: action.payload,
        loadingRolPatch: false,
        responseRolPatch: null,
      };
      break;
    case ROL_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorRolPatch: null,
        loadingRolPatch: null,
        responseRolPatch: false,
      };
      break;
    case ROL_DISABLE:
      state = {
        ...state,
        loadingRolDisable: true,
        errorRolDisable: null,
        responseRolDisable: null,
      };
      break;
    case ROL_DISABLE_SUCCESS:
      state = {
        ...state,
        errorRolDisable: null,
        loadingRolDisable: false,
        responseRolDisable: action.payload,
      };
      break;
    case ROL_DISABLE_ERROR:
      state = {
        ...state,
        errorRolDisable: action.payload,
        loadingRolDisable: false,
        responseRolDisable: null,
      };
      break;
    case ROL_DISABLE_CLEAR_DATA:
      state = {
        ...state,
        errorRolDisable: null,
        loadingRolDisable: null,
        responseRolDisable: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Rol;
import {
  LOGIN_USER,
  LOGIN_USER_CLEAR_DATA,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  USER,
  USER_CLEAR_DATA,
  USER_CONFIRM_EMAIL,
  USER_CONFIRM_EMAIL_CLEAR_DATA,
  USER_CONFIRM_EMAIL_ERROR,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_PASSWORD,
  USER_CONFIRM_PASSWORD_CLEAR_DATA,
  USER_CONFIRM_PASSWORD_ERROR,
  USER_CONFIRM_PASSWORD_SUCCESS,
  USER_CREATE,
  USER_CREATE_CLEAR_DATA,
  USER_CREATE_ERROR,
  USER_CREATE_SUCCESS,
  USER_ERROR,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_CLEAR_DATA,
  USER_PASSWORD_RESET_ERROR,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PATCH,
  USER_PATCH_CLEAR_DATA,
  USER_PATCH_ERROR,
  USER_PATCH_SUCCESS,
  USER_PUT_ROL,
  USER_PUT_ROL_CLEAR_DATA,
  USER_PUT_ROL_ERROR,
  USER_PUT_ROL_SUCCESS,
  USER_SUCCESS,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginUserSuccess = (success) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: success,
  };
};

export const loginUserError = (error) => {
  return {
    type: LOGIN_USER_ERROR,
    payload: error,
  };
};

export const loginUserClearData = () => {
  return {
    type: LOGIN_USER_CLEAR_DATA,
    payload: {},
  };
};

export const userCreate = (body) => {
  return {
    type: USER_CREATE,
    payload: body,
  };
};

export const userCreateSuccess = (success) => {
  return {
    type: USER_CREATE_SUCCESS,
    payload: success,
  };
};

export const userCreateError = (error) => {
  return {
    type: USER_CREATE_ERROR,
    payload: error,
  };
};

export const userCreateClearData = () => {
  return {
    type: USER_CREATE_CLEAR_DATA,
    payload: {},
  };
};

export const user = (body) => {
  return {
    type: USER,
    payload: { body },
  };
};

export const userSuccess = (success) => {
  return {
    type: USER_SUCCESS,
    payload: success,
  };
};

export const userError = (error) => {
  return {
    type: USER_ERROR,
    payload: error,
  };
};

export const userClearData = () => {
  return {
    type: USER_CLEAR_DATA,
    payload: {},
  };
};

export const userPasswordReset = (email) => {
  return {
    type: USER_PASSWORD_RESET,
    payload: email,
  };
};

export const userPasswordResetSuccess = (success) => {
  return {
    type: USER_PASSWORD_RESET_SUCCESS,
    payload: success,
  };
};

export const userPasswordResetError = (error) => {
  return {
    type: USER_PASSWORD_RESET_ERROR,
    payload: error,
  };
};

export const userPasswordResetClearData = () => {
  return {
    type: USER_PASSWORD_RESET_CLEAR_DATA,
    payload: {},
  };
};

export const userConfirmPassword = (body) => {
  return {
    type: USER_CONFIRM_PASSWORD,
    payload: body,
  };
};

export const userConfirmPasswordSuccess = (success) => {
  return {
    type: USER_CONFIRM_PASSWORD_SUCCESS,
    payload: success,
  };
};

export const userConfirmPasswordError = (error) => {
  return {
    type: USER_CONFIRM_PASSWORD_ERROR,
    payload: error,
  };
};

export const userConfirmPasswordClearData = () => {
  return {
    type: USER_CONFIRM_PASSWORD_CLEAR_DATA,
    payload: {},
  };
};

export const userConfirmEmail = (body) => {
  return {
    type: USER_CONFIRM_EMAIL,
    payload: body,
  };
};

export const userConfirmEmailSuccess = (success) => {
  return {
    type: USER_CONFIRM_EMAIL_SUCCESS,
    payload: success,
  };
};

export const userConfirmEmailError = (error) => {
  return {
    type: USER_CONFIRM_EMAIL_ERROR,
    payload: error,
  };
};

export const userConfirmEmailClearData = () => {
  return {
    type: USER_CONFIRM_EMAIL_CLEAR_DATA,
    payload: {},
  };
};

export const userPatch = (body) => {
  return {
    type: USER_PATCH,
    payload: body,
  };
};

export const userPatchSuccess = (success) => {
  return {
    type: USER_PATCH_SUCCESS,
    payload: success,
  };
};

export const userPatchError = (error) => {
  return {
    type: USER_PATCH_ERROR,
    payload: error,
  };
};

export const userPatchClearData = () => {
  return {
    type: USER_PATCH_CLEAR_DATA,
    payload: {},
  };
};

export const userPutRol = (body) => {
  return {
    type: USER_PUT_ROL,
    payload: body,
  };
};

export const userPutRolSuccess = (success) => {
  return {
    type: USER_PUT_ROL_SUCCESS,
    payload: success,
  };
};

export const userPutRolError = (error) => {
  return {
    type: USER_PUT_ROL_ERROR,
    payload: error,
  };
};

export const userPutRolClearData = () => {
  return {
    type: USER_PUT_ROL_CLEAR_DATA,
    payload: {},
  };
};
import { call, put, takeEvery } from "redux-saga/effects";
import {
  catalogAccountTypeError,
  catalogAccountTypeSuccess,
  catalogGenderError,
  catalogGenderSuccess,
  planError,
  planSuccess,
  planPostError,
  planPostSuccess,
  planPutError,
  planPutSuccess,
} from "./actions";
import { getPlanCatalog } from "../../resources/helper/helper";
import { CATALOG_ACCOUNT_TYPE, CATALOG_GENDER, PLAN, PLAN_POST, PLAN_PUT } from "./actionTypes";
import {
  getAccountTypeCatalog,
  getGenderCatalog,

} from "../../resources/helper/catalog";

import { postPlanApi, patchPlanApi } from "../../resources/helper/plans";

function* plan({ payload: { plan } }) {
  try {
    const response = yield call(
      getPlanCatalog,
      plan.enable,
      plan.page,
      plan.limit,
    );
    yield put(planSuccess(response));
  } catch (error) {
    yield put(planError(error));
  }
}

function* catalogGender({ payload: { catalogGender } }) {
  try {
    const response = yield call(
      getGenderCatalog,
      catalogGender.enable,
      catalogGender.page,
      catalogGender.limit,
      catalogGender.clientId,
    );
    yield put(catalogGenderSuccess(response));
  } catch (error) {
    yield put(catalogGenderError(error));
  }
}

function* catalogAccountType({ payload: { catalogAccountType } }) {
  try {
    const response = yield call(
      getAccountTypeCatalog,
      catalogAccountType.enable,
      catalogAccountType.page,
      catalogAccountType.limit,
      catalogAccountType.projectId,
    );
    yield put(catalogAccountTypeSuccess(response));
  } catch (error) {
    yield put(catalogAccountTypeError(error));
  }
}

function* postPlan({ payload: { plan, history } }) {
  try {
    const response = yield call(postPlanApi, plan);
    yield put(planPostSuccess(response));
    //history.push("/plans");
  } catch (error) {
    yield put(planPostError(error));
  }
}

function* putPlan({ payload: { plan, id, history } }) {
  try {
    const response = yield call(patchPlanApi, plan, id);
    yield put(planPutSuccess(response));
    //history.push("/plans");
  }
  catch (error) {
    yield put(planPutError(error));
  }
}



function* planSaga() {
  yield takeEvery(PLAN, plan);
  yield takeEvery(CATALOG_GENDER, catalogGender);
  yield takeEvery(CATALOG_ACCOUNT_TYPE, catalogAccountType);
  yield takeEvery(PLAN_POST, postPlan);
  yield takeEvery(PLAN_PUT, putPlan);
}

export default planSaga;

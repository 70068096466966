import React from "react";
import { LoginAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const LoginView = (props: any) => {
  return (
    <LoginAFY
      onSignIn={props.onSingIn}
      title={props.title}
      logo={props.logo}
      emailError={props.emailError}
      emailRequired={props.emailRequired}
      emailText={props.emailText}
      emailHelperText={props.emailHelperText}
      passwordHelperText={props.passwordHelperText}
      passwordText={props.passwordText}
      passwordError={props.passwordError}
      passwordRequired={props.passwordRequired}
      labelButtonLogin={props.labelButtonLogin}
      labelButtonForgetPassword={props.labelButtonForgetPassword}
      labelInputPassword={props.labelInputPassword}
      userForm={props.userForm}
      onClickForgetPassword={props.onClickForgetPassword}
    />
  );
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  projectError,
  projectOneError,
  projectOneSuccess,
  projectPatchError,
  projectPatchSuccess,
  projectPostError,
  projectPostSuccess,
  projectSuccess,
} from "./actions";
import {
  getProject,
  getProjectId,
  patchProjectApi,
  postProjectApi,
} from "../../resources/helper/project";
import {
  PROJECT,
  PROJECT_ONE,
  PROJECT_PATCH,
  PROJECT_POST,
} from "./actionTypes";

function* project({ payload: { project } }) {
  try {
    const response = yield call(
      getProject,
      project.enable,
      project.page,
      project.limit,
      project.clientId,
      project.projectType,
      project.sponsorId,
      project.description,
    );
    yield put(projectSuccess(response, project.level));
  } catch (error) {
    yield put(projectError(error));
  }
}

function* oneProject({ payload: { project } }) {
  try {
    const response = yield call(getProjectId, project.id);
    yield put(projectOneSuccess(response));
  } catch (error) {
    yield put(projectOneError(error));
  }
}

function* postProject({ payload: { project, history } }) {
  try {
    const response = yield call(postProjectApi, project);
    yield put(projectPostSuccess(response));
  } catch (error) {
    yield put(projectPostError(error));
  }
}

function* projectPatch({ payload: { projectBody, id } }) {
  try {
    const response = yield call(patchProjectApi, projectBody, id);
    yield put(projectPatchSuccess(response));
  } catch (error) {
    yield put(projectPatchError(error));
  }
}

function* projectSaga() {
  yield takeEvery(PROJECT, project);
  yield takeEvery(PROJECT_ONE, oneProject);
  yield takeEvery(PROJECT_POST, postProject);
  yield takeEvery(PROJECT_PATCH, projectPatch);
}

export default projectSaga;

import {
  icons,
  MenuItem,
  select,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const menuItem: MenuItem[] = [
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
    id: "1",
    name: "Editar",
  },
  {
    icon: {
      color: "#006ac6",
      icon: icons.Delete,
      size: sizesIcon.standard,
    },
    disabled: false,
    id: "2",
    name: "Eliminar",
  },
];
export const optionSelect: select[] = [
  {
    id: "1",
    name: "Nombre",
    value: "name",
  },
  {
    id: "2",
    name: "Codigo",
    value: "code",
  },
];
export const selectValueDefault: string = "name";

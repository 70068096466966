import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { identificationTypePost } from "../../store/identificationType/actions";
import {  IdentificationTypeRequest } from "../../model/identificationType";

export const useIdentificationTypePost = (
  trigger: boolean,
  bodyRequest: IdentificationTypeRequest,
) => {
  const dispatch = useDispatch();

  const { responsePostIdentificationType, errorPostIdentificationType, loadingPostIdentificationType } = useSelector(
    (state: any) => ({
      responsePostIdentificationType: state.IdentificationType.responsePostIdentificationType?.data,
      errorPostIdentificationType: state.IdentificationType.errorPostIdentificationType,
      loadingPostIdentificationType: state.IdentificationType.loadingPostIdentificationType,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        identificationTypePost(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePostIdentificationType,
    errorPostIdentificationType,
    loadingPostIdentificationType,
  };
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalCatalogPost } from "../../store/generalCatalog/actions";

export const useGeneralCatalogPost = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const {
    responseGeneralCatalogPost,
    errorGeneralCatalogPost,
    loadingGeneralCatalogPost,
  } = useSelector((state: any) => ({
    responseGeneralCatalogPost:
      state.GeneralCatalog.responseGeneralCatalogPost?.data,
    errorGeneralCatalogPost: state.GeneralCatalog.errorGeneralCatalogPost,
    loadingGeneralCatalogPost: state.GeneralCatalog.loadingGeneralCatalogPost,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        generalCatalogPost({
          clientId: body.data.clientId,
          type: body.data.type,
          code: body.data.code,
          description: body.data.description,
        }),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseGeneralCatalogPost,
    errorGeneralCatalogPost,
    loadingGeneralCatalogPost,
  };
};

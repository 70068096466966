import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { regionPatch } from "../../store/region/actions";
import {  RegionRequest } from "../../model/region";

export const useRegionPatch = (
  trigger: boolean,
  bodyRequest: RegionRequest,
) => {
  const dispatch = useDispatch();

  const { responsePatchRegion, errorPatchRegion, loadingPatchRegion } = useSelector(
    (state: any) => ({
      responsePatchRegion: state.Region.responsePatchRegion?.data,
      errorPatchRegion: state.Region.errorPatchRegion,
      loadingPatchRegion: state.Region.loadingPatchRegion,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        regionPatch(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePatchRegion,
    errorPatchRegion,
    loadingPatchRegion,
  };
};

import {
  CardProps,
  LoadingAFY,
  PendingPageAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
import React from "react";
import { columnsFn } from "./DTO";
import { themeCore } from "../../assets/themes/theme";

export const PendingPageView = (props: {
  title: string;
  loadingDocumentStatistics: boolean;
  handleClickContextMenu: (item: any, param: any) => void;
  dataCard?: CardProps[];
  rows?: any[];
}) => {
  return (
    <>
      <HeaderTitlePage
        breadCrumbs={[]}
        titlePage={props.title}
        steps={[]}
        hideStep={true}
        stepsTop={""}
      />
      <LoadingAFY
        loading={props.loadingDocumentStatistics}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <PendingPageAFY
        heightGrid="calc(100vh - 351px)"
        dataCard={props.dataCard}
        columns={columnsFn(props.handleClickContextMenu)}
        rows={props.rows}
      />
    </>
  );
};

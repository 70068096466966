import {
  PLANS,
  PLANS_CLEAR_DATA,
  PLANS_ERROR,
  PLAN_ONE,
  PLAN_ONE_SUCCESS,
  PLANS_SUCCESS,
  POST_PLAN,
  POST_PLAN_CLEAR_DATA,
  POST_PLAN_ERROR,
  POST_PLAN_SUCCESS,
  PATCH_PLAN,
  PATCH_PLAN_CLEAR_DATA,
  PATCH_PLAN_ERROR,
  PATCH_PLAN_SUCCESS,
} from "./actionTypes";

export const plans = (plans, history) => {
  return {
    type: PLANS,
    payload: { plans, history },
  };
};

export const planOne = (plan, history) => {
  return {
    type: PLAN_ONE,
    payload: { plan, history },
  };
};

export const plansSuccess = (success) => {
  return {
    type: PLANS_SUCCESS,
    payload: success,
  };
};

export const plansError = (error) => {
  return {
    type: PLANS_ERROR,
    payload: error,
  };
};

export const planOneSuccess = (success) => {
  return {
    type: PLAN_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const plansClearData = () => {
  return {
    type: PLANS_CLEAR_DATA,
    payload: {},
  };
};

export const postPlan = (planBody, history) => {
  return {
    type: POST_PLAN,
    payload: { planBody, history },
  };
};

export const postPlanSuccess = (success) => {
  return {
    type: POST_PLAN_SUCCESS,
    payload: success,
  };
};

export const postPlanError = (error) => {
  return {
    type: POST_PLAN_ERROR,
    payload: error,
  };
};

export const postPlanClearData = () => {
  return {
    type: POST_PLAN_CLEAR_DATA,
    payload: {},
  };
};

export const patchPlan = (planBody, history) => {
  return {
    type: PATCH_PLAN,
    payload: { planBody, history },
  };
};

export const patchPlanSuccess = (success) => {
  return {
    type: PATCH_PLAN_SUCCESS,
    payload: success,
  };
};

export const patchPlanError = (error) => {
  return {
    type: PATCH_PLAN_ERROR,
    payload: error,
  };
};

export const patchPlanClearData = () => {
  return {
    type: PATCH_PLAN_CLEAR_DATA,
    payload: {},
  };
};

import {
  PROJECT,
  PROJECT_CLEAR_DATA,
  PROJECT_ERROR,
  PROJECT_ONE,
  PROJECT_ONE_CLEAR_DATA,
  PROJECT_ONE_ERROR,
  PROJECT_ONE_SUCCESS,
  PROJECT_PATCH,
  PROJECT_PATCH_CLEAR_DATA,
  PROJECT_PATCH_ERROR,
  PROJECT_PATCH_SUCCESS,
  PROJECT_POST,
  PROJECT_POST_CLEAR_DATA,
  PROJECT_POST_ERROR,
  PROJECT_POST_SUCCESS,
  PROJECT_SUCCESS,
} from "./actionTypes";

export const project = (project, history) => {
  return {
    type: PROJECT,
    payload: { project, history },
  };
};

export const projectSuccess = (success) => {
  return {
    type: PROJECT_SUCCESS,
    payload: { ...success },
  };
};

export const projectError = (error) => {
  return {
    type: PROJECT_ERROR,
    payload: error,
  };
};

export const projectClearData = () => {
  return {
    type: PROJECT_CLEAR_DATA,
    payload: {},
  };
};

export const projectPost = (project, history) => {
  return {
    type: PROJECT_POST,
    payload: { project, history },
  };
};

export const projectPostSuccess = (success) => {
  return {
    type: PROJECT_POST_SUCCESS,
    payload: { ...success },
  };
};

export const projectPostError = (error) => {
  return {
    type: PROJECT_POST_ERROR,
    payload: error,
  };
};
export const projectPostClearData = () => {
  return {
    type: PROJECT_POST_CLEAR_DATA,
    payload: {},
  };
};

export const projectPatch = (projectBody, id) => {
  return {
    type: PROJECT_PATCH,
    payload: { projectBody, id },
  };
};

export const projectPatchSuccess = (success) => {
  return {
    type: PROJECT_PATCH_SUCCESS,
    payload: success,
  };
};

export const projectPatchError = (error) => {
  return {
    type: PROJECT_PATCH_ERROR,
    payload: error,
  };
};

export const projectPatchClearData = () => {
  return {
    type: PROJECT_PATCH_CLEAR_DATA,
    payload: {},
  };
};

export const projectOne = (project, history) => {
  return {
    type: PROJECT_ONE,
    payload: { project, history },
  };
};

export const projectOneSuccess = (success) => {
  return {
    type: PROJECT_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const projectOneError = (error) => {
  return {
    type: PROJECT_ONE_ERROR,
    payload: error,
  };
};

export const projectOneClearData = () => {
  return {
    type: PROJECT_ONE_CLEAR_DATA,
    payload: {},
  };
};

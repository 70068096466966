export const ROL = "ROL";
export const ROL_SUCCESS = "ROL_SUCCESS";
export const ROL_ERROR = "ROL_ERROR";
export const ROL_CLEAR_DATA = "ROL_CLEAR_DATA";
export const ROL_POST = "ROL_POST";
export const ROL_POST_SUCCESS = "ROL_POST_SUCCESS";
export const ROL_POST_ERROR = "ROL_POST_ERROR";
export const ROL_POST_CLEAR_DATA = "ROL_POST_CLEAR_DATA";
export const ROL_PATCH = "ROL_PATCH";
export const ROL_PATCH_SUCCESS = "ROL_PATCH_SUCCESS";
export const ROL_PATCH_ERROR = "ROL_PATCH_ERROR";
export const ROL_PATCH_CLEAR_DATA = "ROL_PATCH_CLEAR_DATA";
export const ROL_DISABLE = "ROL_DISABLE";
export const ROL_DISABLE_SUCCESS = "ROL_DISABLE_SUCCESS";
export const ROL_DISABLE_ERROR = "ROL_DISABLE_ERROR";
export const ROL_DISABLE_CLEAR_DATA = "ROL_DISABLE_CLEAR_DATA";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchesPatch } from "../../store/branches/actions";

export const useBranchesPatch = (trigger: boolean, body?: any, id?: string) => {
  const dispatch = useDispatch();

  const { responseBranchesPatch, errorBranchesPatch, loadingBranchesPatch } =
    useSelector((state: any) => ({
      responseBranchesPatch: state.Branches.responseBranchesPatch?.data,
      errorBranchesPatch: state.Branches.errorBranchesPatch,
      loadingBranchesPatch: state.Branches.loadingBranchesPatch,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        branchesPatch(
          {
            name: body.data.name,
            code: body.data.code,
            status: body.data.status,
            clientId: body.data.clientId,
          },
          id,
        ),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseBranchesPatch,
    errorBranchesPatch,
    loadingBranchesPatch,
  };
};

import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  CardProps,
  AdminPlanAFY,
  FormCreatePlan,
  LoadingAFY,
  ModalComponentAFY,
  variants,
  sizes,
  typeLoaders,
  ButtonAFY,
  MenuItem
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions, identificationTypeView } from "./data";
import { themeCore } from "../../assets/themes/theme";
import { PremiumEvalOption } from "../../model/premiumEvalOption";

export const View = (props: {
  title: string;
  list: CardProps[];
  openModal: boolean;
  openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  onSaveData: any;
  loadingPost: any;
  dataForm: any;
  modalTitle:string;
  Options: any;
  filters: any;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitClone: () => void;
  
  onNext: () => void;
  
  onClose: () => void;
  onCloseConfirm: () => void;
  onCloseCloneModal : () => void; 

  handleOpenModal: () => void;  
  handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  
  onClickAdd: () => void;
  onClickButtonOptionsDropdown: () => void;
  
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        modalWidth={790}
        border={0}
        //modalHeight={790}
      >
      <FormCreatePlan
        OptionList={props.Options}
        dataForm={props.dataForm}
        initialPercentageBeneficiaries={100}
        labelPercentage="Porcentaje a beneficiar"
        onBlurInput={() => {}}
        onChangeCheckBox={function noRefCheck() {}}
        onChangeInput={function noRefCheck() {}}
        onChangeSelect={function noRefCheck() {}}
        onChangeUpload={function noRefCheck() {}}
        onClickAcquire={function noRefCheck() {}}
        onClickCancel={function noRefCheck() {}}
        
        onClose={function noRefCheck() {}}
        onImage={function noRefCheck() {}}
        onNext={props.onNext}
        
        onUpload={function noRefCheck() {}}
        openModal
        showPercentage
        stepPercentageBeneficiaries={5}
        formsCompleted={[]}
        subtitle="Beneficiario"
        title="Agregar Plan"
        typeInsuredOption={[]}
      />
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseConfirm}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Eliminar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Eliminar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={
                  props.onCloseConfirm
                }
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, cancelar"}
                backgroundColor="red"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalCloneConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseCloneModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Clonar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Clonar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={
                  props.onCloseCloneModal
                }
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, Clonar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmitClone();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>
    
    <AdminPlanAFY
    buttonActionOptions={buttonActionOptions}
    data={props.list}
    filters={props.filters}
    onBack={() => {}}
    onClickAdd={props.onClickAdd}
    onClickButtonOptionsDropdown={props.onClickButtonOptionsDropdown}
    onClickButtonView={(option: MenuItem) => {      props.clickView && props.clickView(option);    }}
    onClickPlanTemplates={function noRefCheck() {}}
    onNext={function noRefCheck() {}}
    titleColor="#3A56A1"
    />

    </>
  );
};

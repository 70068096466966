import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountTypePatch } from "../../store/accountType/actions";

export const useAccountTypePatch = (
  trigger: boolean,
  body?: any,
  id?: string,
) => {
  const dispatch = useDispatch();

  const {
    responseAccountTypePatch,
    errorAccountTypePatch,
    loadingAccountTypePatch,
  } = useSelector((state: any) => ({
    responseAccountTypePatch: state.AccountType.responseAccountTypePatch?.data,
    errorAccountTypePatch: state.AccountType.errorAccountTypePatch,
    loadingAccountTypePatch: state.AccountType.loadingAccountTypePatch,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        accountTypePatch(
          {
            clientId: body.data.clientId,
            description: body.data.description,
          },
          id,
        ),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseAccountTypePatch,
    errorAccountTypePatch,
    loadingAccountTypePatch,
  };
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  getDocumentsStatisticsError,
  getDocumentsStatisticsSuccess,
} from "./actions";
import { DOCUMENTS_STATISTICS } from "./actionTypes";
import { documentsStatisticsAPI } from "../../resources/helper/documentsStatistics";

function* getDocumentsStatistics() {
  try {
    const response = yield call(documentsStatisticsAPI);
    yield put(getDocumentsStatisticsSuccess(response));
  } catch (error) {
    yield put(getDocumentsStatisticsError(error));
  }
}

function* documentsStatisticsSaga() {
  yield takeEvery(DOCUMENTS_STATISTICS, getDocumentsStatistics);
}

export default documentsStatisticsSaga;

import { call, put, takeEvery } from "redux-saga/effects";
import {
  clientSuccess,
  clientOneSuccess,
  clientError,
  clientOneByProjectSuccess,
} from "./actions";
import {
  getClient,
  getOneClient,
  getOneProject,
  getOneSponsor,
} from "../../resources/helper/helper";
import { CLIENT, CLIENT_ONE, CLIENT_ONE_BY_PROJECT } from "./actionTypes";
import { countriesError } from "../countries/actions";

function* client({ payload: { client } }) {
  try {
    const response = yield call(
      getClient,
      client.enable,
      client.page,
      client.limit,
    );
    yield put(clientSuccess(response));
  } catch (error) {
    yield put(clientError(error));
  }
}

function* oneClient({ payload: { client } }) {
  try {
    const response = yield call(getOneClient, client.id);
    yield put(clientOneSuccess(response));
  } catch (error) {
    yield put(clientError(error));
  }
}

function* oneClientByProject({ payload: { client } }) {
  try {
    const responseProject = yield call(getOneProject, client.projectId);
    const responseSponsor = yield call(
      getOneSponsor,
      responseProject.data.sponsorId,
    );
    const responseClient = yield call(
      getOneClient,
      responseSponsor.data.clientId,
    );
    yield put(clientOneByProjectSuccess(responseClient));
  } catch (error) {
    yield put(countriesError(error));
  }
}

function* clientSaga() {
  yield takeEvery(CLIENT, client);
  yield takeEvery(CLIENT_ONE, oneClient);
  yield takeEvery(CLIENT_ONE_BY_PROJECT, oneClientByProject);
}

export default clientSaga;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogAccountType } from "../../store/catalog/actions";

export const GetCatalogAccountType = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const dispatch = useDispatch();

  const {
    responseGetCatalogAccountType,
    loadingGetCatalogAccountType,
    errorGetCatalogAccountType,
  } = useSelector((state: any) => ({
    responseGetCatalogAccountType:
      state.Catalog.responseGetCatalogAccountType?.data,
    loadingGetCatalogAccountType: state.Catalog.loadingGetCatalogAccountType,
    errorGetCatalogAccountType: state.Catalog.errorGetCatalogAccountType,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCatalogAccountType({
          enable,
          page,
          limit,
          type: "CATALOG_ACCOUNT_TYPE",
          clientId,
        }),
      );
    }
  }, [trigger, enable, page, limit, clientId]);

  return {
    responseGetCatalogAccountType,
    loadingGetCatalogAccountType,
    errorGetCatalogAccountType,
  };
};

import React from "react";
import "./style.css";

import {
  AdminUserAFY,
  icons,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ModalDelete } from "./resources/modalDelete";
import { Rol } from "../../model/rol";
import { ModalConfirmationDelete } from "../insurances/productCreation/resources/modalConfirmationDelete";

export const View = (props: {
  // Props Admin
  title: string;
  columns: any;
  buttonTextAdd?: string;
  onClickAdd: () => void;
  permissions: any;

  // Props Form
  titleForm?: string;
  dataForm: any;
  editMode: boolean;
  branchTypeOptions?: Array<any>;
  roleTypeOptions?: Array<any>;
  intermediaryTypeOptions?: Array<any>;
  genderTypeOptions?: Array<any>;
  identificationTypeOptions?: Array<any>;
  statusOptions?: Array<any>;
  emailText?: string;
  emailRequired?: string;
  emailError?: string;
  emailHelperText?: string;
  nameText?: string;
  nameRequired?: string;
  nameHelperText?: string;
  lastNameText?: string;
  lastNameRequired?: string;
  lastNameHelperText?: string;
  passwordText?: string;
  passwordRequired?: string;
  passwordError?: string;
  passwordHelperText?: string;
  passwordConfirmText?: string;
  passwordConfirmRequired?: string;
  passwordConfirmError?: string;
  passwordConfirmHelperText?: string;
  birthDateText?: string;
  birthDateHelperText?: string;
  birthDateRequired?: string;
  identificationTypeText?: string;
  companyTypeText?: string;
  companyTypeOptions?: string;
  branchTypeText?: string;
  roleTypeText?: string;
  intermediaryTypeText?: string;
  genderTypeText?: string;
  identificationNumberText?: string;
  identificationNumberRequired?: string;
  identificationNumberHelperText?: string;
  userNameText?: string;
  userNameRequired?: string;
  userNameHelperText?: string;
  userformText?: string;
  imageUrl?: string;
  activeStep: number;
  buttonText?: string;
  buttonNextText?: string;
  buttonBackText?: string;
  onSaveData: any;

  // Props Drawer
  openDrawer: boolean;
  textDescription: string;
  onCloseDrawer: () => void;

  // Props Search
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;

  openModalDeleteRole: boolean;
  setOpenModalDeleteRole: (value: boolean | (() => boolean)) => void;

  list: any;
  openModal: boolean;
  openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  modalTitle: string;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  handleOpenModal: () => void;
  handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  onClickButtonOptionsDropdown: () => void;
  onClickContextMenu: () => void;
  menuItem: any[];

  //  Rol
  onCloseDrawerRolsUser: () => void;
  openDrawerRoles: boolean;
  rowsRols: Rol[];
  listDropDownRols: any[];
  columnRols: any[];
  buttonLblAddGrid: string;
  labelDropDownRols: string;
  txtSearchDropDownRols: string;
  defaultDropDownRols: any[];
  titleDrawerRol: string;
  textDescriptionRol: string;
  onClickSaveDataRol: (e: React.MouseEvent<HTMLButtonElement>) => void;
  titleDelete?: string;
  subTitleDelete?: string;
  modalTitleRol: string;
  onSubmitDeleteRol: () => void;
}) => {
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />

      <ModalConfirmationDelete
        openModal={props.openModalDeleteRole}
        setOpenModal={props.setOpenModalDeleteRole}
        titleDelete={props.titleDelete}
        subTitleDelete={props.subTitleDelete}
        deleteRecurrence={props.onSubmitDeleteRol}
        deletePaymentMode={props.onSubmitDeleteRol}
      />

      <AdminUserAFY
        // Header toolbar
        title="Usuarios"
        titleColor="#3A56A1"
        insurersView={[
          {
            id: "1",
            name: "List",
            active: false,
            icon: {
              icon: icons.List,
            },
          },
          {
            id: "2",
            name: "CardView",
            active: true,
            icon: {
              icon: icons.GridView,
            },
          },
          {
            id: "3",
            name: "GridView",
            active: true,
            icon: {
              icon: icons.BlurLinear,
            },
          },
        ]}
        // Filter Props
        permissions={props.permissions}
        onChangeSelectBranch={function noRefCheck() {}}
        onChangeSelectRol={function noRefCheck() {}}
        onChangeSelectStatus={function noRefCheck() {}}
        onChangeTextSearch={function noRefCheck() {}}
        onCloseSearch={props.onCloseSearch}
        onChangeSelect={function noRefCheck() {}}
        roleTypeOptions={props.roleTypeOptions}
        branchTypeOptions={props.branchTypeOptions}
        statusTypeOptions={[
          {
            id: 1,
            name: "Activo",
            value: "1",
          },
          {
            id: 2,
            name: "Inactivo",
            value: "2",
          },
        ]}
        colorClientLabel="#3A56A1"
        onClearData={function noRefCheck() {}}
        // Drawer Props
        openDrawer={props.openDrawer}
        onCloseDrawer={props.onCloseDrawer}
        // Admin Props
        buttonTextAdd="Agregar usuario"
        buttonText="Guardar"
        columns={props.columns}
        rows={props.list}
        onBack={() => {}}
        onNext={function noRefCheck() {}}
        onClickAdd={props.onClickAdd}
        // Props Cards
        buttonLblAddGridView="Agregar"
        heightAddNew="136px"
        heightAddNewList="68px"
        heightCard="136px"
        heightCardList="68px"
        menuItem={props.menuItem}
        //New ViewGrid
        columnsGridView={[
          {
            field: "type",
            headerName: "",
            // renderCell: function noRefCheck() {},
            width: 80,
          },
          {
            field: "name",
            flex: 1,
            headerName: "Nombres",
            width: 150,
          },
          {
            field: "lastName",
            flex: 1,
            headerName: "Apellidos",
            width: 150,
          },
          {
            field: "email",
            flex: 1,
            headerName: "Email",
            width: 150,
          },
          {
            field: "status",
            flex: 1,
            headerName: "Estatus",
            width: 150,
          },
          {
            field: "rols",
            flex: 1,
            headerName: "Roles",
            width: 150,
          },
          {
            field: "branch",
            flex: 1,
            headerName: "Sucursal",
            width: 150,
          },
          {
            field: "customer",
            flex: 1,
            headerName: "Clientes",
            width: 150,
          },
          {
            field: "deleted",
            flex: 1,
            headerName: "Eliminado",
            width: 150,
          },
          {
            field: "dateCreated",
            flex: 1,
            headerName: "Fecha Creación",
            width: 150,
          },
          {
            field: "avatar",
            flex: 1,
            headerName: "Avatar",
            width: 150,
          },
        ]}
        rowsGridView={[
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "SI",
            email: "add@yopmail.com",
            id: "1",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "NO",
            email: "add@yopmail.com",
            id: "2",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "SI",
            email: "add@yopmail.com",
            id: "3",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "NO",
            email: "add@yopmail.com",
            id: "4",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "SI",
            email: "add@yopmail.com",
            id: "5",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
          {
            avatar: "",
            branch: "Sucursal Test 01",
            customer: "Cliente del fuego",
            dateCreated: "18/11/2024",
            deleted: "NO",
            email: "add@yopmail.com",
            id: "6",
            lastName: "Slow Motion",
            name: "John Caseth",
            rols: "Rol1|Rol2|Rol3",
            status: "Activo",
          },
        ]}
        data={props.list}
        // Form Props
        titleForm={props.titleForm}
        onSaveData={props.onSaveData}
        editMode={props.editMode}
        emailText={props.emailText}
        emailRequired={props.emailRequired}
        emailError={props.emailError}
        nameText={props.nameText}
        nameRequired={props.nameRequired}
        lastNameText={props.lastNameText}
        lastNameRequired={props.lastNameRequired}
        passwordText={props.passwordText}
        passwordRequired={props.passwordRequired}
        passwordError={props.passwordError}
        passwordConfirmText={props.passwordConfirmText}
        passwordConfirmRequired={props.passwordConfirmRequired}
        passwordConfirmError={props.passwordConfirmError}
        birthDateText={props.birthDateText}
        birthDateRequired={props.birthDateRequired}
        identificationTypeText={props.identificationTypeText}
        identificationTypeOptions={props.identificationTypeOptions}
        companyTypeText={props.companyTypeText}
        branchTypeText={props.branchTypeText}
        roleTypeText={props.roleTypeText}
        intermediaryTypeText={props.intermediaryTypeText}
        genderTypeText={props.genderTypeText}
        genderTypeOptions={props.genderTypeOptions}
        identificationNumberText={props.identificationNumberText}
        identificationNumberRequired={props.identificationNumberRequired}
        identificationNumberHelperText={props.identificationNumberHelperText}
        userNameText={props.userNameText}
        userNameRequired={props.userNameRequired}
        userNameHelperText={props.userNameHelperText}
        textDescription={props.textDescription}
        activeStep={1}
        buttonNextText={props.buttonNextText}
        buttonBackText={props.buttonBackText}
        //unkown props
        onBlurSearch={function noRefCheck() {}}
        onChangeData={props.filterStatus}
        onChangeSearch={props.searchFilter}
        onClickButtonOptionsDropdown={function noRefCheck() {}}
        onClickButtonView={function noRefCheck() {}}
        onClickContextMenu={function noRefCheck() {}}
        onClickDetails={function noRefCheck() {}}
        onClickSeeDetails={function noRefCheck() {}}
        dataForm={props.dataForm}
        //Drawer Rols
        openDrawerRol={props.openDrawerRoles}
        listDropDownRol={props.listDropDownRols}
        columnsRol={props.columnRols}
        rowsRol={props.rowsRols}
        buttonLblAddGrid={props.buttonLblAddGrid}
        labelDropDownRol={props.labelDropDownRols}
        txtSearchDropDownRol={props.txtSearchDropDownRols}
        defaultDropDownRol={props.defaultDropDownRols}
        titleDrawerRol={props.titleDrawerRol}
        textDescriptionRol={props.textDescriptionRol}
        onCloseDrawerRol={props.onCloseDrawerRolsUser}
        onClickSaveRolUser={props.onClickSaveDataRol}
      />
    </>
  );
};
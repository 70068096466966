import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rolPost } from "../../store/rol/actions";

export const useRolPost = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const { responseRolPost, errorRolPost, loadingRolPost } = useSelector(
    (state: any) => ({
      responseRolPost: state.Rol.responseRolPost,
      errorRolPost: state.Rol.errorRolPost,
      loadingRolPost: state.Rol.loadingRolPost,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        rolPost({
          roles: [
            {
              name: body.RolName,
              description: body.RolDescription,
            },
          ],
        }),
      );
    }
  }, [trigger]);

  return {
    responseRolPost,
    errorRolPost,
    loadingRolPost,
  };
};
import {
  CardProps,
  ContextMenuAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";

interface TableColumn {
  field: string;
  headerName: string;
  width: number;
  renderCell?: (params: any) => JSX.Element;
  editable?: boolean;
}

export const columnsFn = (
  handleClickContextMenu: (item: any, param: any) => void,
) => {
  const columns: TableColumn[] = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (params: any) => {
        let menuContext: any = [
          {
            disabled: false,
            icon: {
              color: "#006ac6",
              icon: icons.Search,
              size: sizesIcon.standard,
            },
            id: "1",
            name: "Ver detalles",
          },
        ];
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={menuContext}
              onClickContextMenu={(item: any) =>
                handleClickContextMenu(item, params)
              }
              sizeIcon={sizesIcon.standard}
            />
          </div>
        );
      },
    },
    {
      editable: false,
      field: "status",
      headerName: "Estado",
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <span className={params.value}></span>
          {params.value}
        </div>
      ),
      width: 150,
    },
    {
      field: "quotation",
      headerName: "Cotización",
      width: 200,
    },
    {
      field: "certificate",
      headerName: "No. Póliza",
      width: 200,
    },
    {
      field: "names",
      headerName: "Nombres",
      width: 300,
    },
    {
      field: "lastNames",
      headerName: "Apellidos",
      width: 300,
    },
  ];
  return columns;
};

export const DataCards: CardProps[] = [
  {
    id: "1",
    numericalTitle: "",
    title: "Total pólizas pendientes",
  },
  {
    id: "2",
    numericalTitle: "",
    title: "Total pólizas completadas",
  },
];

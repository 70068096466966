import { ResponseError } from "./ResponseError";
import { LoginResponse } from "../model/login";

const fetchRequest = async (url: string, requestOptions: any) => {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
};

const fetchRequestResponse = async  (url: string, requestOptions: any) => {
     const response = await fetch(url, requestOptions);
     const res = await response.json();

     if (!response.ok) {
        if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }
        throw new ResponseError('HTTP error! Status', res);
    }
    return res;
  };


  const handleUnauthorized =  (status:any) => {
    if (status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }


export {fetchRequest, fetchRequestResponse, handleUnauthorized};

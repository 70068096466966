import {
  SPONSOR,
  SPONSOR_CLEAR_DATA,
  SPONSOR_ERROR,
  SPONSOR_ONE,
  SPONSOR_ONE_SUCCESS,
  SPONSOR_SUCCESS,
  POST_SPONSOR,
  POST_SPONSOR_CLEAR_DATA,
  POST_SPONSOR_ERROR,
  POST_SPONSOR_SUCCESS,
  PATCH_SPONSOR,
  PATCH_SPONSOR_CLEAR_DATA,
  PATCH_SPONSOR_ERROR,
  PATCH_SPONSOR_SUCCESS,
} from "./actionTypes";

export const sponsor = (sponsor, history) => {
  return {
    type: SPONSOR,
    payload: { sponsor, history },
  };
};

export const sponsorOne = (sponsor, history) => {
  return {
    type: SPONSOR_ONE,
    payload: { sponsor, history },
  };
};

export const sponsorSuccess = (success) => {
  return {
    type: SPONSOR_SUCCESS,
    payload: success,
  };
};

export const sponsorError = (error) => {
  return {
    type: SPONSOR_ERROR,
    payload: error,
  };
};

export const sponsorOneSuccess = (success) => {
  return {
    type: SPONSOR_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const sponsorClearData = () => {
  return {
    type: SPONSOR_CLEAR_DATA,
    payload: {},
  };
};

export const postSponsor = (data, history) => {
  return {
    type: POST_SPONSOR,
    payload: { data, history },
  };
};

export const postSponsorSuccess = (success) => {
  return {
    type: POST_SPONSOR_SUCCESS,
    payload: success,
  };
};

export const postSponsorError = (error) => {
  return {
    type: POST_SPONSOR_ERROR,
    payload: error,
  };
};

export const postSponsorClearData = () => {
  return {
    type: POST_SPONSOR_CLEAR_DATA,
    payload: {},
  };
};

export const patchSponsor = (data, id ) => {
  return {
    type: PATCH_SPONSOR,
    payload: { data, id  },
  };
};

export const patchSponsorSuccess = (success) => {
  return {
    type: PATCH_SPONSOR_SUCCESS,
    payload: success,
  };
};

export const patchSponsorError = (error) => {
  return {
    type: PATCH_SPONSOR_ERROR,
    payload: error,
  };
};

export const patchSponsorClearData = () => {
  return {
    type: PATCH_SPONSOR_CLEAR_DATA,
    payload: {},
  };
};

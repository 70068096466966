import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catalogAccountType } from "../store/plan/actions";

export const useGetCatalogAccountType = (
  trigger: boolean,
  projectId?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const {
    responseCatalogAccountType,
    loadingCatalogAccountType,
    errorCatalogAccountType,
  } = useSelector((state: any) => ({
    responseCatalogAccountType: state.Plan.responseCatalogAccountType,
    loadingCatalogAccountType: state.Plan.loadingCatalogAccountType,
    errorCatalogAccountType: state.Plan.errorCatalogAccountType,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        catalogAccountType({
          enable: true,
          page: 1,
          limit: 100,
          projectId: projectId,
        }),
      );
    }
  }, [projectId, trigger]);

  return {
    responseCatalogAccountType,
    errorCatalogAccountType,
    loadingCatalogAccountType,
    setEnable,
    setPage,
    setLimit,
  };
};

import React from "react";
import {
  FormClientsAFY,
  ButtonAFY,
  icons,
  InformationAFY,
  InputAFY,
  ModalComponentAFY,
  ReportFiltersAFY,
  SelectAFY,
  sizes,
  sizesIcon,
  sizesSelect,
  sizesTextField,
  variants,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../components/layout/style";
import { GridColDef } from "@mui/x-data-grid";

interface ClientsViewProps {
  title?: string;
  onClickSeeDetails?: (data: any) => void;
  rows?: any[];
  columns?: GridColDef[];
  onSearch?: (term: string, value: string) => void;
  listIdentificationType?: any[];
}

export const ClientsView = (props: ClientsViewProps) => {
  return (
    <React.Fragment>
      <Title>Clientes</Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description="Busque un cliente por documento, nombre, apellido o email."
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />

      <div style={{ marginTop: "-28px" }}>
        <FormClientsAFY
          title={props.title}
          columns={props.columns || []}
          rows={props.rows || []}
          onClickSeeDetails={props.onClickSeeDetails}
          onSearch={props.onSearch}
          listIdentificationType={props.listIdentificationType}

        />
      </div>
    </React.Fragment>
  );
};

import {
  DataFormVehicles,
  EditFormVehicleAPAPAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";
import {
  carConditionOption,
  getFuelTypeOption,
  getNumberCylindersOption,
} from "../../../apap/salesCore/vehicleDetail/vehicleDetailsDTO";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getCarColor, getVehicles } from "../../../store/vehiclesApap/actions";
import { updateQuote } from "../../../store/updateQuote/actions";
import { getCurrentDatePlusOneYear } from "../../../services/getDates";
import { parsePrice } from "../../../utils/getPrice";

export const ModalEditVehicle = (props: {
  openModalEditVehicle: boolean;
  setOpenModalEditVehicle: (value: boolean | (() => boolean)) => void;
  dataFormVehicle: DataFormVehicles;
  callQuoteOneApi: (quoteId: string) => void;
  quoteId: string;
  planId: string;
  coverTypeId: string;
  campaignId: string;
  catalogPaymentMode: string;
  catalogStatusQuote: string;
  branchId: string;
  userId: string;
  idQuoteVehicle: string;
  productId: string;
  planName: string;
  productType?: string;
  projectTypeLocal?: any;
  salesFlow?: any;
  t: TFunction;
}) => {
  const dispatch = useDispatch();
  const [limitSearchCar] = React.useState<number>(50000);
  const [limitSearchColor] = React.useState<number>(50000);
  const [limit] = React.useState<number>(50);
  const [carDetailsOption, setCarDetailsOption] = React.useState([]);
  const [carColorOption, setCarColorOption] = React.useState([]);

  const { loadingGetVehicles, responseGetVehicles, errorGetVehicles } =
    useSelector((state: any) => ({
      loadingGetVehicles: state.Vehicles.loadingGetVehicles,
      responseGetVehicles: state.Vehicles.responseGetVehicles?.data,
      errorGetVehicles: state.Vehicles.errorGetVehicles,
    }));

  const { loadingCarColor, responseCarColor, errorCarColor } = useSelector(
    (state: any) => ({
      loadingCarColor: state.Vehicles.loadingCarColor,
      responseCarColor: state.Vehicles.responseCarColor?.data,
      errorCarColor: state.Vehicles.errorCarColor,
    }),
  );

  const { responseUpdateQuote } = useSelector((state: any) => ({
    responseUpdateQuote: state.UpdateQuote.responseUpdateQuote?.data,
  }));

  useEffect(() => {
    callGetVehicles("", limit);
    callGetCarColor("", limit);
  }, []);

  useEffect(() => {
    if (responseGetVehicles) {
      setCarDetailsOption(responseGetVehicles);
    }
  }, [responseGetVehicles]);

  useEffect(() => {
    if (responseCarColor) {
      setCarColorOption(responseCarColor);
    }
  }, [responseCarColor]);

  useEffect(() => {
    if (responseUpdateQuote) {
      props.callQuoteOneApi(props.quoteId);
      props.setOpenModalEditVehicle(false);
    }
  }, [responseUpdateQuote]);

  const searchCarDetails = (name: string) => {
    if (name.length === 0) {
      callGetVehicles("", limit);
    }
    if (name.length >= 2) {
      callGetVehicles(name, limitSearchCar);
    }
  };

  const searchCarColor = (name: string) => {
    if (name.length === 0) {
      callGetCarColor("", limit);
    }
    if (name.length >= 2) {
      callGetCarColor(name, limitSearchColor);
    }
  };

  const onSelectCarDetails = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetVehicles("", limit);
    }
  };

  const onSelectCarColor = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetCarColor("", limit);
    }
  };

  const callGetVehicles = (brand: string, limitGet: number) => {
    dispatch(
      getVehicles({
        brand: brand,
        orderBy: "brand",
        orderDir: "asc",
        limit: limitGet,
      }),
    );
  };

  const callGetCarColor = (color: string, limitGet: number) => {
    dispatch(
      getCarColor({
        name: color,
        orderBy: "name",
        orderDir: "asc",
        limit: limitGet,
      }),
    );
  };

  const updateVehicle = (data: any) => {
    let registrationNumber = data.registrationNumber.replaceAll("-", "");
    const dates = getCurrentDatePlusOneYear();
    const newProductType = props.productType
      ? props.productType
      : props.projectTypeLocal;

    let price = parsePrice(data.price);
    let body = {
      planId: props.planId,
      coverTypeId: props.coverTypeId,
      campaignId: props.campaignId,
      catalogPaymentMode: props.catalogPaymentMode,
      catalogStatusQuote: props.catalogStatusQuote,
      branchId: props.branchId,
      userId: props.userId,
      contentType: "VEHICLE",
      contentMap: {
        projectType: newProductType,
        brandId:
          data?.carDetails?.[0]?.brand?.id ?? data?.carDetails?.brand?.id ?? "",
        modelId:
          data?.carDetails?.[0]?.model?.id ?? data?.carDetails?.model?.id ?? "",
        versionVehicle:
          data?.carDetails?.[0]?.version.id ??
          data?.carDetails?.version.id ??
          "",
        yearVehicle: data.year,
        colorId: data.carColor?.[0]?.id ?? data.carColor?.id ?? "",
        registerVehicle: data.chassisNumber,
        deductibleTypeId: price,
        cylinders: Number(
          data.numberCylinders?.[0]?.id ?? data.numberCylinders?.id ?? "",
        ),
        idquoteVehicle: props.idQuoteVehicle,
        combustion: data.fuelType?.[0]?.id ?? data.fuelType?.id ?? "",
        plate: registrationNumber,
        condition: data.carCondition?.[0]?.id ?? data.carCondition?.id ?? "",
        codeId: "",
        branchId: props.branchId,
        paymentPlanId: "",
        productId: props.productId,
        passengerCantVehicle: "0",
        weightVehicle: "0",
        tonnageVehicle: "0",
        usageId: "1",
        typeId: "1",
        classId: "1",
        startDate: dates.startDate,
        endDate: dates.endDate,
        planId: props.planId,
      },
    };
    dispatch(updateQuote({ body, id: props.quoteId }));
  };
  const productSelected = props.salesFlow?.metadata?.navigation?.productType;

  return (
    <EditFormVehicleAPAPAFY
      openModal={props.openModalEditVehicle}
      onCloseModal={() => {
        props.setOpenModalEditVehicle(false);
      }}
      maxYears={1}
      minYears={productSelected === "LEY" ? 50 : 20}
      thousandSeparator={","}
      decimalSeparator={"."}
      decimalScale={2}
      prefix={"RD$ "}
      titleForm={props.t(`carDetails.carForm.title`)}
      titleRow1={`*${props.t("carDetails.carForm.titleRow1")}`}
      titleRow2={`*${props.t("carDetails.carForm.titleRow2")}`}
      titleRow3={`*${props.t("carDetails.carForm.titleRow3")}`}
      titleRow4={`*${props.t("carDetails.carForm.titleRow4")}`}
      titleCheckBox={"Chasis corto"}
      titleRow5={`${props.planName === "Full" ? "*" : ""}${props.t(
        "carDetails.carForm.titleRow5",
      )}`}
      titleRow6={`*${props.t("carDetails.carForm.titleRow6")}`}
      titleRow7={`*${props.t("carDetails.carForm.titleRow7")}`}
      titleRow8={`*${props.t("carDetails.carForm.titleRow8")}`}
      titleRow9={`*${props.t("carDetails.carForm.titleRow9")}`}
      errorTextCarColor={props.t(`carDetails.errorForm.errorTextCarColor`)}
      errorTextCarCondition={props.t(
        `carDetails.errorForm.errorTextCarCondition`,
      )}
      errorTextCarDetails={props.t(`carDetails.errorForm.errorTextCarDetails`)}
      errorTextChassisNumber={props.t(
        productSelected === "FULL"
          ? `carDetails.errorForm.errorTextChassisNumber`
          : `carDetails.errorForm.errorTextChassisNumberNew`,
      )}
      errorTextFuelType={props.t(`carDetails.errorForm.errorTextFuelType`)}
      errorTextNumberCylinders={props.t(
        `carDetails.errorForm.errorTextNumberCylinders`,
      )}
      errorTextPrice={props.t(`carDetails.errorForm.errorTextPrice`)}
      errorTextRegistrationNumber={props.t(
        `carDetails.errorForm.errorTextRegistrationNumber`,
      )}
      errorTextYear={props.t(`carDetails.errorForm.errorTextYear`)}
      helperTextCarColor={props.t(
        `carDetails.helperTextForm.helperTextCarColor`,
      )}
      helperTextCarCondition={props.t(
        `carDetails.helperTextForm.helperTextCarCondition`,
      )}
      helperTextCarDetails={props.t(
        `carDetails.helperTextForm.helperTextCarDetails`,
      )}
      helperTextFuelType={props.t(
        `carDetails.helperTextForm.helperTextFuelType`,
      )}
      helperTextNumberCylinders={props.t(
        `carDetails.helperTextForm.helperTextNumberCylinders`,
      )}
      titleNextButton={props.t(`carDetails.titleNextEditButton`)}
      titlePreviousButton={props.t(`carDetails.titlePreviousEditButton`)}
      modalHeight={"100%"}
      maxLengthChassisNumber={17}
      maxLengthRegistrationNumber={8}
      modalWidth={1170}
      modalPadding={15}
      loadingCarDetails={loadingGetVehicles}
      loadingCarColor={loadingCarColor}
      loadingNumberCylinders={false}
      loadingFuelType={false}
      loadingCarCondition={false}
      dataForm={props.dataFormVehicle}
      carDetailsOption={carDetailsOption}
      carColorOption={carColorOption}
      numberCylindersOption={getNumberCylindersOption}
      fuelTypeOption={getFuelTypeOption(props.t)}
      carConditionOption={carConditionOption(props.t)}
      onChangeCarDetails={searchCarDetails}
      onSelectCarDetails={onSelectCarDetails}
      onChangeCarColor={searchCarColor}
      onSelectCarColor={onSelectCarColor}
      onChangeNumberCylinders={function noRefCheck() {}}
      onChangeFuelType={function noRefCheck() {}}
      onChangeCarCondition={function noRefCheck() {}}
      onClickBack={() => {
        props.setOpenModalEditVehicle(false);
      }}
      onNext={updateVehicle}
      validateGreater={props.planName === "Full"}
    />
  );
};

import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { themeCore } from "../../../assets/themes/theme";

export const LoginFormDocPlace = styled.section`
  background-color: ${themeCore.colors.primary};
`;

export const ContainerImg = styled.div`
  width: 55px;
  height: 36px;
`;

export const ContainerStepProgressAFY = styled.article`
  position: absolute !important;
  right: 396px;
  z-index: 9;
`;

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: "0",
    width: "100%",
    backgroundColor: "#ffffff",
    height: "calc(100vh - 0px)",
    padding: "10px",
    display: "contents",
  },
  card: {
    padding: "10px",
  },
  imgLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

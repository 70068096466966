import {
  ContextMenuAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import newExcel from "../../assets/images/newExcel.svg";
import { SeeDetailsQuote } from "../../fnx/seeDetailsQuote";
import React from "react";

{
  /*
<div style={{ display: "flex", gap: "10px" }}>
<ButtonAFY
  variant={variants.contained}
  label={"Cancelar"}
  color={themeCore.colors.secondary}
  backgroundColor={themeCore.colors.pink}
  disabled={params.row.status === "Cancelada"}
  onClick={() => handleDelete(params)}
/>

<ButtonAFY
  variant={variants.outlined}
  label={"Ver detalles"}
  onClick={SeeDetailsQuote(params)}
/>
</div>
*/
}

const handleClickContextMenu = (item: any, params: any, handleDelete: any) => {
  switch (item.id) {
    case "1":
      handleDelete(params);
      break;
    case "2":
      SeeDetailsQuote(params);
      break;
    default:
      break;
  }
};

export const getColumnsHistory = [
  {
    field: "fechaCarga",
    headerName: "Fecha Carga",
    width: 170,
  },
  {
    field: "usuario",
    headerName: "Usuario",
    width: 270,
  },
  {
    field: "movimiento",
    headerName: "Movimiento",
    width: 170,
  },
  {
    field: "comentario",
    headerName: "Comentario",
    width: 350,
  },
];

export const getColumns = (handleDelete: any) => [
  {
    field: "actions",
    headerName: "Acciones",
    width: 80,
    renderCell: (params: any) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <ContextMenuAFY
          colorButton="#fff"
          iconButton={icons.MoreVert}
          iconColor="#3A56A1"
          menuItem={[
            {
              disabled: params.row.status === "Cancelada",
              icon: {
                color: "#006ac6",
                icon: icons.Delete,
                size: sizesIcon.standard,
              },
              id: "1",
              name: "Cancelar",
            },

            {
              disabled: false,
              icon: {
                color: "#006ac6",
                icon: icons.Search,
                size: sizesIcon.standard,
              },
              id: "2",
              name: "Ver detalles",
            },
            {
              disabled: false,
              icon: {
                color: "#006ac6",
                icon: icons.GridView,
                size: sizesIcon.standard,
              },
              id: "3",
              name: "Historial",
            },
          ]}
          onClickContextMenu={(item: any) =>
            handleClickContextMenu(item, params, handleDelete)
          }
          sizeIcon={sizesIcon.standard}
        />
      </div>
    ),
  },

  {
    editable: false,
    field: "status",
    flex: 1,
    headerName: "Estado",
    renderCell: (params: any) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <span className={params.value}></span>
        {params.value}
      </div>
    ),
    width: 70,
  },
  {
    field: "quotation",
    headerName: "Cotización",
    width: 170,
  },
  {
    field: "firstNameA",
    headerName: "Nombre",
    width: 100,
  },
  {
    field: "lastNameA",
    headerName: "Primer apellido",
    width: 100,
  },
  {
    field: "lastNameB",
    headerName: "Apellido",
    width: 100,
  },
  {
    field: "certificate",
    headerName: "No. certificado",
    width: 170,
  },
  {
    field: "identification",
    headerName: "Identificación",
    width: 150,
  },
  {
    field: "branch",
    headerName: "Sucursal",
    width: 150,
  },
  {
    field: "user",
    headerName: "Usuario",
    width: 150,
  },
  {
    field: "product",
    headerName: "Producto",
    width: 175,
  },
  {
    field: "insuranceCarrier",
    headerName: "Aseguradora",
    width: 150,
  },
  {
    field: "cancellationDate",
    headerName: "Fecha de cancelación",
    width: 250,
  },
  {
    field: "reasonCancellation",
    headerName: "Motivo de cancelación",
    width: 270,
  },
];

export const buttonActionOptions: any = [
  {
    icons: icons.DownloadForOffline,
    id: "1",
    img: newExcel,
    name: "Descargar Excel",
  },
  // {
  //   icons: icons.DownloadForOffline,
  //   id: "2",
  //   img: pdf,
  //   name: "Descargar PDF",
  // },
];

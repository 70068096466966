import { call, put, takeEvery } from "redux-saga/effects";
import {
  policyCancelError,
  policyCancelSuccess,
  policyConfirmError,
  policyConfirmSuccess,
  policyCreateError,
  policyCreateSuccess,
} from "./actions";
import {
  cancelPolicy,
  postPolicy,
  postPolicyConfirm,
} from "../../resources/helper/policy";
import { POLICY_CANCEL, POLICY_CONFIRM, POLICY_CREATE } from "./actionTypes";

function* policy({ payload: { policy } }) {
  try {
    const response = yield call(postPolicy, policy);
    yield put(policyCreateSuccess(response));
  } catch (error) {
    yield put(policyCreateError(error));
  }
}

/*function* policyCreateClearData() {
  try {
    yield put(policyCreateClearData(true));
  } catch (error) {
    yield put(policyCreateError(error));
  }
}*/

function* policyConfirm({ payload: { policy } }) {
  try {
    const response = yield call(postPolicyConfirm, policy, policy.id);
    yield put(policyConfirmSuccess(response));
  } catch (error) {
    yield put(policyConfirmError(error));
  }
}

function* policyCancel({ payload: { policy } }) {
  try {
    const response = yield call(cancelPolicy, policy);
    yield put(policyCancelSuccess(response));
  } catch (error) {
    yield put(policyCancelError(error));
  }
}

function* policySaga() {
  yield takeEvery(POLICY_CREATE, policy);
  yield takeEvery(POLICY_CONFIRM, policyConfirm);
  yield takeEvery(POLICY_CANCEL, policyCancel);
  //yield takeEvery(POLICY_CREATE_CLEAR_DATA, policyCreateClearData);
}

export default policySaga;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { personByDocument } from "../../store/person/actions";

export const GetPersonByDocument = (trigger: boolean, document?: number) => {
  const dispatch = useDispatch();
  const [enable] = React.useState<boolean>(true);
  const [page] = React.useState<number>(1);
  const [limit] = React.useState<number>(10);

  const {
    responsePersonByDocument,
    errorPersonByDocument,
    loadingPersonByDocument,
  } = useSelector((state: any) => ({
    responsePersonByDocument: state.Person.responsePersonByDocument?.data,
    errorPersonByDocument: state.Person.errorPersonByDocument,
    loadingPersonByDocument: state.Person.loadingPersonByDocument,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        personByDocument({
          enable,
          page,
          limit,
          document,
        }),
      );
    }
  }, [trigger, document]);

  return {
    responsePersonByDocument,
    errorPersonByDocument,
    loadingPersonByDocument,
  };
};

import {
  icons,
  MenuItem,
  select,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ButtonOption } from "../../model/sponsor";

export const menuItem: MenuItem[] = [
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
    id: "1",
    name: "Editar",
  },
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.Delete,
      size: sizesIcon.standard,
    },
    id: "2",
    name: "Eliminar",
  },
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.TaskAlt,
      size: sizesIcon.standard,
    },
    id: "3",
    name: "Permisos",
  },
];
export const buttonActionOptions: ButtonOption[] = [
  {
    icons: icons.Menu,
    id: "1",
    name: "editar",
  },
  {
    icons: icons.Menu,
    id: "2",
    name: "eliminar",
  },
];
export const insurersView = [
  {
    id: "1",
    name: "List",
    active: false,
    icon: {
      icon: icons.List,
    },
  },
  {
    id: "2",
    name: "GridView",
    active: true,
    icon: {
      icon: icons.GridView,
    },
  },
];
export const optionSelect: select[] = [
  {
    id: "1",
    name: "Nombre",
    value: "name",
  },
  {
    id: "2",
    name: "Descripción",
    value: "description",
  },
];
export const optionsSelectClient: select[] = [
  {
    id: "1",
    name: "Banesco",
    value: "1",
  },
];
export const selectValueDefault: string = "name";
export const defaultDropDownPermissions: any = [
  {
    active: false,
    id: "1",
    name: "Banesco 1",
  },
];
export const description: string =
  "Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postQuoteUploadDocument } from "../../store/apapUploadAttachments/actions";

export const PostUploadDocuments = (
  trigger: boolean,
  id?: string,
  fileName?: string,
  pdfFile?: File,
  documentProfileId?: string,
) => {
  const dispatch = useDispatch();

  const {
    responseUploadDocuments,
    errorUploadDocuments,
    loadingUploadDocuments,
  } = useSelector((state: any) => ({
    responseUploadDocuments: state.UploadAttach.responseUploadDocuments,
    errorUploadDocuments: state.UploadAttach.errorUploadDocuments,
    loadingUploadDocuments: state.UploadAttach.loadingUploadDocuments,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        postQuoteUploadDocument({
          id: id,
          fileName: fileName,
          pdfFile: pdfFile,
          documentProfileId: documentProfileId,
        }),
      );
    }
  }, [trigger, id, fileName, pdfFile, documentProfileId]);

  return {
    responseUploadDocuments,
    errorUploadDocuments,
    loadingUploadDocuments,
  };
};

import { URLs } from "../url";
import { fetchRequestResponse } from "../fetchRequest";
import { getHeaderUpload } from "../headers";

const putAvatar = async (entity: string, id: string, image: any) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.avatar}/Entities/${entity}/${id}/Avatars`;
  const data = new FormData();
  data.append("file", image);

  const requestOptions = {
    method: "PUT",
    body: data,
    headers: getHeaderUpload(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as any;
  } catch (err: unknown) {
    throw err;
  }
};

export { putAvatar };

import {
  CURRENCIES,
  CURRENCIES_CLEAR_DATA,
  CURRENCIES_ERROR,
  CURRENCIES_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingCurrencies: false,
  responseCurrencies: null,
  errorCurrencies: null,
};

const Currencies = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCIES:
      state = {
        ...state,
        loadingCurrencies: true,
        errorCurrencies: null,
        responseCurrencies: true,
      };
      break;
    case CURRENCIES_SUCCESS:
      state = {
        ...state,
        loadingCurrencies: false,
        errorCurrencies: null,
        responseCurrencies: action.payload,
      };
      break;
    case CURRENCIES_ERROR:
      state = {
        ...state,
        loadingCurrencies: false,
        errorCurrencies: action.payload,
        responseCurrencies: null,
      };
      break;
    case CURRENCIES_CLEAR_DATA:
      state = {
        ...state,
        loadingCurrencies: null,
        errorCurrencies: null,
        responseCurrencies: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Currencies;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sponsor } from "../store/sponsor/actions";
import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useGetInsurance } from "./getInsurance";

export const useProjectsByCustomerId = (
  clientId: string,
  trigger: boolean,
  descriptionInsurance: string,
  insuranceType: string,
  sponsorIdFilter: string,
) => {
  const [trigger2, setTrigger2] = useState(false);
  const [sponsorId, setSponsorId] = useState("");
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const dispatch = useDispatch();

  const { responseInsurance, errorInsurance, loadingInsurance } =
    useGetInsurance(
      trigger2,
      sponsorIdFilter ? sponsorIdFilter : sponsorId,
      descriptionInsurance,
      insuranceType,
    );

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        sponsor({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        }),
      );
    }
  }, [trigger]);

  useEffect(() => {
    if (responseSponsor && responseSponsor.length > 0) {
      let listSponsorFilter = "";
      responseSponsor?.map((sponsor: MenuItem, idx: number) => {
        if (sponsor.id) {
          listSponsorFilter += `&sponsorId=${sponsor.id}`;
        }
      });
      setSponsorId(listSponsorFilter);
      setTrigger2(true);
    }
  }, [responseSponsor]);

  return {
    responseSponsor,
    errorSponsor,
    loadingSponsor,
    responseInsurance,
    errorInsurance,
    loadingInsurance,
    setEnable,
    setPage,
    setLimit,
  };
};

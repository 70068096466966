import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quoteOne } from "../../store/generateQuote/actions";

export const GetQuoteOne = (trigger: boolean, quoteId: string) => {
  const dispatch = useDispatch();

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        quoteOne({
          id: quoteId,
          fullBody: true,
        }),
      );
    }
  }, [trigger]);

  return {
    responseOneQuote,
    errorOneQuote,
    loadingOneQuote,
  };
};

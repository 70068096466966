import { call, put, takeEvery } from "redux-saga/effects";
import {
  paymentModeError,
  paymentModeSuccess,
  postPaymentModeError,
  postPaymentModeSuccess,
} from "./action";
import { PAYMENT_MODE, POST_PAYMENT_MODE } from "./actionTypes";
import {
  getPaymentMode,
  postPaymentModeAPI,
} from "../../resources/helper/paymentMode";

function* paymentMode({ payload: { paymentMode } }) {
  try {
    const response = yield call(getPaymentMode, paymentMode);
    yield put(paymentModeSuccess(response));
  } catch (error) {
    yield put(paymentModeError(error));
  }
}

function* postPaymentMode({ payload: { paymentMode } }) {
  try {
    const response = yield call(postPaymentModeAPI, paymentMode);
    yield put(postPaymentModeSuccess(response));
  } catch (error) {
    yield put(postPaymentModeError(error));
  }
}

function* paymentModeSaga() {
  yield takeEvery(PAYMENT_MODE, paymentMode);
  yield takeEvery(POST_PAYMENT_MODE, postPaymentMode);
}

export default paymentModeSaga;

import {
  BENEFICIARY_PATCH,
  BENEFICIARY_PATCH_CLEAR_DATA,
  BENEFICIARY_PATCH_ERROR,
  BENEFICIARY_PATCH_SUCCESS,
  BENEFIT,
  BENEFIT_CLEAR_DATA,
  BENEFIT_ERROR,
  BENEFIT_SUCCESS,
  GET_BENEFICIARY,
  GET_BENEFICIARY_CLEAR_DATA,
  GET_BENEFICIARY_ERROR,
  GET_BENEFICIARY_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorBenefit: null,
  responseBenefit: null,
  loadingBenefit: false,
  errorBeneficiary: null,
  responseBeneficiary: null,
  loadingBeneficiary: false,
  errorPatchBeneficiary: null,
  responsePatchBeneficiary: null,
  loadingPatchBeneficiary: false,
};

const Beneficiary = (state = initialState, action) => {
  switch (action.type) {
    case BENEFIT:
      state = {
        ...state,
        loadingBenefit: true,
        errorBenefit: null,
        responseBenefit: true,
      };
      break;
    case BENEFIT_SUCCESS:
      state = {
        ...state,
        errorBenefit: null,
        loadingBenefit: false,
        responseBenefit: action.payload,
      };
      break;
    case BENEFIT_ERROR:
      state = {
        ...state,
        errorBenefit: action.payload,
        loadingBenefit: false,
        responseBenefit: null,
      };
      break;
    case BENEFIT_CLEAR_DATA:
      state = {
        ...state,
        errorBenefit: null,
        loadingBenefit: null,
        responseBenefit: false,
      };
      break;
    case GET_BENEFICIARY:
      state = {
        ...state,
        loadingBeneficiary: true,
        errorBeneficiary: null,
        responseBeneficiary: true,
      };
      break;
    case GET_BENEFICIARY_SUCCESS:
      state = {
        ...state,
        errorBeneficiary: null,
        loadingBeneficiary: false,
        responseBeneficiary: action.payload,
      };
      break;
    case GET_BENEFICIARY_ERROR:
      state = {
        ...state,
        errorBeneficiary: action.payload,
        loadingBeneficiary: false,
        responseBeneficiary: null,
      };
      break;
    case GET_BENEFICIARY_CLEAR_DATA:
      state = {
        ...state,
        errorBeneficiary: null,
        loadingBeneficiary: null,
        responseBeneficiary: false,
      };
      break;
    case BENEFICIARY_PATCH:
      state = {
        ...state,
        loadingPatchBeneficiary: true,
        errorPatchBeneficiary: null,
        responsePatchBeneficiary: null,
      };
      break;
    case BENEFICIARY_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchBeneficiary: null,
        loadingPatchBeneficiary: false,
        responsePatchBeneficiary: action.payload,
      };
      break;
    case BENEFICIARY_PATCH_ERROR:
      state = {
        ...state,
        errorPatchBeneficiary: action.payload,
        loadingPatchBeneficiary: false,
        responsePatchBeneficiary: null,
      };
      break;
    case BENEFICIARY_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchBeneficiary: null,
        loadingPatchBeneficiary: null,
        responsePatchBeneficiary: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Beneficiary;

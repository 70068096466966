import {
  INSURED,
  INSURED_SUCCESS,
  INSURED_ERROR,
  INSURED_CLEAR_DATA,
  POST_INSURED,
  POST_INSURED_SUCCESS,
  POST_INSURED_ERROR,
  POST_INSURED_CLEAR_DATA,
  PATCH_INSURED,
  PATCH_INSURED_SUCCESS,
  PATCH_INSURED_ERROR,
  PATCH_INSURED_CLEAR_DATA,
} from "./actionTypes";

export const insured = (insured, history) => {
  return {
    type: INSURED,
    payload: { insured, history },
  };
};

export const insuredSuccess = (success) => {
  return {
    type: INSURED_SUCCESS,
    payload: success,
  };
};

export const insuredError = (error) => {
  return {
    type: INSURED_ERROR,
    payload: error,
  };
};

export const insuredClearData = () => {
  return {
    type: INSURED_CLEAR_DATA,
    payload: {},
  };
};

export const patchInsured = (insured, history) => {
  return {
    type: PATCH_INSURED,
    payload: { insured, history },
  };
}

export const patchInsuredSuccess = (success) => {
  return {
    type: PATCH_INSURED_SUCCESS,
    payload: success,
  };
}

export const patchInsuredError = (error) => {
  return {
    type: PATCH_INSURED_ERROR,
    payload: error,
  };
}

export const patchInsuredClearData = () => {
  return {
    type: PATCH_INSURED_CLEAR_DATA,
    payload: {},
  };
}


export const postInsured = (insured, history) => {
  return {
    type: POST_INSURED,
    payload: { insured, history },
  };
};

export const postInsuredSuccess = (success) => {
  return {
    type: POST_INSURED_SUCCESS,
    payload: success,
  };
};

export const postInsuredError = (error) => {
  return {
    type: POST_INSURED_ERROR,
    payload: error,
  };
}

export const postInsuredClearData = () => {
  return {
    type: POST_INSURED_CLEAR_DATA,
    payload: {},
  };
};

// Path: src/store/insured/reducer.js
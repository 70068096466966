import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { getHeader } from "../headers";


const postCollectionApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.collection;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body.data),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};


export {  postCollectionApi };

import React, { useEffect, useState } from "react";
import { View } from "./view";
import { UserModel } from "../../model/user";
import { useNavigate } from "react-router-dom";
import { UseUserConfirmEmail } from "../../fnx/user/userConfirmEmail";
import {
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ResponseError } from "../../resources/ResponseError";
import showToast from "../../services/toast";
import Logo from "../../assets/images/logoAffinity.svg";

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [bodyRequest, setBodyRequest] = useState<UserModel>({
    userName: "",
    code: "",
  });
  const { responseConfirmEmail, errorConfirmEmail, loadingConfirmEmail } =
    UseUserConfirmEmail(trigger, bodyRequest);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  useEffect(() => {
    if (responseConfirmEmail && responseConfirmEmail?.length > 0) {
      setTrigger(false);
      navigate("/login");
    }
  }, [responseConfirmEmail]);

  useEffect(() => {
    if (errorConfirmEmail) {
      const error = errorConfirmEmail as ResponseError;
      let message = "Error al confirmar correo electrónico";
      if (error.response?.errors !== undefined) {
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      
      setTrigger(false);
    }
  }, [errorConfirmEmail]);

  const handleSubmit = (dataItem: any) => {
    setBodyRequest({ userName: dataItem.email, code: dataItem.code });
    setTrigger(true);
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message,
          () => setSeeToast({ show: false, message: "", type: typeAlert.success })
        )}
      <View
        title="Confirmar correo electrónico"
        subTitle="Ingrese el codigo enviado por correo electrónico"
        emailText="Correo electrónico"
        logo={Logo}
        emailPlaceHolder="Ingresa un Usuario"
        emailRequired="El correo electrónico es requerido"
        emailError="Formato de correo no aceptado"
        emailHelperText="Ingrese su correo electrónico"
        codeText="Codigo"
        codeRequired="El codigo es requerido"
        codeHelperText="Ingrese su codigo"
        btnColor="#fffff"
        buttonText="Confirmar correo"
        userForm="ConfirmEmail"
        onSaveData={handleSubmit}
      />
    </React.Fragment>
  );
};

export default ConfirmEmail;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Branches } from "../../store/branches/actions";

export const useBranchesGetAll = (
  trigger: boolean,
  clientId?: string,
  name?: string,
  code?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responseBranches, errorBranches, loadingBranches } = useSelector(
    (state: any) => ({
      responseBranches: state.Branches.responseBranches?.data,
      errorBranches: state.Branches.errorBranches,
      loadingBranches: state.Branches.loadingBranches,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
          Branches({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
          name: name,
          code: code,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseBranches,
    errorBranches,
    loadingBranches,
    setEnable,
    setPage,
    setLimit,
  };
};

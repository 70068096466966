import {
  CLIENT,
  CLIENT_ONE,
  CLIENT_ERROR,
  CLIENT_SUCCESS,
  CLIENT_ONE_SUCCESS,
  CLIENT_CLEAR_DATA,
  CLIENT_ONE_BY_PROJECT_SUCCESS,
  CLIENT_ONE_BY_PROJECT,
} from "./actionTypes";

export const client = (client, history) => {
  return {
    type: CLIENT,
    payload: { client, history },
  };
};

export const clientOne = (client, history) => {
  return {
    type: CLIENT_ONE,
    payload: { client, history },
  };
};

export const clientOneByProject = (client, history) => {
  return {
    type: CLIENT_ONE_BY_PROJECT,
    payload: { client, history },
  };
};
export const clientSuccess = (success) => {
  return {
    type: CLIENT_SUCCESS,
    payload: { ...success },
  };
};

export const clientOneByProjectSuccess = (success) => {
  return {
    type: CLIENT_ONE_BY_PROJECT_SUCCESS,
    payload: { ...success },
  };
};

export const clientOneSuccess = (success) => {
  return {
    type: CLIENT_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const clientError = (error) => {
  return {
    type: CLIENT_ERROR,
    payload: error,
  };
};

export const clientClearData = () => {
  return {
    type: CLIENT_CLEAR_DATA,
    payload: {},
  };
};

import React from "react";
import {
  CardProps,
  icons,
  MenuItem,
  ReportsPageAFY,
  sizesIcon,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../components/layout/style";
import { dataCard, dataChip, optionList } from "./reportPageDTO";
import { TFunction } from "i18next";

export const ReportView = (props: {
  onClickOptionChips: (item: MenuItem) => void;
  openReport: (item: MenuItem, card: CardProps) => void;
  newDownData: CardProps[];
  dataSeries: any;
  dataCategories: any;
  closeCalendar: () => void;
  filterCalendar: (item: any) => void;
  t: TFunction;
}) => {
  return (
    <React.Fragment>
      <Title>{props.t(`salesReport.title`)}</Title>
      <div style={{ minHeight: "calc(100vh - 137px)" }}>
        <ReportsPageAFY
          avatarVariant={variantsAvatar.rounded}
          iconAvatar={icons.Search}
          iconSearch={icons.Search}
          sizeIcon={sizesIcon.standard}
          sizesIconSearch={sizesIcon.standard}
          // titleClearButton="Limpiar filtros"
          titleChart={props.t(`salesReport.barChart.title`)}
          yAxisTitleChart={props.t(`salesReport.barChart.yAxisTitle`)}
          iconBgColor="#F6F7FB"
          iconColor="#3A56A1"
          iconColorSearch="#3A56A1"
          backgroundColorSearch="#F6F7FB"
          bgChipSelected="#EBEEF6"
          bgChips="#fff"
          onClickActionButton={props.openReport}
          onClickChip={props.onClickOptionChips}
          onCloseCalendar={props.closeCalendar}
          onChangeData={props.filterCalendar}
          dataChips={dataChip}
          data={dataCard}
          optionList={optionList}
          downData={props.newDownData}
          series={props.dataSeries}
          categories={props.dataCategories}
        />
      </div>
    </React.Fragment>
  );
};

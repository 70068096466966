import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userConfirmPassword } from "../../store/auth/actions";
import {  UserRequest } from "../../model/user";

export const UseUserConfirmPassword = (
  trigger: boolean,
  bodyRequest: UserRequest,
) => {
  const dispatch = useDispatch();

  const { responseConfirmPassword, errorConfirmPassword, loadingConfirmPassword } = useSelector(
    (state: any) => ({
      responseConfirmPassword: state.Login.responseConfirmPassword?.data,
      errorConfirmPassword: state.Login.errorConfirmPassword,
      loadingConfirmPassword: state.Login.loadingConfirmPassword,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        userConfirmPassword(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responseConfirmPassword,
    errorConfirmPassword,
    loadingConfirmPassword,
  };
};

import {
  DOCUMENTS_STATISTICS,
  DOCUMENTS_STATISTICS_CLEAR_DATA,
  DOCUMENTS_STATISTICS_ERROR,
  DOCUMENTS_STATISTICS_SUCCESS,
} from "./actionTypes";

export const getDocumentsStatistics = () => {
  return {
    type: DOCUMENTS_STATISTICS,
  };
};

export const getDocumentsStatisticsSuccess = (success) => {
  return {
    type: DOCUMENTS_STATISTICS_SUCCESS,
    payload: success,
  };
};

export const getDocumentsStatisticsError = (error) => {
  return {
    type: DOCUMENTS_STATISTICS_ERROR,
    payload: error,
  };
};

export const getDocumentsStatisticsClearData = () => {
  return {
    type: DOCUMENTS_STATISTICS_CLEAR_DATA,
    payload: {},
  };
};

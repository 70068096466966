import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  ButtonAFY,
  GridAFY,
  icons,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  position,
  sizes,
  typeAlert,
  typeLoaders,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { ApplicantDeclarationView } from "./view";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { hasPermission } from "../../services/getNavigationPages";
import { STEP_APPLICANTDECLARATION } from "../../const/catalog";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ApplicantDeclarationPage = () => {
  const STEP = STEP_APPLICANTDECLARATION;
  const navigate = useNavigate();  const [prima, setPrima] = React.useState<string>("0.00");

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showNextDisabled, setShowNextDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const permissions = {
    create: hasPermission("applican-declaration-view"),
  };


  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    setFilters([
      {
        name: "Producto",
        label: "N/A",
        type: "label",
      },
      {
        name: "Plan",
        label: "N/A",
        type: "label",
        showChangeButton: false,
      },
    ]);


  }, []);





  const handleClickPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigateBack(STEP, navigate);
  };
  const handleClickNext = (e: any) => {
    if (e && e.constructor.name === 'SyntheticBaseEvent') {
      navigateNext(STEP, navigate);
      return;
    } 

    setLoading(true);

    
    try {
      setShowNextDisabled(false);
      
      console.log("---->",e);
      localStorageService.setStepStored({
        id: "",
        name: "",
        step: Steps.applicantDeclaration,
        completed: true,
        applicantDeclaration: e
      });
      
      setSeeToast({
        show: true,
        message: "Los datos han sido guardados",
        type: typeAlert.success,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowNextDisabled(true);
      setSeeToast({
        show: true,
        message: "Error al guardar",
        type: typeAlert.error,
      });
    }


  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })}
      <LoadingAFY
        loading={
          loading
        }
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando"
        bgColor={themeCore.colors.backgroundLoader}
      />

      <ApplicantDeclarationView
        title={"Resumen de asegurabilidad del solicitante"}
        hideFilter={false}
        breadcrumbs={breadCrumbs}
        steps={steps}
        stepProgress={steps}
        previousLabel={"Anterior"}
        nextLabel={"Nueva cotización"}
        nextDisabled={showNextDisabled}
        showInfo={showInfo}
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
        filters={filters}
        resumeDescription={"Valor estimado"}
        resumeValue={`DOP $ ${prima}`}
      />

    </React.Fragment>
  );
};
export const PLANS = "PLANS";
export const PLANS_SUCCESS = "PLANS_SUCCESS";
export const PLAN_ONE = "PLAN_ONE";
export const PLAN_ONE_SUCCESS = "PLAN_ONE_SUCCESS";
export const PLANS_ERROR = "PLANS_ERROR";
export const PLANS_CLEAR_DATA = "PLANS_CLEAR_DATA";
export const POST_PLAN = "POST_PLAN";
export const POST_PLAN_SUCCESS = "POST_PLAN_SUCCESS";
export const POST_PLAN_ERROR = "POST_PLAN_ERROR";
export const POST_PLAN_CLEAR_DATA = "POST_PLAN_CLEAR_DATA";
export const PATCH_PLAN = "PATCH_PLAN";
export const PATCH_PLAN_SUCCESS = "PATCH_PLAN_SUCCESS";
export const PATCH_PLAN_ERROR = "PATCH_PLAN_ERROR";
export const PATCH_PLAN_CLEAR_DATA = "PATCH_PLAN_CLEAR_DATA";
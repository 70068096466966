import { call, put, takeEvery } from "redux-saga/effects";
import {
  insuredError,
  insuredSuccess,
  patchInsuredError,
  patchInsuredSuccess,
  postInsuredError,
  postInsuredSuccess,
} from "./actions";
import {
  INSURED,
  PATCH_INSURED,
  POST_INSURED,
  POST_INSURED_CLEAR_DATA,
} from "./actionTypes";
import {
  getInsured,
  patchInsured,
  postInsuredAPI,
} from "../../resources/helper/insured";

function* insured({ payload: { insured } }) {
  try {
    const response = yield call(getInsured, insured);
    yield put(insuredSuccess(response));
  } catch (error) {
    yield put(insuredError(error));
  }
}

function* postInsured({ payload: { insured } }) {
  try {
    const response = yield call(postInsuredAPI, insured);
    yield put(postInsuredSuccess(response));
  } catch (error) {
    yield put(postInsuredError(error));
  }
}

function* postInsuredClearData() {
  try {
    yield put(postInsuredClearData(true));
  } catch (error) {
    yield put(postInsuredError(error));
  }
}

function* updateInsured({ payload: { insured } }) {
  try {
    const response = yield call(patchInsured, insured.body, insured.id);
    yield put(patchInsuredSuccess(response));
  } catch (error) {
    yield put(patchInsuredError(error));
  }
}

function* insuredSaga() {
  yield takeEvery(INSURED, insured);
  yield takeEvery(POST_INSURED, postInsured);
  yield takeEvery(PATCH_INSURED, updateInsured);
  yield takeEvery(POST_INSURED_CLEAR_DATA, postInsuredClearData);
}

export default insuredSaga;

import {
  DOWNLOAD_QUOTE_All,
  DOWNLOAD_QUOTE_All_CLEAR_DATA,
  DOWNLOAD_QUOTE_All_ERROR,
  DOWNLOAD_QUOTE_All_SUCCESS,
  QUOTE,
  QUOTE_ADD_FIELDS,
  QUOTE_ADD_FIELDS_CLEAR_DATA,
  QUOTE_ADD_FIELDS_ERROR,
  QUOTE_ADD_FIELDS_SUCCESS,
  QUOTE_All,
  QUOTE_All_CLEAR_DATA,
  QUOTE_All_ERROR,
  QUOTE_All_SUCCESS,
  QUOTE_CLEAR_DATA,
  QUOTE_ERROR,
  QUOTE_ONE,
  QUOTE_ONE_CLEAR_DATA,
  QUOTE_ONE_ERROR,
  QUOTE_ONE_SUCCESS,
  QUOTE_SUCCESS,
  QUOTE_UPDATE_STATUS_AUDIT_DOC,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorQuote: null,
  responseQuote: null,
  loadingQuote: false,

  errorOneQuote: null,
  responseOneQuote: null,
  loadingOneQuote: false,

  loadingAllQuote: false,
  responseAllQuote: null,
  errorAllQuote: null,

  loadingDownloadAllQuote: false,
  responseDownloadAllQuote: null,
  errorDownloadAllQuote: null,

  errorQuoteAddFields: null,
  responseQuoteAddFields: null,
  loadingQuoteAddFields: false,

  errorUpdateStatusAuditDocs: null,
  responseUpdateStatusAuditDocs: null,
  loadingUpdateStatusAuditDocs: false,
};

const Quote = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE:
      state = {
        ...state,
        loadingQuote: true,
        errorQuote: null,
        responseQuote: true,
      };
      break;
    case QUOTE_SUCCESS:
      state = {
        ...state,
        errorQuote: null,
        loadingQuote: false,
        responseQuote: action.payload,
      };
      break;
    case QUOTE_ERROR:
      state = {
        ...state,
        errorQuote: action.payload,
        loadingQuote: false,
        responseQuote: null,
      };
      break;
    case QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorQuote: null,
        loadingQuote: null,
        responseQuote: false,
      };
      break;
    //
    case QUOTE_ONE:
      state = {
        ...state,
        loadingOneQuote: true,
        errorOneQuote: null,
        responseOneQuote: null,
      };
      break;
    case QUOTE_ONE_SUCCESS:
      state = {
        ...state,
        errorOneQuote: null,
        loadingOneQuote: false,
        responseOneQuote: action.payload,
      };
      break;
    case QUOTE_ONE_ERROR:
      state = {
        ...state,
        errorOneQuote: action.payload,
        loadingOneQuote: false,
        responseOneQuote: null,
      };
      break;
    case QUOTE_ONE_CLEAR_DATA:
      state = {
        ...state,
        errorOneQuote: null,
        loadingOneQuote: null,
        responseOneQuote: false,
      };
      break;
    //
    case QUOTE_All:
      state = {
        ...state,
        loadingAllQuote: true,
        errorAllQuote: null,
        responseAllQuote: null,
      };
      break;
    case QUOTE_All_SUCCESS:
      state = {
        ...state,
        errorAllQuote: null,
        loadingAllQuote: false,
        responseAllQuote: action.payload,
      };
      break;
    case QUOTE_All_ERROR:
      state = {
        ...state,
        errorAllQuote: action.payload,
        loadingAllQuote: false,
        responseAllQuote: null,
      };
      break;
    case QUOTE_All_CLEAR_DATA:
      state = {
        ...state,
        errorAllQuote: null,
        loadingAllQuote: null,
        responseAllQuote: false,
      };
      break;
    //
    case DOWNLOAD_QUOTE_All:
      state = {
        ...state,
        loadingDownloadAllQuote: true,
        errorDownloadAllQuote: null,
        responseDownloadAllQuote: null,
      };
      break;
    case DOWNLOAD_QUOTE_All_SUCCESS:
      state = {
        ...state,
        errorDownloadAllQuote: null,
        loadingDownloadAllQuote: false,
        responseDownloadAllQuote: action.payload,
      };
      break;
    case DOWNLOAD_QUOTE_All_ERROR:
      state = {
        ...state,
        errorDownloadAllQuote: action.payload,
        loadingDownloadAllQuote: false,
        responseDownloadAllQuote: null,
      };
      break;
    case DOWNLOAD_QUOTE_All_CLEAR_DATA:
      state = {
        ...state,
        errorDownloadAllQuote: null,
        loadingDownloadAllQuote: null,
        responseDownloadAllQuote: false,
      };
      break;
    //
    case QUOTE_ADD_FIELDS:
      state = {
        ...state,
        loadingQuoteAddFields: true,
        errorQuoteAddFields: null,
        responseQuoteAddFields: null,
      };
      break;
    case QUOTE_ADD_FIELDS_SUCCESS:
      state = {
        ...state,
        errorQuoteAddFields: null,
        loadingQuoteAddFields: false,
        responseQuoteAddFields: action.payload,
      };
      break;
    case QUOTE_ADD_FIELDS_ERROR:
      state = {
        ...state,
        errorQuoteAddFields: action.payload,
        loadingQuoteAddFields: false,
        responseQuoteAddFields: null,
      };
      break;
    case QUOTE_ADD_FIELDS_CLEAR_DATA:
      state = {
        ...state,
        errorQuoteAddFields: null,
        loadingQuoteAddFields: null,
        responseQuoteAddFields: false,
      };
      break;
    //
    case QUOTE_UPDATE_STATUS_AUDIT_DOC:
      state = {
        ...state,
        loadingUpdateStatusAuditDocs: true,
        errorUpdateStatusAuditDocs: null,
        responseUpdateStatusAuditDocs: null,
      };
      break;
    case QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS:
      state = {
        ...state,
        errorUpdateStatusAuditDocs: null,
        loadingUpdateStatusAuditDocs: false,
        responseUpdateStatusAuditDocs: action.payload,
      };
      break;
    case QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR:
      state = {
        ...state,
        errorUpdateStatusAuditDocs: action.payload,
        loadingUpdateStatusAuditDocs: false,
        responseUpdateStatusAuditDocs: null,
      };
      break;
    case QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA:
      state = {
        ...state,
        errorUpdateStatusAuditDocs: null,
        loadingUpdateStatusAuditDocs: null,
        responseUpdateStatusAuditDocs: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Quote;

import {
  CERTIFICATE_QUOTE,
  CERTIFICATE_QUOTE_CLEAR_DATA,
  CERTIFICATE_QUOTE_ERROR,
  CERTIFICATE_QUOTE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorCertificateQuote: null,
  responseCertificateQuote: null,
  loadingCertificateQuote: false,
};

const CertificateQuote = (state = initialState, action) => {
  switch (action.type) {
    case CERTIFICATE_QUOTE:
      state = {
        ...state,
        loadingCertificateQuote: true,
        errorCertificateQuote: null,
        responseCertificateQuote: null,
      };
      break;
    case CERTIFICATE_QUOTE_SUCCESS:
      state = {
        ...state,
        errorCertificateQuote: null,
        loadingCertificateQuote: false,
        responseCertificateQuote: action.payload,
      };
      break;
    case CERTIFICATE_QUOTE_ERROR:
      state = {
        ...state,
        errorCertificateQuote: action.payload,
        loadingCertificateQuote: false,
        responseCertificateQuote: null,
      };
      break;
    case CERTIFICATE_QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorCertificateQuote: null,
        loadingCertificateQuote: null,
        responseCertificateQuote: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CertificateQuote;
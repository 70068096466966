import { call, put, takeEvery } from "redux-saga/effects";
import {
  sponsorError,
  sponsorOneSuccess,
  sponsorSuccess,
  postSponsorError,
  postSponsorSuccess,
  patchSponsorError,
  patchSponsorSuccess,
} from "./actions";
import { getOneSponsor } from "../../resources/helper/helper";
import { POST_SPONSOR, SPONSOR, SPONSOR_ONE, PATCH_SPONSOR } from "./actionTypes";
import { getSponsor, postSponsorApi, patchSponsorApi } from "../../resources/helper/sponsor";

function* sponsor({ payload: { sponsor } }) {
  try {
    
    const response = yield call(
      getSponsor,
      sponsor.enable,
      sponsor.page,
      sponsor.limit,
      sponsor.clientId,
      sponsor.name,
      sponsor.code
    );
    yield put(sponsorSuccess(response));
  } catch (error) {
    yield put(sponsorError(error));
  }
}

function* oneSponsor({ payload: { sponsor } }) {
  try {
    const response = yield call(getOneSponsor, sponsor.id);
    yield put(sponsorOneSuccess(response));
  } catch (error) {
    yield put(sponsorError(error));
  }
}

function* postSponsor({ payload:  data  }) {
  try {
    const response = yield call(postSponsorApi, data);
    yield put(postSponsorSuccess(response));
  } catch (error) {
    yield put(postSponsorError(error));
  }
}

function* patchSponsor({ payload:  {data, id}  }) {
  try {
    const response = yield call(patchSponsorApi, data, id);
    yield put(patchSponsorSuccess(response));
  } catch (error) {
    yield put(patchSponsorError(error));
  }
}

function* sponsorSaga() {
  yield takeEvery(SPONSOR, sponsor);
  yield takeEvery(SPONSOR_ONE, oneSponsor);
  yield takeEvery(POST_SPONSOR, postSponsor);
  yield takeEvery(PATCH_SPONSOR, patchSponsor);
}

export default sponsorSaga;

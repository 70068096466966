import {
  COVER_TYPE,
  COVER_TYPE_CLEAR_DATA,
  COVER_TYPE_ERROR,
  COVER_TYPE_SUCCESS,
  COVER_TYPE_GET_ONE,
  COVER_TYPE_GET_ONE_SUCCESS,
  COVER_TYPE_GET_ONE_ERROR,
  COVER_TYPE_GET_ONE_CLEAR_DATA,
  COVER_TYPE_POST,
  COVER_TYPE_POST_SUCCESS,
  COVER_TYPE_POST_ERROR,
  COVER_TYPE_POST_CLEAR_DATA,
  COVER_TYPE_PATCH,
  COVER_TYPE_PATCH_SUCCESS,
  COVER_TYPE_PATCH_ERROR,
  COVER_TYPE_PATCH_CLEAR_DATA,
} from "./actionTypes";

export const coverType = (coverType, history) => {
  return {
    type: COVER_TYPE,
    payload: { coverType, history },
  };
};

export const coverTypeSuccess = (success) => {
  return {
    type: COVER_TYPE_SUCCESS,
    payload: success,
  };
};

export const coverTypeError = (error) => {
  return {
    type: COVER_TYPE_ERROR,
    payload: error,
  };
};

export const coverTypeClearData = () => {
  return {
    type: COVER_TYPE_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypeGetOne = (id) => {
  return {
    type: COVER_TYPE_GET_ONE,
    payload: id,
  };
};

export const coverTypeGetOneSuccess = (success) => {
  return {
    type: COVER_TYPE_GET_ONE_SUCCESS,
    payload: success,
  };
};

export const coverTypeGetOneError = (error) => {
  return {
    type: COVER_TYPE_GET_ONE_ERROR,
    payload: error,
  };
};

export const coverTypeGetOneClearData = () => {
  return {
    type: COVER_TYPE_GET_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypePost = (data, history) => {
  return {
    type: COVER_TYPE_POST,
    payload:{ data, history },
  };
};

export const coverTypePostSuccess = (success) => {
  return {
    type: COVER_TYPE_POST_SUCCESS,
    payload: success,
  };
};

export const coverTypePostError = (error) => {
  return {
    type: COVER_TYPE_POST_ERROR,
    payload: error,
  };
};

export const coverTypePostClearData = () => {
  return {
    type: COVER_TYPE_POST_CLEAR_DATA,
    payload: {},
  };
};

export const coverTypePatch = (id, data) => {
  return {
    type: COVER_TYPE_PATCH,
    payload: { id, data },
  };
};

export const coverTypePatchSuccess = (success) => {
  return {
    type: COVER_TYPE_PATCH_SUCCESS,
    payload: success,
  };
};

export const coverTypePatchError = (error) => {
  return {
    type: COVER_TYPE_PATCH_ERROR,
    payload: error,
  };
};

export const coverTypePatchClearData = () => {
  return {
    type: COVER_TYPE_PATCH_CLEAR_DATA,
    payload: {},
  };
};
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogInsurance } from "../../store/catalog/actions";

export const GetCatalogInsurance = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const dispatch = useDispatch();

  const {
    responseGetCatalogInsurance,
    loadingGetCatalogInsurance,
    errorGetCatalogInsurance,
  } = useSelector((state: any) => ({
    responseGetCatalogInsurance: state.Catalog.responseGetCatalogInsurance,
    loadingGetCatalogInsurance: state.Catalog.loadingGetCatalogInsurance,
    errorGetCatalogInsurance: state.Catalog.errorGetCatalogInsurance,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCatalogInsurance({
          enable,
          page,
          limit,
          type: "CATALOG_INSURED_TYPE",
          clientId,
        }),
      );
    }
  }, [trigger, enable, page, limit, clientId]);

  return {
    responseGetCatalogInsurance,
    loadingGetCatalogInsurance,
    errorGetCatalogInsurance,
  };
};

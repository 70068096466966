import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectTypePost } from "../../store/projectType/actions";

export const useProjectTypePost = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const {
    responseProjectTypePost,
    errorProjectTypePost,
    loadingProjectTypePost,
  } = useSelector((state: any) => ({
    responseProjectTypePost: state.ProjectType.responseProjectTypePost?.data,
    errorProjectTypePost: state.ProjectType.errorProjectTypePost,
    loadingProjectTypePost: state.ProjectType.loadingProjectTypePost,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        projectTypePost({
          clientId: body.data.clientId,
          description: body.data.description,
        }),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseProjectTypePost,
    errorProjectTypePost,
    loadingProjectTypePost,
  };
};

import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { buildQueryString } from "../../utils/queryParam";
import { InsuredResponse } from "../../model/insured";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const postInsuredAPI = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.insured;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchInsured = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.insured + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getInsured = async (insured: any) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${
    URLs.insured
  }?${buildQueryString(insured)}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuredResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getInsured, postInsuredAPI, patchInsured };

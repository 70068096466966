export const PLAN_BENEFIT = "PLAN_BENEFIT";
export const PLAN_BENEFIT_SUCCESS = "PLAN_BENEFIT_SUCCESS";
export const PLAN_BENEFIT_ERROR = "PLAN_BENEFIT_ERROR";
export const PLAN_BENEFIT_CLEAR_DATA = "PLAN_BENEFIT_CLEAR_DATA";
export const PLAN_BENEFIT_UPDATE = "PLAN_BENEFIT_UPDATE";
export const PLAN_BENEFIT_UPDATE_SUCCESS = "PLAN_BENEFIT_UPDATE_SUCCESS";
export const PLAN_BENEFIT_UPDATE_ERROR = "PLAN_BENEFIT_UPDATE_ERROR";
export const PLAN_BENEFIT_DELETE = "PLAN_BENEFIT_DELETE";
export const PLAN_BENEFIT_DELETE_SUCCESS = "PLAN_BENEFIT_DELETE_SUCCESS";
export const PLAN_BENEFIT_DELETE_ERROR = "PLAN_BENEFIT_DELETE_ERROR";
export const PLAN_BENEFIT_CREATE = "PLAN_BENEFIT_CREATE";
export const PLAN_BENEFIT_CREATE_SUCCESS = "PLAN_BENEFIT_CREATE_SUCCESS";
export const PLAN_BENEFIT_CREATE_ERROR = "PLAN_BENEFIT_CREATE_ERROR";


import React, { createContext, useContext, useState } from "react";
import { Status } from "./enum/login";
import LocalStorageService from "./services/localStorage";

interface AuthContextProps {
  status: Status;
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth debe ser usado dentro de un AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const storageKey = "afy-login";
  const localStorageService = new LocalStorageService(storageKey);
  const [status, setStatus] = useState<Status>(
    localStorageService.isAuthenticated()
      ? "authenticated"
      : "no-authenticated",
  );
  return (
    <AuthContext.Provider value={{ status, setStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceType } from "../../store/insuranceType/actions";

export const useInsuranceTypeGetAll = (
  trigger: boolean,
  clientId: string,
  namesearch: string,
  insuranceTypePage: number,
  insuranceTypeLimit: number,
) => {
  const dispatch = useDispatch();
  const [enable] = useState<boolean>(true);

  const { responseInsuranceType, errorInsuranceType, loadingInsuranceType } =
    useSelector((state: any) => ({
      responseInsuranceType: state.InsuranceType.responseInsuranceType,
      errorInsuranceType: state.InsuranceType.errorInsuranceType,
      loadingInsuranceType: state.InsuranceType.loadingInsuranceType,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        insuranceType({
          enable: enable,
          page: insuranceTypePage,
          limit: insuranceTypeLimit,
          clientId: clientId,
          description: namesearch,
        }),
      );
    }
  }, [trigger]);

  return {
    responseInsuranceType,
    errorInsuranceType,
    loadingInsuranceType,
  };
};

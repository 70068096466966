import {
  CAMPAIGNPROJECT,
  CAMPAIGNPROJECT_CLEAR_DATA,
  CAMPAIGNPROJECT_ERROR,
  CAMPAIGNPROJECT_SUCCESS,
  CAMPAIGNPROJECT_POST,
  CAMPAIGNPROJECT_POST_SUCCESS,
  CAMPAIGNPROJECT_POST_ERROR,
  CAMPAIGNPROJECT_POST_CLEAR_DATA,
} from "./actionTypes";
 
export const campaignProject = (campaignProject, history) => {
  return {
    type: CAMPAIGNPROJECT,
    payload: { campaignProject, history },
  };
};

export const campaignProjectSuccess = (success) => {
  return {
    type: CAMPAIGNPROJECT_SUCCESS,
    payload: success,
  };
};

export const campaignProjectError = (error) => {
  return {
    type: CAMPAIGNPROJECT_ERROR,
    payload: error,
  };
};

export const campaignProjectClearData = () => {
  return {
    type: CAMPAIGNPROJECT_CLEAR_DATA,
    payload: {},
  };
};

export const campaignProjectPost = (campaignProject, history) => {
  return {
    type: CAMPAIGNPROJECT_POST,
    payload: { campaignProject, history },
  };
};

export const campaignProjectPostSuccess = (success) => {
  return {
    type: CAMPAIGNPROJECT_POST_SUCCESS,
    payload: { ...success },
  };
};

export const campaignProjectPostError = (error) => {
  return {
    type: CAMPAIGNPROJECT_POST_ERROR,
    payload: error,
  };
};

export const campaignProjectPostClearData = () => {
  return {
    type: CAMPAIGNPROJECT_POST_CLEAR_DATA,
    payload: {},
  };
};


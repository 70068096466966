import {
  PERMISSION_ERROR,
  PERMISSION_SUCCESS,
  PERMISSIONS,
} from "./actionTypes";

const initialState = {
  errorPermission: null,
  responsePermission: null,
  loadingPermission: false,
};

const Permission = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSIONS:
      state = {
        ...state,
        loadingPermission: true,
        errorPermission: null,
        responsePermission: null,
      };
      break;
    case PERMISSION_SUCCESS:
      state = {
        ...state,
        errorPermission: null,
        loadingPermission: false,
        responsePermission: action.payload,
      };
      break;
    case PERMISSION_ERROR:
      state = {
        ...state,
        errorPermission: action.payload,
        loadingPermission: false,
        responsePermission: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Permission;
import {
  COUNTRIES_ERROR,
  COUNTRIES,
  COUNTRIES_SUCCESS,
  COUNTRIES_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorCountries: null,
  responseCountries: null,
  loadingCountries: false,
};

const Countries = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRIES:
      state = {
        ...state,
        loadingCountries: true,
        errorCountries: null,
        responseCountries: true,
      };
      break;
    case COUNTRIES_SUCCESS:
      state = {
        ...state,
        errorCountries: null,
        loadingCountries: false,
        responseCountries: action.payload,
      };
      break;
    case COUNTRIES_ERROR:
      state = {
        ...state,
        errorCountries: action.payload,
        loadingCountries: false,
        responseCountries: null,
      };
      break;
    case COUNTRIES_CLEAR_DATA:
      state = {
        ...state,
        errorCountries: null,
        loadingCountries: null,
        responseCountries: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Countries;

import { fetchRequest, handleUnauthorized } from "../fetchRequest";
import { URLs } from "../url";
import { SponsorResponse } from "../../model/sponsor";
import { getHeader } from "../headers";
import LocalStorageService from "../../services/localStorage";
import { useNavigate } from "react-router-dom";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const getSponsor = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
  name: string,
  code: string
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.sponsor +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (clientId ? `&clientId=${clientId}` : "") +
    (name ? `&name=${name}` : "") +
    (code ? `&code=${code}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSponsorId = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.sponsor + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const postSponsorApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.sponsor;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(newURL, requestOptions)) as SponsorResponse;
  } catch (error: any) {
    throw error;
  }
};

const patchSponsorApi = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.sponsor + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(newURL, requestOptions)) as SponsorResponse;
  } catch (error: any) {
    throw error;
  }
};

export { getSponsor, postSponsorApi, patchSponsorApi, getSponsorId };

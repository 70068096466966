import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { getHeader } from "../headers";

const postCampaignProjectApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.campaignProject;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

export { postCampaignProjectApi };

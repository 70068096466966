import {
  PLANS,
  PLANS_CLEAR_DATA,
  PLANS_ERROR,
  PLAN_ONE,
  PLAN_ONE_SUCCESS,
  PLANS_SUCCESS,
  POST_PLAN,
  POST_PLAN_CLEAR_DATA,
  POST_PLAN_ERROR,
  POST_PLAN_SUCCESS,
  PATCH_PLAN,
  PATCH_PLAN_CLEAR_DATA,
  PATCH_PLAN_ERROR,
  PATCH_PLAN_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPlans: null,
  responsePlans: null,
  loadingPlans: false,
  responseOnePlans: false,
  errorPostPlans: null,
  responsePostPlans: null,
  loadingPostPlans: false,
  errorPatchPlans: null,
  loadingPatchPlans: null,
  responsePatchPlans: false,
};

const plansApi = (state = initialState, action) => {
  switch (action.type) {
    case PLANS:
      state = {
        ...state,
        loadingPlans: true,
        errorPlans: null,
        responsePlans: true,
      };
      break;
    case PLAN_ONE:
      state = {
        ...state,
        loadingPlans: true,
        errorPlans: null,
        responseOnePlans: true,
      };
      break;
    case PLANS_SUCCESS:
      state = {
        ...state,
        errorPlans: null,
        loadingPlans: false,
        responsePlans: action.payload,
      };
      break;
    case PLAN_ONE_SUCCESS:
      state = {
        ...state,
        errorPlans: null,
        loadingPlans: false,
        responseOnePlans: action.payload,
      };
      break;	  
    case PLANS_ERROR:
      state = {
        ...state,
        errorPlans: action.payload,
        loadingPlans: false,
        responsePlans: null,
      };
      break;
    case PLANS_CLEAR_DATA:
      state = {
        ...state,
        errorPlans: null,
        loadingPlans: null,
        responsePlans: false,
      };
      break;
   case POST_PLAN:
      state = {
        ...state,
        loadingPostPlans: true,
        errorPostPlans: null,
        responsePostPlans: true,
      };
      break;
    case POST_PLAN_SUCCESS:
      state = {
        ...state,
        errorPostPlans: null,
        loadingPostPlans: false,
        responsePostPlans: action.payload,
      };
      break;
    case POST_PLAN_ERROR:
      state = {
        ...state,
        errorPostPlans: action.payload,
        loadingPostPlans: false,
        responsePostPlans: null,
      };
      break;
    case POST_PLAN_CLEAR_DATA:
      state = {
        ...state,
        errorPostPlans: null,
        loadingPostPlans: null,
        responsePostPlans: false,
      };
      break;
      case PATCH_PLAN:
      state = {
        ...state,
        loadingPatchPlans: true,
        errorPatchPlans: null,
        responsePatchPlans: true,
      };
      break;
    case PATCH_PLAN_SUCCESS:
      state = {
        ...state,
        errorPatchPlans: null,
        loadingPatchPlans: false,
        responsePatchPlans: action.payload,
      };
      break;
    case PATCH_PLAN_ERROR:
      state = {
        ...state,
        errorPatchPlans: action.payload,
        loadingPatchPlans: false,
        responsePatchPlans: null,
      };
      break;
    case PATCH_PLAN_CLEAR_DATA:
      state = {
        ...state,
        errorPatchPlans: null,
        loadingPatchPlans: null,
        responsePatchPlans: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default plansApi;

import { call, put, takeEvery } from "redux-saga/effects";
import {
  generalCatalogError,
  generalCatalogPatchError,
  generalCatalogPatchSuccess,
  generalCatalogPostError,
  generalCatalogPostSuccess,
  generalCatalogSuccess,
} from "./actions";
import {
  GENERAL_CATALOG,
  GENERAL_CATALOG_PATCH,
  GENERAL_CATALOG_POST,
} from "./actionTypes";
import {
  getGeneralCatalog,
  patchGeneralCatalog,
  postGeneralCatalog,
} from "../../resources/helper/generalCatalog";

function* generalCatalog({ payload: { generalCatalog } }) {
  try {
    const response = yield call(
      getGeneralCatalog,
      generalCatalog.clientId,
      generalCatalog.enable,
      generalCatalog.page,
      generalCatalog.limit,
      generalCatalog.type,
      generalCatalog.code,
      generalCatalog.description,
    );
    yield put(generalCatalogSuccess(response));
  } catch (error) {
    yield put(generalCatalogError(error));
  }
}
function* generalCatalogPost({ payload: { generalCatalogBody } }) {
  try {
    const response = yield call(postGeneralCatalog, generalCatalogBody);
    yield put(generalCatalogPostSuccess(response));
  } catch (error) {
    yield put(generalCatalogPostError(error));
  }
}

function* generalCatalogPatch({ payload: { generalCatalogPatchBody, id } }) {
  try {
    const response = yield call(
      patchGeneralCatalog,
      generalCatalogPatchBody,
      id,
    );
    yield put(generalCatalogPatchSuccess(response));
  } catch (error) {
    yield put(generalCatalogPatchError(error));
  }
}

function* generalCatalogSaga() {
  yield takeEvery(GENERAL_CATALOG, generalCatalog);
  yield takeEvery(GENERAL_CATALOG_POST, generalCatalogPost);
  yield takeEvery(GENERAL_CATALOG_PATCH, generalCatalogPatch);
}

export default generalCatalogSaga;

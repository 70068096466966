import { URLs } from "../url";
import { AccountTypeResponse } from "../../model/accountType";
import { ProjectTypeResponse } from "../../model/projectType";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getAccountType = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
  projectId: string,
  deleted: boolean,
  catalogAccountTypeId: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.accountType +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (clientId ? `&clientId=${clientId}` : "") +
    (projectId ? `&projectId=${projectId}` : "") +
    `&deleted=${deleted}` +
    (catalogAccountTypeId
      ? `&catalogAccountTypeId=${catalogAccountTypeId}`
      : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as AccountTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postAccountType = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.accountType;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as AccountTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchAccountType = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.accountType + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as ProjectTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getAccountType, postAccountType, patchAccountType };

import {
  PROJECT_TYPE,
  PROJECT_TYPE__POST_ERROR,
  PROJECT_TYPE_CLEAR_DATA,
  PROJECT_TYPE_ERROR,
  PROJECT_TYPE_PATCH,
  PROJECT_TYPE_PATCH_CLEAR_DATA,
  PROJECT_TYPE_PATCH_ERROR,
  PROJECT_TYPE_PATCH_SUCCESS,
  PROJECT_TYPE_POST,
  PROJECT_TYPE_POST_CLEAR_DATA,
  PROJECT_TYPE_POST_SUCCESS,
  PROJECT_TYPE_SUCCESS,
} from "./actionTypes";

export const projectType = (projectType, history) => {
  return {
    type: PROJECT_TYPE,
    payload: { projectType, history },
  };
};

export const projectTypeSuccess = (success) => {
  return {
    type: PROJECT_TYPE_SUCCESS,
    payload: success,
  };
};

export const projectTypeError = (error) => {
  return {
    type: PROJECT_TYPE_ERROR,
    payload: error,
  };
};

export const projectTypeClearData = () => {
  return {
    type: PROJECT_TYPE_CLEAR_DATA,
    payload: {},
  };
};
export const projectTypePost = (projectTypeBody, history) => {
  return {
    type: PROJECT_TYPE_POST,
    payload: { projectTypeBody, history },
  };
};

export const projectTypePostSuccess = (success) => {
  return {
    type: PROJECT_TYPE_POST_SUCCESS,
    payload: success,
  };
};

export const projectTypePostError = (error) => {
  return {
    type: PROJECT_TYPE__POST_ERROR,
    payload: error,
  };
};

export const projectTypePostClearData = () => {
  return {
    type: PROJECT_TYPE_POST_CLEAR_DATA,
    payload: {},
  };
};

export const projectTypePatch = (projectTypePatchBody, id) => {
  return {
    type: PROJECT_TYPE_PATCH,
    payload: { projectTypePatchBody, id },
  };
};

export const projectTypePatchSuccess = (success) => {
  return {
    type: PROJECT_TYPE_PATCH_SUCCESS,
    payload: success,
  };
};

export const projectTypePatchError = (error) => {
  return {
    type: PROJECT_TYPE_PATCH_ERROR,
    payload: error,
  };
};

export const projectTypePatchClearData = () => {
  return {
    type: PROJECT_TYPE_PATCH_CLEAR_DATA,
    payload: {},
  };
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewInsuranceType } from "./view";
import {
  InsuranceTypeModel,
  InsuranceTypeRequest,
} from "../../model/insuranceType";
import LocalStorageService from "../../services/localStorage";
import {
  patchInsuranceTypeClearData,
  postInsuranceTypeClearData,
} from "../../store/insuranceType/actions";
import { clearDataPutAvatar, putAvatar } from "../../store/avatar/actions";
import {
  deleteEntity,
  deleteEntityClearData,
} from "../../store/delete/actions";
import { useInsuranceTypeGetAll } from "../../fnx/insuranceType/InsuranceTypeGetAll";
import { useInsuranceTypeCreate } from "../../fnx/insuranceType/InsuranceTypeCreate";
import { useInsuranceTypePatch } from "../../fnx/insuranceType/InsuranceTypePatch";
import {
  CardProps,
  DataFormClient,
  ListDropdownMultipleProps,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { insuranceTypeDataForm } from "./insuranceTypeDTO";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { hasPermission } from "../../services/getNavigationPages";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const InsuranceTypeAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [triggerPost, setTriggerPost] = useState<boolean>(false);
  const [triggerPatch, setTriggerPatch] = useState<boolean>(false);
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [insuranceTypeList, setInsuranceTypeList] = useState<CardProps[]>([]);
  const [listClientId] = useState<
    { id: string; name: string; value: string }[]
  >([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [avatarFile, setAvatarFile] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<InsuranceTypeRequest>();
  const [dataForm, setDataForm] = useState<DataFormClient>(
    insuranceTypeDataForm,
  );
  const [titleDrawer, setTitleDrawer] = useState("");
  const [insuranceTypePage, setInsuranceTypePage] = useState<number>(1);
  const [insuranceTypeLimit, setInsuranceTypeLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseInsuranceType, errorInsuranceType, loadingInsuranceType } =
    useInsuranceTypeGetAll(
      trigger,
      clientId,
      nameSearch,
      insuranceTypePage,
      insuranceTypeLimit,
    );

  const {
    responsePostInsuranceType,
    errorPostInsuranceType,
    loadingPostInsuranceType,
  } = useInsuranceTypeCreate(triggerPost, bodyRequest);

  const {
    responsePatchInsuranceType,
    errorPatchInsuranceType,
    loadingPatchInsuranceType,
  } = useInsuranceTypePatch(triggerPatch, bodyRequest);

  const permissions = {
    create: hasPermission("admin-product-type-create"),
    read: hasPermission("admin-product-type-view"),
    update: hasPermission("admin-product-type-edit"),
    delete: hasPermission("admin-product-type-delete"),
  };

  const { responsePutAvatar, errorPutAvatar, loadingPutAvatar } = useSelector(
    (state: any) => ({
      responsePutAvatar: state.Avatar.responsePutAvatar,
      errorPutAvatar: state.Avatar.errorPutAvatar,
      loadingPutAvatar: state.Avatar.loadingPutAvatar,
    }),
  );

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  useEffect(() => {
    if (!responseInsuranceType?.data) {
      setTrigger(true);
    }
  }, []);

  useEffect(() => {
    if (responseInsuranceType && responseInsuranceType?.data?.length > 0) {
      const newInsuranceTypeList: CardProps[] = responseInsuranceType.data.map(
        (item: InsuranceTypeModel) => ({
          id: item.id,
          title: item.description,
          logo: item.logoUrl,
        }),
      );
      setInsuranceTypeList(newInsuranceTypeList);
      if (Array.isArray(responseInsuranceType.data)) {
        setInsuranceType(responseInsuranceType.data);
      }
    }
    if (responseInsuranceType?.pagingInfo) {
      setCountPagination(responseInsuranceType?.pagingInfo.totalItems);
    }
    if (responseInsuranceType) {
      setTrigger(false);
    }
  }, [responseInsuranceType]);

  useEffect(() => {
    if (responsePostInsuranceType && responsePostInsuranceType?.data) {
      showToastSuccessMessage(
        "Tipo de producto creado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(postInsuranceTypeClearData());
      dispatch(
        putAvatar("ProjectType", responsePostInsuranceType.data.id, avatarFile),
      );
      setAvatarFile(null);
      setTriggerPost(false);
    } else if (errorPostInsuranceType) {
      showErrorToast(errorPostInsuranceType, setSeeToast, t);
      setTriggerPost(false);
    }
  }, [responsePostInsuranceType, errorPostInsuranceType]);

  useEffect(() => {
    if (responsePutAvatar && responsePutAvatar?.url) {
      showToastSuccessMessage(
        "Tipo de producto actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(clearDataPutAvatar());
      setTrigger(true);
    } else if (errorPutAvatar) {
      showErrorToast(errorPutAvatar, setSeeToast, t);
    }
  }, [responsePutAvatar, errorPutAvatar]);

  useEffect(() => {
    if (responseDeleteEntity) {
      showToastSuccessMessage(
        "Tipo de producto eliminado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (responsePatchInsuranceType && responsePatchInsuranceType.data) {
      setOpenModal(false);
      if (avatarFile != null) {
        dispatch(
          putAvatar(
            "ProjectType",
            responsePatchInsuranceType.data.id,
            avatarFile,
          ),
        );
        setAvatarFile(null);
      } else {
        showToastSuccessMessage(
          "Tipo de producto actualizado con éxito.",
          typeAlert.success,
          setSeeToast,
        );
      }
      setTrigger(true);
      setTriggerPatch(false);
      dispatch(patchInsuranceTypeClearData());
    } else if (errorPatchInsuranceType) {
      showErrorToast(errorPatchInsuranceType, setSeeToast, t);
      setTriggerPatch(false);
    }
  }, [responsePatchInsuranceType, errorPatchInsuranceType]);

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const setInsuranceType = (responseSponsor: InsuranceTypeModel[]) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseSponsor?.map((item: InsuranceTypeModel) => {
      if (item.id && item.description) {
        dataBranches.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setInsuranceTypeOptions(dataBranches);
  };

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    const idSponsor = item?.sponsor.map((item: any) => item.id).join(",");
    setTrigger(true);
  };

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      id: row.id,
      Name: row.title,
      clientId: row.clientId,
    });
  };

  const handleSubmitInsuranceType = (dataInsuranceType: any, file: File) => {
    if (isUpdate) {
      setBodyRequest({
        id: dataInsuranceType.id,
        description: dataInsuranceType.Name,
      });
      setOpenDrawer(false);
      setTriggerPatch(true);
      setAvatarFile(file);
    } else {
      setBodyRequest({
        description: dataInsuranceType.Name,
      });
      setOpenDrawer(false);
      setTriggerPost(true);
      setAvatarFile(file);
    }
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenDrawer(true);
    setTitleDrawer("Agregar tipo de producto");
    setDataForm({
      ...dataForm,
      id: "",
      Name: "",
      clientId: "",
      Img: "",
    });
  };

  const onClickContextMenu = (opt?: any, item?: any) => {
    switch (opt.id) {
      case "1":
        setIsUpdate(true);
        setTitleDrawer("Modificar tipo de producto");
        setOpenDrawer(true);
        showEdit(item);
        break;
      case "2":
        setOpenModalConfirmDelete(true);
        setitemModal(item);
        break;
      case "3":
        setitemModal(item);
        setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  const handleDelete = () => {
    setOpenModalConfirmDelete(false);
    dispatch(
      deleteEntity({
        id: itemModal.id,
        entity: "ProjectTypeEntity",
        idUser: clientId,
      }),
    );
  };

  const handleClone = () => {
    let body = {
      clientId: itemModal.clientId,
      name: itemModal.title,
    };
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
    setTriggerPost(true);
    dispatch(postInsuranceTypeClearData());
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
    setTrigger(true);
  };

  const onChangePage = (e: number) => {
    setInsuranceTypePage(e);
    setTrigger(true);
  };

  const onChangeRowsPerPage = (e: number) => {
    setInsuranceTypeLimit(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingInsuranceType ||
          loadingPostInsuranceType ||
          loadingPatchInsuranceType ||
          loadingDeleteEntity
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingDeleteEntity
            ? "Eliminando tipo de producto"
            : "Cargando tios de producto..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <ViewInsuranceType
        title="Tipo de Productos"
        projectTypeTitle={
          isUpdate ? "Modificar Tipo de Producto" : "Agregar Tipo de Producto"
        }
        permissions={permissions}
        loadingInsuranceType={loadingInsuranceType}
        insuranceTypeOptions={insuranceTypeOptions}
        insuranceTypeList={insuranceTypeList}
        listClientId={listClientId}
        openModal={openModal}
        openModalConfirmDelete={openModalConfirmDelete}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPostInsuranceType={loadingPostInsuranceType}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        onSaveData={handleSubmitInsuranceType}
        clickView={clickView}
        onClickDelete={handleDelete}
        onSubmitClone={handleClone}
        onCloseCloneModal={handleCloseCloneModal}
        onClickAdd={handleOnClickAdd}
        onClickContextMenu={onClickContextMenu}
        filterStatus={filterStatus}
        searchFilter={searchFilter}
        onCloseSearch={onCloseSearch}
        openDrawer={openDrawer}
        onCloseDrawer={closeDrawer}
        setOpenModalConfirmDelete={setOpenModalConfirmDelete}
        insuranceTypePage={insuranceTypePage}
        insuranceTypeLimit={insuranceTypeLimit}
        countPagination={countPagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        titleDrawer={titleDrawer}
      />
    </React.Fragment>
  );
};

export default InsuranceTypeAdmin;

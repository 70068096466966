import {
  ROL,
  ROL_CLEAR_DATA,
  ROL_DISABLE,
  ROL_DISABLE_CLEAR_DATA,
  ROL_DISABLE_ERROR,
  ROL_DISABLE_SUCCESS,
  ROL_ERROR,
  ROL_PATCH,
  ROL_PATCH_CLEAR_DATA,
  ROL_PATCH_ERROR,
  ROL_PATCH_SUCCESS,
  ROL_POST,
  ROL_POST_CLEAR_DATA,
  ROL_POST_ERROR,
  ROL_POST_SUCCESS,
  ROL_SUCCESS,
} from "./actionTypes";

export const rol = (rol, history) => {
  return {
    type: ROL,
    payload: { rol, history },
  };
};

export const rolSuccess = (success) => {
  return {
    type: ROL_SUCCESS,
    payload: success,
  };
};

export const rolError = (error) => {
  return {
    type: ROL_ERROR,
    payload: error,
  };
};

export const rolClearData = () => {
  return {
    type: ROL_CLEAR_DATA,
    payload: {},
  };
};

export const rolPost = (rolBody, history) => {
  return {
    type: ROL_POST,
    payload: { rolBody, history },
  };
};

export const rolPostSuccess = (success) => {
  return {
    type: ROL_POST_SUCCESS,
    payload: success,
  };
};

export const rolPostError = (error) => {
  return {
    type: ROL_POST_ERROR,
    payload: error,
  };
};

export const rolPostClearData = () => {
  return {
    type: ROL_POST_CLEAR_DATA,
    payload: {},
  };
};

export const rolPatch = (rolPatchBody, id) => {
  return {
    type: ROL_PATCH,
    payload: { rolPatchBody, id },
  };
};

export const rolPatchSuccess = (success) => {
  return {
    type: ROL_PATCH_SUCCESS,
    payload: success,
  };
};

export const rolPatchError = (error) => {
  return {
    type: ROL_PATCH_ERROR,
    payload: error,
  };
};

export const rolPatchClearData = () => {
  return {
    type: ROL_PATCH_CLEAR_DATA,
    payload: {},
  };
};

export const rolDisable = (id) => {
  return {
    type: ROL_DISABLE,
    payload: { id },
  };
};

export const rolDisableSuccess = (success) => {
  return {
    type: ROL_DISABLE_SUCCESS,
    payload: success,
  };
};

export const rolDisableError = (error) => {
  return {
    type: ROL_DISABLE_ERROR,
    payload: error,
  };
};

export const rolDisableClearData = () => {
  return {
    type: ROL_DISABLE_CLEAR_DATA,
    payload: {},
  };
};
export const SPONSOR = "SPONSOR";
export const SPONSOR_SUCCESS = "SPONSOR_SUCCESS";
export const SPONSOR_ONE = "SPONSOR_ONE";
export const SPONSOR_ONE_SUCCESS = "SPONSOR_ONE_SUCCESS";
export const SPONSOR_ERROR = "SPONSOR_ERROR";
export const SPONSOR_CLEAR_DATA = "SPONSOR_CLEAR_DATA";
export const POST_SPONSOR = "POST_SPONSOR";
export const POST_SPONSOR_SUCCESS = "POST_SPONSOR_SUCCESS";
export const POST_SPONSOR_ERROR = "POST_SPONSOR_ERROR";
export const POST_SPONSOR_CLEAR_DATA = "POST_SPONSOR_CLEAR_DATA";
export const PATCH_SPONSOR = "PATCH_SPONSOR";
export const PATCH_SPONSOR_SUCCESS = "PATCH_SPONSOR_SUCCESS";
export const PATCH_SPONSOR_ERROR = "PATCH_SPONSOR_ERROR";
export const PATCH_SPONSOR_CLEAR_DATA = "PATCH_SPONSOR_CLEAR_DATA";

import {
  PAGEACTIONFLAGS,
  PAGEACTIONFLAGS_CLEAR_DATA,
  PAGEACTIONFLAGS_ERROR,
  PAGEACTIONFLAGS_SUCCESS,
  POST_ACTION_FLAGS,
  POST_ACTION_FLAGS_CLEAR_DATA,
  POST_ACTION_FLAGS_ERROR,
  POST_ACTION_FLAGS_SUCCESS,
} from "./actionTypes";

export const pageActionFlagsApi = (pageActionFlags, history) => {
  return {
    type: PAGEACTIONFLAGS,
    payload: { pageActionFlags, history },
  };
};

export const pageActionFlagsSuccess = (success) => {
  return {
    type: PAGEACTIONFLAGS_SUCCESS,
    payload: success,
  };
};

export const pageActionFlagsError = (error) => {
  return {
    type: PAGEACTIONFLAGS_ERROR,
    payload: error,
  };
};

export const pageActionFlagsClearData = () => {
  return {
    type: PAGEACTIONFLAGS_CLEAR_DATA,
    payload: {},
  };
};
//
export const postActionFlags = (actionFlags, history) => {
  return {
    type: POST_ACTION_FLAGS,
    payload: { actionFlags, history },
  };
};

export const postActionFlagsSuccess = (success) => {
  return {
    type: POST_ACTION_FLAGS_SUCCESS,
    payload: success,
  };
};

export const postActionFlagsError = (error) => {
  return {
    type: POST_ACTION_FLAGS_ERROR,
    payload: error,
  };
};

export const postActionFlagsClearData = () => {
  return {
    type: POST_ACTION_FLAGS_CLEAR_DATA,
    payload: {},
  };
};

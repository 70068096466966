import {
  ButtonAFY,
  ContextMenuAFY,
  DataFormVehicles,
  IconAFY,
  icons,
  MenuItem,
  sizes,
  sizesIcon,
  state,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { Steps } from "../../enum/steps";
import { getCustomerAction } from "../../fnx/customer";
import { TFunction } from "i18next";
import LocalStorageService from "../../services/localStorage";
import { DivLongText, DivStatus } from "../../apap/salesCore/auditPage/style";
import React from "react";
import { newFormattedDate } from "../../utils/date";

interface TableColumn {
  field: string;
  headerName: string;
  width: number;
  renderCell?: (params: any) => JSX.Element;
}

export const stepProgress: MenuItem[] = [
  {
    id: "1",
    name: "Nombre del seguro",
    state: state.complete,
  },
  {
    id: "2",
    name: "Tipo de plan",
    state: state.complete,
  },
  {
    id: "3",
    name: "Datos del cliente",
    state: state.complete,
  },
  {
    id: "4",
    name: "Confirmación",
    state: state.complete,
  },
  {
    id: "5",
    name: "Datos de pago",
    state: state.complete,
  },
  {
    id: "6",
    name: "Confirmación de pago",
    state: state.complete,
  },
];

export const TabAutoColumns = (f: (e: any) => void, isQuote: string) => {
  const active = isQuote == "false";
  const columns: TableColumn[] = [
    {
      field: "type",
      headerName: "Acciones",
      width: 150,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <ContextMenuAFY
            colorButton="#fff"
            iconButton={icons.MoreVert}
            iconColor="#3A56A1"
            menuItem={[
              {
                disabled: active,
                icon: {
                  color: "#006ac6",
                  icon: icons.Edit,
                  size: sizesIcon.standard,
                },
                id: "1",
                name: "Actualizar",
              },
            ]}
            onClickContextMenu={f}
            sizeIcon={sizesIcon.standard}
          />
        </div>
      ),
    },
    {
      field: "brand",
      headerName: "Marca",
      width: 150,
    },
    {
      field: "model",
      headerName: "Modelo",
      width: 150,
    },
    {
      field: "color",
      headerName: "Color",
      width: 150,
    },
    {
      field: "price",
      headerName: "Valor",
      width: 150,
    },
    {
      field: "cylinders",
      headerName: "Numero de cilindros",
      width: 250,
    },
    {
      field: "fueltype",
      headerName: "Tipo de combustible",
      width: 250,
    },
    {
      field: "plate",
      headerName: "Placa",
      width: 150,
    },
  ];
  return columns;
};

export const Tab1Columns = (showEdit: any, canEditInsurance: boolean) => {
  const columns: TableColumn[] = [
    {
      field: "type",
      headerName: "Tipo asegurado",
      width: 180,
    },
    {
      field: "names",
      headerName: "Nombres",
      width: 280,
    },

    {
      field: "lastNames",
      headerName: "Apellidos",
      width: 280,
    },

    {
      field: "marriedName",
      headerName: "Apellido de casada",
      width: 240,
    },
    {
      field: "phone1",
      headerName: "Teléfono",
      width: 150,
    },
    {
      field: "dob",
      headerName: "Fecha nacimiento",
      width: 220,
    },
  ];
  if (canEditInsurance) {
    columns.unshift({
      field: "actions",
      headerName: "Acciones",
      width: 100,
      renderCell: (params: any) => (
        <>
          {params.row.type !== "Titular" && (
            <ButtonAFY
              size={sizes.small}
              variant={variants.outlined}
              label={"Editar"}
              color={themeCore.colors.buttonPrimary}
              onClick={() => showEdit(params)}
            />
          )}
        </>
      ),
    });
  }
  return columns;
};

export const Tab2Columns = (showEdit: any, canEditBeneficiary: boolean) => {
  const columns: TableColumn[] = [
    {
      field: "type",
      headerName: "Tipo beneficiario",
      width: 200,
    },
    {
      field: "names",
      headerName: "Nombres",
      width: 280,
    },
    {
      field: "lastNames",
      headerName: "Apellidos",
      width: 280,
    },
    {
      field: "dob",
      headerName: "Fecha nacimiento",
      width: 230,
    },
    {
      field: "percentage",
      headerName: "Porcentaje",
      width: 170,
    },
  ];

  if (canEditBeneficiary) {
    columns.unshift({
      field: "actions",
      headerName: "Acciones",
      width: 100,
      renderCell: (params: any) => (
        <>
          {params.row.type !== "Titular" && (
            <ButtonAFY
              size={sizes.small}
              variant={variants.outlined}
              label={"Editar"}
              color={themeCore.colors.buttonPrimary}
              onClick={() => showEdit(params)}
            />
          )}
        </>
      ),
    });
  }
  return columns;
};

export const Tab3Columns: any = [
  {
    field: "accountName",
    headerName: "Nombre de la cuenta",
    width: 300,
  },
  {
    field: "accountNumber",
    headerName: "Número de cuenta",
    width: 200,
  },
  {
    field: "accountType",
    headerName: "Tipo de cuenta",
    width: 250,
  },
  {
    field: "paymentMode",
    headerName: "Modo de pago",
    width: 150,
  },
  {
    field: "prime",
    headerName: "Prima",
    width: 150,
  },
];

export interface vehicleDetail {
  id: number;
  brand: string;
  model: string;
  color: string;
  price: string;
  cylinders: string;
  fueltype: string;
  plate: string;
  year?: string;
  chassisNumber?: string;
  status?: string;
}

export function getFilters(
  filtersDTO: any,
  isVidaTermino: boolean,
  tl: TFunction,
) {
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const filtersForApapAuto = [
    {
      name: "Producto",
      // label: filtersDTO.steps[Steps.insurance]?.name ?? "",
      label: filtersDTO.product,
      type: "label",
    },
    {
      name: "Plan",
      // label: filtersDTO.steps[Steps.customer]?.name ?? "",
      label: filtersDTO.plan,
      type: "label",
      showChangeButton: false,
    },
    {
      name: "Campaña",
      // label: filtersDTO.steps[Steps.customer].campaignSelected?.name ?? "",
      label: filtersDTO.campaing,
      type: "label",
      showChangeButton: false,
    },
    {
      name: "Prima",
      label: filtersDTO.currency + " " + filtersDTO.prime,
      type: "label",
    },
    {
      name: "Año",
      label: filtersDTO.year,
      type: "label",
    },
    {
      name: "Modelo",
      label: filtersDTO.model,
      type: "label",
    },
    {
      name: "Marca",
      label: filtersDTO.brand,
      type: "label",
    },
    {
      name: "Chasis",
      label: filtersDTO.chassis,
      type: "label",
    },
    {
      name: "Placa",
      label: filtersDTO.plate,
      type: "label",
    },
    {
      name: "Estado del Vehiculo",
      label: filtersDTO.statusVehicle,
      type: "label",
    },
  ];

  const filtersForOthers = [
    {
      name: "Tipo de producto",
      label: filtersDTO.steps[Steps.insurance]?.name ?? "",
      type: "label",
    },
    {
      name: "Producto",
      label: filtersDTO.steps[Steps.insurance]?.name ?? "",
      type: "label",
    },
    {
      name: "Plan",
      label: filtersDTO.steps[Steps.customer]?.name ?? "",
      type: "label",
      showChangeButton: !(
        filtersDTO.isQuote !== null && filtersDTO.isQuote === "false"
      ),
    },
    {
      name: "Tipo de pago",
      label: filtersDTO.steps[Steps.customer].paymentModeSelected?.name ?? "",
      type: "label",
    },
    {
      name: "Tipo de cobertura",
      label: filtersDTO.steps[Steps.customer].coverType?.name ?? "",
      type: "label",
    },
    {
      name: "Campaña",
      label: filtersDTO.steps[Steps.customer].campaignSelected?.name ?? "",
      type: "label",
    },
    {
      name: "Prima",
      label: `${filtersDTO.currency} ${filtersDTO.prime}`,
      type: "label",
    },
  ];

  const filtersForVidaTermino = [
    {
      name: tl("financialInformation.dataFilter.Product"),
      label: filtersDTO.product,
      type: "label",
    },
    {
      name: tl("financialInformation.dataFilter.Birthdate"),
      label: newFormattedDate(filtersDTO.birthdate),
      type: "label",
    },
    {
      name: tl("financialInformation.dataFilter.Gender"),
      label: filtersDTO.gender,
      type: "label",
    },
    // {
    //   name: tl("financialInformation.dataFilter.PolicyDuration"),
    //   label:
    //     localStorageService.getStepStored().steps[Steps.initialInfo].initialInfo
    //       .policyDuration + " años",
    //   type: "label",
    // },
    // {
    //   name: tl("financialInformation.dataFilter.SumInsured"),
    //   label: `RD$ ${filtersDTO.prime}`,
    //   type: "label",
    // },
  ];

  if (isVidaTermino && isVidaTermino === true) {
    return filtersForVidaTermino;
  }
  return getCustomerAction().customerAuto.includes(filtersDTO.subdomain)
    ? filtersForApapAuto
    : filtersForOthers;
}

export const dataEditVehicleForm: DataFormVehicles = {
  carDetails: [
    {
      brand: {
        id: "",
        name: "",
      },
      model: {
        id: "",
        name: "",
      },
      version: {
        id: "",
        name: "",
      },
    },
  ],
  year: "",
  carColor: [
    {
      id: "",
      name: "",
    },
  ],
  chassisNumber: "",
  price: "",
  numberCylinders: [
    {
      id: "",
      name: "",
    },
  ],
  fuelType: [
    {
      id: "",
      name: "",
    },
  ],
  registrationNumber: "",
  carCondition: [
    {
      id: "",
      name: "",
    },
  ],
};

export const StatementColumns = (tl: TFunction) => {
  const columns: TableColumn[] = [
    {
      field: "accountName",
      headerName: tl(`confirmationData.columnsStatement.accountName`),
      width: 300,
    },
    {
      field: "amount",
      headerName: tl(`confirmationData.columnsStatement.amount`),
      width: 150,
    },
    {
      field: "paymentDate",
      headerName: tl(`confirmationData.columnsStatement.paymentDate`),
      width: 250,
    },
    {
      field: "dateExpire",
      headerName: tl(`confirmationData.columnsStatement.dateExpire`),
      width: 200,
    },
    {
      field: "datePaid",
      headerName: tl(`confirmationData.columnsStatement.datePaid`),
      width: 200,
    },
    {
      field: "state",
      headerName: tl(`confirmationData.columnsStatement.state`),
      width: 150,
    },
  ];
  return columns;
};

const getStatusColor = (state: string) => {
  switch (state) {
    case "Listo":
      return "green";
    case "Rechazado":
      return "red";
    case "Pendiente":
      return "yellow";
    case "Sin documento":
      return "blue";
    default:
      return "gray";
  }
};

export const AdditionalDocumentationColumns = (
  handleClickContextMenu: (id: any, params: any) => void,
) => {
  const columns: TableColumn[] = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (param: any) => {
        return (
          <div style={{ display: "flex" }}>
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.UploadFile,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Subir documento",
                },
                {
                  disabled: !param.row.documentClientUrl,
                  icon: {
                    color: "#006ac6",
                    icon: icons.Visibility,
                    size: sizesIcon.standard,
                  },
                  id: "2",
                  name: "Visualizar",
                },
              ]}
              onClickContextMenu={(item) => handleClickContextMenu(item, param)}
              sizeIcon={sizesIcon.standard}
            />
          </div>
        );
      },
    },
    {
      field: "docType",
      width: 450,
      headerName: "Tipo de documento",
      renderCell: (params: any) => {
        return <DivLongText>{params.value}</DivLongText>;
      },
    },
    {
      width: 200,
      field: "state",
      headerName: "Estado",
      renderCell: (params: any) => {
        return (
          <DivStatus>
            <IconAFY icon={icons.Circle} color={getStatusColor(params.value)} />{" "}
            {params.value}
          </DivStatus>
        );
      },
    },
    {
      field: "date",
      headerName: "Fecha",
      width: 200,
    },
    {
      field: "user",
      headerName: "Usuario",
      width: 175,
    },
  ];
  return columns;
};

export const rowsClientDocument: any[] = [
  {
    id: "",
    docType: "",
    state: "",
    date: "",
    user: "",
  },
];

import { URLs } from "../url";
import { UpdateQuoteResponse } from "../../model/updateQuote";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const updateQuoteAPI = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.quote + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as UpdateQuoteResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchQuoteAPI = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.quote + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as UpdateQuoteResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { updateQuoteAPI, patchQuoteAPI };
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postQuoteAttach } from "../../store/apapUploadAttachments/actions";

export const PostUploadAttachments = (
  trigger: boolean,
  id?: string,
  fileName?: string,
  pdfFile?: File,
  code?: string,
) => {
  const dispatch = useDispatch();

  const { responseUploadAttach, errorUploadAttach, loadingUploadAttach } =
    useSelector((state: any) => ({
      responseUploadAttach: state.UploadAttach.responseUploadAttach,
      errorUploadAttach: state.UploadAttach.errorUploadAttach,
      loadingUploadAttach: state.UploadAttach.loadingUploadAttach,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        postQuoteAttach({
          id: id,
          fileName: fileName,
          pdfFile: pdfFile,
          code: code,
        }),
      );
    }
  }, [trigger, id, fileName, pdfFile]);

  return {
    responseUploadAttach,
    errorUploadAttach,
    loadingUploadAttach,
  };
};

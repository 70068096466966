import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  AdminIdentificationTypeAFY,
  CardProps,
  DataFormClient,
  icons,
  MenuItem,
  sizesIcon,
  sizesSelect,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  buttonActionOptions,
  buttonActionOptionsList,
  optionsSelect,
  view,
} from "./data";
import { ModalDelete } from "./resources/modalDelete";

export const ViewIdentificationType = (props: {
  list: CardProps[];
  openModal: boolean;
  // openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  onSaveData: any;
  loadingPost: any;
  dataForm: DataFormClient;
  modalTitle: string;
  editMode: boolean;
  isNumericOptions: any;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitClone: () => void;
  onClose: () => void;
  // onCloseConfirm: () => void;
  onCloseCloneModal: () => void;
  handleOpenModal: () => void;
  // handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  onClickAdd: () => void;
  // onClickButtonOptionsDropdown: () => void;
  onClickContextMenu: () => void;
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;
  openDrawer: boolean;
  setOpenDrawer: (value: boolean | (() => boolean)) => void;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  onCloseDrawer: () => void;
  page: number;
  limit: number;
  onChangeRowsPerPage: (e: number) => void;
  onChangePage: (e: number) => void;
  countPagination: number;
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />
      <AdminIdentificationTypeAFY
        dataForm={props.dataForm}
        title="Tipos de identificación"
        showFilters={true}
        backgroundColor="#ffffff"
        titleSelectSponsor="Tipo de Identificacion"
        titleSelectInsurance="Codigo"
        titleClearButton="Limpiar filtros"
        clientLabel="Cliente"
        textFilterSearch="Buscar"
        textFilterSearchDrop="Buscar"
        textBackSearch="Volver"
        backgroundColorSearch="#F6F7FB"
        titleColor="#3A56A1"
        colorClientLabel="#3A56A1"
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        heightAddNew="120px"
        heightAddNewList="50px"
        heightCard="120px"
        heightCardList="50px"
        backgroundColorCardAdd=""
        iconBackgroundColor={""}
        textColor={""}
        isNumericOptions={props.isNumericOptions}
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        selectSize={sizesSelect.small}
        avatarVariant={variantsAvatar.rounded}
        selectVariant={variantsTextField.outlined}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        disabledSelect={true}
        insurersView={view}
        buttonActionOptions={buttonActionOptions}
        menuItem={buttonActionOptionsList}
        optionsSelect={optionsSelect}
        data={props.list}
        onClickAdd={props.onClickAdd}
        onClickContextMenu={props.onClickContextMenu}
        onCloseSearch={props.onCloseSearch}
        onChangeData={props.filterStatus}
        onChangeSearch={props.searchFilter}
        onClickButtonView={(option: MenuItem) => {
          props.clickView && props.clickView(option);
        }}
        editMode={props.editMode}
        titleDrawer={"Agregar Tipo de Identificación"}
        topLogoUpload={"145px"}
        topNameLogoUpload={""}
        rightLogoUpload={"238px"}
        rightNameLogoUpload={""}
        viewLinearForm={true}
        widthLabelLinearForm={"300px"}
        textDescription={
          "Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam"
        }
        onSaveData={props.onSaveData}
        openDrawer={props.openDrawer}
        onCloseDrawer={props.onCloseDrawer}
        isSponsor={true}
        countPagination={props.countPagination}
        rowsPerPage={props.limit}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        pagePagination={props.page}
        titlePagination={"Registros por página"}
      />
    </>
  );
};
import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { RegionRequest, RegionResponse } from "../../model/region";
import { Level } from "../../enum/region";
import { getHeader } from "../headers";

const regionGet = async (
  enable: boolean,
  page: number,
  limit: number,
  name?: string,
  zipcode?: string,
  code?: string,
  level?: Level,
  parentRegionId?: string,
  clientId?: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.region +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  if (name) newURL += `&name=${name}`;
  if (level) newURL += `&type=${level.toString()}`;
  if (parentRegionId) newURL += `&parentRegionId=${parentRegionId}`;
  //if (clientId) newURL += `&clientId=${clientId}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as RegionResponse;
  } catch (error: any) {
    throw error;
  }
};

const regionGetOne = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.region + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const regionPost = async (body: RegionRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.region;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as RegionResponse;
  } catch (error: any) {
    throw error;
  }
};

const regionPatch = async (body: RegionRequest, id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.region + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as RegionResponse;
  } catch (error: any) {
    throw error;
  }
};

export { regionGet, regionGetOne, regionPost, regionPatch };

import {
  CardProps,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

export const dataCard: CardProps[] = [
  {
    id: "1",
    subTitle: "Por Rango de fechas",
    title: "Ventas",
    code: "vents",
  },
  {
    id: "2",
    subTitle: "Por Rango de fechas",
    title: "Pólizas canceladas",
    code: "polCancel",
  },
  {
    id: "3",
    subTitle: "Por Rango de fechas",
    title: "Ventas y Cancelaciones",
    code: "vents&cancel",
  },
  {
    id: "4",
    subTitle: "Por Rango de fechas",
    title: "Clientes totales",
    code: "totalClient",
  },
];

export const optionList: MenuItem[] = [
  {
    id: "1",
    name: "Previsualizar",
  },
  {
    id: "2",
    name: "Descargar",
  },
];

export const downData: CardProps[] = [
  {
    id: "1",
    numericalTitle: "0",
    title: "Ventas netas",
  },
  {
    id: "2",
    numericalTitle: "0",
    title: "Cotizaciones",
  },
  {
    id: "3",
    numericalTitle: "0",
    title: "Pólizas canceladas",
  },
  {
    id: "4",
    numericalTitle: "0",
    title: "Clientes",
  },
];

export const seriesFn = (tl: TFunction) => {
  const series: any[] = [
    {
      color: "#3A56A1",
      data: [],
      name: tl(`salesReport.barChart.series.name1`), //EMITIDAS
    },
    {
      color: "#A8B5D6",
      data: [],
      name: tl(`salesReport.barChart.series.name2`), // COTIZADAS
    },
  ];
  return series;
};

export const categories: any = [];

export const dataChip: MenuItem[] = [
  {
    active: true,
    id: "1",
    name: "Mes actual",
    code: "currentMonth",
  },
  {
    active: false,
    id: "2",
    name: "Mes anterior",
    code: "prevMonth",
  },
  {
    active: false,
    id: "3",
    name: "Trimestre actual",
    code: "currentQuarter",
  },
  {
    active: false,
    id: "4",
    name: "Trimestre anterior",
    code: "prevQuarter",
  },
  {
    active: false,
    id: "5",
    name: "Semestre actual",
    code: "currentSemester",
  },
];

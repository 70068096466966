import {
  CURRENCIES,
  CURRENCIES_CLEAR_DATA,
  CURRENCIES_ERROR,
  CURRENCIES_SUCCESS,
} from "./actionTypes";

export const currencies = (currencies, history) => {
  return {
    type: CURRENCIES,
    payload: { currencies, history },
  };
};

export const currenciesSuccess = (success) => {
  return {
    type: CURRENCIES_SUCCESS,
    payload: success,
  };
};

export const currenciesError = (error) => {
  return {
    type: CURRENCIES_ERROR,
    payload: error,
  };
};

export const currenciesClearData = () => {
  return {
    type: CURRENCIES_CLEAR_DATA,
    payload: {},
  };
};

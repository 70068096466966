export const parsePrice = (priceString: string): number => {
  if (priceString) {
    const cleanedString = priceString.replace(/[^0-9.,-]/g, "");

    const normalizedString = cleanedString.replace(/,/g, "");

    return parseFloat(normalizedString);
  }
  return 0;
};

export const formatNumberCustom = (num: number): string => {
  const parts = num.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export const formatToTwoDecimals = (value: string): string => {
  const numberValue = parseFloat(value);
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numberValue);
};

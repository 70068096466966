import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { identificationTypePatch } from "../../store/identificationType/actions";
import { IdentificationTypeResponse, IdentificationTypeRequest } from "../../model/identificationType";

export const useIdentificationTypePatch = (
  trigger: boolean,
  bodyRequest: IdentificationTypeRequest,
) => {
  const dispatch = useDispatch();

  const { responsePatchIdentificationType, errorPatchIdentificationType, loadingPatchIdentificationType } = useSelector(
    (state: any) => ({
      responsePatchIdentificationType: state.IdentificationType.responsePatchIdentificationType?.data,
      errorPatchIdentificationType: state.IdentificationType.errorPatchIdentificationType,
      loadingPatchIdentificationType: state.IdentificationType.loadingPatchIdentificationType,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        identificationTypePatch(bodyRequest.data?.id, bodyRequest),
      );
    }
  }, [bodyRequest]);

  return {
    responsePatchIdentificationType,
    errorPatchIdentificationType,
    loadingPatchIdentificationType,
  };
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountTypePost } from "../../store/accountType/actions";

export const useAccountTypePost = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const {
    responseAccountTypePost,
    errorAccountTypePost,
    loadingAccountTypePost,
  } = useSelector((state: any) => ({
    responseAccountTypePost: state.AccountType.responseAccountTypePost?.data,
    errorAccountTypePost: state.AccountType.errorAccountTypePost,
    loadingAccountTypePost: state.AccountType.loadingAccountTypePost,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        accountTypePost({
          clientId: body.data.clientId,
          description: body.data.description,
        }),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseAccountTypePost,
    errorAccountTypePost,
    loadingAccountTypePost,
  };
};

import {
  CAMPAIGN,
  CAMPAIGN_CLEAR_DATA,
  CAMPAIGN_ERROR,
  CAMPAIGN_PATCH,
  CAMPAIGN_PATCH_CLEAR_DATA,
  CAMPAIGN_PATCH_ERROR,
  CAMPAIGN_PATCH_SUCCESS,
  CAMPAIGN_POST,
  CAMPAIGN_POST_CLEAR_DATA,
  CAMPAIGN_POST_ERROR,
  CAMPAIGN_POST_SUCCESS,
  CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ONE,
  GET_CAMPAIGN_ONE_CLEAR_DATA,
  GET_CAMPAIGN_ONE_ERROR,
  GET_CAMPAIGN_ONE_SUCCESS,
} from "./actionTypes";

export const campaign = (campaign, history) => {
  return {
    type: CAMPAIGN,
    payload: { campaign, history },
  };
};

export const campaignSuccess = (success) => {
  return {
    type: CAMPAIGN_SUCCESS,
    payload: success,
  };
};

export const campaignError = (error) => {
  return {
    type: CAMPAIGN_ERROR,
    payload: error,
  };
};

export const campaignClearData = () => {
  return {
    type: CAMPAIGN_CLEAR_DATA,
    payload: {},
  };
};

//

export const campaignPost = (campaign, history) => {
  return {
    type: CAMPAIGN_POST,
    payload: { campaign, history },
  };
};

export const campaignPostSuccess = (success) => {
  return {
    type: CAMPAIGN_POST_SUCCESS,
    payload: { ...success },
  };
};

export const campaignPostError = (error) => {
  return {
    type: CAMPAIGN_POST_ERROR,
    payload: error,
  };
};

export const campaignPostClearData = () => {
  return {
    type: CAMPAIGN_POST_CLEAR_DATA,
    payload: {},
  };
};

//

export const campaignPatch = (campaignBody, id) => {
  return {
    type: CAMPAIGN_PATCH,
    payload: { campaignBody, id },
  };
};

export const campaignPatchSuccess = (success) => {
  return {
    type: CAMPAIGN_PATCH_SUCCESS,
    payload: success,
  };
};

export const campaignPatchError = (error) => {
  return {
    type: CAMPAIGN_PATCH_ERROR,
    payload: error,
  };
};

export const campaignPatchClearData = () => {
  return {
    type: CAMPAIGN_PATCH_CLEAR_DATA,
    payload: {},
  };
};

export const campaignOne = (campaignId, history) => {
  return {
    type: GET_CAMPAIGN_ONE,
    payload: { campaignId, history },
  };
};

export const campaignOneSuccess = (success) => {
  return {
    type: GET_CAMPAIGN_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const campaignOneError = (error) => {
  return {
    type: GET_CAMPAIGN_ONE_ERROR,
    payload: error,
  };
};

export const campaignOneClearData = () => {
  return {
    type: GET_CAMPAIGN_ONE_CLEAR_DATA,
    payload: {},
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { permissions } from "../../store/permissions/actions";

export const usePermissionGetAll = (
  trigger: boolean,
  clientId?: string,
  pageCatalog?: number,
  limitCatalog?: number,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { responsePermission, errorPermission, loadingPermission } =
    useSelector((state: any) => ({
      responsePermission: state.Permission.responsePermission?.data,
      errorPermission: state.Permission.errorPermission,
      loadingPermission: state.Permission.loadingPermission,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        permissions({
          clientId: clientId,
          enable: enable,
          page: pageCatalog,
          limit: limitCatalog,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responsePermission,
    errorPermission,
    loadingPermission,
    setEnable,
  };
};
import { icons } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { GridColDef } from "@mui/x-data-grid";
import newExcel from "../../../assets/images/newExcel.svg";

export const Columns: GridColDef[] = [
  {
    field: "certificate",
    headerName: "No. certificado",
    width: 190,
  },
  {
    field: "product",
    headerName: "Producto",
    width: 175,
  },
  {
    field: "creationDate",
    headerName: "Fecha de emisión",
    width: 180,
  },
  {
    field: "names",
    headerName: "Nombres",
    width: 250,
  },
  {
    field: "lastNames",
    headerName: "Apellidos",
    width: 250,
  },
  {
    field: "identificationNumber",
    headerName: "Cédula del cliente",
    width: 220,
  },
  {
    field: "paymentType",
    headerName: "Tipo de cuenta",
    width: 300,
  },
  {
    field: "accountNumber",
    headerName: "Numero de cuenta",
    width: 320,
  },
  {
    field: "prime",
    headerName: "Prima",
    width: 160,
  },
  {
    field: "status",
    headerName: "Estado",
    width: 130,
  },
  {
    field: "userName",
    headerName: "Nombre del empleado",
    width: 250,
  },
  {
    field: "user",
    headerName: "Usuario",
    width: 160,
  },
  {
    field: "branch",
    headerName: "Sucursal",
    width: 160,
  },
  {
    field: "cancellationDate",
    headerName: "Fecha de cancelación",
    width: 250,
  },
  {
    field: "reasonCancellation",
    headerName: "Motivo de cancelación",
    width: 270,
  },
];

export interface buttonActionOptions {
  id: string;
  icons: icons;
  name: string;
  img?: string;
}

export const buttonActionOptions: buttonActionOptions[] = [
  {
    icons: icons.DownloadForOffline,
    id: "1",
    img: newExcel,
    name: "Descargar Excel",
  },
  // {
  //   icons: icons.DownloadForOffline,
  //   id: "2",
  //   img: pdf,
  //   name: "Descargar PDF",
  // },
];

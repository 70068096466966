import {
  INSURANCE_TYPE,
  INSURANCE_TYPE_CLEAR_DATA,
  INSURANCE_TYPE_ERROR,
  INSURANCE_TYPE_ONE,
  INSURANCE_TYPE_ONE_SUCCESS,
  INSURANCE_TYPE_SUCCESS,
  POST_INSURANCE_TYPE,
  POST_INSURANCE_TYPE_CLEAR_DATA,
  POST_INSURANCE_TYPE_ERROR,
  POST_INSURANCE_TYPE_SUCCESS,
  PATCH_INSURANCE_TYPE,
  PATCH_INSURANCE_TYPE_CLEAR_DATA,
  PATCH_INSURANCE_TYPE_ERROR,
  PATCH_INSURANCE_TYPE_SUCCESS,
} from "./actionTypes";

export const insuranceType = (insuranceType, history) => {
  return {
    type: INSURANCE_TYPE,
    payload: { insuranceType, history },
  };
};

export const insuranceTypeOne = (insuranceType, history) => {
  return {
    type: INSURANCE_TYPE_ONE,
    payload: { insuranceType, history },
  };
};

export const insuranceTypeSuccess = (success) => {
  return {
    type: INSURANCE_TYPE_SUCCESS,
    payload: success,
  };
};

export const insuranceTypeError = (error) => {
  return {
    type: INSURANCE_TYPE_ERROR,
    payload: error,
  };
};

export const insuranceTypeOneSuccess = (success) => {
  return {
    type: INSURANCE_TYPE_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const insuranceTypeClearData = () => {
  return {
    type: INSURANCE_TYPE_CLEAR_DATA,
    payload: {},
  };
};


export const postInsuranceType = (body) => {
  return {
    type: POST_INSURANCE_TYPE,
    payload: { body },
  };
};

export const postInsuranceTypeSuccess = (success) => {
  return {
    type: POST_INSURANCE_TYPE_SUCCESS,
    payload: success,
  };
};

export const postInsuranceTypeError = (error) => {
  return {
    type: POST_INSURANCE_TYPE_ERROR,
    payload: error,
  };
};

export const postInsuranceTypeClearData = () => {
  return {
    type: POST_INSURANCE_TYPE_CLEAR_DATA,
    payload: {},
  };
};

export const patchInsuranceType = (body, id) => {
  return {
    type: PATCH_INSURANCE_TYPE,
    payload: { body, id},
  };
};

export const patchInsuranceTypeSuccess = (success) => {
  return {
    type: PATCH_INSURANCE_TYPE_SUCCESS,
    payload: success,
  };
};

export const patchInsuranceTypeError = (error) => {
  return {
    type: PATCH_INSURANCE_TYPE_ERROR,
    payload: error,
  };
};

export const patchInsuranceTypeClearData = () => {
  return {
    type: PATCH_INSURANCE_TYPE_CLEAR_DATA,
    payload: {},
  };
};

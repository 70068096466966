import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchInsuranceType } from "../../store/insuranceType/actions";

export const useInsuranceTypePatch = (trigger: boolean, body: any) => {
  const dispatch = useDispatch();

  const {
    responsePatchInsuranceType,
    errorPatchInsuranceType,
    loadingPatchInsuranceType,
  } = useSelector((state: any) => ({
    responsePatchInsuranceType: state.InsuranceType.responsePatchInsuranceType,
    errorPatchInsuranceType: state.InsuranceType.errorPatchInsuranceType,
    loadingPatchInsuranceType: state.InsuranceType.loadingPatchInsuranceType,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        patchInsuranceType(
          {
            description: body.description,
          },
          body.id,
        ),
      );
    }
  }, [trigger]);

  return {
    responsePatchInsuranceType,
    errorPatchInsuranceType,
    loadingPatchInsuranceType,
  };
};

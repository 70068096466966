export const CAMPAIGN = "CAMPAIGN";
export const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS";
export const CAMPAIGN_ERROR = "CAMPAIGN_ERROR";
export const CAMPAIGN_CLEAR_DATA = "CAMPAIGN_CLEAR_DATA";

export const CAMPAIGN_POST = "CAMPAIGN_POST";
export const CAMPAIGN_POST_SUCCESS = "CAMPAIGN_POST_SUCCESS";
export const CAMPAIGN_POST_ERROR = "CAMPAIGN_POST_ERROR";
export const CAMPAIGN_POST_CLEAR_DATA = "CAMPAIGN_POST_CLEAR_DATA";

export const CAMPAIGN_PATCH = "CAMPAIGN_PATCH";
export const CAMPAIGN_PATCH_SUCCESS = "CAMPAIGN_PATCH_SUCCESS";
export const CAMPAIGN_PATCH_ERROR = "CAMPAIGN_PATCH_ERROR";
export const CAMPAIGN_PATCH_CLEAR_DATA = "CAMPAIGN_PATCH_CLEAR_DATA";

export const GET_CAMPAIGN_ONE = "GET_CAMPAIGN_ONE";
export const GET_CAMPAIGN_ONE_SUCCESS = "GET_CAMPAIGN_ONE_SUCCESS";
export const GET_CAMPAIGN_ONE_ERROR = "GET_CAMPAIGN_ONE_ERROR";
export const GET_CAMPAIGN_ONE_CLEAR_DATA = "GET_CAMPAIGN_ONE_CLEAR_DATA";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../store/plans/actions";

export const useGetPlansAllV2 = (trigger: boolean, planIds?: string) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const dispatch = useDispatch();

  const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans?.data,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        plans({
          enable: enable,
          page: page,
          limit: limit,
          planId: planIds,
        }),
      );
    }
  }, [enable, page, limit, trigger, planIds]);

  return {
    responsePlans,
    errorPlans,
    loadingPlans,
    setEnable,
    setPage,
    setLimit,
  };
};
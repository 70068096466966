import { call, put, takeEvery } from "redux-saga/effects";
import { currenciesError, currenciesSuccess } from "./actions";
import { CURRENCIES } from "./actionTypes";
import { getCurrenciesAPI } from "../../resources/helper/currencies";

function* getCurrencies({ payload: { currencies } }) {
  try {
    const response = yield call(
      getCurrenciesAPI,
      currencies.enable,
      currencies.orderBy,
      currencies.orderDir,
      currencies.page,
      currencies.limit,
    );
    yield put(currenciesSuccess(response));
  } catch (error) {
    yield put(currenciesError(error));
  }
}

function* currenciesSaga() {
  yield takeEvery(CURRENCIES, getCurrencies);
}

export default currenciesSaga;

export const INSURANCES = "INSURANCES";
export const INSURANCES_SUCCESS = "INSURANCES_SUCCESS";
export const INSURANCES_ONE = "INSURANCES_ONE";
export const INSURANCES_ONE_SUCCESS = "INSURANCES_ONE_SUCCESS";
export const INSURANCES_ERROR = "INSURANCES_ERROR";
export const INSURANCES_CLEAR_DATA = "INSURANCES_CLEAR_DATA";
export const POST_INSURANCES = "POST_INSURANCES";
export const POST_INSURANCES_SUCCESS = "POST_INSURANCES_SUCCESS";
export const POST_INSURANCES_ERROR = "POST_INSURANCES_ERROR";
export const POST_INSURANCES_CLEAR_DATA = "POST_INSURANCES_CLEAR_DATA";
export const PATCH_INSURANCES = "PATCH_INSURANCES";
export const PATCH_INSURANCES_SUCCESS = "PATCH_INSURANCES_SUCCESS";
export const PATCH_INSURANCES_ERROR = "PATCH_INSURANCES_ERROR";
export const PATCH_INSURANCES_CLEAR_DATA = "PATCH_INSURANCES_CLEAR_DATA";
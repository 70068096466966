import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { regionPost } from "../../store/region/actions";
import {  RegionRequest } from "../../model/region";

export const useRegionPost = (
  trigger: boolean,
  bodyRequest: RegionRequest,
) => {
  const dispatch = useDispatch();

  const { responsePostRegion, errorPostRegion, loadingPostRegion } = useSelector(
    (state: any) => ({
      responsePostRegion: state.Region.responsePostRegion?.data,
      errorPostRegion: state.Region.errorPostRegion,
      loadingPostRegion: state.Region.loadingPostRegion,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        regionPost(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePostRegion,
    errorPostRegion,
    loadingPostRegion
  };
};

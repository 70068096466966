import React from "react";

export const dataColum = [
  {
    editable: false,
    field: "quote",
    flex: 1,
    headerName: "Cotización",
    width: 150,

    renderCell: (params: any) => (
      <div style={{ color: "rgb(58, 86, 161)" }}>{params.value}</div>
    ),
  },
  {
    editable: false,
    field: "identification",
    flex: 1,
    headerName: "Identificación",
    width: 200,
  },
  {
    editable: false,
    field: "name",
    flex: 1,
    headerName: "Nombre",
    width: 150,
  },
  {
    editable: false,
    field: "firstSurname",
    flex: 1,
    headerName: "Primer apellido",
    width: 150,
  },
  {
    editable: false,
    field: "secondSurname",
    flex: 1,
    headerName: "Segundo apellido",
    width: 150,
  },
  {
    field: "marriedName",
    flex: 1,
    headerName: "Apellido Casada",
    width: 150,
  },
  {
    field: "status",
    flex: 1,
    headerName: "Estatus póliza",
    width: 150,
  },
  {
    field: "branch",
    flex: 1,
    headerName: "Sucursal",
    width: 150,
  },
  {
    field: "user",
    flex: 1,
    headerName: "Usuario",
    width: 150,
  },
];

import {
  breadcrumb,
  ContextMenuAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "seguro",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Registro",
    key: "registro",
    ariaCurrent: "",
  },
];

export const Columns = (handleClickContextMenu: any) => {
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <ContextMenuAFY
            colorButton="#fff"
            iconButton={icons.MoreVert}
            iconColor="#3A56A1"
            menuItem={[
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.PermIdentity,
                  size: sizesIcon.standard,
                },
                id: "1",
                name: "Beneficiarios",
              },
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.PermIdentity,
                  size: sizesIcon.standard,
                },
                id: "2",
                name: "Asegurados",
              },
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.Search,
                  size: sizesIcon.standard,
                },
                id: "2",
                name: "Ver detalles",
              },
            ]}
            onClickContextMenu={(item: any) =>
              handleClickContextMenu(item, params)
            }
            sizeIcon={sizesIcon.standard}
          />
        </div>
      ),
    },
    {
      editable: false,
      field: "estado",
      flex: 1,
      headerName: "Estado",
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <span className={params.value}></span>
          {params.value}
        </div>
      ),
      width: 70,
    },
    {
      editable: false,
      field: "cotizacion",
      flex: 1,
      headerName: "Cotización",
      width: 150,
    },
    {
      editable: false,
      field: "poliza",
      flex: 1,
      headerName: "Poliza",
      width: 150,
    },
    {
      editable: false,
      field: "producto",
      flex: 1,
      headerName: "Producto",
      width: 150,
    },
    {
      editable: false,
      field: "plan",
      flex: 1,
      headerName: "Plan",
      width: 150,
    },
    {
      field: "prima",
      flex: 1,
      headerName: "Prima",
      width: 160,
    },
  ];
};

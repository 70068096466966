import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { campaignOne } from "../../store/campaign/actions";

export const GetCampaignOne = (trigger: boolean, id?: string) => {
  const dispatch = useDispatch();

  const { responseGetOneCampaign, errorGetOneCampaign, loadingGetOneCampaign } =
    useSelector((state: any) => ({
      responseGetOneCampaign: state.Campaign.responseGetOneCampaign?.data,
      errorGetOneCampaign: state.Campaign.errorGetOneCampaign,
      loadingGetOneCampaign: state.Campaign.loadingGetOneCampaign,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        campaignOne({
          id: id,
        }),
      );
    }
  }, [trigger, id]);

  return {
    responseGetOneCampaign,
    errorGetOneCampaign,
    loadingGetOneCampaign,
  };
};

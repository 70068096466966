import {
  PAYMENT_MODE,
  PAYMENT_MODE_CLEAR_DATA,
  PAYMENT_MODE_ERROR,
  PAYMENT_MODE_SUCCESS,
  POST_PAYMENT_MODE,
  POST_PAYMENT_MODE_CLEAR_DATA,
  POST_PAYMENT_MODE_ERROR,
  POST_PAYMENT_MODE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPaymentMode: null,
  responsePaymentMode: null,
  loadingPaymentMode: false,

  errorPostPaymentMode: null,
  responsePostPaymentMode: null,
  loadingPostPaymentMode: false,
};

const PaymentModeApi = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_MODE:
      state = {
        ...state,
        loadingPaymentMode: true,
        errorPaymentMode: null,
        responsePaymentMode: true,
      };
      break;
    case PAYMENT_MODE_SUCCESS:
      state = {
        ...state,
        errorPaymentMode: null,
        loadingPaymentMode: false,
        responsePaymentMode: action.payload,
      };
      break;
    case PAYMENT_MODE_ERROR:
      state = {
        ...state,
        errorPaymentMode: action.payload,
        loadingPaymentMode: false,
        responsePaymentMode: null,
      };
      break;
    case PAYMENT_MODE_CLEAR_DATA:
      state = {
        ...state,
        errorPaymentMode: null,
        loadingPaymentMode: null,
        responsePaymentMode: false,
      };
      break;
    //
    case POST_PAYMENT_MODE:
      state = {
        ...state,
        loadingPostPaymentMode: true,
        errorPostPaymentMode: null,
        responsePostPaymentMode: true,
      };
      break;
    case POST_PAYMENT_MODE_SUCCESS:
      state = {
        ...state,
        errorPostPaymentMode: null,
        loadingPostPaymentMode: false,
        responsePostPaymentMode: action.payload,
      };
      break;
    case POST_PAYMENT_MODE_ERROR:
      state = {
        ...state,
        errorPostPaymentMode: action.payload,
        loadingPostPaymentMode: false,
        responsePostPaymentMode: null,
      };
      break;
    case POST_PAYMENT_MODE_CLEAR_DATA:
      state = {
        ...state,
        errorPostPaymentMode: null,
        loadingPostPaymentMode: null,
        responsePostPaymentMode: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PaymentModeApi;

export const GET_CATALOG_BY_ID = "GET_CATALOG_BY_ID";
export const GET_CATALOG_BY_ID_SUCCESS = "GET_CATALOG_BY_ID_SUCCESS";
export const GET_CATALOG_BY_ID_ERROR = "GET_CATALOG_BY_ID_ERROR";
export const GET_CATALOG_BY_ID_CLEAR_DATA = "GET_CATALOG_BY_ID_CLEAR_DATA";

export const GET_CATALOG_STATUS_QUOTE = "GET_CATALOG_STATUS_QUOTE";
export const GET_CATALOG_STATUS_QUOTE_SUCCESS =
  "GET_CATALOG_STATUS_QUOTE_SUCCESS";
export const GET_CATALOG_STATUS_QUOTE_ERROR = "GET_CATALOG_STATUS_QUOTE_ERROR";
export const GET_CATALOG_STATUS_QUOTE_CLEAR_DATA =
  "GET_CATALOG_STATUS_QUOTE_CLEAR_DATA";

export const GET_CATALOGS_TYPE = "GET_CATALOGS_TYPE";
export const GET_CATALOGS_TYPE_SUCCESS = "GET_CATALOGS_TYPE_SUCCESS";
export const GET_CATALOGS_TYPE_ERROR = "GET_CATALOGS_TYPE_ERROR";
export const GET_CATALOGS_TYPE_CLEAR_DATA = "GET_CATALOGS_TYPE_CLEAR_DATA";

export const CATALOG_PAYMENT_MODE = "CATALOG_PAYMENT_MODE";
export const CATALOG_PAYMENT_MODE_SUCCESS = "CATALOG_PAYMENT_MODE_SUCCESS";
export const CATALOG_PAYMENT_MODE_ERROR = "CATALOG_PAYMENT_MODE_ERROR";
export const CATALOG_PAYMENT_MODE_CLEAR_DATA =
  "CATALOG_PAYMENT_MODE_CLEAR_DATA";

export const GET_CATALOG_ACCOUNT_TYPE = "GET_CATALOG_ACCOUNT_TYPE";
export const GET_CATALOG_ACCOUNT_TYPE_SUCCESS =
  "GET_CATALOG_ACCOUNT_TYPE_SUCCESS";
export const GET_CATALOG_ACCOUNT_TYPE_ERROR = "GET_CATALOG_ACCOUNT_TYPE_ERROR";
export const GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA =
  "GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA";

export const GET_CATALOG_BENEFICIARY = "GET_CATALOG_BENEFICIARY";
export const GET_CATALOG_BENEFICIARY_SUCCESS =
  "GET_CATALOG_BENEFICIARY_SUCCESS";
export const GET_CATALOG_BENEFICIARY_ERROR = "GET_CATALOG_BENEFICIARY_ERROR";
export const GET_CATALOG_BENEFICIARY_CLEAR_DATA =
  "GET_CATALOG_BENEFICIARY_CLEAR_DATA";

export const GET_CATALOG_INSURANCE = "GET_CATALOG_INSURANCE";
export const GET_CATALOG_INSURANCE_SUCCESS = "GET_CATALOG_INSURANCE_SUCCESS";
export const GET_CATALOG_INSURANCE_ERROR = "GET_CATALOG_INSURANCE_ERROR";
export const GET_CATALOG_INSURANCE_CLEAR_DATA =
  "GET_CATALOG_INSURANCE_CLEAR_DATA";

// responseGetCatalogBeneficiary: null,
//     errorGetCatalogBeneficiary: null,
//     loadingGetCatalogBeneficiary: false,
//
//     responseGetCatalogInsurance: null,
//     errorGetCatalogInsurance: null,
//     loadingGetCatalogInsurance: false,

import { call, put, takeEvery } from "redux-saga/effects";
import {
  rolDisableError,
  rolDisableSuccess,
  rolError,
  rolPatchError,
  rolPatchSuccess,
  rolPostError,
  rolPostSuccess,
  rolSuccess,
} from "./actions";
import {
  disableRol,
  getRol,
  patchRol,
  postRol,
} from "../../resources/helper/rol";
import { ROL, ROL_DISABLE, ROL_PATCH, ROL_POST } from "./actionTypes";

function* rol({ payload: { rol } }) {
  try {
    const response = yield call(
      getRol,
      rol.clientId,
      rol.enable,
      rol.page,
      rol.limit,
      rol.name,
      rol.description,
    );
    yield put(rolSuccess(response));
  } catch (error) {
    yield put(rolError(error));
  }
}
function* rolPost({ payload: { rolBody } }) {
  try {
    const response = yield call(postRol, rolBody);
    yield put(rolPostSuccess(response));
  } catch (error) {
    yield put(rolPostError(error));
  }
}

function* rolPatch({ payload: { rolPatchBody, id } }) {
  try {
    const response = yield call(patchRol, rolPatchBody, id);
    yield put(rolPatchSuccess(response));
  } catch (error) {
    yield put(rolPatchError(error));
  }
}

function* rolDisable({ payload: { id } }) {
  try {
    const response = yield call(disableRol, id);
    yield put(rolDisableSuccess(response));
  } catch (error) {
    yield put(rolDisableError(error));
  }
}

function* rolSaga() {
  yield takeEvery(ROL, rol);
  yield takeEvery(ROL_POST, rolPost);
  yield takeEvery(ROL_PATCH, rolPatch);
  yield takeEvery(ROL_DISABLE, rolDisable);
}

export default rolSaga;
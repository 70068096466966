import { call, put, takeEvery } from "redux-saga/effects";
import { 
  premiumEvalOptionSuccess, premiumEvalOptionError,  
  premiumEvalOptionGetOneSuccess, premiumEvalOptionGetOneError,
  premiumEvalOptionPostSuccess, premiumEvalOptionPostError,
  premiumEvalOptionPatchSuccess, premiumEvalOptionPatchError
} from "./actions";
import { premiumEvalOptionGet, premiumEvalOptionGetOne, premiumEvalOptionPost, premiumEvalOptionPatch } from "../../resources/helper/premiumEvalOption";
import { PREMIUM_EVAL_OPTION, PREMIUM_EVAL_OPTION_POST, PREMIUM_EVAL_OPTION_PATCH, PREMIUM_EVAL_OPTION_GET_ONE  } from "./actionTypes";

function* premiumEvalOptionApi({ payload: { premiumEvalOption } }) {
  try {
    const response = yield call(
      premiumEvalOptionGet,
      premiumEvalOption.enable,
      premiumEvalOption.page,
      premiumEvalOption.limit,
    );
    yield put(premiumEvalOptionSuccess(response));
  } catch (error) {
    yield put(premiumEvalOptionError(error));
  }
}

function* premiumEvalOptionGetOneApi({ payload: { premiumEvalOption } }) {
  try {
    const response = yield call(premiumEvalOptionGetOne, premiumEvalOption.id);
    yield put(premiumEvalOptionGetOneSuccess(response));
  } catch (error) {
    yield put(premiumEvalOptionGetOneError(error));
  }
}

function* premiumEvalOptionPostApi({ payload: { premiumEvalOption } }) {
  try {
    const response = yield call(premiumEvalOptionPost, premiumEvalOption);
    yield put(premiumEvalOptionPostSuccess(response));
  } catch (error) {
    yield put(premiumEvalOptionPostError(error));
  }
}

function* premiumEvalOptionPatchApi({ payload: { premiumEvalOption, id } }) {
  try {
    const response = yield call(premiumEvalOptionPatch, premiumEvalOption, id);
    yield put(premiumEvalOptionPatchSuccess(response));
  } catch (error) {
    yield put(premiumEvalOptionPatchError(error));
  }
}

function* premiumEvalOptionSaga() {
  yield takeEvery(PREMIUM_EVAL_OPTION, premiumEvalOptionApi);
  yield takeEvery(PREMIUM_EVAL_OPTION_GET_ONE, premiumEvalOptionGetOneApi);
  yield takeEvery(PREMIUM_EVAL_OPTION_POST, premiumEvalOptionPostApi);
  yield takeEvery(PREMIUM_EVAL_OPTION_PATCH, premiumEvalOptionPatchApi);
}

export default premiumEvalOptionSaga;

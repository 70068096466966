import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coverTypePlanPost } from "../../store/coverTypePlan/actions";
import {  CoverTypePlanRequest } from "../../model/coverTypePlan";

export const useCoverTypePlanPost = (
  trigger: boolean,
  bodyRequest: CoverTypePlanRequest,
) => {
  const dispatch = useDispatch();

  const { responsePostCoverTypePlan, errorPostCoverTypePlan, loadingPostCoverTypePlan } = useSelector(
    (state: any) => ({
      responsePostCoverTypePlan: state.CoverTypePlan.responsePostCoverTypePlan,
      errorPostCoverTypePlan: state.CoverTypePlan.errorPostCoverTypePlan,
      loadingPostCoverTypePlan: state.CoverTypePlan.loadingPostCoverTypePlan,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        coverTypePlanPost(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePostCoverTypePlan,
    errorPostCoverTypePlan,
    loadingPostCoverTypePlan,
  };
};

import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import {
  CoverTypePlanRequest,
  CoverTypePlanResponse,
} from "../../model/coverTypePlan";
import { getHeader } from "../headers";

const coverTypePlanGet = async (
  enable: boolean,
  page: number,
  limit: number,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.coverTypePlan +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as CoverTypePlanResponse;
  } catch (error: any) {
    throw error;
  }
};

const coverTypePlanGetOne = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverTypePlan + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const coverTypePlanPost = async (body: CoverTypePlanRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverTypePlan;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as CoverTypePlanResponse;
  } catch (error: any) {
    throw error;
  }
};

const coverTypePlanPatch = async (body: CoverTypePlanRequest, id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverTypePlan + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as CoverTypePlanResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  coverTypePlanGet,
  coverTypePlanGetOne,
  coverTypePlanPost,
  coverTypePlanPatch,
};

export const INSURANCE_TYPE = "INSURANCE_TYPE";
export const INSURANCE_TYPE_SUCCESS = "INSURANCE_TYPE_SUCCESS";
export const INSURANCE_TYPE_ONE = "INSURANCE_TYPE_ONE";
export const INSURANCE_TYPE_ONE_SUCCESS = "INSURANCE_TYPE_ONE_SUCCESS";
export const INSURANCE_TYPE_ERROR = "INSURANCE_TYPE_ERROR";
export const INSURANCE_TYPE_CLEAR_DATA = "INSURANCE_TYPE_CLEAR_DATA";
export const POST_INSURANCE_TYPE = "POST_INSURANCE_TYPE";
export const POST_INSURANCE_TYPE_SUCCESS = "POST_INSURANCE_TYPE_SUCCESS";
export const POST_INSURANCE_TYPE_ERROR = "POST_INSURANCE_TYPE_ERROR";
export const POST_INSURANCE_TYPE_CLEAR_DATA = "POST_INSURANCE_TYPE_CLEAR_DATA";
export const PATCH_INSURANCE_TYPE = "PATCH_INSURANCE_TYPE";
export const PATCH_INSURANCE_TYPE_SUCCESS = "PATCH_INSURANCE_TYPE_SUCCESS";
export const PATCH_INSURANCE_TYPE_ERROR = "PATCH_INSURANCE_TYPE_ERROR";
export const PATCH_INSURANCE_TYPE_CLEAR_DATA = "PATCH_INSURANCE_TYPE_CLEAR_DATA";
export const GENERAL_CATALOG = "GENERAL_CATALOG";
export const GENERAL_CATALOG_SUCCESS = "GENERAL_CATALOG_SUCCESS";
export const GENERAL_CATALOG_ERROR = "GENERAL_CATALOG_ERROR";
export const GENERAL_CATALOG_CLEAR_DATA = "GENERAL_CATALOG_CLEAR_DATA";
export const GENERAL_CATALOG_POST = "GENERAL_CATALOG_POST";
export const GENERAL_CATALOG_POST_SUCCESS = "GENERAL_CATALOG_POST_SUCCESS";
export const GENERAL_CATALOG_POST_ERROR = "GENERAL_CATALOG_POST_ERROR";
export const GENERAL_CATALOG_POST_CLEAR_DATA =
  "GENERAL_CATALOG_POST_CLEAR_DATA";
export const GENERAL_CATALOG_PATCH = "GENERAL_CATALOG_PATCH";
export const GENERAL_CATALOG_PATCH_SUCCESS = "GENERAL_CATALOG_PATCH_SUCCESS";
export const GENERAL_CATALOG_PATCH_ERROR = "GENERAL_CATALOG_PATCH_ERROR";
export const GENERAL_CATALOG_PATCH_CLEAR_DATA =
  "GENERAL_CATALOG_PATCH_CLEAR_DATA";

import { call, put, takeEvery } from "redux-saga/effects";
import { campaignProjectPostError, campaignProjectPostSuccess } from "./actions";
import { postCampaignProjectApi } from "../../resources/helper/campaignProject";
import { CAMPAIGNPROJECT_POST } from "./actionTypes";

function* postCampaignProject({ payload: { campaignProject, history } }) {
  try {
    const response = yield call(postCampaignProjectApi, campaignProject);
    yield put(campaignProjectPostSuccess(response));
  } catch (error) {
    yield put(campaignProjectPostError(error));
  }
}

function* campaignProjectPostSaga() {
  yield takeEvery(CAMPAIGNPROJECT_POST, postCampaignProject);
}

export default campaignProjectPostSaga;

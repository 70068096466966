import React, { useEffect, useState } from "react";
import { PendingPageView } from "./pendingPageView";
import { useNavigate } from "react-router-dom";
import {
  CardProps,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { DataCards } from "./DTO";
import { GetDocumentsStatistics } from "../../fnx/documentsStatistics/getDocumentsStatistics";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentsStatisticsClearData } from "../../store/documentStatistics/actions";
import { quoteAll, quoteAllClearData } from "../../store/generateQuote/actions";
import { RowsModel } from "../../model/insurancePoliciesSummary";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import showToast from "../../services/toast";

export const PendingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [dataCard, setDataCard] = React.useState<CardProps[]>(DataCards);
  const [rows, setRows] = useState<any>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(500000);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const {
    loadingDocumentStatistics,
    responseDocumentStatistics,
    errorDocumentStatistics,
  } = GetDocumentsStatistics(trigger);

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    }),
  );

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorDocumentStatistics) {
      showErrorToast(errorDocumentStatistics, setSeeToast, t);
    }
  }, [errorDocumentStatistics]);

  useEffect(() => {
    setTrigger(true);
    getQuoteAll();
  }, []);

  useEffect(() => {
    if (responseDocumentStatistics?.statuses) {
      const updatedDataCard = dataCard.map((card) => {
        const statusMatch = responseDocumentStatistics.statuses.find(
          (status: any) => {
            if (card.id === "1" && status.status === "PENDING") return true;
            return card.id === "2" && status.status === "READY";
          },
        );
        return {
          ...card,
          numericalTitle: statusMatch ? statusMatch.count : "0",
        };
      });

      setDataCard(updatedDataCard);
    }
    dispatch(getDocumentsStatisticsClearData());
  }, [responseDocumentStatistics]);

  // const setDataQuoteToRows = (responseAllQuote: RowsModel[]) => {};

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      const dataR = responseAllQuote?.map((item: RowsModel) => ({
        id: item.id,
        quotation: item.code,
        certificate: item.policyId && item.policyId?.code,
        names: `${item.firstNameA} ${item.firstNameB}`,
        lastNames: `${item.lastNameA} ${item.lastNameB}`,
        status: item.catalogStatusQuote?.description,
        productId: item.productId.id,
      }));

      setRows(dataR);
      dispatch(quoteAllClearData());
    }
  }, [responseAllQuote]);

  const getQuoteAll = () => {
    dispatch(
      quoteAll({
        enable: enable,
        page: page,
        limit: limit,
        profileDocumentsStatus: "PENDING",
      }),
    );
  };

  const handleClickContextMenu = (item: any, param: any) => {
    switch (item.id) {
      case "1":
        if (
          param.row.status === "Cotizada" ||
          param.row.status === "cotizada"
        ) {
          navigate(
            `/confirmationForm/${param.row.id}/true/false/${param.row.productId}/true`,
          );
        } else {
          navigate(
            `/confirmationForm/${param.row.id}/false/false/${param.row.productId}/true`,
          );
        }
        break;
    }
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <PendingPageView
        title={"Documentos cliente"}
        loadingDocumentStatistics={loadingDocumentStatistics || loadingAllQuote}
        handleClickContextMenu={handleClickContextMenu}
        dataCard={dataCard}
        rows={rows}
      />
    </>
  );
};

import {
  INSURANCES,
  INSURANCES_CLEAR_DATA,
  INSURANCES_ERROR,
  INSURANCES_ONE,
  INSURANCES_ONE_SUCCESS,
  INSURANCES_SUCCESS,
  POST_INSURANCES,
  POST_INSURANCES_CLEAR_DATA,
  POST_INSURANCES_ERROR,
  POST_INSURANCES_SUCCESS,
  PATCH_INSURANCES,
  PATCH_INSURANCES_CLEAR_DATA,
  PATCH_INSURANCES_ERROR,
  PATCH_INSURANCES_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorInsurances: null,
  responseInsurances: null,
  loadingInsurances: false,
  errorPostInsurances: null,
  responsePostInsurances: null,
  loadingPostInsurances: false,
  errorPatchInsurances: null,
  loadingPatchInsurances: null,
  responsePatchInsurances: false,
};

const insurancesApi = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCES:
      state = {
        ...state,
        loadingInsurances: true,
        errorInsurances: null,
        responseInsurances: true,
      };
      break;
    case INSURANCES_ONE:
      state = {
        ...state,
        loadingInsurances: true,
        errorInsurances: null,
        responseOneInsurances: true,
      };
      break;
    case INSURANCES_SUCCESS:
      state = {
        ...state,
        errorInsurances: null,
        loadingInsurances: false,
        responseInsurances: action.payload,
      };
      break;
    case INSURANCES_ONE_SUCCESS:
      state = {
        ...state,
        errorInsurances: null,
        loadingInsurances: false,
        responseOneInsurances: action.payload,
      };
      break;
    case INSURANCES_ERROR:
      state = {
        ...state,
        errorInsurances: action.payload,
        loadingInsurances: false,
        responseInsurances: null,
      };
      break;
    case INSURANCES_CLEAR_DATA:
      state = {
        ...state,
        errorInsurances: null,
        loadingInsurances: null,
        responseInsurances: false,
      };
      break;
    case POST_INSURANCES:
      state = {
        ...state,
        loadingPostInsurances: true,
        errorPostInsurances: null,
        responsePostInsurances: true,
      };
      break;
    case POST_INSURANCES_SUCCESS:
      state = {
        ...state,
        errorPostInsurances: null,
        loadingPostInsurances: false,
        responsePostInsurances: action.payload,
      };
      break;
    case POST_INSURANCES_ERROR:
      state = {
        ...state,
        errorPostInsurances: action.payload,
        loadingPostInsurances: false,
        responsePostInsurances: null,
      };
      break;
    case POST_INSURANCES_CLEAR_DATA:
      state = {
        ...state,
        errorPostInsurances: null,
        loadingPostInsurances: null,
        responsePostInsurances: false,
      };
      break;
  case PATCH_INSURANCES:
      state = {
        ...state,
        loadingPatchInsurances: true,
        errorPatchInsurances: null,
        responsePatchInsurances: true,
      };
      break;
    case PATCH_INSURANCES_SUCCESS:
      state = {
        ...state,
        errorPatchInsurances: null,
        loadingPatchInsurances: false,
        responsePatchInsurances: action.payload,
      };
      break;
    case PATCH_INSURANCES_ERROR:
      state = {
        ...state,
        errorPatchInsurances: action.payload,
        loadingPatchInsurances: false,
        responsePatchInsurances: null,
      };
      break;
    case PATCH_INSURANCES_CLEAR_DATA:
      state = {
        ...state,
        errorPatchInsurances: null,
        loadingPatchInsurances: null,
        responsePatchInsurances: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default insurancesApi;

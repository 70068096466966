import {
  PAYMENT_MODE,
  PAYMENT_MODE_CLEAR_DATA,
  PAYMENT_MODE_ERROR,
  PAYMENT_MODE_SUCCESS,
  POST_PAYMENT_MODE,
  POST_PAYMENT_MODE_CLEAR_DATA,
  POST_PAYMENT_MODE_ERROR,
  POST_PAYMENT_MODE_SUCCESS,
} from "./actionTypes";

export const paymentModeAction = (paymentMode, history) => {
  return {
    type: PAYMENT_MODE,
    payload: { paymentMode, history },
  };
};

export const paymentModeSuccess = (success) => {
  return {
    type: PAYMENT_MODE_SUCCESS,
    payload: success,
  };
};

export const paymentModeError = (error) => {
  return {
    type: PAYMENT_MODE_ERROR,
    payload: error,
  };
};

export const paymentModeClearData = () => {
  return {
    type: PAYMENT_MODE_CLEAR_DATA,
    payload: {},
  };
};

export const postPaymentMode = (paymentMode, history) => {
  return {
    type: POST_PAYMENT_MODE,
    payload: { paymentMode, history },
  };
};

export const postPaymentModeSuccess = (success) => {
  return {
    type: POST_PAYMENT_MODE_SUCCESS,
    payload: success,
  };
};

export const postPaymentModeError = (error) => {
  return {
    type: POST_PAYMENT_MODE_ERROR,
    payload: error,
  };
};

export const postPaymentModeClearData = () => {
  return {
    type: POST_PAYMENT_MODE_CLEAR_DATA,
    payload: {},
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rol } from "../../store/rol/actions";

export const useRolGetAll = (
  trigger: boolean,
  clientId?: string,
  pageCatalog?: number,
  limitCatalog?: number,
  name?: string,
  description?: string,
  nameSearch?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { responseRol, errorRol, loadingRol } = useSelector((state: any) => ({
    responseRol: state.Rol.responseRol,
    errorRol: state.Rol.errorRol,
    loadingRol: state.Rol.loadingRol,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        rol({
          clientId: clientId,
          enable: enable,
          page: pageCatalog,
          limit: limitCatalog,
          name: name,
          description: description,
          nameSearch: nameSearch,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseRol,
    errorRol,
    loadingRol,
    setEnable,
  };
};
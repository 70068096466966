import {
  breadcrumb,
  CardProps,
  FilterProps,
  InformationProps,
  insuranceFilterProps,
  itemPlanProps,
  listTypeCardProps,
  pages,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export interface InsurancePageProps {
  titlePage: string;
  titleMetaTag: string;
  informationPage: InformationProps;
  showFilterSearch?: boolean;
  filter: insuranceFilterProps;
  catalog?: FilterProps;
  data?: itemPlanProps[];
  listCard?: listTypeCardProps;
  card?: CardProps;
  breadCrumbs?: breadcrumb[];
  viewType: string;
  pages?: pages.seguro;
}
export interface CustomerPageProps {
  id?: string;
  titlePage: string;
  titleMetaTag: string;
  informationPage: InformationProps;
  showFilterSearch?: boolean;
  filter: insuranceFilterProps;
  catalog?: FilterProps;
  data?: itemPlanProps[];
  listCard?: listTypeCardProps;
  card?: CardProps;
  breadCrumbs?: breadcrumb[];
  viewType: string;
  pages?: pages.seguro;
}

export const columnQuotes = [
  {
    editable: false,
    field: "quote",
    headerName: "Cotización/Póliza",
    width: 250,
  },
  {
    editable: false,
    field: "name",
    headerName: "Nombre y Apellido",
    width: 250,
  },
  {
    editable: false,
    field: "product",
    headerName: "Producto",
    width: 350,
  },
  {
    editable: false,
    field: "storeChannel",
    headerName: "Sucursal, tienda o canal",
    width: 260,
  },
  {
    editable: false,
    field: "status",
    headerName: "Estado",
    width: 160,
  },
];

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "seguro",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
];

import React, { useEffect, useState } from "react";
import { Container, Content, WraperSidebar, WrapperFooter } from "./style";
import LogoAFY from "../../assets/images/logoAffinity.svg";
import { getCustomerAction } from "../../fnx/customer";
import {
  FooterAFY,
  HeaderAFY,
  icons,
  MenuItem,
  SideBarAFY,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import LogoAFYfooter from "../../assets/images/logoAffinityW.svg";
import { ItemMenu } from "./data";
import LocalStorageService from "../../services/localStorage";
import { useAuth } from "../../authContext";
import { useDispatch } from "react-redux";
import { loginUserClearData } from "../../store/auth/actions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const View = (props: any) => {
  const dispatch = useDispatch();
  const [menuHeader, setMenuHeader] = React.useState<MenuItem[]>([]);
  const [menusSideBar, setMenusSideBar] = React.useState<MenuItem[]>([]);
  const { status, setStatus } = useAuth();
  const [showLogo, setShowLogo] = useState(true);

  const currentDate: Date = new Date();
  const year = currentDate.getFullYear();
  const [name] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.payload.name,
  );
  const [lastName] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.payload.family_name,
  );
  const [userName] = React.useState<string>(
    lsLogin.getUserLoginStored().userName,
  );
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [token] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.jwtToken,
  );

  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );

  useEffect(() => {
    if (props.menus) {
      setMenuHeader(props.menus);
    }
  }, [props.menus]);

  useEffect(() => {
    if (getCustomerAction().customerAuto.includes(company)) {
      setShowLogo(false);
    }
  }, [props.menus]);

  useEffect(() => {
    if (props.menusSB) {
      setMenusSideBar(props.menusSB);
    }
  }, [props.menusSB]);

  useEffect(() => {
    const appId = process.env.REACT_APP_ID_CHAT;
    const reportId = process.env.REACT_APP_REPORT_ID_CHAT;
    const script = document.createElement("script");
    script.src = `${process.env.REACT_APP_HOST_CHAT}`;
    script.type = "module";
    script.async = true;
    script.onload = () => {
      if (window.ChatWidget) {
        window.ChatWidget.initializeChatWidget({
          containerId: "chat-widget-container",
          appId: props.rolMenu === null ? appId : reportId,
          secMode: "internal_auth",
          clientId,
          token: `Bearer ${token}`,
        });
      }
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [props.rolMenu]);

  const logout = (e: MenuItem) => {
    switch (e.id) {
      case "1": {
        lsLogin.setLogout();
        setStatus("no-authenticated");
        dispatch(loginUserClearData());
        //limpia todos los key del storage
        localStorage.clear();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <HeaderAFY
        username={userName}
        first_name={/*`${name} ${lastName}`*/ ""}
        iconButton={icons.Person}
        iconUser={icons.Person}
        last_name={""}
        logoUrl={LogoAFY}
        menu={ItemMenu}
        hideBadge={false}
        onClickBadge={props.onClickBadge}
        customBadgeColor={"#fba730"}
        badgeColorText={"#ffffff"}
        countBadgeContent={props.countBadge}
        menus={menuHeader}
        onClickContextMenu={(e) => {
          logout(e);
        }}
        onClickLogo={function noRefCheck() {}}
        onClickMenu={props.onClickMenu}
        primaryColorText={themeCore.colors.primaryText}
        secondaryColorText={themeCore.colors.fourthText}
        sizeIconButton={sizesIcon.standard}
        backgroundColor={themeCore.colors.backgroundSecondary}
        showLogo={showLogo}
      />

      <Container>
        <WraperSidebar
          style={{
            width: props.showSideBar ? "268px" : "40px",
            minWidth: props.showSideBar ? "240px" : "29px",
          }}
        >
          <SideBarAFY
            backColorSideBar={themeCore.colors.backgroundSecondary}
            buttonColor="#006ac6"
            iconButtonColor="#fff"
            iconColor="#006ac6"
            menuSideBar={menusSideBar}
            onClickMenu={props.onClickSideBar}
            onClickStatus={props.onClickStatus}
            sizeIconButton={sizesIcon.medium}
            textItemColor="#006ac6"
            title="inicio"
            titleColor="#006ac6"
          />
        </WraperSidebar>
        <div id="chat-widget-container" style={{ zIndex: 9999 }}></div>
        <section
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "98%",
          }}
        >
          <Content>{props.children}</Content>
        </section>
      </Container>

      <WrapperFooter>
        <FooterAFY
          logo={LogoAFYfooter}
          textColor={themeCore.colors.primaryText}
          backgroundColor={themeCore.colors.fourth}
          copyRight={`© Affinity ${year}. Derechos reservados.`}
        />
        <article
          style={{
            position: "absolute",
            bottom: "11px",
            fontSize: "9px",
            right: "10px",
            color: "rgb(58, 86, 161)",
          }}
        >
          version 1.4.01 beta
        </article>
      </WrapperFooter>
    </>
  );
};

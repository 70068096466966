import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { themeCore } from "../../assets/themes/theme";

export const LoginFormDocPlace = styled.section`
  background-color: ${themeCore.colors.primary};
`;

export const ContainerStepProgress = styled.article`
  position: fixed !important;
  top: 79px;
  right: 426px;
  z-index: 99;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    backgroundColor: "#119949 !important",
    color: "#fff !important",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  description: {
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "40px",
  },
  modalLabel: {
    width: "100px",
    display: "inline-block",
    fontWeight: "bold",
  },
  components: {
    justifyContent: "center",
    width: "100%",
    clear: "both",
  },
  component: {
    width: "100%",
  },
  label: {
    fontSize: "14px",
    marginBottom: "10px",
  },
  buttonsContainer: {
    marginTop: "40px",
    marginBottom: "0px",
    display: "flex",
    justifyContent: "center",
  },
  buttonsContainerConfirm: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  iconDelete: {
    color: "rgb(0, 106, 198)",
    fontSize: "20px !important",
    cursor: "pointer",
    margin: "0 auto",
  },
  button: {
    margin: "10px",
  },
}));

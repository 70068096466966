import {
  icons,
  MenuItem,
  select,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import insurance from "../../assets/images/insurance.png";

export const insurersView: MenuItem[] = [
  {
    active: false,
    icon: {
      icon: icons.List,
    },
    id: "1",
    name: "List",
  },
  {
    active: true,
    icon: {
      icon: icons.GridView,
    },
    id: "2",
    name: "GridView",
  },
];

export const buttonActionOptions: MenuItem[] = [
  {
    disabled: false,
    id: "1",
    name: "Planes",
    divider: true,
    avatar: {
      image: insurance,
    },
  },
  {
    disabled: false,
    id: "2",
    name: "Modificar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
  },
  {
    disabled: false,
    id: "3",
    name: "Clonar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.FileCopy,
      size: sizesIcon.standard,
    },
  },
  {
    disabled: false,
    id: "4",
    name: "Eliminar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.DeleteForever,
      size: sizesIcon.standard,
    },
  },
];

export const optionsSelect: select[] = [
  {
    id: "1",
    name: "Banesco",
    value: "1",
  },
];

import { URLs } from "../url";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";
import { CatalogueResponse } from "../../model/catalogue";

const getCertificateQuote = async (
  title: string,
  quoteId: string,
  enable: boolean,
  page: number,
  limit: number,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.certificate +
    `?title=${title}` +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (quoteId ? `&quoteId=${quoteId}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CatalogueResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getCertificateQuote };
import { URLs } from "../url";
import { getHeader } from "../headers";
import { PermissionResponse } from "../../model/permission";
import { handleUnauthorized } from "../fetchRequest";

const getPermission = async (
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.permission +
    `?clientId=${clientId}` +
    `&enable=${enable}&page=${page}&limit=${limit}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PermissionResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export { getPermission };
import { call, put, takeEvery } from "redux-saga/effects";
import { 
  coverTypeSuccess, coverTypeError,  
  coverTypeGetOneSuccess, coverTypeGetOneError,
  coverTypePostSuccess, coverTypePostError,
  coverTypePatchSuccess, coverTypePatchError
} from "./actions";
import { coverTypeGet, coverTypeGetOne, coverTypePost, coverTypePatch } from "../../resources/helper/coverType";
import { COVER_TYPE, COVER_TYPE_POST, COVER_TYPE_PATCH, COVER_TYPE_GET_ONE  } from "./actionTypes";

function* coverTypeApi({ payload: { coverType } }) {
  try {
    const response = yield call(
      coverTypeGet,
      coverType.enable,
      coverType.page,
      coverType.limit,
    );
    yield put(coverTypeSuccess(response));
  } catch (error) {
    yield put(coverTypeError(error));
  }
}

function* coverTypeGetOneApi({ payload: { coverType } }) {
  try {
    const response = yield call(coverTypeGetOne, coverType.id);
    yield put(coverTypeGetOneSuccess(response));
  } catch (error) {
    yield put(coverTypeGetOneError(error));
  }
}

function* coverTypePostApi({ payload: { data } }) {
  try {
    const response = yield call(coverTypePost, data);
    yield put(coverTypePostSuccess(response));
  } catch (error) {
    yield put(coverTypePostError(error));
  }
}

function* coverTypePatchApi({ payload: { coverType, id } }) {
  try {
    const response = yield call(coverTypePatch, coverType, id);
    yield put(coverTypePatchSuccess(response));
  } catch (error) {
    yield put(coverTypePatchError(error));
  }
}

function* coverTypeSaga() {
  yield takeEvery(COVER_TYPE, coverTypeApi);
  yield takeEvery(COVER_TYPE_GET_ONE, coverTypeGetOneApi);
  yield takeEvery(COVER_TYPE_POST, coverTypePostApi);
  yield takeEvery(COVER_TYPE_PATCH, coverTypePatchApi);
}

export default coverTypeSaga;

import { URLs } from "../url";
import { getHeaderUpload } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const uploadQuoteDocumentsAPI = async (
  quoteId: string,
  fileName: string,
  pdfFile: File,
  documentProfileId: string,
) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.quote}/${quoteId}/Documents`;
  const data = new FormData();
  data.append("file", pdfFile);
  data.append("fileName", fileName);
  data.append("documentProfileId", documentProfileId);
  const requestOptions = {
    method: "POST",
    body: data,
    headers: getHeaderUpload(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { uploadQuoteDocumentsAPI };

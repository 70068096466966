import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountType } from "../../store/accountType/actions";

export const useAccountTypeGetAll = (
  trigger: boolean,
  clientId?: string,
  // projectId?: string,
  //catalogAccountTypeId?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responseAccountType, errorAccountType, loadingAccountType } =
    useSelector((state: any) => ({
      responseAccountType: state.AccountType.responseAccountType?.data,
      errorAccountType: state.AccountType.errorAccountType,
      loadingAccountType: state.AccountType.loadingAccountType,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        accountType({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
          //  projectId: projectId,
          // catalogAccountTypeId,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseAccountType,
    errorAccountType,
    loadingAccountType,
    setEnable,
    setPage,
    setLimit,
  };
};

import { call, put, takeEvery } from "redux-saga/effects";
import { 
  regionError, regionSuccess, 
  regionGetOneSuccess, regionGetOneError,
  regionPostSuccess, regionPostError,
  regionPatchSuccess, regionPatchError
} from "./actions";
import { regionGet, regionGetOne, regionPost, regionPatch } from "../../resources/helper/region";
import { REGION, REGION_POST, REGION_PATCH, REGION_GET_ONE  } from "./actionTypes";

function* regionApi({ payload: { region } }) {
  try {
    const response = yield call(
      regionGet,
      region.enable,
      region.page,
      region.limit,
      region.name,
      region.zipCode,
      region.code,  
      region.level,
      region.parentRegionId,
      region.clientId,
    );
    yield put(regionSuccess(response, region.level));
  } catch (error) {
    yield put(regionError(error));
  }
}

function* regionGetOneApi({ payload:  region  }) {
  try {
    const response = yield call(regionGetOne, region.id);
    yield put(regionGetOneSuccess(response));
  } catch (error) {
    yield put(regionGetOneError(error));
  }
}

function* regionPostApi({ payload:  region }) {
  try {
    const response = yield call(regionPost, region);
    yield put(regionPostSuccess(response));
  } catch (error) {
    yield put(regionPostError(error));
  }
}

function* regionPatchApi(  { payload:  region  }  ) {
  try {
     const response = yield call(regionPatch,  region,  region?.data?.id);
     yield put(regionPatchSuccess(response));
   } catch (error) {
     yield put(regionPatchError(error));
   }
}

function* regionSaga() {
  yield takeEvery(REGION, regionApi);
  yield takeEvery(REGION_GET_ONE, regionGetOneApi);
  yield takeEvery(REGION_POST, regionPostApi);
  yield takeEvery(REGION_PATCH, regionPatchApi);
}

export default regionSaga;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { project } from "../../store/project/actions";

export const GetProjectAll = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  sponsorId: string,
  description?: string,
) => {
  const dispatch = useDispatch();

  const { responseProject, errorProject, loadingProject } = useSelector(
    (state: any) => ({
      responseProject: state.Project.responseProject,
      errorProject: state.Project.errorProject,
      loadingProject: state.Project.loadingProject,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        project({
          enable: enable,
          page: page,
          limit: limit,
          sponsorId: sponsorId,
          description: description,
        }),
      );
    }
  }, [sponsorId, enable, limit, page, trigger, description]);

  return {
    responseProject,
    errorProject,
    loadingProject,
  };
};

import React from "react";
import { useParams } from "react-router-dom";
import {
  ContextMenuAFY,
  DataFormClient,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const ColumnsPaymentRecurrence = () => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {(!idProduct || (idProduct && isEdit === "true")) && (
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              // onClickContextMenu={(item: any) =>
              //   // handleClickContextMenu(item, params)
              // }
              sizeIcon={sizesIcon.standard}
            />
          )}
        </div>
      ),
    },
    {
      editable: true,
      field: "code",
      flex: 1,
      headerName: "Código",
      width: 150,
    },
    {
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};

export const ColumnsPaymentMode = () => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {(!idProduct || (idProduct && isEdit === "true")) && (
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              // onClickContextMenu={(item: any) =>
              //   // handleClickContextMenu(item, params)
              // }
              sizeIcon={sizesIcon.standard}
            />
          )}
        </div>
      ),
    },

    {
      editable: true,
      field: "code",
      flex: 1,
      headerName: "Código",
      width: 150,
    },
    {
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};

export const dataProductCreationForm: DataFormClient = {
  IdSponsor: "",
  IdTypeInsurance: "",
  Description: "",
  Code: "",
  IdCurrency: "",
  CertifyNumber: "",
  QuotesNumber: 0,
  PolicyNumber: 0,
};

export const dataForm: DataFormClient = {
  Name: "",
  Code: "",
  
};

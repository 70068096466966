import {
  ContextMenuAFY,
  DataFormRol,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useParams } from "react-router-dom";
import React from "react";

export const rolDataForm: DataFormRol = {
  id: "",
  clientId: "",
  RolName: "",
  RolDescription: "",
};

export interface RolPermission {
  id: string;
  active?: boolean;
  name?: string;
  description?: string;
}

export const ColumnsPermissions = (clickContextMenuPaymentMode: any) => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "type",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              onClickContextMenu={() =>
                clickContextMenuPaymentMode(params?.row)
              }
              sizeIcon={sizesIcon.standard}
            />
          }
        </div>
      ),
    },

    {
      editable: false,
      field: "name",
      flex: 1,
      headerName: "Permiso",
      width: 150,
    },
    {
      editable: false,
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};
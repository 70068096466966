import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sponsor } from "../../store/sponsor/actions";

export const useSponsorGetAll = (
  trigger: boolean,
  clientId: string,
  namesearch: string,
  pageSponsor: number,
  sponsorLimit: number,
) => {
  const dispatch = useDispatch();
  const [enable] = useState<boolean>(true);

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        sponsor({
          enable: enable,
          page: pageSponsor,
          limit: sponsorLimit,
          clientId: clientId,
          name: namesearch,
        }),
      );
    }
  }, [trigger]);

  return {
    responseSponsor,
    errorSponsor,
    loadingSponsor,
  };
};

export const REGION = "REGION";
export const REGION_SUCCESS = "REGION_SUCCESS";
export const REGION_ERROR = "REGION_ERROR";
export const REGION_CLEAR_DATA = "REGION_CLEAR_DATA";

export const REGION_GET_ONE = "REGION_GET_ONE";
export const REGION_GET_ONE_SUCCESS = "REGION_GET_ONE_SUCCESS";
export const REGION_GET_ONE_ERROR = "REGION_GET_ONE_ERROR";
export const REGION_GET_ONE_CLEAR_DATA = "REGION_GET_ONE_CLEAR_DATA";

export const REGION_POST = "REGION_POST";
export const REGION_POST_SUCCESS = "REGION_POST_SUCCESS";
export const REGION_POST_ERROR = "REGION_POST_ERROR";
export const REGION_POST_CLEAR_DATA = "REGION_POST_CLEAR_DATA";

export const REGION_PATCH = "REGION_PATCH";
export const REGION_PATCH_SUCCESS = "REGION_PATCH_SUCCESS";
export const REGION_PATCH_ERROR = "REGION_PATCH_ERROR";
export const REGION_PATCH_CLEAR_DATA = "REGION_PATCH_CLEAR_DATA";
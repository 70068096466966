import React from "react";
import {
  breadcrumb,
  FinancialInformationAFY,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";
import { TFunction } from "i18next";
import { DataForm, filtersData } from "./financialInformationDTO";

export const FinalInformationView = (props: {
  steps: MenuItem[];
  breadCrumbs: breadcrumb[];
  onClickSaveForm: (data: any) => void;
  onNextStep: () => void;
  onBackStep: () => void;
  t: TFunction;
}) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        titlePage={props.t("financialInformation.title")}
        steps={props.steps}
        hideStep={false}
        stepsTop={"0px"}
      />
        <div style={{ height: "40px" }}></div>
      <FinancialInformationAFY
        titleButtonForm={props.t("financialInformation.titleButtonForm")}
        titleFilters={props.t("financialInformation.titleFilters")}
        titleMetaTags={props.t("financialInformation.titleMetaTags")}
        titleNextButton={props.t("financialInformation.titleNextButton")}
        titlePreviousButton={props.t(
          "financialInformation.titlePreviousButton",
        )}
        minHeightGrid="calc(100vh - 145px)"
        onClickSaveForm={props.onClickSaveForm}
        onClickNext={props.onNextStep}
        onClickPrevious={props.onBackStep}
        data={DataForm(props.t)}
        filters={filtersData(props.t)}
      />
    </React.Fragment>
  );
};

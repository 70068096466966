export const PROJECT = "PROJECT";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const PROJECT_CLEAR_DATA = "PROJECT_CLEAR_DATA";

export const PROJECT_ONE = "PROJECT_ONE";
export const PROJECT_ONE_SUCCESS = "PROJECT_ONE_SUCCESS";
export const PROJECT_ONE_ERROR = "PROJECT_ONE_ERROR";
export const PROJECT_ONE_CLEAR_DATA = "PROJECT_ONE_CLEAR_DATA";

export const PROJECT_POST = "PROJECT_POST";
export const PROJECT_POST_SUCCESS = "PROJECT_POST_SUCCESS";
export const PROJECT_POST_ERROR = "PROJECT_POST_ERROR";
export const PROJECT_POST_CLEAR_DATA = "PROJECT_POST_CLEAR_DATA";

export const PROJECT_PATCH = "PROJECT_PATCH";
export const PROJECT_PATCH_SUCCESS = "PROJECT_PATCH_SUCCESS";
export const PROJECT_PATCH_ERROR = "PROJECT_PATCH_ERROR";
export const PROJECT_PATCH_CLEAR_DATA = "PROJECT_PATCH_CLEAR_DATA";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import LocalStorageService from "../../services/localStorage";
//import { useBranchesPatch  } from "../../fnx/branches/branchesPatch";
//import { useBranchesPost  } from "../../fnx/branches/branchesPost";
import { deleteEntityClearData } from "../../store/delete/actions";

import {
  CardProps,
  MenuItem,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { useProjectTypeGetAll } from "../../fnx/projectType/projectTypeGetAll";
import { ProjectType, ProjectTypeRequest } from "../../model/projectType";
import { showErrorToast } from "../../fnx/showError";
import { useProjectTypePost } from "../../fnx/projectType/projectTypePost";
import { useProjectTypePatch } from "../../fnx/projectType/projectTypePatch";
import { useTranslation } from "react-i18next";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ProjectTypesAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listProjectTypes, setListProjectTypes] = useState<CardProps[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [idProjectType, setIdProjectType] = useState("");

  const [bodyRequest, setBodyRequest] = useState<ProjectTypeRequest>({
    data: {
      clientId: "",
    },
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    Description: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseProjectType, errorProjectType, loadingProjectType } =
    useProjectTypeGetAll(trigger, clientId);

  const {
    responseProjectTypePost,
    errorProjectTypePost,
    loadingProjectTypePost,
  } = useProjectTypePost(triggerPost, bodyRequest);

  const {
    responseProjectTypePatch,
    errorProjectTypePatch,
    loadingProjectTypePatch,
  } = useProjectTypePatch(triggerPatch, bodyRequest, idProjectType);

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const callProjectTypes = () => {
    setTrigger(!trigger);
  };

  useEffect(() => {
    callProjectTypes();
  }, []);

  const callProjectTypePost = () => {
    setTriggerPost(!triggerPost);
  };

  const callProjectTypePatch = () => {
    setTriggerPatch(!triggerPatch);
  };

  useEffect(() => {
    if (responseProjectType && responseProjectType?.length > 0) {
      const listProjectTypes: CardProps[] = responseProjectType.map(
        (item: ProjectType) => ({
          id: item.id,
          title: item.description,
          clientId: item.clientId,
        }),
      );
      setListProjectTypes(listProjectTypes);
      setTrigger(false);
    }
  }, [responseProjectType]);

  /*Valida respuesta cuando ya se inserto el registro*/
  useEffect(() => {
    setTriggerPost(false);
    if (responseProjectTypePost) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
    }
    callProjectTypes();
  }, [responseProjectTypePost]);

  /*Valida respuesta cuando ya se actualizo el registro*/
  useEffect(() => {
    setTriggerPost(false);
    if (responseProjectTypePatch) {
      setSeeToast({
        show: true,
        message: "Registro actualizado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
    }
    callProjectTypes();
  }, [responseProjectTypePatch]);

  /*Muestra si tubo errores el GET ALL de Tipo de Proyectos*/
  useEffect(() => {
    if (errorProjectType) {
      showErrorToast(errorProjectType, setSeeToast, t);
    }
  }, [errorProjectType]);

  /*Muestra si tubo errores el POST de Tipo de Proyectos*/
  useEffect(() => {
    if (errorProjectTypePost) {
      showErrorToast(errorProjectTypePost, setSeeToast, t);
    }
  }, [errorProjectTypePost]);

  /*Muestra si tubo errores el Patch de Tipo de Proyectos*/
  useEffect(() => {
    if (errorProjectTypePatch) {
      showErrorToast(errorProjectTypePatch, setSeeToast, t);
    }
  }, [errorProjectTypePatch]);

  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      Id: row.id,
      Description: row.title,
      clientId: clientId,
    });
  };

  /*Submit para actualizar o agregar un registro*/
  const handleSubmitProjectType = (dataProject: any) => {
    if (isUpdate) {
      /*Se llena la informacion al momento de acutalizar*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          description: dataProject.Description,
        },
      });
      // Se asigna el id del ProjectType para a actualizar
      setIdProjectType(dataForm.Id);

      /*Llamda al FNX para actualizar PATCH*/
      callProjectTypePatch();
      // setAvatarFile(file);
    } else {
      /*Se procede a crear un nuevo Registro*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          description: dataProject.Description,
        },
      });
      // setAvatarFile(file);

      /*Llamada al FNX para crear Registro*/
      callProjectTypePost();
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      Id: "",
      Description: "",
      clientId: "",
    });
  };

  const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      // Opcion Editar
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(item);
    } else if (opt.id === "2") {
      // Opcion eliminar
      // setitemModal(item);
      // setOpenModalConfirm(true);
      setSeeToast({
        show: true,
        message: "Operación no permitida!",
        type: typeAlert.warning,
      });

      setOpenModal(false);
    } else if (opt.id === "3") {
      setitemModal(item);
      setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  /*Api que se ejecuta cuando el catalogo tiene la opcion
   * para eliminar registros*/
  const handleDelete = () => {
    setSeeToast({
      show: true,
      message: "Operación no permitida!",
      type: typeAlert.warning,
    });
    
    setOpenModal(false);
  };

  const handleClone = () => {};

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <View
        title="Tipos de Proyectos"
        projectTypeTitle={
          isUpdate ? "Modificar Tipo Proyecto" : "Agregar Tipo Proyecto"
        }
        list={listProjectTypes}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onSaveData={handleSubmitProjectType}
        onClickAdd={handleOnClickAdd}
        onClickCancel={handleCloseModal}
        onClickButtonOptionsDropdown={(opt?: any, item?: any): void => {
          handleOnClickButtonOptionsDropdown(opt, item);
        }}
      />
    </React.Fragment>
  );
};

export default ProjectTypesAdmin;

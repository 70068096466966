export const INSURED = "INSURED";
export const INSURED_SUCCESS = "INSURED_SUCCESS";
export const INSURED_ERROR = "INSURED_ERROR";
export const INSURED_CLEAR_DATA = "INSURED_CLEAR_DATA";
export const POST_INSURED = "POST_INSURED";
export const POST_INSURED_SUCCESS = "POST_INSURED_SUCCESS";
export const POST_INSURED_ERROR = "POST_INSURED_ERROR";
export const POST_INSURED_CLEAR_DATA = "POST_INSURED_CLEAR_DATA";
export const PATCH_INSURED = "PATCH_INSURED";
export const PATCH_INSURED_SUCCESS = "PATCH_INSURED_SUCCESS";
export const PATCH_INSURED_ERROR = "PATCH_INSURED_ERROR";
export const PATCH_INSURED_CLEAR_DATA = "PATCH_INSURED_CLEAR_DATA";


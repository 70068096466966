import { call, put, takeEvery } from "redux-saga/effects";
import {
  accountTypeError,
  accountTypePatchError,
  accountTypePatchSuccess,
  accountTypePostError,
  accountTypePostSuccess,
  accountTypeSuccess,
} from "./actions";
import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_PATCH,
  ACCOUNT_TYPE_POST,
} from "./actionTypes";
import {
  getAccountType,
  patchAccountType,
  postAccountType,
} from "../../resources/helper/accountType";

function* accountType({ payload: { accountType } }) {
  try {
    const response = yield call(
      getAccountType,
      accountType.enable,
      accountType.page,
      accountType.limit,
      accountType.clientId,
      accountType.projectId,
      accountType.deleted,
      accountType.catalogAccountTypeId,
    );
    yield put(accountTypeSuccess(response));
  } catch (error) {
    yield put(accountTypeError(error));
  }
}

function* accountTypePost({ payload: { accountType } }) {
  try {
    const response = yield call(postAccountType, accountType);
    yield put(accountTypePostSuccess(response));
  } catch (error) {
    yield put(accountTypePostError(error));
  }
}

function* accountTypePatch({ payload: { accountTypePatchBody, id } }) {
  try {
    const response = yield call(patchAccountType, accountTypePatchBody, id);
    yield put(accountTypePatchSuccess(response));
  } catch (error) {
    yield put(accountTypePatchError(error));
  }
}

function* AccountTypeSaga() {
  yield takeEvery(ACCOUNT_TYPE, accountType);
  yield takeEvery(ACCOUNT_TYPE_POST, accountTypePost);
  yield takeEvery(ACCOUNT_TYPE_PATCH, accountTypePatch);
}

export default AccountTypeSaga;

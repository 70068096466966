import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectOne } from "../../store/project/actions";

export const GetProjectOne = (trigger: boolean, id?: string) => {
  const dispatch = useDispatch();

  const { responseOneProject, errorOneProject, loadingOneProject } =
    useSelector((state: any) => ({
      responseOneProject: state.Project.responseOneProject?.data,
      errorOneProject: state.Project.errorOneProject,
      loadingOneProject: state.Project.loadingOneProject,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        projectOne({
          id: id,
        }),
      );
    }
  }, [trigger, id]);

  return {
    responseOneProject,
    errorOneProject,
    loadingOneProject,
  };
};

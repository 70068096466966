import { useStyles } from "../generalCatalog/style";
import {
  AdminRolAFY,
  CardGeneralCatalogProps,
  DataFormRol,
  icons,
  MenuItem,
  select,
  sizesIcon,
  sizesSelect,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { ModalDelete } from "../sponsor/resorources/modalDelete";
import {
  buttonActionOptions,
  insurersView,
  menuItem,
  optionSelect,
  optionsSelectClient,
  selectValueDefault,
} from "./data";
import { RolPermission } from "./rol.Dto";
import { ModalConfirmationDelete } from "../insurances/productCreation/resources/modalConfirmationDelete";

export const RolView = (props: {
  title: string;
  titleDrawer: string;
  list: CardGeneralCatalogProps[];
  avatarVariant: variantsAvatar;
  openModal: boolean;
  onSaveData?: any;
  loadingPostRol: any;
  dataForm: DataFormRol;
  modalTitle: string;
  editMode: boolean;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitDeletePermission: () => void;
  viewLinearForm: boolean;
  onClose: () => void;
  onCloseSearch: () => void;
  openDrawer: boolean;
  setOpenDrawer: (value: boolean | (() => boolean)) => void;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;

  openModalDeletePermission: boolean;
  setOpenModalDeletePermission: (value: boolean | (() => boolean)) => void;

  modalTitlePermission: string;

  onCloseDrawer: () => void;
  onCloseDrawerPermission: () => void;
  searchFilter: (term: any) => void;
  filterStatus: (item: any) => void;

  handleOpenModal: () => void;
  onChangeSelect: (event: SelectChangeEvent<string>) => void;
  onClickContextMenu: (event: MenuItem, card: CardGeneralCatalogProps) => void;

  onClickAdd: () => void;
  onClickCancel: () => void;
  onFilterApply: (value: any) => void;
  onFilterEmpty: (value: any) => void;
  selectValueDefault: string;
  menuItem?: MenuItem[];
  optionsSelect?: select[];
  optionsSelectClient?: select[];
  inputHeigth?: string;
  clientFilterLbl?: string;

  /*Drawer Permissions*/
  listDropDownPermissions: any[];
  columnsPermissions: any[];
  rowsPermissions: RolPermission[];
  buttonLblAddGrid: string;
  labelDropDownPermissions: string;
  txtSearchDropDownPermissions: string;
  defaultDropDownPermissions: any[];
  titleDrawerPermission: string;
  textDescriptionPermission: string;
  openDrawerPermission: boolean;
  onClickSaveDataPermission: (e: React.MouseEvent<HTMLButtonElement>) => void;
  titleDelete?: string;
  subTitleDelete?: string;

  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
  pageCatalog: number;
  limitCatalog: number;
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />

      <ModalConfirmationDelete
        openModal={props.openModalDeletePermission}
        setOpenModal={props.setOpenModalDeletePermission}
        titleDelete={props.titleDelete}
        subTitleDelete={props.subTitleDelete}
        deleteRecurrence={props.onSubmitDeletePermission}
        deletePaymentMode={props.onSubmitDeletePermission}
      />

      <AdminRolAFY
        dataForm={props.dataForm}
        avatarVariant={props.avatarVariant}
        backgroundColor={"#ffffff"}
        backgroundColorSearch={"#F6F7FB"}
        buttonActionOptions={buttonActionOptions}
        clientFilterLabel={props.clientFilterLbl}
        clientLabel={"Filtrar"}
        colorClientLabel={"#3A56A1"}
        customHeightInput={props.inputHeigth}
        data={props.list}
        disabledSelectClient={true}
        fontSizeSelect={14}
        fontSizeSelectClient={10}
        heightAddNew={"113px"}
        heightAddNewList={"60px"}
        heightCard={"113px"}
        heightCardList={"60px"}
        iconAvatar={icons.Search}
        iconBgColor={"#F6F7FB"}
        iconColor={"#3A56A1"}
        iconColorSearch={"#3A56A1"}
        iconSearch={icons.Search}
        insurersView={insurersView}
        menuItem={menuItem}
        onChangeSelect={props.onChangeSelect}
        onChangeTextSearch={function noRefCheck() {}}
        // onClearData={function noRefCheck() {}}
        onClickAdd={props.onClickAdd}
        onClickContextMenu={props.onClickContextMenu}
        openDrawer={props.openDrawer}
        onCloseDrawer={props.onCloseDrawer}
        onCloseSearch={props.onCloseSearch}
        onFilterApply={props.onFilterApply}
        onFilterEmpty={props.onFilterEmpty}
        onSaveData={props.onSaveData}
        optionsSelect={optionSelect}
        optionsSelectClient={optionsSelectClient}
        rightLogoUpload={"274px"}
        rightNameLogoUpload={"269px"}
        selectSize={sizesSelect.small}
        selectSizeClient={sizesSelect.small}
        selectVariant={variantsTextField.outlined}
        selectVariantClient={variantsTextField.outlined}
        selectedValueDefault={selectValueDefault}
        showFilters
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        textBackSearch="Volver"
        textDescription="Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam"
        textFilterSearch={"Buscar"}
        textFilterSearchDrop={"Buscar"}
        title={props.title}
        titleClearButton={"Limpiar filtros"}
        titleColor={"#3A56A1"}
        // editMode={props.editMode}
        titleDrawer={props.titleDrawer}
        titleSearchButton={"Buscar"}
        viewLinearForm={props.viewLinearForm}
        widthLabelLinearForm="300px"
        //Drawer Permissions
        listDropDownRol={props.listDropDownPermissions}
        columnsRol={props.columnsPermissions}
        rowsRol={props.rowsPermissions}
        buttonLblAddGrid={props.buttonLblAddGrid}
        labelDropDownRol={props.labelDropDownPermissions}
        txtSearchDropDownRol={props.txtSearchDropDownPermissions}
        defaultDropDownRol={props.defaultDropDownPermissions}
        titleDrawerRol={props.titleDrawerPermission}
        textDescriptionRol={props.textDescriptionPermission}
        openDrawerRol={props.openDrawerPermission}
        onCloseDrawerRol={props.onCloseDrawerPermission}
        // onClickSelectAllDropDown={props.onClickSelectAllDropDown}
        // onClickSelectDropDown={props.onClickSelectDropDown}
        onClickSaveRolUser={props.onClickSaveDataPermission}
        titlePagination={"Registros por página"}
        countPagination={props.countPagination}
        rowsPerPage={props.limitCatalog}
        onChangePage={props.onChangePage}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        pagePagination={props.pageCatalog}
      />
    </>
  );
};

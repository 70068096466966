import {
  CAMPAIGNPROJECT,
  CAMPAIGNPROJECT_CLEAR_DATA,
  CAMPAIGNPROJECT_ERROR,
  CAMPAIGNPROJECT_SUCCESS,
  CAMPAIGNPROJECT_POST,
  CAMPAIGNPROJECT_POST_SUCCESS,
  CAMPAIGNPROJECT_POST_ERROR,
  CAMPAIGNPROJECT_POST_CLEAR_DATA,

} from "./actionTypes";

const initialState = {
  errorCampaignProject: null,
  responseCampaignProject: null,
  loadingCampaignProject: false,
  responseCampaignProjectPost: null,
  responseCampaignProjectPostError: null,
  loadingCampaignProjectPost: false,
};

const CampaignProject = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGNPROJECT: 
      state = {
        ...state,
        loadingCampaignProject: true,
        errorCampaignProject: null,
        responseCampaignProject: true,
      };
      break;
    case CAMPAIGNPROJECT_SUCCESS:
      state = {
        ...state,
        errorCampaignProject: null,
        loadingCampaignProject: false,
        responseCampaignProject: action.payload,
      };
      break;
    case CAMPAIGNPROJECT_ERROR:
      state = {
        ...state,
        errorCampaignProject: action.payload,
        loadingCampaignProject: false,
        responseCampaignProject: null,
      };
      break;
    case CAMPAIGNPROJECT_CLEAR_DATA:
      state = {
        ...state,
        errorCampaignProject: null,
        loadingCampaignProject: null,
        responseCampaignProject: false,
      };
      break;
    case CAMPAIGNPROJECT_POST:
      state = {
        ...state,
        loadingCampaignProjectPost: true,
        errorCampaignProjectPost: null,
        responseCampaignProjectPost: true,
      };
      break;
    case CAMPAIGNPROJECT_POST_SUCCESS:
      state = {
        ...state,
        errorCampaignProjectPost: null,
        loadingCampaignProjectPost: false,
        responseCampaignProjectPost: action.payload,
      };
      break;
    case CAMPAIGNPROJECT_POST_ERROR:
      state = {
        ...state,
        errorCampaignProjectPost: action.payload,
        loadingCampaignProjectPost: false,
        responseCampaignProjectPost: null,
      };
      break;
    case CAMPAIGNPROJECT_POST_CLEAR_DATA:
      state = {
        ...state,
        errorCampaignProjectPost: null,
        loadingCampaignProjectPost: null,
        responseCampaignProjectPost: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CampaignProject;

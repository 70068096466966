import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { themeCore } from "../../assets/themes/theme";

export const LoginFormDocPlace = styled.section`
  background-color: ${themeCore.colors.primary};
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: "10px",
  },
}));

import React from "react";
import {
  breadcrumb,
  BreadcrumbAFY,
  MedicalInformationAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
interface ClientsViewProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
  showInfo?: boolean;
  tableHeight?: string;
  filters?: any[];
  stepProgress?: any;
  resumeDescription?: string;
  resumeValue?: string;
  nextLabel?: string;
  previousLabel?: string;
  onClickPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (data: any) => void;
  nextDisabled?: boolean;
  showPrevious?: boolean;
  hideFilter?: boolean;
  steps?: any;
}

export const MedicalInformationView = (props: ClientsViewProps) => {
  return (
    <React.Fragment>
   
      <HeaderTitlePage
        breadCrumbs={props.breadcrumbs}
        titlePage={props.title}
        steps={props.steps}
        hideStep={false}
        stepsTop={"0px"}
      />
      <div style={{ height: "40px" }}></div>
      <MedicalInformationAFY
      titleMetaTags = {props.title}
      titleFilters= "Resumen"
      titleButtonForm= "Guardar"
      titleNextButton= "Siguiente"
      titlePreviousButton= "Anterior"

      minHeightGrid={"calc(100vh - 73px)"}
      onClickNext = {props.onClickNext}
      data= {[
        {
            name: "select_container",
            type: "multi-column",
            label: "¿Padece o ha padecido en los últimos 10 años alguna de las siguientes enfermedades?",
            childrenDescription: "¿Padece o ha padecido en los últimos 10 años alguna de las siguientes enfermedades?",
            childrenRows: 3,
            children: [
                {
                    name: "gallbladder_disorder",
                    type: "checkbox",
                    label: "Alteración de la vesícula"
                },
                {
                    name: "tuberculosis",
                    type: "checkbox",
                    label: "Tuberculosis"
                },
                {
                    name: "bones",
                    type: "checkbox",
                    label: "Huesos"
                },
                {
                    name: "hiv_aids",
                    type: "checkbox",
                    label: "SIDA / VIH"
                },
                {
                    name: "stomach",
                    type: "checkbox",
                    label: "Estómago"
                },
                {
                    name: "asthma",
                    type: "checkbox",
                    label: "Asma"
                },
                {
                    name: "joints",
                    type: "checkbox",
                    label: "Articulaciones"
                },
                {
                    name: "cancer",
                    type: "checkbox",
                    label: "Cáncer"
                },
                {
                    name: "liver",
                    type: "checkbox",
                    label: "Hígado"
                },
                {
                    name: "bronchitis",
                    type: "checkbox",
                    label: "Bronquitis"
                },
                {
                    name: "muscles",
                    type: "checkbox",
                    label: "Músculos"
                },
                {
                    name: "leukemia",
                    type: "checkbox",
                    label: "Leucemia"
                },
                {
                    name: "intestines",
                    type: "checkbox",
                    label: "Intestinos"
                },
                {
                    name: "kidney_stones",
                    type: "checkbox",
                    label: "Piedras en el riñón"
                },
                {
                    name: "lupus",
                    type: "checkbox",
                    label: "Lupus"
                },
                {
                    name: "lymphoma",
                    type: "checkbox",
                    label: "Linfoma"
                },
                {
                    name: "pancreas",
                    type: "checkbox",
                    label: "Páncreas"
                },
                {
                    name: "nephritis",
                    type: "checkbox",
                    label: "Nefritis"
                },
                {
                    name: "high_blood_pressure",
                    type: "checkbox",
                    label: "Hipertensión arterial"
                },
                {
                    name: "anemia",
                    type: "checkbox",
                    label: "Anemia"
                },
                {
                    name: "hernias",
                    type: "checkbox",
                    label: "Hernias"
                },
                {
                    name: "epilepsy",
                    type: "checkbox",
                    label: "Epilepsia"
                },
                {
                    name: "heart_attack",
                    type: "checkbox",
                    label: "Infarto"
                },
                {
                    name: "thalassemia",
                    type: "checkbox",
                    label: "Talasemia"
                },
                {
                    name: "ulcers",
                    type: "checkbox",
                    label: "Úlceras"
                },
                {
                    name: "multiple_sclerosis",
                    type: "checkbox",
                    label: "Esclerosis múltiple"
                },
                {
                    name: "chest_pain",
                    type: "checkbox",
                    label: "Angina de pecho"
                },
                {
                    name: "prostate_disorders",
                    type: "checkbox",
                    label: "Alteraciones en la próstata"
                },
                {
                    name: "tumors",
                    type: "checkbox",
                    label: "Tumores"
                },
                {
                    name: "rheumatism",
                    type: "checkbox",
                    label: "Reumatismo"
                },
                {
                    name: "diabetes",
                    type: "checkbox",
                    label: "Diabetes"
                },
                {
                    name: "uterus_disorders",
                    type: "checkbox",
                    label: "Alteraciones en el útero"
                },
                {
                    name: "depression",
                    type: "checkbox",
                    label: "Depresión"
                },
                {
                    name: "deforming_arthritis",
                    type: "checkbox",
                    label: "Artritis deformativa"
                },
                {
                    name: "dengue",
                    type: "checkbox",
                    label: "Dengue"
                },
                {
                    name: "breast_disorders",
                    type: "checkbox",
                    label: "Alteraciones en las mamas"
                },
                {
                    name: "anxiety",
                    type: "checkbox",
                    label: "Ansiedad"
                },
                {
                    name: "spine",
                    type: "checkbox",
                    label: "Espina dorsal"
                },
                {
                    name: "chagas_disease",
                    type: "checkbox",
                    label: "Chagas"
                },
                {
                    name: "ovary_disorders",
                    type: "checkbox",
                    label: "Alteraciones en los ovarios"
                }
            ]
        },
        {
            name: "consume_drugs",
            type: "toggle",
            label: "¿Consumo de drogas y/o alcohol por la cual ha sido tratado y/o hospitalizado?",
            childrenDescription: "Si su respuesta es afirmativa, especifique",
            children: [
                {
                    name: "drug_type",
                    type: "radio",
                    radioOptions: [
                        {
                            id: "1",
                            name: "Alcohol"
                        },
                        {
                            id: "2",
                            name: "Drogas"
                        }
                    ]
                }
            ]
        },
        {
            name: "medical_visit",
            type: "toggle",
            label: "¿Ha consultado en los últimos 5 años a un médico por cualquier razón (excluyendo el embarazo) que exija en la actualidad o haya exigido un tratamiento o seguimiento médico?",
            childrenDescription: "Si su respuesta es afirmativa, especifique",
            children: [
                {
                    name: "affirmative_questions_medical_visit",
                    type: "text",
                    label: "",
                    helperText: "si su respuesta es afirmativa, especifique"
                }
            ]
        },
        {
            name: "pregnancy",
            type: "toggle",
            label: "¿Está embarazada?",
            childrenDescription: "Infique período de embarazo",
            children: [
                {
                    name: "pregnant_time",
                    type: "select",
                    selectOptions: [
                        {
                            id: 1,
                            name: "1 Mes"
                        },
                        {
                            id: 2,
                            name: "2 Meses"
                        },
                        {
                            id: 3,
                            name: "3 Meses"
                        }
                    ]
                }
            ]
        },
        {
            name: "other_disease",
            type: "toggle",
            label: "¿Otra enfermedad?",
            childrenDescription: "Si su respuesta es afirmativa, especifique",
            children: [
                {
                    name: "affirmative-questions-text",
                    type: "text",
                    label: "",
                    helperText: "si su respuesta es afirmativa, especifique"
                }
            ]
        }
      ]}
      filters= {props.filters}
      />
    </React.Fragment>
  );
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPutRol } from "../../store/auth/actions";
import { UserPutRolRequest } from "../../model/user";

export const useUserPutRole = (
  trigger: boolean,
  bodyRequest: UserPutRolRequest,
) => {
  const dispatch = useDispatch();

  const { responseUserPutRol, errorUserPutRol, loadingUserPutRol } =
    useSelector((state: any) => ({
      responseUserPutRol: state.Login.responseUserPutRol?.data,
      errorUserPutRol: state.Login.errorUserPutRol,
      loadingUserPutRol: state.Login.loadingUserPutRol,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(userPutRol(bodyRequest));
    }
  }, [bodyRequest, trigger]);

  return {
    responseUserPutRol,
    errorUserPutRol,
    loadingUserPutRol,
  };
};
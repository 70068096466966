import { FinalInformationView } from "./view";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  breadcrumb,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {navigateBack, navigateNext, stepProgress} from "../../../dataBase/stepProgress";
import {STEP_FINANCIALINFO} from "../../../const/catalog";
import { useTranslation } from "react-i18next";
import { Steps } from "../../../enum/steps";
import LocalStorageService from "../../../services/localStorage";

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);
const STEP = STEP_FINANCIALINFO;

export const FinancialInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
  }, []);

  const onClickSaveForm = (data: any) => {
    console.log("Data", data);
    localStorageService.setStepStored({
      id: "",
      name: "FinancialIformation",
      step: Steps.financialInformation,
      completed: true,
      financialInformation: data,
    });
  };

  const onNextStep = () => {
    navigateNext(STEP, navigate);
  };

  const onBackStep = () => {
    navigateBack(STEP, navigate);
  };

  return (
    <FinalInformationView
      breadCrumbs={breadCrumbs}
      steps={steps}
      t={t}
      onClickSaveForm={onClickSaveForm}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
    />
  );
};

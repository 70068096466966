import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import {
  IdentificationTypeRequest,
  IdentificationTypeResponse,
} from "../../model/identificationType";
import { getHeader } from "../headers";

const getClientId = () => {
  return (
    (
      JSON.parse(localStorage.getItem("afy-login") || "{}") as {
        clientId: string;
      }
    ).clientId || null
  );
};

const identificationTypeGet = async (
  enable: boolean,
  page: number,
  limit: number,
  description: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.identificationType +
    `?enable=${enable}&page=${page}&limit=${limit}&clientId=${getClientId()}` +
    (description ? `&description=${description}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as IdentificationTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const identificationTypeGetOne = async (id: string) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.identificationType + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const identificationTypePost = async (body: IdentificationTypeRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.identificationType;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({data: {
      "description": body.data.description,
      "code": body.data.code,
      "maxCharacter": Number(body.data.maxCharacter),
      "minCharacter": Number(body.data.minCharacter),
      "isNumeric": body.data.isNumeric,
    }}),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as IdentificationTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const identificationTypePatch = async (
  body: IdentificationTypeRequest,
  id: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.identificationType + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify( body ),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as IdentificationTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  identificationTypeGet,
  identificationTypeGetOne,
  identificationTypePost,
  identificationTypePatch,
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  postCollectionSuccess,
  postCollectionError
} from "./actions";
import { POST_COLLECTION} from "./actionTypes";
import { postCollectionApi} from "../../resources/helper/collection";



function* postCollection({ payload:  data  }) {
  try {
    const response = yield call(postCollectionApi, data);
    yield put(postCollectionSuccess(response));
  } catch (error) {
    yield put(postCollectionError(error));
  }
}

function* collectionSaga() {
  yield takeEvery(POST_COLLECTION, postCollection);
}

export default collectionSaga;

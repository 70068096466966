import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewSponsor } from "./view";
import { SponsorModel, SponsorRequest } from "../../model/sponsor";
import LocalStorageService from "../../services/localStorage";
import {
  patchSponsorClearData,
  postSponsorClearData,
} from "../../store/sponsor/actions";
import { clearDataPutAvatar, putAvatar } from "../../store/avatar/actions";
import {
  deleteEntity,
  deleteEntityClearData,
} from "../../store/delete/actions";
import { useSponsorGetAll } from "../../fnx/sponsor/sponsorGetAll";
import { useSponsorCreate } from "../../fnx/sponsor/sponsorCreate";
import { useSponsorPatch } from "../../fnx/sponsor/sponsorPatch";
import {
  CardProps,
  DataFormClient,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { sponsorDataForm } from "./sponsorDTO";
import { hasPermission } from "../../services/getNavigationPages";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const SponsorAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageSponsor, setPageSponsor] = useState<number>(1);
  const [sponsorLimit, setSponsorLimit] = useState<number>(20);
  const [countPaginationSponsor, setCountPaginationSponsor] =
    useState<number>(0);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [triggerPost, setTriggerPost] = useState<boolean>(false);
  const [triggerPatch, setTriggerPatch] = useState<boolean>(false);
  const [sponsorList, setSponsorList] = useState<CardProps[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [avatarFile, setAvatarFile] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [titleDrawer, setTitleDrawer] = useState<string>("");
  const [itemModal, setitemModal] = useState<any>(null);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<SponsorRequest>();
  const [dataForm, setDataForm] = useState<DataFormClient>(sponsorDataForm);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseSponsor, errorSponsor, loadingSponsor } = useSponsorGetAll(
    trigger,
    clientId,
    nameSearch,
    pageSponsor,
    sponsorLimit,
  );
  const { responsePostSponsor, errorPostSponsor, loadingPostSponsor } =
    useSponsorCreate(triggerPost, bodyRequest);

  const { responsePatchSponsor, errorPatchSponsor, loadingPatchSponsor } =
    useSponsorPatch(triggerPatch, bodyRequest);

  const { responsePutAvatar, errorPutAvatar, loadingPutAvatar } = useSelector(
    (state: any) => ({
      responsePutAvatar: state.Avatar.responsePutAvatar,
      errorPutAvatar: state.Avatar.errorPutAvatar,
      loadingPutAvatar: state.Avatar.loadingPutAvatar,
    }),
  );

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  useEffect(() => {
    if (!responseSponsor?.data) {
      setTrigger(true);
    }
  }, []);

  useEffect(() => {
    if (responseSponsor && responseSponsor?.data) {
      const newSponsorList: CardProps[] = responseSponsor.data.map(
        (item: SponsorModel) => ({
          id: item.id,
          title: item.name,
          code: item.code,
          logo: item.logoUrl,
          clientId: item.clientId,
        }),
      );
      setSponsorList(newSponsorList);
      setTrigger(false);
    }
    if (responseSponsor?.pagingInfo) {
      setCountPaginationSponsor(responseSponsor?.pagingInfo.totalItems);
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (responsePostSponsor && responsePostSponsor?.data) {
      showToastSuccessMessage(
        "Patrocinador creado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setTrigger(true);
      dispatch(postSponsorClearData());
      dispatch(putAvatar("Sponsor", responsePostSponsor.data.id, avatarFile));
      setAvatarFile(null);
      setTriggerPost(false);
    } else if (errorPostSponsor) {
      showErrorToast(errorPostSponsor, setSeeToast, t);
      setTriggerPost(false);
    }
  }, [responsePostSponsor, errorPostSponsor]);

  useEffect(() => {
    if (responsePutAvatar && responsePutAvatar?.url) {
      showToastSuccessMessage(
        "Patrocinador actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );

      dispatch(clearDataPutAvatar());
      setOpenModal(false);
      setTrigger(true);
    }
  }, [responsePutAvatar]);

  useEffect(() => {
    if (errorPutAvatar) {
      showErrorToast(errorPutAvatar, setSeeToast, t);
    }
  }, [errorPutAvatar]);

  useEffect(() => {
    if (responseDeleteEntity) {
      showToastSuccessMessage(
        "Patrocinador eliminado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (responsePatchSponsor && responsePatchSponsor.data) {
      setOpenDrawer(false);
      if (avatarFile != null) {
        dispatch(
          putAvatar("Sponsor", responsePatchSponsor.data.id, avatarFile),
        );
        setAvatarFile(null);
      } else {
        showToastSuccessMessage(
          "Patrocinador actualizado con éxito.",
          typeAlert.success,
          setSeeToast,
        );
      }
      setTrigger(true);
      setTriggerPatch(false);
      dispatch(patchSponsorClearData());
    } else if (errorPatchSponsor) {
      showErrorToast(errorPatchSponsor, setSeeToast, t);
      setTriggerPatch(false);
    }
  }, [responsePatchSponsor, errorPatchSponsor]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    const idSponsor = item?.sponsor.map((item: any) => item.id).join(",");
    setTrigger(true);
  };

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      id: row.id,
      SponsorName: row.title,
      Code: row.code,
      clientId: row.clientId,
    });
  };

  const permissions = {
    create: hasPermission("admin-sponsor-create"),
    edit: hasPermission("admin-sponsor-edit"),
    delete: hasPermission("admin-sponsor-delete"),
  };

  const handleSubmitSponsor = (dataSponsor: any, file: File) => {
    if (isUpdate) {
      setBodyRequest({
        id: dataSponsor.id,
        clientId: dataSponsor.clientId,
        name: dataSponsor.SponsorName,
        code: dataSponsor.Code,
      });
      setTriggerPatch(true);
      setAvatarFile(file);
    } else {
      setBodyRequest({
        name: dataSponsor.SponsorName,
        code: dataSponsor.Code,
      });
      setDataForm(sponsorDataForm);
      setOpenDrawer(false);
      setTriggerPost(true);
      setAvatarFile(file);
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setDataForm({
      SponsorName: "",
      Code: "",
      Img: "",
    });
    setTitleDrawer("Agregar patrocinador");
    setIsUpdate(false);
    setOpenDrawer(true);
  };

  const onClickContextMenu = (opt?: any, item?: any) => {
    switch (opt.id) {
      case "1":
        setIsUpdate(true);
        setTitleDrawer("Modificar patrocinador");
        setOpenDrawer(true);
        showEdit(item);
        break;
      case "2":
        setOpenModalDelete(true);
        setitemModal(item);
        break;
      case "3":
        setitemModal(item);
        setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    dispatch(
      deleteEntity({
        id: itemModal.id,
        entity: "SponsorEntity",
        idUser: clientId,
      }),
    );
  };

  const handleClone = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
    dispatch(postSponsorClearData());
    setAvatarFile(itemModal.logo);
  };

  const onCloseDrawer = () => {
    setDataForm({
      SponsorName: "",
      Code: "",
      Img: "",
    });
    setOpenDrawer(false);
    setTitleDrawer("");
  };

  const onChangeRowsPerPage = (e: number) => {
    setSponsorLimit(e);
    setTrigger(true);
  };

  const onChangePage = (e: number) => {
    setPageSponsor(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingSponsor ||
          loadingPostSponsor ||
          loadingPatchSponsor ||
          loadingDeleteEntity ||
          loadingPutAvatar
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingDeleteEntity ? "Eliminando sponsor" : "Cargando sponsor..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <ViewSponsor
        permissions={permissions}
        loadingPostSponsor={loadingPostSponsor}
        sponsorList={sponsorList}
        openModal={openModal}
        openModalCloneConfirm={openModalCloneConfirm}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        onClickContextMenu={onClickContextMenu}
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        filterStatus={filterStatus}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onSaveData={handleSubmitSponsor}
        onClickAdd={handleOnClickAdd}
        editMode={isUpdate}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        onCloseDrawer={onCloseDrawer}
        pageSponsor={pageSponsor}
        sponsorLimit={sponsorLimit}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        countPaginationSponsor={countPaginationSponsor}
        titleDrawer={titleDrawer}
      />
    </React.Fragment>
  );
};

export default SponsorAdmin;
import {
  CAMPAIGN,
  CAMPAIGN_CLEAR_DATA,
  CAMPAIGN_ERROR,
  CAMPAIGN_PATCH,
  CAMPAIGN_PATCH_CLEAR_DATA,
  CAMPAIGN_PATCH_ERROR,
  CAMPAIGN_PATCH_SUCCESS,
  CAMPAIGN_POST,
  CAMPAIGN_POST_CLEAR_DATA,
  CAMPAIGN_POST_ERROR,
  CAMPAIGN_POST_SUCCESS,
  CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ONE,
  GET_CAMPAIGN_ONE_CLEAR_DATA,
  GET_CAMPAIGN_ONE_ERROR,
  GET_CAMPAIGN_ONE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorCampaign: null,
  responseCampaign: null,
  loadingCampaign: false,

  responseCampaignPost: null,
  responseCampaignPostError: null,
  loadingCampaignPost: false,

  responseGetOneCampaign: null,
  errorGetOneCampaign: null,
  loadingGetOneCampaign: false,

  errorCampaignPatch: null,
  responseCampaignPatch: null,
  loadingCampaignPatch: false,
};

const Campaign = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN:
      state = {
        ...state,
        loadingCampaign: true,
        errorCampaign: null,
        responseCampaign: true,
      };
      break;
    case CAMPAIGN_SUCCESS:
      state = {
        ...state,
        errorCampaign: null,
        loadingCampaign: false,
        responseCampaign: action.payload,
      };
      break;
    case CAMPAIGN_ERROR:
      state = {
        ...state,
        errorCampaign: action.payload,
        loadingCampaign: false,
        responseCampaign: null,
      };
      break;
    case CAMPAIGN_CLEAR_DATA:
      state = {
        ...state,
        errorCampaign: null,
        loadingCampaign: null,
        responseCampaign: false,
      };
      break;
    //
    case CAMPAIGN_POST:
      state = {
        ...state,
        loadingCampaignPost: true,
        errorCampaignPost: null,
        responseCampaignPost: true,
      };
      break;
    case CAMPAIGN_POST_SUCCESS:
      state = {
        ...state,
        errorCampaignPost: null,
        loadingCampaignPost: false,
        responseCampaignPost: action.payload,
      };
      break;
    case CAMPAIGN_POST_ERROR:
      state = {
        ...state,
        errorCampaignPost: action.payload,
        loadingCampaignPost: false,
        responseCampaignPost: null,
      };
      break;
    case CAMPAIGN_POST_CLEAR_DATA:
      state = {
        ...state,
        errorCampaignPost: null,
        loadingCampaignPost: null,
        responseCampaignPost: false,
      };
      break;
    //
    case GET_CAMPAIGN_ONE:
      state = {
        ...state,
        loadingGetOneCampaign: true,
        errorGetOneCampaign: null,
        responseGetOneCampaign: null,
      };
      break;
    case GET_CAMPAIGN_ONE_SUCCESS:
      state = {
        ...state,
        errorGetOneCampaign: null,
        loadingGetOneCampaign: false,
        responseGetOneCampaign: action.payload,
      };
      break;
    case GET_CAMPAIGN_ONE_ERROR:
      state = {
        ...state,
        errorGetOneCampaign: action.payload,
        loadingGetOneCampaign: false,
        responseGetOneCampaign: null,
      };
      break;
    case GET_CAMPAIGN_ONE_CLEAR_DATA:
      state = {
        ...state,
        errorGetOneCampaign: null,
        loadingGetOneCampaign: null,
        responseGetOneCampaign: false,
      };
      break;
    //
    case CAMPAIGN_PATCH:
      state = {
        ...state,
        loadingCampaignPatch: true,
        errorCampaignPatch: null,
        responseCampaignPatch: null,
      };
      break;
    case CAMPAIGN_PATCH_SUCCESS:
      state = {
        ...state,
        errorCampaignPatch: null,
        loadingCampaignPatch: false,
        responseCampaignPatch: action.payload,
      };
      break;
    case CAMPAIGN_PATCH_ERROR:
      state = {
        ...state,
        errorCampaignPatch: action.payload,
        loadingCampaignPatch: false,
        responseCampaignPatch: null,
      };
      break;
    case CAMPAIGN_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorCampaignPatch: null,
        loadingCampaignPatch: null,
        responseCampaignPatch: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Campaign;

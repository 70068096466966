import styled from "styled-components";

export const ContainerTitles = styled.div`
  display: flex;
  align-items: center;
`;

export const Title_II = styled.div`
  margin: 0 0 7px 5px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: #696969;
`;

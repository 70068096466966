import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { CoverTypeRequest, CoverTypeResponse } from "../../model/coverType";
import { getHeader } from "../headers";

const coverTypeGet = async (enable: boolean, page: number, limit: number) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.coverType +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as CoverTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const coverTypeGetOne = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverType + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const coverTypePost = async (body: CoverTypeRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverType;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as CoverTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const coverTypePatch = async (body: CoverTypeRequest, id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.coverType + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as CoverTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

export { coverTypeGet, coverTypeGetOne, coverTypePost, coverTypePatch };

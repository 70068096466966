export const IDENTIFICATION_TYPE = "IDENTIFICATION_TYPE";
export const IDENTIFICATION_TYPE_SUCCESS = "IDENTIFICATION_TYPE_SUCCESS";
export const IDENTIFICATION_TYPE_ERROR = "IDENTIFICATION_TYPE_ERROR";
export const IDENTIFICATION_TYPE_CLEAR_DATA = "IDENTIFICATION_TYPE_CLEAR_DATA";

export const IDENTIFICATION_TYPE_GET_ONE = "IDENTIFICATION_TYPE_GET_ONE";
export const IDENTIFICATION_TYPE_GET_ONE_SUCCESS = "IDENTIFICATION_TYPE_GET_ONE_SUCCESS";
export const IDENTIFICATION_TYPE_GET_ONE_ERROR = "IDENTIFICATION_TYPE_GET_ONE_ERROR";
export const IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA = "IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA";

export const IDENTIFICATION_TYPE_POST = "IDENTIFICATION_TYPE_POST";
export const IDENTIFICATION_TYPE_POST_SUCCESS = "IDENTIFICATION_TYPE_POST_SUCCESS";
export const IDENTIFICATION_TYPE_POST_ERROR = "IDENTIFICATION_TYPE_POST_ERROR";
export const IDENTIFICATION_TYPE_POST_CLEAR_DATA = "IDENTIFICATION_TYPE_POST_CLEAR_DATA";

export const IDENTIFICATION_TYPE_PATCH = "IDENTIFICATION_TYPE_PATCH";
export const IDENTIFICATION_TYPE_PATCH_SUCCESS = "IDENTIFICATION_TYPE_PATCH_SUCCESS";
export const IDENTIFICATION_TYPE_PATCH_ERROR = "IDENTIFICATION_TYPE_PATCH_ERROR";
export const IDENTIFICATION_TYPE_PATCH_CLEAR_DATA = "IDENTIFICATION_TYPE_PATCH_CLEAR_DATA";
import {
  IDENTIFICATION_TYPE_CLEAR_DATA,
  IDENTIFICATION_TYPE,
  IDENTIFICATION_TYPE_ERROR,
  IDENTIFICATION_TYPE_SUCCESS,
  IDENTIFICATION_TYPE_GET_ONE,
  IDENTIFICATION_TYPE_GET_ONE_SUCCESS,
  IDENTIFICATION_TYPE_GET_ONE_ERROR,
  IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA,
  IDENTIFICATION_TYPE_POST,
  IDENTIFICATION_TYPE_POST_SUCCESS,
  IDENTIFICATION_TYPE_POST_ERROR,
  IDENTIFICATION_TYPE_POST_CLEAR_DATA,
  IDENTIFICATION_TYPE_PATCH,
  IDENTIFICATION_TYPE_PATCH_SUCCESS,
  IDENTIFICATION_TYPE_PATCH_ERROR,
  IDENTIFICATION_TYPE_PATCH_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorIdentificationType: null,
  responseIdentificationType: null,
  loadingIdentificationType: false,
  errorPostIdentificationType: null,
  responsePostIdentificationType: null,
  loadingPostIdentificationType: false,
  errorPatchIdentificationType: null,
  loadingPatchIdentificationType: null,
  responsePatchIdentificationType: false,
};

const identificationType = (state = initialState, action) => {
  switch (action.type) {
    case IDENTIFICATION_TYPE:
      state = {
        ...state,
        loadingIdentificationType: true,
        errorIdentificationType: null,
        responseIdentificationType: true,
      };
      break;
    case IDENTIFICATION_TYPE_SUCCESS:
      state = {
        ...state,
        errorIdentificationType: null,
        loadingIdentificationType: false,
        responseIdentificationType: action.payload,
      };
      break;
    case IDENTIFICATION_TYPE_ERROR:
      state = {
        ...state,
        errorIdentificationType: action.payload,
        loadingIdentificationType: false,
        responseIdentificationType: null,
      };
      break;
    case IDENTIFICATION_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorIdentificationType: null,
        loadingIdentificationType: null,
        responseIdentificationType: false,
      };
      break;
      case IDENTIFICATION_TYPE_GET_ONE:
        state = {
          ...state,
          loadingIdentificationType: true,
          errorIdentificationType: null,
          responseIdentificationType: null,
        };
        break;
      case IDENTIFICATION_TYPE_GET_ONE_SUCCESS:
        state = {
          ...state,
          errorIdentificationType: null,
          loadingIdentificationType: false,
          responseIdentificationType: action.payload,
        };
        break;
      case IDENTIFICATION_TYPE_GET_ONE_ERROR:
        state = {
          ...state,
          errorIdentificationType: action.payload,
          loadingIdentificationType: false,
          responseIdentificationType: null,
        };
        break;
      case IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA:
        state = {
          ...state,
          errorIdentificationType: null,
          loadingIdentificationType: null,
          responseIdentificationType: false,
        };
        break;      
    case IDENTIFICATION_TYPE_POST:
      state = {
        ...state,
        loadingPostIdentificationType: true,
        errorPostIdentificationType: null,
        responsePostIdentificationType: true,
      };
      break;
    case IDENTIFICATION_TYPE_POST_SUCCESS:
      state = {
        ...state,
        errorPostIdentificationType: null,
        loadingPostIdentificationType: false,
        responsePostIdentificationType: action.payload,
      };
      break;
    case IDENTIFICATION_TYPE_POST_ERROR:
      state = {
        ...state,
        errorPostIdentificationType: action.payload,
        loadingPostIdentificationType: false,
        responsePostIdentificationType: null,
      };
      break;
    case IDENTIFICATION_TYPE_POST_CLEAR_DATA:
      state = {
        ...state,
        errorPostIdentificationType: null,
        loadingPostIdentificationType: null,
        responsePostIdentificationType: false,
      };
      break;
    case IDENTIFICATION_TYPE_PATCH:
      state = {
        ...state,
        loadingPatchIdentificationType: true,
        errorPatchIdentificationType: null,
        responsePatchIdentificationType: true,
      };
      break;
    case IDENTIFICATION_TYPE_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchIdentificationType: null,
        loadingPatchIdentificationType: false,
        responsePatchIdentificationType: action.payload,
      };
      break;
    case IDENTIFICATION_TYPE_PATCH_ERROR:
      state = {
        ...state,
        errorPatchIdentificationType: action.payload,
        loadingPatchIdentificationType: false,
        responsePatchIdentificationType: null,
      };
      break;
    case IDENTIFICATION_TYPE_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchIdentificationType: null,
        loadingPatchIdentificationType: null,
        responsePatchIdentificationType: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default identificationType;

import { call, put, takeEvery } from "redux-saga/effects";
import { successPutAvatar, errorPutAvatar } from "./actions";
import { PUT_AVATAR } from "./actionTypes";
import { putAvatar } from "../../resources/helper/avatar";

function* addAvatar({ payload: { entity, id, image } }) {
  try {
    const response = yield call(putAvatar, entity, id, image);
    yield put(successPutAvatar(response));
  } catch (error) {
    yield put(errorPutAvatar(error));
  }
}

function* avatarSaga() {
  yield takeEvery(PUT_AVATAR, addAvatar);
}

export default avatarSaga;
import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  AdminGeneralCatalogAFY,
  ButtonAFY,
  CardGeneralCatalogProps,
  icons,
  MenuItem,
  ModalComponentAFY,
  select,
  sizes,
  sizesIcon,
  sizesSelect,
  variants,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions } from "./data";
import { generalCatalogView } from "../generalCatalog/data";
import { SelectChangeEvent } from "@mui/material";

export const View = (props: {
  title: string;
  list: CardGeneralCatalogProps[];
  openModal: boolean;
  openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  onSaveData?: any;
  loadingPost: any;
  dataForm: any;
  modalTitle: string;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitClone: () => void;

  onClose: () => void;
  onCloseConfirm: () => void;
  onCloseCloneModal: () => void;

  handleOpenModal: () => void;
  handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  onChangeSelect: (event: SelectChangeEvent<string>) => void;
  onClickContextMenu: (event: MenuItem, card: CardGeneralCatalogProps) => void;

  onClickAdd: () => void;
  onClickCancel: () => void;
  onFilterApply: (value: any) => void;
  onFilterEmpty: (value: any) => void;
  generalCatalogTitle: string;
  selectValueDefault: string;
  menuItem?: MenuItem[];
  optionsSelect?: select[];
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        modalWidth={490}
        border={0}
      >
        {/*<FormBranchesAFY*/}
        {/*  isOpenModal*/}
        {/*  onClickCancel={props.onClickCancel}*/}
        {/*  onSaveData={props.onSaveData}*/}
        {/*  branchesTitle={props.generalCatalogTitle}*/}
        {/*  dataForm={props.dataForm}*/}
        {/*/>*/}
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseConfirm}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Eliminar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Eliminar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={props.onCloseConfirm}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, cancelar"}
                backgroundColor="red"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModalCloneConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseCloneModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Clonar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea Clonar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={props.onCloseCloneModal}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, Clonar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmitClone();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <AdminGeneralCatalogAFY
        avatarVariant={variantsAvatar.rounded}
        backgroundColorSearch={"#F6F7FB"}
        colorClientLabel={"#3A56A1"}
        title={props.title}
        viewList={false}
        heightAddNew={"136px"}
        heightAddNewList={"68px"}
        heightCard={"136px"}
        heightCardList={"68px"}
        insurersView={generalCatalogView}
        buttonActionOptions={buttonActionOptions}
        data={props.list}
        iconBackgroundColor="#ffffff"
        textColor="#3A56A1"
        onClickAdd={props.onClickAdd}
        onClickButtonView={(option: MenuItem) => {
          props.clickView && props.clickView(option);
        }}
        menuItem={props.menuItem}
        onClickContextMenu={props.onClickContextMenu}
        fontSizeSelect={14}
        titleSearchButton={"Buscar"}
        titleClearButton={"Limpiar filtros"}
        clientLabel={"Filtrar"}
        selectSize={sizesSelect.small}
        selectVariant={variantsTextField.outlined}
        iconSearch={icons.Search}
        iconAvatar={icons.Search}
        iconBgColor={"#F6F7FB"}
        iconColor={"#3A56A1"}
        iconColorSearch={"#3A56A1"}
        sizesIconSearch={sizesIcon.standard}
        optionsSelect={props.optionsSelect}
        onFilterApply={props.onFilterApply}
        textBackSearch={"Volver"}
        textFilterSearch={"Buscar"}
        textFilterSearchDrop={"Buscar"}
        sizeIcon={sizesIcon.standard}
        titleColor={"#3A56A1"}
        onFilterEmpty={props.onFilterEmpty}
        onChangeSelect={props.onChangeSelect}
        selectedValueDefault={props.selectValueDefault}
      />
    </>
  );
};
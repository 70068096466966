export const ACCOUNT_TYPE = "ACCOUNT_TYPE";
export const ACCOUNT_TYPE_SUCCESS = "ACCOUNT_TYPE_SUCCESS";
export const ACCOUNT_TYPE_ERROR = "ACCOUNT_TYPE_ERROR";
export const ACCOUNT_TYPE_CLEAR_DATA = "ACCOUNT_TYPE_CLEAR_DATA";

export const ACCOUNT_TYPE_POST = "ACCOUNT_TYPE_POST";
export const ACCOUNT_TYPE_POST_SUCCESS = "ACCOUNT_TYPE_POST_SUCCESS";
export const ACCOUNT_TYPE_POST_ERROR = "ACCOUNT_TYPE_POST_ERROR";
export const ACCOUNT_TYPE_POST_CLEAR_DATA = "ACCOUNT_TYPE_POST_CLEAR_DATA";

export const ACCOUNT_TYPE_PATCH = "ACCOUNT_TYPE_PATCH";

export const ACCOUNT_TYPE_PATCH_SUCCESS = "ACCOUNT_TYPE_PATCH_SUCCESS";

export const ACCOUNT_TYPE_PATCH_ERROR = "ACCOUNT_TYPE_PATCH_ERROR";

export const ACCOUNT_TYPE_PATCH_CLEAR_DATA = "ACCOUNT_TYPE_PATCH_CLEAR_DATA";

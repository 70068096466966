import { URLs } from "../url";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const documentsStatisticsAPI = async () => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.quote}/Documents/Statistics`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { documentsStatisticsAPI };

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { personById } from "../../store/person/actions";

export const GetPersonById = (trigger: boolean, personId?: string) => {
  const dispatch = useDispatch();

  const { responsePersonById, errorPersonById, loadingPersonById } =
    useSelector((state: any) => ({
      responsePersonById: state.Person.responsePersonById,
      errorPersonById: state.Person.errorPersonById,
      loadingPersonById: state.Person.loadingPersonById,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(personById(personId));
    }
  }, [trigger, personId]);

  return {
    responsePersonById,
    errorPersonById,
    loadingPersonById,
  };
};

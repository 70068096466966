import {
  PLAN_BENEFIT,
  PLAN_BENEFIT_CLEAR_DATA,
  PLAN_BENEFIT_ERROR,
  PLAN_BENEFIT_SUCCESS,
  PLAN_BENEFIT_CREATE,
  PLAN_BENEFIT_CREATE_SUCCESS,
  PLAN_BENEFIT_CREATE_ERROR,
  PLAN_BENEFIT_DELETE,
  PLAN_BENEFIT_DELETE_ERROR,
  PLAN_BENEFIT_DELETE_SUCCESS,
  PLAN_BENEFIT_UPDATE,
  PLAN_BENEFIT_UPDATE_ERROR,
  PLAN_BENEFIT_UPDATE_SUCCESS,

} from "./actionTypes";

const initialState = {
  errorPlanBenefit: null,
  responsePlanBenefit: null,
  loadingPlanBenefit: false,
  errorPlanBenefitCreate: null,
  responsePlanBenefitCreate: null,
  loadingPlanBenefitCreate: false,
  errorPlanBenefitDelete: null,
  responsePlanBenefitDelete: null,
  loadingPlanBenefitDelete: false,
  errorPlanBenefitUpdate: null,
  responsePlanBenefitUpdate: null,
  loadingPlanBenefitUpdate: false,
};

const PlanBenefit = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_BENEFIT:
      state = {
        ...state,
        loadingPlanBenefit: true,
        errorPlanBenefit: null,
        responsePlanBenefit: true,
      };
      break;
    case PLAN_BENEFIT_SUCCESS:
      state = {
        ...state,
        errorPlanBenefit: null,
        loadingPlanBenefit: false,
        responsePlanBenefit: action.payload,
      };
      break;
    case PLAN_BENEFIT_ERROR:
      state = {
        ...state,
        errorPlanBenefit: action.payload,
        loadingPlanBenefit: false,
        responsePlanBenefit: null,
      };
      break;
    case PLAN_BENEFIT_CLEAR_DATA:
      state = {
        ...state,
        errorPlanBenefit: null,
        loadingPlanBenefit: null,
        responsePlanBenefit: false,
      };
      break;
    case PLAN_BENEFIT_CREATE:
      state = {
        ...state,
        loadingPlanBenefitCreate: true,
        errorPlanBenefitCreate: null,
        responsePlanBenefitCreate: true,
      };
      break;
    case PLAN_BENEFIT_CREATE_SUCCESS:
      state = {
        ...state,
        errorPlanBenefitCreate: null,
        loadingPlanBenefitCreate: false,
        responsePlanBenefitCreate: action.payload,
      };
      break;
    case PLAN_BENEFIT_CREATE_ERROR:
      state = {
        ...state,
        errorPlanBenefitCreate: action.payload,
        loadingPlanBenefitCreate: false,
        responsePlanBenefitCreate: null,
      };
      break;
    case PLAN_BENEFIT_DELETE:
      state = {
        ...state,
        loadingPlanBenefitDelete: true,
        errorPlanBenefitDelete: null,
        responsePlanBenefitDelete: true,
      };
      break;
    case PLAN_BENEFIT_DELETE_SUCCESS:
      state = {
        ...state,
        errorPlanBenefitDelete: null,
        loadingPlanBenefitDelete: false,
        responsePlanBenefitDelete: action.payload,
      };
      break;
    case PLAN_BENEFIT_DELETE_ERROR:
      state = {
        ...state,
        errorPlanBenefitDelete: action.payload,
        loadingPlanBenefitDelete: false,
        responsePlanBenefitDelete: null,
      };
      break;
    case PLAN_BENEFIT_UPDATE:
      state = {
        ...state,
        loadingPlanBenefitUpdate: true,
        errorPlanBenefitUpdate: null,
        responsePlanBenefitUpdate: true,
      };
      break;
    case PLAN_BENEFIT_UPDATE_SUCCESS:
      state = {
        ...state,
        errorPlanBenefitUpdate: null,
        loadingPlanBenefitUpdate: false,
        responsePlanBenefitUpdate: action.payload,
      };
      break;
    case PLAN_BENEFIT_UPDATE_ERROR:
      state = {
        ...state,
        errorPlanBenefitUpdate: action.payload,
        loadingPlanBenefitUpdate: false,
        responsePlanBenefitUpdate: null,
      };
      break;
      
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PlanBenefit;

import React, { useEffect } from "react";
import {
  breadcrumb,
  MenuItem,
  MenuStatus,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { breadcrumDTO } from "../../dataBase/layout";
import { View } from "./view";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import {
  GetMenuSideBarAdministration,
  GetMenuSideBarHome,
  GetMenuSideBarReport,
} from "../../services/nav";
import { NavigateStructure } from "../../interface/nav";
import { projectClearData } from "../../store/project/actions";
import { quoteAllClearData } from "../../store/generateQuote/actions";
import { pageActionFlagsClearData } from "../../store/pageActionFlags/actions";
import { GetDocumentsStatistics } from "../../fnx/documentsStatistics/getDocumentsStatistics";
import { getDocumentsStatisticsClearData } from "../../store/documentStatistics/actions";
import { useDispatch } from "react-redux";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const Layout = ({ children }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [rolMenu, setRolMenu] = React.useState<string | null>(null);
  const storageKeyMh = "afy-menuh";
  const lsMH = new LocalStorageService(storageKeyMh);
  const storageKeyMs = "afy-menus";
  const lsMS = new LocalStorageService(storageKeyMs);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [showSideBar, setShowSideBar] = React.useState<null | boolean>(true);
  const [countBadge, setCountBadge] = React.useState<number>(0);
  /*const storageKeySp = "afy-steps";
                      const lsSP = new LocalStorageService(storageKeySp);*/
  const [menuHorizontal, setMenuHorizontal] = React.useState<MenuItem[]>(
    lsMH.getMenuHorizontalStored(),
  );
  const [menuSideBar, setMenuSideBar] = React.useState<NavigateStructure[]>(
    lsMS.getMenuSideBarStored(),
  );

  const { responseDocumentStatistics } = GetDocumentsStatistics(trigger);

  useEffect(() => {
    setBreadCrumbs(breadcrumDTO());
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (responseDocumentStatistics?.statuses) {
      const pendingStatus = responseDocumentStatistics.statuses.find(
        (status: any) => status?.status === "PENDING",
      );
      setCountBadge(Number(pendingStatus.count));
      dispatch(getDocumentsStatisticsClearData());
    }
  }, [responseDocumentStatistics]);

  const changeStatusSideBar = (status: MenuStatus) => {
    setShowSideBar(!status.status);
  };

  const setActiveMenuHorizontal = (item: MenuItem) => {
    localStorage.removeItem("afy-menus");
    localStorage.removeItem("afy-action-flags");
    localStorage.removeItem("afy-steps");
    localStorage.removeItem("afy-variables");

    const updatedMenuH = menuHorizontal.map((menuItem) => {
      if (menuItem.id === item.id) {
        return { ...menuItem, active: true };
      }
      return { ...menuItem, active: false };
    });

    switch (item.id) {
      case "inicio":
        setRolMenu(null);
        navigate("/");
        lsMS.setMenuSideBarStored(GetMenuSideBarHome());
        break;
      case "admin":
        setRolMenu("admin");
        navigate("/sponsor-admin");
        lsMS.setMenuSideBarStored(GetMenuSideBarAdministration());
        break;
      case "reporte":
        setRolMenu(null);
        navigate("/report");
        lsMS.setMenuSideBarStored(GetMenuSideBarReport());
        break;

      default:
        break;
    }

    let st = lsMS.getMenuSideBarStored();
    console.log("st", st);

    setMenuSideBar(st);
    setMenuHorizontal(updatedMenuH);
    lsMH.setMenuHorizontalStored(updatedMenuH);
  };

  const setActiveMenuSideBar = (item: NavigateStructure) => {
    const updatedMenuS = menuSideBar.map((menuItem) => {
      if (menuItem.id === item.id) {
        return { ...menuItem, active: true };
      }
      return { ...menuItem, active: false };
    });
    setMenuSideBar(updatedMenuS);
    lsMS.setMenuSideBarStored(updatedMenuS);
  };

  const onClickSideBar = (value: NavigateStructure) => {
    projectClearData();
    quoteAllClearData();
    pageActionFlagsClearData();
    navigate(value.route);
    localStorage.removeItem("afy-action-flags");
    localStorage.removeItem("afy-steps");
    localStorage.removeItem("afy-variables");
  };

  const onClickBadge = () => {
    navigate("/pending-info-page/");
  };

  return (
    <View
      onClickSideBar={onClickSideBar}
      onClickStatus={changeStatusSideBar}
      onClickMenu={setActiveMenuHorizontal}
      showSideBar={showSideBar}
      children={children}
      menus={menuHorizontal}
      menusSB={menuSideBar}
      breadCrumbs={breadCrumbs}
      setActiveMenuSideBar={setActiveMenuSideBar}
      rolMenu={rolMenu}
      onClickBadge={onClickBadge}
      countBadge={countBadge}
    />
  );
};

export const POLICY = "POLICY";
export const POLICY_CREATE = "POLICY_CREATE";
export const POLICY_CREATE_SUCCESS = "POLICY_CREATE_SUCCESS";
export const POLICY_SUCCESS = "POLICY_SUCCESS";
export const POLICY_ERROR = "POLICY_ERROR";
export const POLICY_CREATE_ERROR = "POLICY_CREATE_ERROR";
export const POLICY_CREATE_CLEAR_DATA = "POLICY_CREATE_CLEAR_DATA";
export const POLICY_CLEAR_DATA = "POLICY_CLEAR_DATA";
export const POLICY_ONE = "POLICY_ONE";
export const POLICY_ONE_SUCCESS = "POLICY_ONE_SUCCESS";
export const POLICY_ONE_ERROR = "POLICY_ONE_ERROR";
export const POLICY_ONE_CLEAR_DATA = "POLICY_ONE_CLEAR_DATA";
export const POLICY_CONFIRM = "POLICY_CONFIRM";
export const POLICY_CONFIRM_SUCCESS = "POLICY_CONFIRM_SUCCESS";
export const POLICY_CONFIRM_ERROR = "POLICY_CONFIRM_ERROR";
export const POLICY_CONFIRM_CLEAR_DATA = "POLICY_CONFIRM_CLEAR_DATA";
export const POLICY_CANCEL = "POLICY_CANCEL";
export const POLICY_CANCEL_SUCCESS = "POLICY_CANCEL_SUCCESS";
export const POLICY_CANCEL_ERROR = "POLICY_CANCEL_ERROR";
export const POLICY_CANCEL_CLEAR_DATA = "POLICY_CANCEL_CLEAR_DATA";

import {
  breadcrumb,
  CardProps,
  icons,
  InformationProps,
  insuranceFilterProps,
  listTypeCardProps,
  pages,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { TFunction } from "i18next";

export const insuranceStorybook = (t: TFunction) => ({
  titlePage: t("product.title"),
  viewType: "2",
  titleMetaTag: t("product.titleMetaTag"),
  informationPage: {
    backgroundColor: themeCore.colors.background,
    colorText: themeCore.colors.secondaryText,
    description: t("product.info"),
    icon: icons.Help,
    iconSize: sizesIcon.standard,
    iconColor: themeCore.colors.primary,
  },
  filter: {
    title: t("product.filter.title"),
    titleFirstChip: t("product.filter.titleFirstChip"),
    backgroundColor: themeCore.colors.third,
    bgChipInsurers: themeCore.colors.third,
    bgChipInsurersSelected: themeCore.colors.text,
    colorIconChip: themeCore.colors.primary,
    colorText: themeCore.colors.primary,
    bgColorIconButton: themeCore.colors.text,
    colorIconButton: themeCore.colors.primary,
    bgColorButton: themeCore.colors.third,
    bgColorIconButtonSelected: themeCore.colors.primary,
    colorIconButtonSelected: themeCore.colors.third,
    insurers: [],
  },
  data: [],
});

export interface InsurancePageProps {
  titlePage: string;
  titleMetaTag: string;
  informationPage: InformationProps;
  filter: insuranceFilterProps;
  data?: CardProps[];
  listCard?: listTypeCardProps;
  card?: CardProps;
  breadCrumbs?: breadcrumb[];
  viewType: string;
  pages?: pages.seguro;
}

export interface ResponseInsurance {
  titlePage: string;
  titleMetaTag: string;
  informationPage: InformationProps;
  filter: insuranceFilterProps;
  data?: CardProps[];
  listCard?: listTypeCardProps;
  card?: CardProps;
  breadCrumbs?: breadcrumb[];
  viewType: string;
  pages?: pages.seguro;
}

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "producto",
    ariaCurrent: "",
  },
];

import {
  DELETE_ENTITY,
  DELETE_ENTITY_CLEAR_DATA,
  DELETE_ENTITY_ERROR,
  DELETE_ENTITY_SUCCESS,
} from "./actionTypes";

export const deleteEntity = (deleteEntity, history) => {
  return {
    type: DELETE_ENTITY,
    payload: { deleteEntity, history },
  };
};

export const deleteEntitySuccess = (success) => {
  return {
    type: DELETE_ENTITY_SUCCESS,
    payload: success,
  };
};

export const deleteEntityError = (error) => {
  return {
    type: DELETE_ENTITY_ERROR,
    payload: error,
  };
};

export const deleteEntityClearData = () => {
  return {
    type: DELETE_ENTITY_CLEAR_DATA,
    payload: {},
  };
};

export const CATALOG_PAYMENT_MODE = "CATALOG_PAYMENT_MODE";
export const CATALOG_STATUS_POLICY = "CATALOG_STATUS_POLICY";
export const CATALOG_CIVIL_STATUS = "CATALOG_CIVIL_STATUS";
export const CATALOG_BENEFICIARY_TYPE = "CATALOG_BENEFICIARY_TYPE";
export const CATALOG_INSURED_TYPE = "CATALOG_INSURED_TYPE";
export const CATALOG_REASON_CANCELLATION = "CATALOG_REASON_CANCELLATION";
export const CATALOG_SEXO = "CATALOG_SEXO";
export const CATALOG_STATUS_QUOTE = "CATALOG_STATUS_QUOTE";
export const CATALOG_ACCOUNT_TYPE = "CATALOG_ACCOUNT_TYPE";

export const STEP_INSURANCE = "insurance";
export const STEP_PLAN = "plan";
export const STEP_AUDIT = "auditPage";
export const STEP_PAYMENTMETHOD = "paymentMethod";
export const STEP_PAYMENTCONFIRMATION = "paymentConfirmation";
export const STEP_CLIENTRECORD = "clientRecord";
export const STEP_CUSTOMERFORM = "customerForm";
export const STEP_CONFIRMATIONFORM = "confirmationForm";
export const STEP_VEHICLEDETAILS = "VehicleDetails";
export const STEP_PLANDETAILS = "planDetails";
export const STEP_APPLICANTDECLARATION = "applicantDeclaration";
export const STEP_INITIALINFO = "initialInfo";
export const STEP_FINANCIALINFO = "financialInfo";
export const STEP_MEDICALINFORMATION = "medicalInfo";

export const PLAN = "PLAN";
export const PLAN_SUCCESS = "PLAN_SUCCESS";
export const PLAN_ERROR = "PLAN_ERROR";
export const PLAN_CLEAR_DATA = "PLAN_CLEAR_DATA";
export const CATALOG_GENDER = "CATALOG_GENDER";
export const CATALOG_GENDER_SUCCESS = "CATALOG_GENDER_SUCCESS";
export const CATALOG_GENDER_ERROR = "CATALOG_GENDER_ERROR";
export const CATALOG_ACCOUNT_TYPE = "CATALOG_ACCOUNT_TYPE";
export const CATALOG_ACCOUNT_TYPE_SUCCESS = "CATALOG_ACCOUNT_TYPE_SUCCESS";
export const CATALOG_ACCOUNT_TYPE_ERROR = "CATALOG_ACCOUNT_TYPE_ERROR";
export const CATALOG_ACCOUNT_TYPE_CLEAR_DATA =
  "CATALOG_ACCOUNT_TYPE_CLEAR_DATA";
export const PLAN_POST = "PLAN_POST";
export const PLAN_POST_SUCCESS = "PLAN_POST_SUCCESS";
export const PLAN_POST_ERROR = "PLAN_POST_ERROR";
export const PLAN_PUT = "PLAN_PUT";
export const PLAN_PUT_SUCCESS = "PLAN_PUT_SUCCESS";
export const PLAN_PUT_ERROR = "PLAN_PUT_ERROR";

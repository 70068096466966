import { call, put, takeEvery } from "redux-saga/effects";
import {
  campaignOneError,
  campaignOneSuccess,
  campaignPatchError,
  campaignPatchSuccess,
  campaignPostError,
  campaignPostSuccess,
} from "./actions";
import {
  getCampaignById,
  patchCampaignApi,
  postCampaignApi,
} from "../../resources/helper/campaign";
import { CAMPAIGN_PATCH, CAMPAIGN_POST, GET_CAMPAIGN_ONE } from "./actionTypes";

function* postCampaign({ payload: { campaign, history } }) {
  try {
    const response = yield call(postCampaignApi, campaign);
    yield put(campaignPostSuccess(response));
  } catch (error) {
    yield put(campaignPostError(error));
  }
}

function* campaignPatch({ payload: { campaignBody, id } }) {
  try {
    const response = yield call(patchCampaignApi, campaignBody, id);
    yield put(campaignPatchSuccess(response));
  } catch (error) {
    yield put(campaignPatchError(error));
  }
}

function* getOneCampaign({ payload: { campaignId } }) {
  try {
    const response = yield call(getCampaignById, campaignId.id);
    yield put(campaignOneSuccess(response));
  } catch (error) {
    yield put(campaignOneError(error));
  }
}

function* campaignPostSaga() {
  yield takeEvery(CAMPAIGN_POST, postCampaign);
  yield takeEvery(CAMPAIGN_PATCH, campaignPatch);
  yield takeEvery(GET_CAMPAIGN_ONE, getOneCampaign);
}

export default campaignPostSaga;

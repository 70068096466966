import { call, put, takeEvery } from "redux-saga/effects";
import {
  insurancesError,
  insurancesOneSuccess,
  insurancesSuccess,
  postInsurancesError,
  postInsurancesSuccess,
  patchInsurancesError,
  patchInsurancesSuccess,
} from "./actions";
import { POST_INSURANCES, INSURANCES, INSURANCES_ONE, PATCH_INSURANCES } from "./actionTypes";
import { getInsurances, postInsurancesApi, patchInsurancesApi, getInsurancesIdApi } from "../../resources/helper/insurances";

function* insurances({ payload: { insurances } }) {
  try {
    const response = yield call(
      getInsurances,
      insurances.enable,
      insurances.page,
      insurances.limit,
    );
    yield put(insurancesSuccess(response));
  } catch (error) {
    yield put(insurancesError(error));
  }
}


function* oneInsurances({ payload: { insurances } }) {
  try {
    const response = yield call(getInsurancesIdApi, insurances.id);
    yield put(insurancesOneSuccess(response));
  } catch (error) {
    yield put(insurancesError(error));
  }
}

function* postInsurances({ payload: { insurancesBody } }) {
  try {
    const response = yield call(postInsurancesApi, insurancesBody);
    yield put(postInsurancesSuccess(response));
  } catch (error) {
    yield put(postInsurancesError(error));
  }
}

function* patchInsurances({ payload: { insurancesBody } }) {
  try {
    const response = yield call(patchInsurancesApi, insurancesBody);
    yield put(patchInsurancesSuccess(response));
  } catch (error) {
    yield put(patchInsurancesError(error));
  }
}

function* insurancesSaga() {
  yield takeEvery(INSURANCES, insurances);
  yield takeEvery(INSURANCES_ONE, oneInsurances);
  yield takeEvery(POST_INSURANCES, postInsurances);
  yield takeEvery(PATCH_INSURANCES, patchInsurances);
}

export default insurancesSaga;

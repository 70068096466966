import React, { useEffect } from "react";
import {
  AlertAFY,
  ButtonAFY,
  DataFormClient,
  FormBenefitAFY,
  FormPrimeAFY,
  FormsPlansAFY,
  IconAFY,
  icons,
  select,
  severity,
  sizes,
  sizesIcon,
  variants,
  variantsAlert,
  ModalComponentAFY,
  ContextMenuAFY
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../../components/layout/style";
import {
  Container,
  ContainerAlert,
  ContainerIcon,
  ContainerTitle,
} from "./style";
import { themeCore } from "../../../assets/themes/theme";




export const PlanCreationView = (props: {
  permissions: any;
  currentTab: number;
  scenarios?: any;
  onClickAddBenefits: (data: any) => void;
  onClickAddPrimes: (data: any) => void;
  coverageTypeList: select[];
  handleSubmitPlan: (data: any) => void;
  showAlert?: boolean;
  setShowAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  panelActive?: number;
  autoClose?: number;
  autoCloseActive?: boolean;
  seeButtonAddPlans?: boolean;
  activateEditMode?: boolean;
  showButtonsActions?: boolean;
  onClickBack?: () => void;
  showCancelButton?: boolean;
  idPlan?: string;
  isEdit?: string;
  openModalBenefit?: boolean;
  onCloseModalBenefit?: () => void;
  openModalPrime?: boolean;
  onCloseModalPrime?: () => void;
  setShowCancelButton: (value: boolean | (() => boolean)) => void;
  planData?: DataFormClient;
  dataFormBenefits?: any;
  benefitsData?: any;
  disableBenefits?: boolean;
  disablePrimes?: boolean;
  onSaveBenefit: (data: any) => void;
  onSavePrime: (data: any) => void;
  handleClickContextMenu: (item: any, row: any, type: string) => void;
  coverageType?: select[];
  paymentModeList?: select[];
  dataFormPrimes?: any;
  onUpdateCoverTypeSelected: (data: any) => void;
  coverageTypesPrime?: any;
}) => {

  const convertToSelectedItems = () => {
    let items: string[] = [];
    let result = "";
    if ((props.coverageTypesPrime?.length ?? 0) > 0) {

      props.coverageTypesPrime?.map((item:any) => {
        items.push(item.name ?? "");
      });
      result = items.join(", ");
      return result;
    }
  };

    return (
      <React.Fragment>

        <ModalComponentAFY
          openModal={props.openModalBenefit}
          backgroundColor="#ffffff"
          onClose={props.onCloseModalBenefit}
          modalWidth={490}
          border={0}
        >
          <FormBenefitAFY
            title={"Información del beneficio"}
            dataFormBenefits={props.dataFormBenefits}
            onClickCancel={props.onCloseModalBenefit}
            onNext={props.onSaveBenefit}

          />
        </ModalComponentAFY>
        <ModalComponentAFY
          openModal={props.openModalPrime}
          backgroundColor="#ffffff"
          onClose={props.onCloseModalPrime}
          modalWidth={490}
          border={0}
        >
          <FormPrimeAFY
            title={"Información de la prima"}
            TypeList={props.paymentModeList}
            CoverTypeList={props.coverageTypesPrime}
            dataFormPrimes={props.dataFormPrimes}
            onClickCancel={props.onCloseModalPrime}
            onNext={props.onSavePrime}

          />
        </ModalComponentAFY>

        <ContainerAlert>
          <Container>
            <ButtonAFY
              variant={variants.text}
              label={"Regresar"}
              icon={icons.ArrowBackIosNew}
              size={sizes.small}
              color={themeCore.colors.primaryText}
              onClick={props.onClickBack}
            />
          </Container>
          <div style={{ marginTop: "15px", marginBottom: "-15px" }}>
            <AlertAFY
              title=""
              isOpen={props.showAlert}
              setAutoClose={props.autoCloseActive}
              autoClose={props.autoClose}
              description={""}
              iconClose={icons.Close}
              iconCloseColor="#fff"
              onClose={() => {
                props.setShowAlert?.(false);
              }}
              severity={severity.info}
              variant={variantsAlert.filled}
              backgroundColor={"#637985"}
              iconColor={"#FFF"}
              spacing={0}
              textColor={"#fff"}
              width={"100%"}
            />
          </div>
        </ContainerAlert>
        <ContainerTitle>
          <Title>Planes</Title>
          {props.idPlan && props.isEdit !== "true" && (
            <ContainerIcon>
              <IconAFY
                icon={icons.Visibility}
                size={sizesIcon.medium}
                color={"#3A56A1"}
              />
            </ContainerIcon>
          )}
        </ContainerTitle>

        <FormsPlansAFY
          handleChangeTab={(e: any, tab: number) => { localStorage.setItem("tab", tab.toString()); }}
          permissions={props.permissions}
          coverageTypes={props.coverageType}
          onChangeSelect={props.onUpdateCoverTypeSelected}
          onNext={props.handleSubmitPlan}
          onSendPlanForm={props.handleSubmitPlan}
          onSendPrimes={props.handleSubmitPlan}
          onSendBenefits={props.handleSubmitPlan}
          onClickAddBenefits={props.onClickAddBenefits}
          onClickAddPrimes={props.onClickAddPrimes}
          labelButtonAddPlan={"Agregar planes"}
          labelButtonAdd={"Guardar plan"}
          labelButtonAddBenefits={"Agregar beneficios"}
          labelButtonAddPrimes={"Agregar primas"}
          titleButtonAdd={"Agregar"}
          titleButtonCancel={"Cancelar"}
          benefitsTitle={"Beneficios"}
          primesTitle={"Tabla de primas"}
          textLegend={"Para crear un beneficio, primero es necesario crear un plan."}
          panelActive={props.panelActive}
          disableBenefits={props.disableBenefits}
          disablePrimes={props.disablePrimes}
          activateEditMode={props.activateEditMode}
          showCancelButton={props.activateEditMode}
          formsCompleted={[true]}
          showButtonsActions={true}
          seeButtonAddPlans={false}
          showButtonAddBenefits={true}
          showButtonAddPrimes={true}
          selectedItems={convertToSelectedItems()}
          textLegendColor={"#bcbcbc"}


          //\
          typeList={props.coverageTypeList}

          columnsBenefits={[
            {
              field: "type",

              headerName: "Acciones",
              width: 100,
              renderCell: (params: any) => (
                <div style={{ display: "flex", gap: "10px" }}>

                  <ContextMenuAFY
                    colorButton="#fff"
                    iconButton={icons.MoreVert}
                    iconColor="#3A56A1"
                    menuItem={[
                      {
                        disabled: false,
                        icon: {
                          color: "#006ac6",
                          icon: icons.Edit,
                          size: sizesIcon.standard,
                        },
                        id: "edit",
                        name: "Editar",
                      },
                      {
                        disabled: false,
                        icon: {
                          color: "#006ac6",
                          icon: icons.DeleteForever,
                          size: sizesIcon.standard,
                        },
                        id: "delete",
                        name: "Eliminar",
                      }
                    ]}
                    onClickContextMenu={(item: any) =>
                      props.handleClickContextMenu(item, params.row, "benefits")
                    }
                    sizeIcon={sizesIcon.standard}
                  />

                </div>
              )
            },
            {
              field: "description",
              headerName: "Descripción",
              flex: 1,
              width: 150,
            },
            {
              field: "value",
              headerName: "Valor",
              flex: 1,
              width: 150,
            },
          ]}
          rowsBenefits={props.benefitsData}
          columnsPrimes={[
            {
              field: "type",
              flex: 1,
              headerName: "Acciones",
              width: 150,
              renderCell: (params: any) => (
                <div style={{ display: "flex", gap: "10px" }}>

                  <ContextMenuAFY
                    colorButton="#fff"
                    iconButton={icons.MoreVert}
                    iconColor="#3A56A1"
                    menuItem={[
                      {
                        disabled: false,
                        icon: {
                          color: "#006ac6",
                          icon: icons.Edit,
                          size: sizesIcon.standard,
                        },
                        id: "edit",
                        name: "Editar",
                      },
                      {
                        disabled: false,
                        icon: {
                          color: "#006ac6",
                          icon: icons.DeleteForever,
                          size: sizesIcon.standard,
                        },
                        id: "delete",
                        name: "Eliminar",
                      }
                    ]}
                    onClickContextMenu={(item: any) =>
                      props.handleClickContextMenu(item, params.row, "primes")
                    }
                    sizeIcon={sizesIcon.standard}
                  />

                </div>
              )
            },
            {
              field: "minAge",
              headerName: "Edad mínima",
              flex: 1,
              width: 150,
            },
            {
              field: "maxAge",
              headerName: "Edad máxima",
              flex: 1,
              width: 150,
            },
            {
              field: "paymentMode",
              headerName: "Periodicidad",
              flex: 1,
              width: 150,

            },
            {
              field: "coverType",
              headerName: "Tipo de cobertura",
              flex: 1,
              width: 150,

            },
            {
              field: "price",
              headerName: "Prima",
              flex: 1,
              width: 150,
            },

          ]}
          rowsPrimes={props.scenarios}

          paymentModeOption={[]}
          dataPlanCreationForm={props.planData}

        />
      </React.Fragment>
    );
  };


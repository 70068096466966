import {
  PREMIUM_EVAL_OPTION,
  PREMIUM_EVAL_OPTION_SUCCESS,
  PREMIUM_EVAL_OPTION_ERROR,
  PREMIUM_EVAL_OPTION_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_GET_ONE,
  PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS,
  PREMIUM_EVAL_OPTION_GET_ONE_ERROR,
  PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_POST,
  PREMIUM_EVAL_OPTION_POST_SUCCESS,
  PREMIUM_EVAL_OPTION_POST_ERROR,
  PREMIUM_EVAL_OPTION_POST_CLEAR_DATA,
  PREMIUM_EVAL_OPTION_PATCH,
  PREMIUM_EVAL_OPTION_PATCH_SUCCESS,
  PREMIUM_EVAL_OPTION_PATCH_ERROR,
  PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorPremiumEvalOption: null,
  responsePremiumEvalOption: null,
  loadingPremiumEvalOption: false,
  errorPostPremiumEvalOption: null,
  responsePostPremiumEvalOption: null,
  loadingPostPremiumEvalOption: false,
  errorPatchPremiumEvalOption: null,
  loadingPatchPremiumEvalOption: null,
  responsePatchPremiumEvalOption: false,
};

const premiumEvalOption = (state = initialState, action) => {
  switch (action.type) {
    case PREMIUM_EVAL_OPTION:
      state = {
        ...state,
        loadingPremiumEvalOption: true,
        errorPremiumEvalOption: null,
        responsePremiumEvalOption: true,
      };
      break;
    case PREMIUM_EVAL_OPTION_SUCCESS:
      state = {
        ...state,
        errorPremiumEvalOption: null,
        loadingPremiumEvalOption: false,
        responsePremiumEvalOption: action.payload,
      };
      break;
    case PREMIUM_EVAL_OPTION_ERROR:
      state = {
        ...state,
        errorPremiumEvalOption: action.payload,
        loadingPremiumEvalOption: false,
        responsePremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_CLEAR_DATA:
      state = {
        ...state,
        errorPremiumEvalOption: null,
        loadingPremiumEvalOption: null,
        responsePremiumEvalOption: false,
      };
      break;
      case PREMIUM_EVAL_OPTION_GET_ONE:
        state = {
          ...state,
          loadingPremiumEvalOption: true,
          errorPremiumEvalOption: null,
          responsePremiumEvalOption: null,
        };
        break;
      case PREMIUM_EVAL_OPTION_GET_ONE_SUCCESS:
        state = {
          ...state,
          errorPremiumEvalOption: null,
          loadingPremiumEvalOption: false,
          responsePremiumEvalOption: action.payload,
        };
        break;
      case PREMIUM_EVAL_OPTION_GET_ONE_ERROR:
        state = {
          ...state,
          errorPremiumEvalOption: action.payload,
          loadingPremiumEvalOption: false,
          responsePremiumEvalOption: null,
        };
        break;
      case PREMIUM_EVAL_OPTION_GET_ONE_CLEAR_DATA:
        state = {
          ...state,
          errorPremiumEvalOption: null,
          loadingPremiumEvalOption: null,
          responsePremiumEvalOption: null,
        };
        break;      
    case PREMIUM_EVAL_OPTION_POST:
      state = {
        ...state,
        loadingPostPremiumEvalOption: true,
        errorPostPremiumEvalOption: null,
        responsePostPremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_POST_SUCCESS:
      state = {
        ...state,
        errorPostPremiumEvalOption: null,
        loadingPostPremiumEvalOption: false,
        responsePostPremiumEvalOption: action.payload,
      };
      break;
    case PREMIUM_EVAL_OPTION_POST_ERROR:
      state = {
        ...state,
        errorPostPremiumEvalOption: action.payload,
        loadingPostPremiumEvalOption: false,
        responsePostPremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_POST_CLEAR_DATA:
      state = {
        ...state,
        errorPostPremiumEvalOption: null,
        loadingPostPremiumEvalOption: null,
        responsePostPremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_PATCH:
      state = {
        ...state,
        loadingPatchPremiumEvalOption: true,
        errorPatchPremiumEvalOption: null,
        responsePatchPremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchPremiumEvalOption: null,
        loadingPatchPremiumEvalOption: false,
        responsePatchPremiumEvalOption: action.payload,
      };
      break;
    case PREMIUM_EVAL_OPTION_PATCH_ERROR:
      state = {
        ...state,
        errorPatchPremiumEvalOption: action.payload,
        loadingPatchPremiumEvalOption: false,
        responsePatchPremiumEvalOption: null,
      };
      break;
    case PREMIUM_EVAL_OPTION_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchPremiumEvalOption: null,
        loadingPatchPremiumEvalOption: null,
        responsePatchPremiumEvalOption: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default premiumEvalOption;

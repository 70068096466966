import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogues } from "../../store/catalogue/actions";

export const useCataloguesGetAll = (
  trigger: boolean,
  type?: string,
  clientId?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responseCatalogues, errorCatalogues, loadingCatalogues } =
    useSelector((state: any) => ({
      responseCatalogues: state.Catalogues.responseCatalogues?.data,
      errorCatalogues: state.Catalogues.errorCatalogues,
      loadingCatalogues: state.Catalogues.loadingCatalogues,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCatalogues({
          enable: enable,
          page: page,
          limit: limit,
          type: type,
          clientId: clientId,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseCatalogues,
    errorCatalogues,
    loadingCatalogues,
    setEnable,
    setPage,
    setLimit,
  };
};

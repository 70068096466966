export const CAMPAIGNPROJECT = "CAMPAIGNPROJECT";
export const CAMPAIGNPROJECT_SUCCESS = "CAMPAIGNPROJECT_SUCCESS";
export const CAMPAIGNPROJECT_ERROR = "CAMPAIGNPROJECT_ERROR";
export const CAMPAIGNPROJECT_CLEAR_DATA = "CAMPAIGNPROJECT_CLEAR_DATA";

export const CAMPAIGNPROJECT_POST = "CAMPAIGNPROJECT_POST";
export const CAMPAIGNPROJECT_POST_SUCCESS = "CAMPAIGNPROJECT_POST_SUCCESS";
export const CAMPAIGNPROJECT_POST_ERROR = "CAMPAIGNPROJECT_POST_ERROR";
export const CAMPAIGNPROJECT_POST_CLEAR_DATA = "CAMPAIGNPROJECT_POST_CLEAR_DATA";


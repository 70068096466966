import {
  PLAN_BENEFIT,
  PLAN_BENEFIT_CLEAR_DATA,
  PLAN_BENEFIT_ERROR,
  PLAN_BENEFIT_SUCCESS,
  PLAN_BENEFIT_UPDATE,
  PLAN_BENEFIT_UPDATE_SUCCESS,
  PLAN_BENEFIT_UPDATE_ERROR,
  PLAN_BENEFIT_DELETE,
  PLAN_BENEFIT_DELETE_SUCCESS,
  PLAN_BENEFIT_DELETE_ERROR,
  PLAN_BENEFIT_CREATE,
  PLAN_BENEFIT_CREATE_SUCCESS,
  PLAN_BENEFIT_CREATE_ERROR,
} from "./actionTypes";

export const planBenefit = (planBenefit, history) => {
  return {
    type: PLAN_BENEFIT,
    payload: { planBenefit, history },
  };
};

export const planBenefitSuccess = (success) => {
  return {
    type: PLAN_BENEFIT_SUCCESS,
    payload: success,
  };
};

export const planBenefitError = (error) => {
  return {
    type: PLAN_BENEFIT_ERROR,
    payload: error,
  };
};

export const planBenefitClearData = () => {
  return {
    type: PLAN_BENEFIT_CLEAR_DATA,
    payload: {},
  };
};

export const planBenefitUpdate = (planBenefit, id, history) => {
  return {
    type: PLAN_BENEFIT_UPDATE,
    payload: { planBenefit, id, history },
  };
};

export const planBenefitUpdateSuccess = (success) => {
  return {
    type: PLAN_BENEFIT_UPDATE_SUCCESS,
    payload: success,
  };
};

export const planBenefitUpdateError = (error) => {
  return {
    type: PLAN_BENEFIT_UPDATE_ERROR,
    payload: error,
  };
}

export const planBenefitDelete = (id, history) => {
  return {
    type: PLAN_BENEFIT_DELETE,
    payload: { id, history },
  };
}

export const planBenefitDeleteSuccess = (success) => {
  return {
    type: PLAN_BENEFIT_DELETE_SUCCESS,
    payload: success,
  };
}

export const planBenefitDeleteError = (error) => {
  return {
    type: PLAN_BENEFIT_DELETE_ERROR,
    payload: error,
  };
}

export const planBenefitCreate = (planBenefit, history) => {
  return {
    type: PLAN_BENEFIT_CREATE,
    payload: { planBenefit, history },
  };
}

export const planBenefitCreateSuccess = (success) => {
  return {
    type: PLAN_BENEFIT_CREATE_SUCCESS,
    payload: success,
  };
}

export const planBenefitCreateError = (error) => {
  return {
    type: PLAN_BENEFIT_CREATE_ERROR,
    payload: error,
  };
}


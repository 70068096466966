import { call, put, takeEvery } from "redux-saga/effects";
import { 
	insuranceTypeError,
	insuranceTypeOneSuccess,	
	insuranceTypeSuccess,
	postInsuranceTypeError,
	postInsuranceTypeSuccess,
	patchInsuranceTypeError,
	patchInsuranceTypeSuccess,	
} from "./actions";
import { POST_INSURANCE_TYPE, INSURANCE_TYPE, INSURANCE_TYPE_ONE, PATCH_INSURANCE_TYPE } from "./actionTypes";
import { getInsuranceTypeApi, postInsuranceTypeApi, patchInsuranceTypeApi, getInsuranceTypeIdApi } from "../../resources/helper/insurancesType";

function* insuranceType({ payload: { insuranceType } }) {
  try {
    const response = yield call(
      getInsuranceTypeApi,
      insuranceType.enable,
      insuranceType.page,
      insuranceType.limit,
      insuranceType.clientId,
      insuranceType.description,
    );
    yield put(insuranceTypeSuccess(response));
  } catch (error) {
    yield put(insuranceTypeError(error));
  }
}

function* oneInsuranceType({ payload: { insuranceType } }) {
  try {
    const response = yield call(getInsuranceTypeIdApi, insuranceType.id);
    yield put(insuranceTypeOneSuccess(response));
  } catch (error) {
    yield put(insuranceTypeError(error));
  }
}

function* postInsuranceType({ payload: { body } }) {
  try {
    const response = yield call(postInsuranceTypeApi, body);
    yield put(postInsuranceTypeSuccess(response));
  } catch (error) {
    yield put(postInsuranceTypeError(error));
  }
}

function* patchInsuranceType({ payload: { body, id } }) {
  try {
    const response = yield call(patchInsuranceTypeApi, body, id);
    yield put(patchInsuranceTypeSuccess(response));
  } catch (error) {
    yield put(patchInsuranceTypeError(error));
  }
}

function* insuranceTypeSaga() {
  yield takeEvery(INSURANCE_TYPE, insuranceType);
  yield takeEvery(INSURANCE_TYPE_ONE, oneInsuranceType);
  yield takeEvery(POST_INSURANCE_TYPE, postInsuranceType);
  yield takeEvery(PATCH_INSURANCE_TYPE, patchInsuranceType);
}

export default insuranceTypeSaga;

import { call, put, takeEvery } from "redux-saga/effects";
import { PERMISSIONS } from "./actionTypes";
import { permissionError, permissionSuccess } from "./actions";
import { getPermission } from "../../resources/helper/permission";

function* permission({ payload: { permission } }) {
  try {
    const response = yield call(
      getPermission,
      permission.clientId,
      permission.enable,
      permission.page,
      permission.limit,
      permission.name,
      permission.description,
    );
    yield put(permissionSuccess(response));
  } catch (error) {
    yield put(permissionError(error));
  }
}
function* permissionSaga() {
  yield takeEvery(PERMISSIONS, permission);
}

export default permissionSaga;
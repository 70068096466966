import {
  PATCH_QUOTE,
  PATCH_QUOTE_CLEAR_DATA,
  PATCH_QUOTE_ERROR,
  PATCH_QUOTE_SUCCESS,
  UPDATE_QUOTE,
  UPDATE_QUOTE_CLEAR_DATA,
  UPDATE_QUOTE_ERROR,
  UPDATE_QUOTE_SUCCESS,
} from "./actionTypes";

export const updateQuote = (quote, id) => {
  return {
    type: UPDATE_QUOTE,
    payload: { quote, id },
  };
};

export const updateQuoteSuccess = (success) => {
  return {
    type: UPDATE_QUOTE_SUCCESS,
    payload: success,
  };
};

export const updateQuoteError = (error) => {
  return {
    type: UPDATE_QUOTE_ERROR,
    payload: error,
  };
};

export const updateQuoteClearData = () => {
  return {
    type: UPDATE_QUOTE_CLEAR_DATA,
    payload: {},
  };
};

export const patchQuote = (quote, id) => {
  return {
    type: PATCH_QUOTE,
    payload: { quote, id },
  };
};

export const patchQuoteSuccess = (success) => {
  return {
    type: PATCH_QUOTE_SUCCESS,
    payload: success,
  };
};

export const patchQuoteError = (error) => {
  return {
    type: PATCH_QUOTE_ERROR,
    payload: error,
  };
};

export const patchQuoteClearData = () => {
  return {
    type: PATCH_QUOTE_CLEAR_DATA,
    payload: {},
  };
};
import {
  ButtonAFY,
  ModalComponentAFY,
  sizes,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { ButtonsContainer, Description, Title, useStyles } from "../style";

export const ModalDelete = (props: any) => {
  const classes = useStyles();
  return (
    <ModalComponentAFY
      openModal={props.openModalConfirmDelete}
      backgroundColor="#ffffff"
      onClose={() => {
        props.setOpenModalConfirmDelete(false);
      }}
      border={0}
      minWidthModal={"500px"}
      padding={30}
    >
      <div style={{ marginTop: "10px" }}>
        <Title>Eliminar {props.itemCard?.title}</Title>
        <Description>
          ¿Está seguro que desea eliminar{" "}
          <strong>{props.itemCard?.title}</strong>?
        </Description>
        <ButtonsContainer>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"No"}
              onClick={() => {
                props.setOpenModalConfirmDelete(false);
              }}
            />
          </div>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"Si, eliminar"}
              backgroundColor="red"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                props.onClickDelete(props.itemCard);
              }}
            />
          </div>
        </ButtonsContainer>
      </div>
    </ModalComponentAFY>
  );
};

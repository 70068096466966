import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectType } from "../../store/projectType/actions";

export const useProjectTypeGetAll = (trigger: boolean, clientId?: string) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responseProjectType, errorProjectType, loadingProjectType } =
    useSelector((state: any) => ({
      responseProjectType: state.ProjectType.responseProjectType?.data,
      errorProjectType: state.ProjectType.errorProjectType,
      loadingProjectType: state.ProjectType.loadingProjectType,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        projectType({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseProjectType,
    errorProjectType,
    loadingProjectType,
    setEnable,
    setPage,
    setLimit,
  };
};

import { URLs } from "../url";
import { getHeaderUpload } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const uploadQuoteAttachmentsAPI = async (
  id: string,
  fileName: string,
  pdfFile: File,
  code: string,
) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.quote}/${id}/Attachments`;
  const data = new FormData();
  data.append("file", pdfFile);
  data.append("name", fileName);
  data.append("code", code);

  const requestOptions = {
    method: "POST",
    body: data,
    headers: getHeaderUpload(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { uploadQuoteAttachmentsAPI };

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActionFlagsApi } from "../../store/pageActionFlags/actions";

export const GetActionFlags = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  projectId: string,
) => {
  const dispatch = useDispatch();

  const {
    responsePageActionFlags,
    loadingPageActionFlags,
    errorPageActionFlags,
  } = useSelector((state: any) => ({
    responsePageActionFlags:
      state.PageActionFlags.responsePageActionFlags?.data,
    loadingPageActionFlags: state.PageActionFlags.loadingPageActionFlags,
    errorPageActionFlags: state.PageActionFlags.errorPageActionFlags,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        pageActionFlagsApi({
          enable,
          page,
          limit,
          projectId: projectId,
        }),
      );
    }
  }, [trigger, enable, page, limit, projectId]);

  return {
    responsePageActionFlags,
    loadingPageActionFlags,
    errorPageActionFlags,
  };
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogPaymentMode } from "../../store/catalog/actions";

export const GetCatalogPaymentMode = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const dispatch = useDispatch();

  const {
    responseGetCatalogPaymentMode,
    loadingGetCatalogPaymentMode,
    errorGetCatalogPaymentMode,
  } = useSelector((state: any) => ({
    responseGetCatalogPaymentMode:
      state.Catalog.responseGetCatalogPaymentMode?.data,
    loadingGetCatalogPaymentMode: state.Catalog.loadingGetCatalogPaymentMode,
    errorGetCatalogPaymentMode: state.Catalog.errorGetCatalogPaymentMode,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCatalogPaymentMode({
          enable,
          page,
          limit,
          type: "CATALOG_PAYMENT_MODE",
          clientId,
        }),
      );
    }
  }, [trigger, enable, page, limit, clientId]);

  return {
    responseGetCatalogPaymentMode,
    loadingGetCatalogPaymentMode,
    errorGetCatalogPaymentMode,
  };
};

export const BENEFIT = "BENEFIT";
export const BENEFIT_SUCCESS = "BENEFIT_SUCCESS";
export const BENEFIT_ERROR = "BENEFIT_ERROR";
export const BENEFIT_CLEAR_DATA = "BENEFIT_CLEAR_DATA";

export const GET_BENEFICIARY = "GET_BENEFICIARY";
export const GET_BENEFICIARY_SUCCESS = "GET_BENEFICIARY_SUCCESS";
export const GET_BENEFICIARY_ERROR = "GET_BENEFICIARY_ERROR";
export const GET_BENEFICIARY_CLEAR_DATA = "GET_BENEFICIARY_CLEAR_DATA";

export const BENEFICIARY_PATCH = "BENEFICIARY_PATCH";
export const BENEFICIARY_PATCH_SUCCESS = "BENEFICIARY_PATCH_SUCCESS";
export const BENEFICIARY_PATCH_ERROR = "BENEFICIARY_PATCH_ERROR";
export const BENEFICIARY_PATCH_CLEAR_DATA = "BENEFICIARY_PATCH_CLEAR_DATA";

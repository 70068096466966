import LocalStorageService from "../services/localStorage";
import { Steps } from "../enum/steps";

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

export const getCustomerAction = () => {
  const customerAuto = ["apap-auto", "bancounion", "fdd"];
  return {
    customerAuto,
  };
};

export const isApapAuto = (subdomain:any) => {
  return  getCustomerAction().customerAuto.includes(subdomain) ? true : false;
}

export const isCustomer = (customer: string) => {
  const result =
  localStorageService
    .getStepStored()
    .steps[Steps.insurance]?.flow?.metadata?.navigation?.productType === customer 
    ? true
    : false;
  return result;
}
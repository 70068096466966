import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentModeAction } from "../../store/paymentModes/action";

export const GetPaymentMode = (trigger: boolean, projectId: string) => {
  const dispatch = useDispatch();
  const [enable] = useState<boolean>(true);
  const [page] = useState<number>(1);
  const [limit] = useState<number>(100);

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    useSelector((state: any) => ({
      responsePaymentMode: state.PaymentMode.responsePaymentMode?.data,
      errorPaymentMode: state.PaymentMode.errorPaymentMode,
      loadingPaymentMode: state.PaymentMode.loadingPaymentMode,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        paymentModeAction({
          enable: enable,
          page,
          limit,
          projectId: projectId,
          deleted: false,
        }),
      );
    }
  }, [trigger]);

  return {
    responsePaymentMode,
    errorPaymentMode,
    loadingPaymentMode,
  };
};

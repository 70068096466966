import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { GridColDef } from "@mui/x-data-grid";

export const dataChip: MenuItem[] = [
  {
    active: true,
    id: "1",
    name: "Mes actual",
    code: "currentMonth",
  },
  {
    active: false,
    id: "2",
    name: "Mes anterior",
    code: "prevMonth",
  },
  {
    active: false,
    id: "3",
    name: "Trimestre actual",
    code: "currentQuarter",
  },
  {
    active: false,
    id: "4",
    name: "Trimestre anterior",
    code: "prevQuarter",
  },
  {
    active: false,
    id: "5",
    name: "Semestre actual",
    code: "currentSemester",
  },
];

export const Columns: GridColDef[] = [
  {
    field: "id",
    flex: 1,
    headerName: "ID",
  },
  {
    field: "agent",
    flex: 1,
    headerName: "Agentes",
  },
  {
    field: "sales",
    flex: 1,
    headerName: "Ventas",
  },
];

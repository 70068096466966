import {
  breadcrumb,
  DataFormClient,
  MenuItem,
  state,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "producto",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Validar cliente",
    key: "cliente",
    ariaCurrent: "",
  },
  {
    name: "Cofirmación cliente",
    key: "confirmacion",
    ariaCurrent: "",
  },
  {
    name: "Pago",
    key: "pago",
    ariaCurrent: "",
  },
  {
    name: "Cofirmación pago",
    key: "confirmacion",
    ariaCurrent: "",
  },
];

export const stepProgress: MenuItem[] = [
  {
    id: "1",
    name: "Nombre del producto",
    state: state.complete,
  },
  {
    id: "2",
    name: "Tipo de plan",
    state: state.complete,
  },
  {
    id: "3",
    name: "Datos del cliente",
    state: state.complete,
  },
  {
    id: "4",
    name: "Confirmación",
    state: state.complete,
  },
  {
    id: "5",
    name: "Datos de pago",
    state: state.complete,
  },
  {
    id: "6",
    name: "Confirmación de pago",
    state: state.complete,
  },
];

export const getDataFormFilters = (
  dataForm: DataFormClient & { Insurance?: string } & { CoverType?: string } & {
    PaymentMode?: string;
  } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
) => {
  return [
    {
      label: "Datos personales",
      type: "subtitle",
    },
    {
      label: `${dataForm?.Name}`,
      name: "Nombre",
      type: "label",
    },
    {
      label: `${dataForm.LastNames}`,
      name: "Apellidos",
      type: "label",
    },

    {
      label: `${dataForm.DocumentNumber?.replace(
        /(\d{3})(\d{7})(\d{1})/,
        "$1-$2-$3"
      )}`,
      name: "No. de documento",
      type: "label",
    },
    {
      label: "Datos de seguro",
      type: "subtitle",
    },
    {
      label: `${dataForm.Insurance}`,
      name: "Producto",
      type: "label",
    },
    {
      label: `${dataForm.PlanTypeName}`,
      name: "Plan",
      type: "label",
    },
    {
      label: `${dataForm.CampaignName}`,
      name: "Campaña",
      type: "label",
    },
    {
      label: `${dataForm.CoverType}`,
      name: "Tipo de cobertura",
      type: "label",
    },
    {
      label: `${dataForm.PaymentMode}`,
      name: "Modalidad de pago",
      type: "label",
    },
    {
      label: `${dataForm.Currency}`,
      name: "Moneda",
      type: "label",
    },
    {
      label: `${dataForm.Prima}`,
      name: "Prima",
      type: "label",
    },
  ];
};

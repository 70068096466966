import { tr } from "date-fns/locale";
import {
  REGION,
  REGION_SUCCESS,
  REGION_ERROR,
  REGION_CLEAR_DATA,
  REGION_GET_ONE,
  REGION_GET_ONE_SUCCESS,
  REGION_GET_ONE_ERROR,
  REGION_GET_ONE_CLEAR_DATA,
  REGION_POST,
  REGION_POST_SUCCESS,
  REGION_POST_ERROR,
  REGION_POST_CLEAR_DATA,
  REGION_PATCH,
  REGION_PATCH_SUCCESS,
  REGION_PATCH_ERROR,
  REGION_PATCH_CLEAR_DATA,
  } from "./actionTypes";

const initialState = {
  responseRegion: null,
  loadingRegion: false,
  responseRegionOne: null,
  loadingRegionOne: false,
  errorRegion: null,
  errorPostRegion: null,
  errorPatchRegion: null,
  errorRegionOne: null,
  responsePostRegion: null,
  loadingPostRegion: false,
  errorPatchRegion: null,
  loadingPatchRegion: null,
  responsePatchRegion: false,
};

const Region = (state = initialState, action) => {
  switch (action.type) {
    case REGION:
      state = {
        ...state,
        loadingRegion: true,
        errorRegion: null,
        responseRegion: true,
      };
      break;
    case REGION_SUCCESS:
      state = {
        ...state,
        loadingRegion: false,
        responseRegion: action.payload,
      };
      break;
    case REGION_ERROR:
      state = {
        ...state,
        loadingRegion: false,
        errorRegion: action.payload,
      };
      break;
    case REGION_CLEAR_DATA:
      state = {
        ...state,
        responseRegion: null,
        errorRegion: null,
      };
      break;
    case REGION_GET_ONE:
      state = {
        ...state,
        loadingRegionOne: true,
        errorRegionOne: null,
      };
      break;
    case REGION_GET_ONE_SUCCESS:
      state = {
        ...state,
        loadingRegionOne: false,
        responseRegionOne: action.payload,
      };
      break;
    case REGION_GET_ONE_ERROR:
      state = {
        ...state,
        loadingRegionOne: false,
        errorRegionOne: action.payload,
      };
      break;
    case REGION_GET_ONE_CLEAR_DATA:
      state = {
        ...state,
        responseRegionOne: null,
        errorRegionOne: null,
      };
      break;
    case REGION_POST:
      state = {
        ...state,
        loadingPostRegion: true,
        errorPostRegion: null,
        responsePostRegion: true,
      };
      break;
    case REGION_POST_SUCCESS:
      state = {
        ...state,
        errorPostRegion: null,
        loadingPostRegion: false,
        responsePostRegion: action.payload,
      };
      break;
    case REGION_POST_ERROR:
      state = {
        ...state,
        errorPostRegion: action.payload,
        loadingPostRegion: false,
        responsePostRegion: null,
      };
      break;
    case REGION_POST_CLEAR_DATA:
      state = {
        ...state,
        errorPostRegion: null,
        loadingPostRegion: null,
        responsePostRegion: null,
      };
      break;
    case REGION_PATCH:
      state = {
        ...state,
        loadingPatchRegion: true,
        errorPatchRegion: null,
        responsePatchRegion: true,
      };
      break;
    case REGION_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchRegion: null,
        loadingPatchRegion: false,
        responsePatchRegion: action.payload,
      };
      break;
    case REGION_PATCH_ERROR:
      state = {
        ...state,
        errorPatchRegion: action.payload,
        loadingPatchRegion: false,
        responsePatchRegion: null,
      };
      break;
    case REGION_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchRegion: null,
        loadingPatchRegion: null,
        responsePatchRegion: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Region;


import {
  PERMISSION_ERROR,
  PERMISSION_SUCCESS,
  PERMISSIONS,
} from "./actionTypes";

export const permissions = (permission, history) => {
  return {
    type: PERMISSIONS,
    payload: { permission, history },
  };
};

export const permissionSuccess = (success) => {
  return {
    type: PERMISSION_SUCCESS,
    payload: success,
  };
};

export const permissionError = (error) => {
  return {
    type: PERMISSION_ERROR,
    payload: error,
  };
};
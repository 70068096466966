import {
  PROJECT,
  PROJECT_CLEAR_DATA,
  PROJECT_ERROR,
  PROJECT_ONE,
  PROJECT_ONE_CLEAR_DATA,
  PROJECT_ONE_ERROR,
  PROJECT_ONE_SUCCESS,
  PROJECT_PATCH,
  PROJECT_PATCH_CLEAR_DATA,
  PROJECT_PATCH_ERROR,
  PROJECT_PATCH_SUCCESS,
  PROJECT_POST,
  PROJECT_POST_CLEAR_DATA,
  PROJECT_POST_ERROR,
  PROJECT_POST_SUCCESS,
  PROJECT_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorProject: null,
  responseProject: null,
  loadingProject: false,

  responseProjectPost: null,
  responseProjectPostError: null,
  loadingProjectPost: false,

  errorOneProject: null,
  responseOneProject: null,
  loadingOneProject: false,

  errorProjectPatch: null,
  responseProjectPatch: null,
  loadingProjectPatch: false,
};

const Project = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT:
      state = {
        ...state,
        loadingProject: true,
        errorProject: null,
        responseProject: null,
      };
      break;
    case PROJECT_SUCCESS:
      state = {
        ...state,
        errorProject: null,
        loadingProject: false,
        responseProject: action.payload,
      };
      break;
    case PROJECT_ERROR:
      state = {
        ...state,
        errorProject: action.payload,
        loadingProject: false,
        responseProject: null,
      };
      break;
    case PROJECT_CLEAR_DATA:
      state = {
        ...state,
        errorProject: null,
        loadingProject: null,
        responseProject: false,
      };
      break;
    //
    case PROJECT_POST:
      state = {
        ...state,
        loadingProjectPost: true,
        errorProjectPost: null,
        responseProjectPost: true,
      };
      break;
    case PROJECT_POST_SUCCESS:
      state = {
        ...state,
        errorProjectPost: null,
        loadingProjectPost: false,
        responseProjectPost: action.payload,
      };
      break;
    case PROJECT_POST_ERROR:
      state = {
        ...state,
        errorProjectPost: action.payload,
        loadingProjectPost: false,
        responseProjectPost: null,
      };
      break;
    case PROJECT_POST_CLEAR_DATA:
      state = {
        ...state,
        errorProjectPost: null,
        loadingProjectPost: null,
        responseProjectPost: false,
      };
      break;

    //
    case PROJECT_PATCH:
      state = {
        ...state,
        loadingProjectPatch: true,
        errorProjectPatch: null,
        responseProjectPatch: true,
      };
      break;
    case PROJECT_PATCH_SUCCESS:
      state = {
        ...state,
        errorProjectPatch: null,
        loadingProjectPatch: false,
        responseProjectPatch: action.payload,
      };
      break;
    case PROJECT_PATCH_ERROR:
      state = {
        ...state,
        errorProjectPatch: action.payload,
        loadingProjectPatch: false,
        responseProjectPatch: null,
      };
      break;
    case PROJECT_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorProjectPatch: null,
        loadingProjectPatch: null,
        responseProjectPatch: false,
      };
      break;
    //
    case PROJECT_ONE:
      state = {
        ...state,
        loadingOneProject: true,
        errorOneProject: null,
        responseOneProject: null,
      };
      break;
    case PROJECT_ONE_SUCCESS:
      state = {
        ...state,
        errorOneProject: null,
        loadingOneProject: false,
        responseOneProject: action.payload,
      };
      break;
    case PROJECT_ONE_ERROR:
      state = {
        ...state,
        errorOneProject: action.payload,
        loadingOneProject: false,
        responseOneProject: null,
      };
      break;
    case PROJECT_ONE_CLEAR_DATA:
      state = {
        ...state,
        errorOneProject: null,
        loadingOneProject: null,
        responseOneProject: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Project;

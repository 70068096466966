import {
  CANCEL_QUOTE,
  CANCEL_QUOTE_CLEAR_DATA,
  CANCEL_QUOTE_ERROR,
  CANCEL_QUOTE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorCancelQuote: null,
  responseCancelQuote: null,
  loadingCancelQuote: false,
};

const CancelQuote = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_QUOTE:
      state = {
        ...state,
        loadingCancelQuote: true,
        errorCancelQuote: null,
        responseCancelQuote: true,
      };
      break;
    case CANCEL_QUOTE_SUCCESS:
      state = {
        ...state,
        errorCancelQuote: null,
        loadingCancelQuote: false,
        responseCancelQuote: action.payload,
      };
      break;
    case CANCEL_QUOTE_ERROR:
      state = {
        ...state,
        errorCancelQuote: action.payload,
        loadingCancelQuote: false,
        responseCancelQuote: null,
      };
      break;
    case CANCEL_QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorCancelQuote: null,
        loadingCancelQuote: null,
        responseCancelQuote: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CancelQuote;

import { useEffect, useState } from "react";
import { useGetPermissionList } from "../fnx/getPermissions";

export const GetNavigationPages = () => {
  let permissionsConfig: { name: string }[] = [];
  const [trigger2, setTrigger2] = useState(false);
  const { permissionList } = useGetPermissionList(trigger2);
  useEffect(() => {
    setTrigger2(true);
  }, []);

  if (localStorage.getItem("afy-login")) {
    const item = localStorage.getItem("afy-login");
    if (item !== null) {
      switch ("rootRol") {
        case "rootRol":
          permissionsConfig = permissionList;
          break;
        default:
          permissionsConfig = [];
      }
    }
  }
  return {
    permissionsConfig,
  };
};
//Todo: add the right role to the user
export const hasAuditRole = (tag: any) => {
  const storedLogin = localStorage.getItem("afy-login");
  const rol = storedLogin ? JSON.parse(storedLogin) : { roles: [] };
  
  const hasFiscalRole = rol.roles.some(
    (role: { name: string }) =>
      (role.name).toLowerCase() === "user" ||
    (role.name).toLowerCase() === "fiscal" ||
    (role.name).toLowerCase() === "audit" 
  );
  return hasFiscalRole;
};

export const hasPermission = (tag: any) => {
  const storedLogin = localStorage.getItem("afy-login");
  const rol = storedLogin ? JSON.parse(storedLogin) : { roles: [] };
  const hasAdminRole = rol.roles.some(
    (role: { name: string }) =>
      (role.name).toLowerCase() === "administrator" ||
    (role.name).toLowerCase() === "admin" ||
    (role.name).toLowerCase() === "administrador"
  );
  const permission = GetNavigationPages().permissionsConfig;
  if (hasAdminRole) {
    return true;
  }
 
  return permission.find((i) => i.name === tag) ? true : false;
}



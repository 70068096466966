import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectTypePatch } from "../../store/projectType/actions";

export const useProjectTypePatch = (
  trigger: boolean,
  body?: any,
  id?: string,
) => {
  const dispatch = useDispatch();

  const {
    responseProjectTypePatch,
    errorProjectTypePatch,
    loadingProjectTypePatch,
  } = useSelector((state: any) => ({
    responseProjectTypePatch: state.ProjectType.responseProjectTypePatch?.data,
    errorProjectTypePatch: state.ProjectType.errorProjectTypePatch,
    loadingProjectTypePatch: state.ProjectType.loadingProjectTypePatch,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        projectTypePatch(
          {
            clientId: body.data.clientId,
            description: body.data.description,
          },
          id,
        ),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseProjectTypePatch,
    errorProjectTypePatch,
    loadingProjectTypePatch,
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coverTypePost } from "../../store/coverType/actions";
import {  CoverTypeRequest } from "../../model/coverType";

export const useCoverTypePost = (
  trigger: boolean,
  bodyRequest: CoverTypeRequest,
) => {
  const dispatch = useDispatch();

  const { responsePostCoverType, errorPostCoverType, loadingPostCoverType } = useSelector(
    (state: any) => ({
      responsePostCoverType: state.CoverType.responsePostCoverType?.data,
      errorPostCoverType: state.CoverType.errorPostCoverType,
      loadingPostCoverType: state.CoverType.loadingPostCoverType,
    }),
  );

  useEffect(() => {
    console.log('useCoverTypePost',bodyRequest);
    if (trigger) {
      dispatch(
        coverTypePost(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePostCoverType,
    errorPostCoverType,
    loadingPostCoverType,
  };
};

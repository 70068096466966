import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userCreate } from "../../store/auth/actions";
import {  UserRequest } from "../../model/user";

export const useCreateUser = (
  trigger: boolean,
  bodyRequest: UserRequest,
) => {
  const dispatch = useDispatch();

  const { responseUserCreate, errorUserCreate, loadingUserCreate } = useSelector(
    (state: any) => ({
      responseUserCreate: state.Login.responseUserCreate?.data,
      errorUserCreate: state.Login.errorUserCreate,
      loadingUserCreate: state.Login.loadingUserCreate,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        userCreate(bodyRequest),
      );
    }
  }, [trigger]);

  return {
    responseUserCreate,
    errorUserCreate,
    loadingUserCreate,
  };
};


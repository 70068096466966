import React from "react";
import "./style.css";

import {
  AdminBranchAFY,
  AdminRegionAFY,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ModalDelete } from "./resources/modalDelete";

export const View = (props: {

  // Props Admin
  title: string;
  columns: any;
  buttonTextAdd?: string;
  onClickAdd: () => void;

  // Props Form
  titleForm?: string;
  dataForm: any;
  editMode: boolean;
  buttonText?: string;
  onSaveData: any;

  // Props Drawer
  openDrawer: boolean;
  textDescription: string;
  onCloseDrawer: () => void;

  // Props Search
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;

  list?: any;
  modalTitle: string;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onClickContextMenu: () => void;

}) => {
  
  return (
    <>
       <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />

      <AdminBranchAFY
        // Filter Props
        showSelectStatus={true}
        showSelectBranch={true}
        showSelectRole={true}
        titleButtonClear= "Limpiar filtros"

        // Drawer Props
        openDrawer={props.openDrawer}


        // Admin Props
        title={props.title}
        buttonTextAdd={props.buttonTextAdd}
        buttonText={props.buttonText}
        columns={props.columns}
        rows={props.list}
        onClickAdd={props.onClickAdd}

        // Form Props
        editMode={props.editMode}
        titleForm={props.titleForm}
        textDescription="Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, doloremque cumque ipsa ad reprehenderit animi similique, voluptas laborum dolore labore esse placeat natus molestiae accusantium molestias pariatur beatae dignissimos. "
        onSaveData={props.onSaveData}
        widthLabelLinearForm={"400px"}
        
        //unkown props
        onBlurSearch={function noRefCheck() {}}
        onChangeData={props.filterStatus}
        onCloseSearch={props.onCloseSearch}
        onChangeSearch={props.searchFilter}
        onCloseDrawer={props.onCloseDrawer}
        dataForm={props.dataForm} 
        

      />
    </>
  );
};

import {
  INSURANCE,
  INSURANCE_CLEAR_DATA,
  INSURANCE_ERROR,
  INSURANCE_SUCCESS,
} from "./actionTypes";

export const insuranceApi = (insurance, history) => {
  return {
    type: INSURANCE,
    payload: { insurance, history },
  };
};

export const insuranceSuccess = (success) => {
  return {
    type: INSURANCE_SUCCESS,
    payload: success,
  };
};

export const insuranceError = (error) => {
  return {
    type: INSURANCE_ERROR,
    payload: error,
  };
};

export const insuranceClearData = () => {
  return {
    type: INSURANCE_CLEAR_DATA,
    payload: {},
  };
};

import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { getHeader } from "../headers";

const getInsurances = async (enable: boolean, page: number, limit: number) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.project +
    `?enable=${enable}&page=${page}&limit=${limit}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const getInsurancesIdApi = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.sponsor + "/${id}";

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const postInsurancesApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.project;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const patchInsurancesApi = async (body: any, id: string) => {
  console.log("patch sponsor", body, id);
  let newURL = process.env.REACT_APP_HOST_API + URLs.sponsor + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  getInsurances,
  getInsurancesIdApi,
  postInsurancesApi,
  patchInsurancesApi,
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchSponsor } from "../../store/sponsor/actions";

export const useSponsorPatch = (
  trigger: boolean,
  body: any,
  
) => {
  
  const dispatch = useDispatch();

  const { responsePatchSponsor, errorPatchSponsor, loadingPatchSponsor } =
  useSelector((state: any) => ({
    responsePatchSponsor: state.Sponsor.responsePatchSponsor,
    errorPatchSponsor: state.Sponsor.errorPatchSponsor,
    loadingPatchSponsor: state.Sponsor.loadingPatchSponsor,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(patchSponsor({
        clientId: body.clientId,
        name: body.name,  
        code: body.code,
      }, body.id));
  }}, [trigger]);

  return {
    responsePatchSponsor,
    errorPatchSponsor,
    loadingPatchSponsor,
  };
};

export const PROJECT_TYPE = "PROJECT_TYPE";
export const PROJECT_TYPE_SUCCESS = "PROJECT_TYPE_SUCCESS";
export const PROJECT_TYPE_ERROR = "PROJECT_TYPE_ERROR";
export const PROJECT_TYPE_CLEAR_DATA = "PROJECT_TYPE_CLEAR_DATA";

export const PROJECT_TYPE_POST = "PROJECT_TYPE_POST";
export const PROJECT_TYPE_POST_SUCCESS = "PROJECT_TYPE_POST_SUCCESS";
export const PROJECT_TYPE__POST_ERROR = "PROJECT_TYPE__POST_ERROR";
export const PROJECT_TYPE_POST_CLEAR_DATA = "PROJECT_TYPE_POST_CLEAR_DATA";

export const PROJECT_TYPE_PATCH = "PROJECT_TYPE_PATCH";
export const PROJECT_TYPE_PATCH_SUCCESS = "PROJECT_TYPE_PATCH_SUCCESS";
export const PROJECT_TYPE_PATCH_ERROR = "PROJECT_TYPE_PATCH_ERROR";
export const PROJECT_TYPE_PATCH_CLEAR_DATA = "PROJECT_TYPE_PATCH_CLEAR_DATA";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userConfirmEmail } from "../../store/auth/actions";
import {  UserModel } from "../../model/user";

export const UseUserConfirmEmail = (
  trigger: boolean,
  bodyRequest: UserModel,
) => {
  const dispatch = useDispatch();

  const { responseConfirmEmail, errorConfirmEmail, loadingConfirmEmail } = useSelector(
    (state: any) => ({
      responseConfirmEmail: state.Login.responseConfirmEmail?.data,
      errorConfirmEmail: state.Login.errorConfirmEmail,
      loadingConfirmEmail: state.Login.loadingConfirmEmail,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        userConfirmEmail(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responseConfirmEmail,
    errorConfirmEmail,
    loadingConfirmEmail,
  };
};

import { URLs } from "../url";
import {
  PersonRequest,
  PersonRequestFull,
  updatePersonResponse,
} from "../../model/person";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getPersonByIdAPI = async (id: string) => {
  let newURL = `${process.env.REACT_APP_HOST_API}${URLs.person}/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PersonRequest;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPersonByDocumentAPI = async (
  document: number,
  enable: boolean,
  page: number,
  limit: number
) => {
  let newURL =
    `${process.env.REACT_APP_HOST_API}${URLs.person}?numberId=${document}` +
    `&enable=${enable}&page=${page}&limit=${limit}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PersonRequestFull;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updatePersonAPI = async (body: any, id: string) => {
  let newURL = `${process.env.REACT_APP_HOST_API}${URLs.person}/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as updatePersonResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getPersonByIdAPI, updatePersonAPI, getPersonByDocumentAPI };

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogBeneficiary } from "../../store/catalog/actions";

export const GetCatalogBeneficiary = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const dispatch = useDispatch();

  const {
    responseGetCatalogBeneficiary,
    loadingGetCatalogBeneficiary,
    errorGetCatalogBeneficiary,
  } = useSelector((state: any) => ({
    responseGetCatalogBeneficiary: state.Catalog.responseGetCatalogBeneficiary,
    loadingGetCatalogBeneficiary: state.Catalog.loadingGetCatalogBeneficiary,
    errorGetCatalogBeneficiary: state.Catalog.errorGetCatalogBeneficiary,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCatalogBeneficiary({
          enable,
          page,
          limit,
          type: "CATALOG_BENEFICIARY_TYPE",
          clientId,
        }),
      );
    }
  }, [trigger, enable, page, limit, clientId]);

  return {
    responseGetCatalogBeneficiary,
    loadingGetCatalogBeneficiary,
    errorGetCatalogBeneficiary,
  };
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceType } from "../../store/insuranceType/actions";

export const GetInsurancesTypes = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const dispatch = useDispatch();

  const { responseInsuranceType, errorInsuranceType, loadingInsuranceType } =
    useSelector((state: any) => ({
      responseInsuranceType: state.InsuranceType.responseInsuranceType?.data,
      errorInsuranceType: state.InsuranceType.errorInsuranceType,
      loadingInsuranceType: state.InsuranceType.loadingInsuranceType,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        insuranceType({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        }),
      );
    }
  }, [clientId, enable, limit, page, trigger]);

  return {
    responseInsuranceType,
    errorInsuranceType,
    loadingInsuranceType,
  };
};

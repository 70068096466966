import {
  icons,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ButtonOption } from "../../model/sponsor";

export const identificationTypeView: MenuItem[] = [
  {
    active: false,
    icon: {
      icon: icons.List,
    },
    id: "1",
    name: "List",
  },
  {
    active: true,
    icon: {
      icon: icons.GridView,
    },
    id: "2",
    name: "GridView",
  },
];

export const buttonActionOptions: ButtonOption[] = [
  {
    icons: icons.Menu,
    id: "1",
    name: "editar",
  },
  {
    icons: icons.Menu,
    id: "2",
    name: "eliminar",
  },
];

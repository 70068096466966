import React, { useEffect, useState } from "react";
import {
  CardGeneralCatalogProps,
  DataFormRol,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LocalStorageService from "../../services/localStorage";
import { SelectChangeEvent } from "@mui/material";
import { useRolGetAll } from "../../fnx/rol/rolGetAll";
import {
  defaultDropDownPermissions,
  description,
  menuItem,
  optionSelect,
  optionsSelectClient,
  selectValueDefault,
} from "./data";
import { RolView } from "./view";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { useRolPost } from "../../fnx/rol/rolPost";
import { Rol, RolRequest } from "../../model/rol";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import {
  rolDisableClearData,
  rolPatchClearData,
  rolPostClearData,
} from "../../store/rol/actions";
import { useRolPatch } from "../../fnx/rol/rolPatch";
import { ColumnsPermissions, rolDataForm, RolPermission } from "./rol.Dto";
import { useRolDisable } from "../../fnx/rol/rolDisable";
import { usePermissionGetAll } from "../../fnx/permission/permissionGetAll";
import { RowsModel } from "../../model/insurancePoliciesSummary";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
export const RolAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [triggerPost, setTriggerPost] = useState<boolean>(false);
  const [triggerPatch, setTriggerPatch] = useState<boolean>(false);
  const [triggerDisable, setTriggerDisable] = useState<boolean>(false);
  const [triggerPermission, setTriggerPermission] = useState<boolean>(false);
  const [rolList, setRolList] = useState<CardGeneralCatalogProps[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalDeletePermission, setOpenModalDeletePermission] =
    useState<boolean>(false);

  const [pageCatalog, setPageCatalog] = useState<number>(1);
  const [limitCatalog, setLimitCatalog] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [valueName, setValueName] = useState();
  const [valueDescription, setValueDescription] = useState();

  const [permissionList, setPermissionList] = useState([]);
  const [dbPermissionList, setDbPermissionList] = useState<RolPermission[]>([]);

  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDrawerPermission, setOpenDrawerPermission] =
    useState<boolean>(false);
  const [itemModal, setItemModal] = useState<any>(null);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<RolRequest>();
  const [dataForm, setDataForm] = useState<DataFormRol>(rolDataForm);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [titleDelete, setTitleDelete] = React.useState("");
  const [subTitleDelete, setSubTitleDelete] = React.useState("");
  const [permissionId, setPermissionId] = React.useState("");

  /*Llamada al getAll para recuperar todos los registros*/
  const { responseRol, errorRol, loadingRol } = useRolGetAll(
    trigger,
    clientId,
    pageCatalog,
    limitCatalog,
    valueName,
    valueDescription,
    nameSearch,
  );

  /*Llamada al getAll para recuperar todos los permisos*/
  const { responsePermission, errorPermission, loadingPermission } =
    usePermissionGetAll(triggerPermission, clientId, pageCatalog, 10000);

  /*Llamada al POST */
  const { responseRolPost, errorRolPost, loadingRolPost } = useRolPost(
    triggerPost,
    bodyRequest,
  );

  /*Llamada al Patch*/
  const { responseRolPatch, errorRolPatch, loadingRolPatch } = useRolPatch(
    triggerPatch,
    bodyRequest,
  );

  /*Llamada al Disable*/
  const { responseRolDisable, errorRolDisable, loadingRolDisable } =
    useRolDisable(triggerDisable, itemModal);

  const clickContextMenuPaymentMode = (item: RowsModel) => {
    setOpenModalDeletePermission(true);
    setTitleDelete("Permisos");
    setSubTitleDelete(`el permiso ${item.description?.toLowerCase()}`);
    setPermissionId(item.id ? item.id : "");
    setIsUpdate(true);
    // showEdit(item);

    /*Unifica listas quita duplicados y actualiza */
    const listPermissionDb: string[] = itemModal.permission.map(
      (rol: RolPermission) => rol.id,
    );

    //Quita el ID que deseo de la lista
    const updatedListPermissionDb = listPermissionDb.filter(
      (id) => id !== item.id,
    );

    //Actualiza la nueva lista de Permisos
    setBodyRequest({
      id: itemModal.id,
      clientId: itemModal.clientId,
      RolName: itemModal.code,
      RolDescription: itemModal.type,
      Permissions: updatedListPermissionDb,
    });
    setOpenDrawerPermission(false);
    setOpenModal(false);
  };

  const columnsPermissions = ColumnsPermissions(clickContextMenuPaymentMode);

  /*Call Trigger para GetAll*/
  const callRol = () => {
    setTrigger(!trigger);
  };

  const callPermission = () => {
    setTriggerPermission(!triggerPermission);
  };

  useEffect(() => {
    callRol();
  }, []);

  /*habilita trigger para getAll api de Permisos*/
  useEffect(() => {
    callPermission();
  }, [responseRol]);

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responseRol && responseRol?.data?.length > 0) {
      const listRols: CardGeneralCatalogProps[] = responseRol?.data?.map(
        (item: Rol) => ({
          id: item.id,
          type: item.description,
          code: item.name,
          description: "",
          clientId: item.clientId,
          fontSizeType: 12,
          fontSizeDescription: 12,
          codeLabel: "Nombre: ",
          typeLabel: "Descripción: ",
          permission: item.permissions,
        }),
      );
      setRolList(listRols);
      setTrigger(false);
    } else if (responseRol && responseRol?.length === 0) {
      setTrigger(false);
      setRolList([]);
    }
    if (responseRol?.pagingInfo) {
      setCountPagination(responseRol?.pagingInfo.totalItems);
    }
  }, [responseRol]);

  console.log("responseRol", responseRol);

  /*Valida respuesta del getAll de Permisos*/
  useEffect(() => {
    if (responsePermission && responsePermission?.length > 0) {
      setPermissionList(responsePermission);
      setTriggerPermission(false);
    } else if (responsePermission && responsePermission?.length == 0) {
      setTriggerPermission(false);
      setPermissionList([]);
    }
  }, [responsePermission]);

  /*Valida respuesta de la creacion del ROL*/
  useEffect(() => {
    if (responseRolPost && responseRolPost?.length > 0) {
      showToastSuccessMessage(
        "Rol creado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setTriggerPost(false);
      callRol();
      dispatch(rolPostClearData());
    } else if (errorRolPost) {
      showErrorToast(errorRolPost, setSeeToast, t);
      setTriggerPost(false);
    }
  }, [responseRolPost, errorRolPost]);

  /*Valida respuesta del update del ROL*/
  useEffect(() => {
    if (responseRolPatch && responseRolPatch?.id.length > 0) {
      showToastSuccessMessage(
        "Rol actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setTriggerPatch(false);
      callRol();
      dispatch(rolPatchClearData());
    } else if (errorRolPatch) {
      showErrorToast(errorRolPatch, setSeeToast, t);
      setTriggerPatch(false);
    }
  }, [responseRolPatch, errorRolPatch]);

  /*Valida respuesta del disable del ROL*/
  useEffect(() => {
    if (responseRolDisable && responseRolDisable?.id.length > 0) {
      showToastSuccessMessage(
        "Rol desactivado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setTriggerDisable(false);
      callRol();
      dispatch(rolDisableClearData());
    } else if (errorRolDisable) {
      showErrorToast(errorRolDisable, setSeeToast, t);
      setTriggerDisable(false);
    }
  }, [responseRolDisable, errorRolDisable]);
  const onClickClearData = (Data: any) => {
    // Valida clean filtro y limpia los filtros
    if (Data.filter.type_filter === "empty") {
      setValueDescription(undefined);
      setValueName(undefined);
    }
    // Llama a la consulta general sin filtros
    callRol();
  };
  /*Mapea los filtros para enviarlos al API para busqueda*/
  const onClickApplyFilter = (Data: any) => {
    if (Data.filter.type_filter === "name") {
      setValueName(Data.filter.filter_value);
      setValueDescription(undefined);
    } else if (Data.filter.type_filter === "description") {
      setValueDescription(Data.filter.filter_value);
      setValueName(undefined);
    }
    callRol();
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    console.log("Event", event);
  };
  const searchFilter = (term: string) => {
    setNameSearch(term);
  };
  const filterStatus = (item: any) => {
    // const idSponsor = item?.sponsor.map((item: any) => item.id).join(",");
    // setTrigger(true);
  };

  const newItem = () => {
    setDataForm({
      id: "",
      clientId: "",
      RolName: "",
      RolDescription: "",
    });
  };
  const showEdit = (row: any) => {
    setDataForm({
      id: row.id,
      clientId: row.clientId,
      RolName: row.code,
      RolDescription: row.type,
    });
  };
  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };
  const handleOnClickAdd = () => {
    newItem();
    setIsUpdate(false);
    setOpenDrawer(true);
  };
  /*Mapea el objeto para enviar en el POST*/
  const handleSubmitRol = (dataRol: any) => {
    if (isUpdate) {
      setBodyRequest({
        id: dataRol.id,
        clientId: dataRol.clientId,
        RolName: dataRol.RolName,
        RolDescription: dataRol.RolDescription,
      });
      setTriggerPatch(true);
      setOpenDrawer(false);
    } else {
      setBodyRequest({
        RolName: dataRol.RolName,
        RolDescription: dataRol.RolDescription,
      });
      setDataForm(rolDataForm);
      setOpenDrawer(false);
      setTriggerPost(true);
    }
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const onClickContextMenu = (opt?: any, item?: any) => {
    switch (opt.id) {
      case "1":
        setIsUpdate(true);
        setOpenDrawer(true);
        showEdit(item);
        break;
      case "2":
        setOpenModalDelete(true);
        setItemModal(item);
        break;
      case "3":
        const listPermissionDb: RolPermission[] = item.permission?.map(
          (item: RolPermission) => ({
            id: item.id,
            name: item.name,
            description: item.description,
          }),
        );

        setDbPermissionList(listPermissionDb);
        setItemModal(item);
        setIsUpdate(true);
        showEdit(item);
        setOpenDrawerPermission(true);
        break;
    }
  };
  const clickView = (view: MenuItem) => {
    setItemModal(view);
  };
  const onCloseDrawer = () => {
    setDataForm(rolDataForm);
    setOpenDrawer(false);
  };
  const onCloseDrawerPermissions = () => {
    setDbPermissionList([]);
    setOpenDrawerPermission(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    setTriggerDisable(true);
  };
  const handleDeletePermission = () => {
    setOpenModalDeletePermission(false);
    setTriggerPatch(true);
    // setTriggerDisable(true);
  };

  const onHandleSaveRolPermission = (data: any) => {
    const listPermissionDb: string[] = itemModal.permission.map(
      (item: RolPermission) => item.id,
    );
    const listPermission: string[] = data.map((item: RolPermission) => item.id);
    const uniqueList: string[] = Array.from(
      new Set([...listPermissionDb, ...listPermission]),
    );

    setBodyRequest({
      id: itemModal.id,
      clientId: itemModal.clientId,
      RolName: itemModal.code,
      RolDescription: itemModal.type,
      Permissions: uniqueList,
    });
    setTriggerPatch(true);
    setOpenDrawerPermission(false);
    setOpenModal(false);
  };

  const onChangePage = (e: number) => {
    setPageCatalog(e);
    setTrigger(true);
  };

  const onChangeRowsPerPage = (e: number) => {
    setLimitCatalog(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingRol || loadingRolPost || loadingRolPatch || loadingRolDisable
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando roles..."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <RolView
        title={"Rol"}
        titleDrawer={isUpdate ? "Modificar Rol" : "Agregar Rol"}
        list={rolList}
        avatarVariant={variantsAvatar.rounded}
        openModal={openModal}
        onSaveData={handleSubmitRol}
        loadingPostRol={loadingRolPost}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        editMode={isUpdate}
        clickView={clickView}
        onSubmit={handleDelete}
        viewLinearForm={true}
        onClose={handleCloseModal}
        onCloseSearch={onCloseSearch}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        openModalDeletePermission={openModalDeletePermission}
        setOpenModalDeletePermission={setOpenModalDeletePermission}
        onCloseDrawer={onCloseDrawer}
        searchFilter={searchFilter}
        filterStatus={filterStatus}
        handleOpenModal={handleOpenModal}
        onChangeSelect={handleSelectChange}
        onClickContextMenu={onClickContextMenu}
        onClickAdd={handleOnClickAdd}
        onClickCancel={handleCloseModal}
        onFilterApply={onClickApplyFilter}
        onFilterEmpty={onClickClearData}
        selectValueDefault={selectValueDefault}
        menuItem={menuItem}
        optionsSelect={optionSelect}
        optionsSelectClient={optionsSelectClient}
        inputHeigth={"20.3px"}
        clientFilterLbl={"Cliente"}
        //Drawer Roles
        listDropDownPermissions={permissionList}
        columnsPermissions={columnsPermissions}
        rowsPermissions={dbPermissionList}
        buttonLblAddGrid={"Agregar Permisos"}
        labelDropDownPermissions={"Permisos"}
        txtSearchDropDownPermissions={"Buscar"}
        defaultDropDownPermissions={defaultDropDownPermissions}
        titleDrawerPermission={"Agregar Permisos"}
        textDescriptionPermission={description}
        openDrawerPermission={openDrawerPermission}
        titleDelete={titleDelete}
        subTitleDelete={subTitleDelete}
        onCloseDrawerPermission={onCloseDrawerPermissions}
        onClickSaveDataPermission={onHandleSaveRolPermission}
        modalTitlePermission={"Permisos"}
        onSubmitDeletePermission={handleDeletePermission}
        countPagination={countPagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        limitCatalog={limitCatalog}
        pageCatalog={pageCatalog}
      />
    </React.Fragment>
  );
};

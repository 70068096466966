export const COVER_TYPE = "COVER_TYPE";
export const COVER_TYPE_SUCCESS = "COVER_TYPE_SUCCESS";
export const COVER_TYPE_ERROR = "COVER_TYPE_ERROR";
export const COVER_TYPE_CLEAR_DATA = "COVER_TYPE_CLEAR_DATA";

export const COVER_TYPE_GET_ONE = "COVER_TYPE_GET_ONE";
export const COVER_TYPE_GET_ONE_SUCCESS = "COVER_TYPE_GET_ONE_SUCCESS";
export const COVER_TYPE_GET_ONE_ERROR = "COVER_TYPE_GET_ONE_ERROR";
export const COVER_TYPE_GET_ONE_CLEAR_DATA = "COVER_TYPE_GET_ONE_CLEAR_DATA";

export const COVER_TYPE_POST = "COVER_TYPE_POST";
export const COVER_TYPE_POST_SUCCESS = "COVER_TYPE_POST_SUCCESS";
export const COVER_TYPE_POST_ERROR = "COVER_TYPE_POST_ERROR";
export const COVER_TYPE_POST_CLEAR_DATA = "COVER_TYPE_POST_CLEAR_DATA";

export const COVER_TYPE_PATCH = "COVER_TYPE_PATCH";
export const COVER_TYPE_PATCH_SUCCESS = "COVER_TYPE_PATCH_SUCCESS";
export const COVER_TYPE_PATCH_ERROR = "COVER_TYPE_PATCH_ERROR";
export const COVER_TYPE_PATCH_CLEAR_DATA = "COVER_TYPE_PATCH_CLEAR_DATA";
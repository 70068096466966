import {
  breadcrumb,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const breadcrumDTO = (): breadcrumb[] => {
  return [
    {
      name: "Inicio",
      key: "inicio",
      ariaCurrent: "",
    },
    {
      name: "Nueva Venta",
      key: "nueva_venta",
      ariaCurrent: "",
    },
  ];
};

export const menuDTO = (): MenuItem[] => {
  return [
    {
      active: true,
      disabled: false,
      id: "inicial",
      name: "Inicio",
    },
    {
      active: false,
      disabled: false,
      id: "servicios",
      name: "Servicios",
    },
    {
      active: false,
      disabled: false,
      id: "informacion",
      name: "Informacion",
    },
  ];
};

export const menuSideBarDTO = (): MenuItem[] => {
  return [
    {
      active: true,
      disabled: false,
      id: "1",
      name: "Nueva venta",
    },
    {
      active: false,
      disabled: false,
      id: "2",
      name: "Ver pólizas",
    },
    {
      active: false,
      disabled: false,
      id: "3",
      name: "Reportes",
    },
  ];
};

import {
  CANCEL_QUOTE,
  CANCEL_QUOTE_CLEAR_DATA,
  CANCEL_QUOTE_ERROR,
  CANCEL_QUOTE_SUCCESS,
} from "./actionTypes";

export const cancelQuote = (quote, id) => {
  return {
    type: CANCEL_QUOTE,
    payload: { quote, id },
  };
};

export const cancelQuoteSuccess = (success) => {
  return {
    type: CANCEL_QUOTE_SUCCESS,
    payload: success,
  };
};

export const cancelQuoteError = (error) => {
  return {
    type: CANCEL_QUOTE_ERROR,
    payload: error,
  };
};

export const cancelQuoteClearData = () => {
  return {
    type: CANCEL_QUOTE_CLEAR_DATA,
    payload: {},
  };
};

import {
  GET_CATALOGUES,
  GET_CATALOGUES_CLEAR_DATA,
  GET_CATALOGUES_ERROR,
  GET_CATALOGUES_SUCCESS,
} from "./actionType";

export const getCatalogues = (catalogue, history) => {
  return {
    type: GET_CATALOGUES,
    payload: { catalogue, history },
  };
};

export const getCataloguesSuccess = (success) => {
  return {
    type: GET_CATALOGUES_SUCCESS,
    payload: success,
  };
};

export const getCataloguesError = (error) => {
  return {
    type: GET_CATALOGUES_ERROR,
    payload: error,
  };
};

export const getCataloguesClearData = () => {
  return {
    type: GET_CATALOGUES_CLEAR_DATA,
    payload: {},
  };
};

import {
  DOWNLOAD_QUOTE_All,
  DOWNLOAD_QUOTE_All_CLEAR_DATA,
  DOWNLOAD_QUOTE_All_ERROR,
  DOWNLOAD_QUOTE_All_SUCCESS,
  QUOTE,
  QUOTE_ADD_FIELDS,
  QUOTE_ADD_FIELDS_CLEAR_DATA,
  QUOTE_ADD_FIELDS_ERROR,
  QUOTE_ADD_FIELDS_SUCCESS,
  QUOTE_All,
  QUOTE_All_CLEAR_DATA,
  QUOTE_All_ERROR,
  QUOTE_All_SUCCESS,
  QUOTE_CLEAR_DATA,
  QUOTE_ERROR,
  QUOTE_ONE,
  QUOTE_ONE_CLEAR_DATA,
  QUOTE_ONE_ERROR,
  QUOTE_ONE_SUCCESS,
  QUOTE_SUCCESS,
  QUOTE_UPDATE_STATUS_AUDIT_DOC,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR,
  QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS,
} from "./actionTypes";

export const quote = (quote, history) => {
  return {
    type: QUOTE,
    payload: { quote, history },
  };
};

export const quoteSuccess = (success) => {
  return {
    type: QUOTE_SUCCESS,
    payload: success,
  };
};

export const quoteError = (error) => {
  return {
    type: QUOTE_ERROR,
    payload: error,
  };
};

export const quoteClearData = () => {
  return {
    type: QUOTE_CLEAR_DATA,
    payload: {},
  };
};

export const quoteOne = (quoteOne, history) => {
  return {
    type: QUOTE_ONE,
    payload: { quoteOne, history },
  };
};

export const quoteOneSuccess = (success) => {
  return {
    type: QUOTE_ONE_SUCCESS,
    payload: success,
  };
};

export const quoteOneError = (error) => {
  return {
    type: QUOTE_ONE_ERROR,
    payload: error,
  };
};

export const quoteOneClearData = () => {
  return {
    type: QUOTE_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const quoteAll = (quoteAll, history) => {
  return {
    type: QUOTE_All,
    payload: { quoteAll, history },
  };
};

export const quoteAllSuccess = (success) => {
  return {
    type: QUOTE_All_SUCCESS,
    payload: success,
  };
};

export const quoteAllError = (error) => {
  return {
    type: QUOTE_All_ERROR,
    payload: error,
  };
};

export const quoteAllClearData = () => {
  return {
    type: QUOTE_All_CLEAR_DATA,
    payload: {},
  };
};

export const downloadQuoteAll = (downloadQuoteAll, history) => {
  return {
    type: DOWNLOAD_QUOTE_All,
    payload: { downloadQuoteAll, history },
  };
};

export const downloadQuoteAllSuccess = (success) => {
  return {
    type: DOWNLOAD_QUOTE_All_SUCCESS,
    payload: success,
  };
};

export const downloadQuoteAllError = (error) => {
  return {
    type: DOWNLOAD_QUOTE_All_ERROR,
    payload: error,
  };
};

export const downloadQuoteAllClearData = () => {
  return {
    type: DOWNLOAD_QUOTE_All_CLEAR_DATA,
    payload: {},
  };
};

//PUT QUOTE ADDITIONAL FIELDS
export const quoteAddFields = (quote, history) => {
  return {
    type: QUOTE_ADD_FIELDS,
    payload: { quote, history },
  };
};

export const quoteAddFieldsSuccess = (success) => {
  return {
    type: QUOTE_ADD_FIELDS_SUCCESS,
    payload: success,
  };
};

export const quoteAddFieldsError = (error) => {
  return {
    type: QUOTE_ADD_FIELDS_ERROR,
    payload: error,
  };
};

export const quoteAddFieldsClearData = () => {
  return {
    type: QUOTE_ADD_FIELDS_CLEAR_DATA,
    payload: {},
  };
};

//UPDATE STATUS AUDIT DOCUMENTS

export const updateStatusAuditDoc = (quote, history) => {
  return {
    type: QUOTE_UPDATE_STATUS_AUDIT_DOC,
    payload: { quote, history },
  };
};

export const updateStatusAuditDocSuccess = (success) => {
  return {
    type: QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS,
    payload: success,
  };
};

export const updateStatusAuditDocError = (error) => {
  return {
    type: QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR,
    payload: error,
  };
};

export const updateStatusAuditDocClearData = () => {
  return {
    type: QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA,
    payload: {},
  };
};

export const QUOTE = "QUOTE";
export const QUOTE_SUCCESS = "QUOTE_SUCCESS";
export const QUOTE_ERROR = "QUOTE_ERROR";
export const QUOTE_CLEAR_DATA = "QUOTE_CLEAR_DATA";

export const QUOTE_ONE = "QUOTE_ONE";
export const QUOTE_ONE_SUCCESS = "QUOTE_ONE_SUCCESS";
export const QUOTE_ONE_ERROR = "QUOTE_ONE_ERROR";
export const QUOTE_ONE_CLEAR_DATA = "QUOTE_ONE_CLEAR_DATA";

export const QUOTE_All = "QUOTE_All";
export const QUOTE_All_SUCCESS = "QUOTE_All_SUCCESS";
export const QUOTE_All_ERROR = "QUOTE_All_ERROR";
export const QUOTE_All_CLEAR_DATA = "QUOTE_All_CLEAR_DATA";

export const DOWNLOAD_QUOTE_All = "DOWNLOAD_QUOTE_All";
export const DOWNLOAD_QUOTE_All_SUCCESS = "DOWNLOAD_QUOTE_All_SUCCESS";
export const DOWNLOAD_QUOTE_All_ERROR = "DOWNLOAD_QUOTE_All_ERROR";
export const DOWNLOAD_QUOTE_All_CLEAR_DATA = "DOWNLOAD_QUOTE_All_CLEAR_DATA";

export const QUOTE_ADD_FIELDS = "QUOTE_ADD_FIELDS";
export const QUOTE_ADD_FIELDS_SUCCESS = "QUOTE_ADD_FIELDS_SUCCESS";
export const QUOTE_ADD_FIELDS_ERROR = "QUOTE_ADD_FIELDS_ERROR";
export const QUOTE_ADD_FIELDS_CLEAR_DATA = "QUOTE_ADD_FIELDS_CLEAR_DATA";

export const QUOTE_UPDATE_STATUS_AUDIT_DOC = "QUOTE_UPDATE_STATUS_AUDIT_DOC";
export const QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS =
  "QUOTE_UPDATE_STATUS_AUDIT_DOC_SUCCESS";
export const QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR =
  "QUOTE_UPDATE_STATUS_AUDIT_DOC_ERROR";
export const QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA =
  "QUOTE_UPDATE_STATUS_AUDIT_DOC_CLEAR_DATA";

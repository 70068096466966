import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { rolDisable } from "../../store/rol/actions";

export const useRolDisable = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const { responseRolDisable, errorRolDisable, loadingRolDisable } =
    useSelector((state: any) => ({
      responseRolDisable: state.Rol.responseRolDisable,
      errorRolDisable: state.Rol.errorRolDisable,
      loadingRolDisable: state.Rol.loadingRolDisable,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(rolDisable(body.id));
    }
  }, [trigger]);

  return {
    responseRolDisable,
    errorRolDisable,
    loadingRolDisable,
  };
};
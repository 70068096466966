import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_CLEAR_DATA,
  ACCOUNT_TYPE_ERROR,
  ACCOUNT_TYPE_PATCH,
  ACCOUNT_TYPE_PATCH_CLEAR_DATA,
  ACCOUNT_TYPE_PATCH_ERROR,
  ACCOUNT_TYPE_PATCH_SUCCESS,
  ACCOUNT_TYPE_POST,
  ACCOUNT_TYPE_POST_CLEAR_DATA,
  ACCOUNT_TYPE_POST_ERROR,
  ACCOUNT_TYPE_POST_SUCCESS,
  ACCOUNT_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorAccountType: null,
  responseAccountType: null,
  loadingAccountType: false,

  errorAccountTypePost: null,
  responseAccountTypePost: null,
  loadingAccountTypePost: false,
};

const AccountType = (state = initialState, action) => {
  switch (action.type) {
    // aqui inicia el get
    case ACCOUNT_TYPE:
      state = {
        ...state,
        loadingAccountType: true,
        errorAccountType: null,
        responseAccountType: true,
      };
      break;
    case ACCOUNT_TYPE_SUCCESS:
      state = {
        ...state,
        errorAccountType: null,
        loadingAccountType: false,
        responseAccountType: action.payload,
      };
      break;
    case ACCOUNT_TYPE_ERROR:
      state = {
        ...state,
        errorAccountType: action.payload,
        loadingAccountType: false,
        responseAccountType: null,
      };
      break;
    case ACCOUNT_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorAccountType: null,
        loadingAccountType: null,
        responseAccountType: false,
      };
      break;
    // aqui inicia el POST
    case ACCOUNT_TYPE_POST:
      state = {
        ...state,
        loadingAccountTypePost: true,
        errorAccountTypePost: null,
        responseAccountTypePost: null,
      };
      break;
    case ACCOUNT_TYPE_POST_SUCCESS:
      state = {
        ...state,
        errorAccountTypePost: null,
        loadingAccountTypePost: false,
        responseAccountTypePost: action.payload,
      };
      break;
    case ACCOUNT_TYPE_POST_ERROR:
      state = {
        ...state,
        errorAccountTypePost: action.payload,
        loadingAccountTypePost: false,
        responseAccountTypePost: null,
      };
      break;
    case ACCOUNT_TYPE_POST_CLEAR_DATA:
      state = {
        ...state,
        errorAccountTypePost: null,
        loadingAccountTypePost: null,
        responseAccountTypePost: false,
      };
      break;
    // aqui inicia el PATCH
    case ACCOUNT_TYPE_PATCH:
      state = {
        ...state,
        loadingAccountTypePatch: true,
        errorAccountTypePatch: null,
        responseAccountTypePatch: null,
      };
      break;
    case ACCOUNT_TYPE_PATCH_SUCCESS:
      state = {
        ...state,
        errorAccountTypePatch: null,
        loadingAccountTypePatch: false,
        responseAccountTypePatch: action.payload,
      };
      break;
    case ACCOUNT_TYPE_PATCH_ERROR:
      state = {
        ...state,
        errorAccountTypePatch: action.payload,
        loadingAccountTypePatch: false,
        responseAccountTypePatch: null,
      };
      break;
    case ACCOUNT_TYPE_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorAccountTypePatch: null,
        loadingAccountTypePatch: null,
        responseAccountTypePatch: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AccountType;

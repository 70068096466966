import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postActionFlags,
  postActionFlagsClearData,
} from "../../store/pageActionFlags/actions";

export const PostActionFlags = (trigger: boolean, projectId: string) => {
  const dispatch = useDispatch();

  const {
    responsePostActionFlags,
    loadingPostActionFlags,
    errorPostActionFlags,
  } = useSelector((state: any) => ({
    responsePostActionFlags:
      state.PageActionFlags.responsePostActionFlags?.data,
    loadingPostActionFlags: state.PageActionFlags.loadingPostActionFlags,
    errorPostActionFlags: state.PageActionFlags.errorPostActionFlags,
  }));

  useEffect(() => {
    if (trigger) {
      const names = [
        "afy-plan-range-age",
        "afy-confirm-data-show-insured",
        "afy-confirm-data-add-insured",
        "afy-confirm-data-show-beneficiary",
        "afy-confirm-data-add-beneficiary",
        "afy-validate-customer-default-country",
      ];
      for (const name of names) {
        dispatch(
          postActionFlags({
            name: name,
            value: "false",
            projectId: projectId,
          }),
        );
        dispatch(postActionFlagsClearData());
      }
    }
  }, [projectId, trigger]);

  return {
    responsePostActionFlags,
    loadingPostActionFlags,
    errorPostActionFlags,
  };
};

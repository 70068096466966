import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { identificationType } from "../../store/identificationType/actions";

export const useIdentificationTypeGetAll = (
  trigger: boolean,
  description: string,
  code: string,
  page: number,
  limit: number,
) => {
  const [enable, setEnable] = useState<boolean>(true);

  const dispatch = useDispatch();

  const { responseIdentificationType, errorIdentificationType, loadingIdentificationType } = useSelector(
    (state: any) => ({
      responseIdentificationType: state.IdentificationType.responseIdentificationType,
      errorIdentificationType: state.IdentificationType.errorIdentificationType,
      loadingIdentificationType: state.IdentificationType.loadingIdentificationType,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        identificationType({
          enable: enable,
          page: page,
          limit: limit,
          description: description,
          code: code,
        }),
      );
    }
  }, [description,code,trigger]);

  return {
    responseIdentificationType,
    errorIdentificationType,
    loadingIdentificationType,
  };
};

export const QUOTE_UPLOAD_ATTACH = "QUOTE_UPLOAD_ATTACH";
export const QUOTE_UPLOAD_ATTACH_SUCCESS = "QUOTE_UPLOAD_ATTACH_SUCCESS";
export const QUOTE_UPLOAD_ATTACH_ERROR = "QUOTE_UPLOAD_ATTACH_ERROR";
export const QUOTE_UPLOAD_ATTACH_CLEAR_DATA = "QUOTE_UPLOAD_ATTACH_CLEAR_DATA";

export const QUOTE_UPLOAD_DOCUMENT = "QUOTE_UPLOAD_DOCUMENT";
export const QUOTE_UPLOAD_DOCUMENT_SUCCESS = "QUOTE_UPLOAD_DOCUMENT_SUCCESS";
export const QUOTE_UPLOAD_DOCUMENT_ERROR = "QUOTE_UPLOAD_DOCUMENT_ERROR";
export const QUOTE_UPLOAD_DOCUMENT_CLEAR_DATA =
  "QUOTE_UPLOAD_DOCUMENT_CLEAR_DATA";

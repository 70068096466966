import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const DivLongText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const DivStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: "10px",
  },
}));

import {
  CATALOG_ACCOUNT_TYPE,
  CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
  CATALOG_ACCOUNT_TYPE_ERROR,
  CATALOG_ACCOUNT_TYPE_SUCCESS,
  CATALOG_GENDER,
  CATALOG_GENDER_SUCCESS,
  PLAN,
  PLAN_CLEAR_DATA,
  PLAN_ERROR,
  PLAN_POST,
  PLAN_POST_ERROR,
  PLAN_POST_SUCCESS,
  PLAN_PUT,
  PLAN_PUT_ERROR,
  PLAN_PUT_SUCCESS,
  PLAN_SUCCESS,
} from "./actionTypes";

export const catalogAccountType = (catalogAccountType, history) => {
  return {
    type: CATALOG_ACCOUNT_TYPE,
    payload: { catalogAccountType, history },
  };
};

export const catalogAccountTypeSuccess = (success) => {
  return {
    type: CATALOG_ACCOUNT_TYPE_SUCCESS,
    payload: success,
  };
};

export const catalogAccountTypeError = (error) => {
  return {
    type: CATALOG_ACCOUNT_TYPE_ERROR,
    payload: error,
  };
};

export const catalogAccountTypeClearData = () => {
  return {
    type: CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
    payload: {},
  };
};

export const catalogGender = (catalogGender, history) => {
  return {
    type: CATALOG_GENDER,
    payload: { catalogGender, history },
  };
};

export const catalogGenderSuccess = (success) => {
  return {
    type: CATALOG_GENDER_SUCCESS,
    payload: success,
  };
};

export const catalogGenderError = (error) => {
  return {
    type: catalogGenderError(),
    payload: error,
  };
};

export const plan = (plan, history) => {
  return {
    type: PLAN,
    payload: { plan, history },
  };
};
export const planSuccess = (success) => {
  return {
    type: PLAN_SUCCESS,
    payload: success,
  };
};
export const planError = (error) => {
  return {
    type: PLAN_ERROR,
    payload: error,
  };
};

export const planPost = (plan, history) => {
  return {
    type: PLAN_POST,
    payload: { plan, history },
  };
};

export const planPostSuccess = (success) => {
  return {
    type: PLAN_POST_SUCCESS,
    payload: success,
  };
};

export const planPostError = (error) => {
  return {
    type: PLAN_POST_ERROR,
    payload: error,
  };
};

export const planPut = (plan, id, history) => {
  return {
    type: PLAN_PUT,
    payload: { plan, id, history },
  };
};

export const planPutSuccess = (success) => {
  return {
    type: PLAN_PUT_SUCCESS,
    payload: success,
  };
};

export const planPutError = (error) => {
  return {
    type: PLAN_PUT_ERROR,
    payload: error,
  };
};

export const planClearData = () => {
  return {
    type: PLAN_CLEAR_DATA,
    payload: {},
  };
};

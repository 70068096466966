import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { premiumEvalOption } from "../../store/premiumEvalOption/actions";

export const usePremiumEvalOptionGetAll = (
  trigger: boolean,
  description?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responsePremiumEvalOption, errorPremiumEvalOption, loadingPremiumEvalOption } = useSelector(
    (state: any) => ({
      responsePremiumEvalOption: state.PremiumEvalOption.responsePremiumEvalOption?.data,
      errorPremiumEvalOption: state.PremiumEvalOption.errorPremiumEvalOption,
      loadingPremiumEvalOption: state.PremiumEvalOption.loadingPremiumEvalOption,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        premiumEvalOption({
          enable: enable,
          page: page,
          limit: limit,
          description: description,
        }),
      );
    }
  }, [description,trigger]);

  return {
    responsePremiumEvalOption,
    errorPremiumEvalOption,
    loadingPremiumEvalOption,
  };
};

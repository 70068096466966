import React from "react";
import { Title } from "../../components/layout/style";
import { ContainerTitles, Title_II } from "./style";
import {
  AgentDashboardAFY,
  icons,
  sizesIcon,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Columns, dataChip } from "./agentDashboardDTO";

export const AgentDashboardView = (props: {}) => {
  return (
    <React.Fragment>
      <ContainerTitles>
        <Title>Reportes |</Title>
        <Title_II> Historial agentes</Title_II>
      </ContainerTitles>
      <AgentDashboardAFY
        titleBarChart="Producción por producto"
        titleClearButton="Limpiar filtros"
        titleLineChart="Ventas y cancelaciones"
        titlePercentage="Seguro"
        titleSemiCircleChart="Top productos<br>más<br>vendidos"
        yAxisTitle="Ventas totales"
        tableHeight="400px"
        tableWidth="100%"
        innerSize="50%"
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        backgroundColorEvenRow="#EBEEF6"
        backgroundColorOddRow="#fff"
        backgroundColorSearch="#F6F7FB"
        bgChipSelected="#EBEEF6"
        bgChips="#fff"
        rowHeight={30}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        avatarVariant={variantsAvatar.rounded}
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        columns={Columns}
        dataChips={dataChip}
        downData={[
          {
            id: "1",
            numericalTitle: "356",
            title: "Ventas netas",
          },
          {
            id: "2",
            numericalTitle: "97",
            title: "Pólizas canceladas",
          },
          {
            id: "3",
            numericalTitle: "41",
            title: "Nuevos asegurados",
          },
          {
            id: "4",
            numericalTitle: "82",
            title: "Nuevos clientes",
          },
        ]}
        rows={[
          {
            agent: "Jon",
            id: 1,
            sales: 35,
          },
          {
            agent: "Cersei",
            id: 2,
            sales: 42,
          },
          {
            agent: "Jaime",
            id: 3,
            sales: 45,
          },
          {
            agent: "Arya",
            id: 4,
            sales: 16,
          },
          {
            agent: "Daenerys",
            id: 5,
            sales: 65,
          },
          {
            agent: "Goku",
            id: 6,
            sales: 150,
          },
          {
            agent: "Ferrara",
            id: 7,
            sales: 44,
          },
          {
            agent: "Rossini",
            id: 8,
            sales: 36,
          },
          {
            agent: "Harvey",
            id: 9,
            sales: 65,
          },
          {
            agent: "Gregorio",
            id: 10,
            sales: 95,
          },
        ]}
        series={[
          {
            data: [
              {
                id: "1",
                y: 25,
              },
              {
                id: "2",
                y: 125,
              },
              {
                id: "3",
                y: 200,
              },
              {
                id: "4",
                y: 135,
              },
            ],
            name: "Seguro",
          },
        ]}
        seriesLineChart={[
          {
            data: [5.2, 5.7, 8.7, 13.9, 18.2, 21.4, 25, 22.8, 17.5, 12.1, 7.6],
            marker: {
              symbol: "square",
            },
            name: "Ventas",
          },
          {
            data: [1.6, 3.3, 5.9, 10.5, 13.5, 14.5, 14.4, 11.5, 8.7, 4.7, 2.6],
            marker: {
              symbol: "diamond",
            },
            name: "Cancelaciones",
          },
        ]}
        seriesSemiCircleChart={[
          {
            color: "#7cb5ec",
            id: "1",
            name: "cancer",
            y: 61.41,
          },
          {
            color: "#434348",
            id: "2",
            name: "autos",
            y: 11.84,
          },
          {
            color: "#90ed7d",
            id: "3",
            name: "accidentes personales",
            y: 10.85,
          },
          {
            color: "#f7a35c",
            id: "4",
            name: "daños a terceros",
            y: 4.67,
          },
          {
            color: "#f15c80",
            id: "5",
            name: "dental",
            y: 2.61,
          },
        ]}
        xAxisLineChart={[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]}
        categories={["cancer", "vehiculos", "medico", "dental"]}
        // onBlurSearch={() => {}}
        // onBlurTextSearch={function noRefCheck() {}}
        // onCloseSearch={function noRefCheck() {}}
        // onChangeSearch={function noRefCheck() {}}
        // onChangeTextSearch={function noRefCheck() {}}
        // onClickActionButton={function noRefCheck() {}}
        onChangeData={function noRefCheck() {}}
        onClickBarChart={function noRefCheck() {}}
        onClickChip={function noRefCheck() {}}
        onClickRows={function noRefCheck() {}}
        onClickSemiCircleChart={function noRefCheck() {}}
        onCloseCalendar={function noRefCheck() {}}
      />
    </React.Fragment>
  );
};

import React from "react";
import {
  ButtonAFY,
  ModalComponentAFY,
  sizes,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ButtonsContainer, Description, Title } from "./style";
import { useStyles } from "../../adminCore/sponsor/style";

export const DeleteModal = (props: any) => {
  const classes = useStyles();
  return (
    <ModalComponentAFY
      openModal={props.openModalConfirmDelete}
      backgroundColor="#ffffff"
      onClose={() => {
        props.setOpenModalConfirmDelete(false);
      }}
      border={0}
      minWidthModal={"500px"}
      padding={30}
    >
      <div style={{ marginTop: "10px" }}>
        <Title>Eliminar {props.modalTitle}</Title>
        <Description>
          ¿Está seguro que desea eliminar <strong>{props.modalTitle}</strong>?
        </Description>
        <ButtonsContainer>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"No"}
              onClick={() => {
                props.setOpenModalConfirmDelete(false);
              }}
            />
          </div>
          <div className={classes.button}>
            <ButtonAFY
              variant={variants.contained}
              size={sizes.large}
              label={"Si, eliminar"}
              backgroundColor="red"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                props.onClickDelete();
              }}
            />
          </div>
        </ButtonsContainer>
      </div>
    </ModalComponentAFY>
  );
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  projectTypeError,
  projectTypePatchError,
  projectTypePatchSuccess,
  projectTypePostError,
  projectTypePostSuccess,
  projectTypeSuccess,
} from "./actions";
import {
  PROJECT_TYPE,
  PROJECT_TYPE_PATCH,
  PROJECT_TYPE_POST,
} from "./actionTypes";
import {
  getProjectType,
  patchProjectType,
  postProjectType,
} from "../../resources/helper/projectType";

function* projectType({ payload: { projectType } }) {
  try {
    const response = yield call(
      getProjectType,
      projectType.clientId,
      projectType.enable,
      projectType.page,
      projectType.limit,
    );
    yield put(projectTypeSuccess(response));
  } catch (error) {
    yield put(projectTypeError(error));
  }
}
function* projectTypePost({ payload: { projectTypeBody } }) {
  try {
    const response = yield call(postProjectType, projectTypeBody);
    yield put(projectTypePostSuccess(response));
  } catch (error) {
    yield put(projectTypePostError(error));
  }
}

function* projectTypePatch({ payload: { projectTypePatchBody, id } }) {
  try {
    const response = yield call(patchProjectType, projectTypePatchBody, id);
    yield put(projectTypePatchSuccess(response));
  } catch (error) {
    yield put(projectTypePatchError(error));
  }
}

function* projectTypeSaga() {
  yield takeEvery(PROJECT_TYPE, projectType);
  yield takeEvery(PROJECT_TYPE_POST, projectTypePost);
  yield takeEvery(PROJECT_TYPE_PATCH, projectTypePatch);
}

export default projectTypeSaga;

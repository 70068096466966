import {
  CATALOG_PAYMENT_MODE,
  CATALOG_PAYMENT_MODE_CLEAR_DATA,
  CATALOG_PAYMENT_MODE_ERROR,
  CATALOG_PAYMENT_MODE_SUCCESS,
  GET_CATALOG_ACCOUNT_TYPE,
  GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
  GET_CATALOG_ACCOUNT_TYPE_ERROR,
  GET_CATALOG_ACCOUNT_TYPE_SUCCESS,
  GET_CATALOG_BENEFICIARY,
  GET_CATALOG_BENEFICIARY_CLEAR_DATA,
  GET_CATALOG_BENEFICIARY_ERROR,
  GET_CATALOG_BENEFICIARY_SUCCESS,
  GET_CATALOG_BY_ID,
  GET_CATALOG_BY_ID_CLEAR_DATA,
  GET_CATALOG_BY_ID_ERROR,
  GET_CATALOG_BY_ID_SUCCESS,
  GET_CATALOG_INSURANCE,
  GET_CATALOG_INSURANCE_CLEAR_DATA,
  GET_CATALOG_INSURANCE_ERROR,
  GET_CATALOG_INSURANCE_SUCCESS,
  GET_CATALOG_STATUS_QUOTE,
  GET_CATALOG_STATUS_QUOTE_CLEAR_DATA,
  GET_CATALOG_STATUS_QUOTE_ERROR,
  GET_CATALOG_STATUS_QUOTE_SUCCESS,
  GET_CATALOGS_TYPE,
  GET_CATALOGS_TYPE_CLEAR_DATA,
  GET_CATALOGS_TYPE_ERROR,
  GET_CATALOGS_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorGetCatalogById: null,
  responseGetCatalogById: null,
  loadingGetCatalogById: false,

  errorGetCatalogStatusQuote: null,
  responseGetCatalogStatusQuote: null,
  loadingGetCatalogStatusQuote: false,

  responseGetCatalogsType: null,
  errorGetCatalogsType: null,
  loadingGetCatalogsType: false,

  responseGetCatalogPaymentMode: null,
  errorGetCatalogPaymentMode: null,
  loadingGetCatalogPaymentMode: false,

  responseGetCatalogAccountType: null,
  errorGetCatalogAccountType: null,
  loadingGetCatalogAccountType: false,

  responseGetCatalogBeneficiary: null,
  errorGetCatalogBeneficiary: null,
  loadingGetCatalogBeneficiary: false,

  responseGetCatalogInsurance: null,
  errorGetCatalogInsurance: null,
  loadingGetCatalogInsurance: false,
};

const Catalog = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOG_BY_ID:
      state = {
        ...state,
        loadingGetCatalogById: true,
        errorGetCatalogById: null,
        responseGetCatalogById: true,
      };
      break;
    case GET_CATALOG_BY_ID_SUCCESS:
      state = {
        ...state,
        errorGetCatalogById: null,
        loadingGetCatalogById: false,
        responseGetCatalogById: action.payload,
      };
      break;
    case GET_CATALOG_BY_ID_ERROR:
      state = {
        ...state,
        errorGetCatalogById: action.payload,
        loadingGetCatalogById: false,
        responseGetCatalogById: null,
      };
      break;
    case GET_CATALOG_BY_ID_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogById: null,
        loadingGetCatalogById: null,
        responseGetCatalogById: false,
      };
      break;
    case GET_CATALOG_STATUS_QUOTE:
      state = {
        ...state,
        loadingGetCatalogStatusQuote: true,
        errorGetCatalogStatusQuote: null,
        responseGetCatalogStatusQuote: null,
      };
      break;
    case GET_CATALOG_STATUS_QUOTE_SUCCESS:
      state = {
        ...state,
        errorGetCatalogStatusQuote: null,
        loadingGetCatalogStatusQuote: false,
        responseGetCatalogStatusQuote: action.payload,
      };
      break;
    case GET_CATALOG_STATUS_QUOTE_ERROR:
      state = {
        ...state,
        errorGetCatalogStatusQuote: action.payload,
        loadingGetCatalogStatusQuote: false,
        responseGetCatalogStatusQuote: null,
      };
      break;
    case GET_CATALOG_STATUS_QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogStatusQuote: null,
        loadingGetCatalogStatusQuote: null,
        responseGetCatalogStatusQuote: false,
      };
      break;
    case GET_CATALOGS_TYPE:
      state = {
        ...state,
        loadingGetCatalogsType: true,
        errorGetCatalogsType: null,
        responseGetCatalogsType: null,
      };
      break;
    case GET_CATALOGS_TYPE_SUCCESS:
      state = {
        ...state,
        errorGetCatalogsType: null,
        loadingGetCatalogsType: false,
        responseGetCatalogsType: action.payload,
      };
      break;
    case GET_CATALOGS_TYPE_ERROR:
      state = {
        ...state,
        errorGetCatalogsType: action.payload,
        loadingGetCatalogsType: false,
        responseGetCatalogsType: null,
      };
      break;
    case GET_CATALOGS_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogsType: null,
        loadingGetCatalogsType: null,
        responseGetCatalogsType: false,
      };
      break;
    case CATALOG_PAYMENT_MODE:
      state = {
        ...state,
        loadingGetCatalogPaymentMode: true,
        errorGetCatalogPaymentMode: null,
        responseGetCatalogPaymentMode: null,
      };
      break;
    case CATALOG_PAYMENT_MODE_SUCCESS:
      state = {
        ...state,
        errorGetCatalogPaymentMode: null,
        loadingGetCatalogPaymentMode: false,
        responseGetCatalogPaymentMode: action.payload,
      };
      break;
    case CATALOG_PAYMENT_MODE_ERROR:
      state = {
        ...state,
        errorGetCatalogPaymentMode: action.payload,
        loadingGetCatalogPaymentMode: false,
        responseGetCatalogPaymentMode: null,
      };
      break;
    case CATALOG_PAYMENT_MODE_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogPaymentMode: null,
        loadingGetCatalogPaymentMode: null,
        responseGetCatalogPaymentMode: false,
      };
      break;
    case GET_CATALOG_ACCOUNT_TYPE:
      state = {
        ...state,
        loadingGetCatalogAccountType: true,
        errorGetCatalogAccountType: null,
        responseGetCatalogAccountType: null,
      };
      break;
    case GET_CATALOG_ACCOUNT_TYPE_SUCCESS:
      state = {
        ...state,
        errorGetCatalogAccountType: null,
        loadingGetCatalogAccountType: false,
        responseGetCatalogAccountType: action.payload,
      };
      break;
    case GET_CATALOG_ACCOUNT_TYPE_ERROR:
      state = {
        ...state,
        errorGetCatalogAccountType: action.payload,
        loadingGetCatalogAccountType: false,
        responseGetCatalogAccountType: null,
      };
      break;
    case GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogAccountType: null,
        loadingGetCatalogAccountType: null,
        responseGetCatalogAccountType: false,
      };
      break;
    //
    case GET_CATALOG_BENEFICIARY:
      state = {
        ...state,
        loadingGetCatalogBeneficiary: true,
        errorGetCatalogBeneficiary: null,
        responseGetCatalogBeneficiary: null,
      };
      break;
    case GET_CATALOG_BENEFICIARY_SUCCESS:
      state = {
        ...state,
        errorGetCatalogBeneficiary: null,
        loadingGetCatalogBeneficiary: false,
        responseGetCatalogBeneficiary: action.payload,
      };
      break;
    case GET_CATALOG_BENEFICIARY_ERROR:
      state = {
        ...state,
        errorGetCatalogBeneficiary: action.payload,
        loadingGetCatalogBeneficiary: false,
        responseGetCatalogBeneficiary: null,
      };
      break;
    case GET_CATALOG_BENEFICIARY_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogBeneficiary: null,
        loadingGetCatalogBeneficiary: null,
        responseGetCatalogBeneficiary: false,
      };
      break;
    //
    case GET_CATALOG_INSURANCE:
      state = {
        ...state,
        loadingGetCatalogInsurance: true,
        errorGetCatalogInsurance: null,
        responseGetCatalogInsurance: null,
      };
      break;
    case GET_CATALOG_INSURANCE_SUCCESS:
      state = {
        ...state,
        errorGetCatalogInsurance: null,
        loadingGetCatalogInsurance: false,
        responseGetCatalogInsurance: action.payload,
      };
      break;
    case GET_CATALOG_INSURANCE_ERROR:
      state = {
        ...state,
        errorGetCatalogInsurance: action.payload,
        loadingGetCatalogInsurance: false,
        responseGetCatalogInsurance: null,
      };
      break;
    case GET_CATALOG_INSURANCE_CLEAR_DATA:
      state = {
        ...state,
        errorGetCatalogInsurance: null,
        loadingGetCatalogInsurance: null,
        responseGetCatalogInsurance: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Catalog;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchesPost } from "../../store/branches/actions";

export const useBranchesPost = (trigger: boolean, body?: any) => {
  const dispatch = useDispatch();

  const { responseBranchesPost, errorBranchesPost, loadingBranchesPost } =
    useSelector((state: any) => ({
      responseBranchesPost: state.Branches.responseBranchesPost?.data,
      errorBranchesPost: state.Branches.errorBranchesPost,
      loadingBranchesPost: state.Branches.loadingBranchesPost,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        branchesPost({
          name: body.data.name,
          code: body.data.code,
          status: body.data.status,
          clientId: body.data.clientId,
        }),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseBranchesPost,
    errorBranchesPost,
    loadingBranchesPost,
  };
};

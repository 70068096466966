export enum Steps {
  insurance = 0,
  plan = 1,
  customer = 2,
  beneficiary = 3,
  confirmData = 4,
  pay = 5,
  vehiclesDetails = 7,
  auditDocumentUrl = 8,
  initialInfo = 9,
  applicantDeclaration = 10,
  financialInformation = 11,
  medicalInformation = 12,
}

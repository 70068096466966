import { ContainerImg, useStyles } from "./style";
import {
  IconAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";

export const Icon = (props: {
  logoImg: string;
  showIcon: boolean;
  iconPage: icons;
}) => {
  const classes = useStyles();
  return (
    <>
      {props.showIcon && (
        <div style={{ margin: "0 0 0 5px" }}>
          {props.logoImg ? (
            <ContainerImg>
              <img
                src={props.logoImg}
                alt={"logo"}
                className={classes.imgLogo}
              />
            </ContainerImg>
          ) : (
            <div style={{ marginTop: "5px" }}>
              <IconAFY
                color="#2d488d"
                icon={props.iconPage}
                size={sizesIcon.standard}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

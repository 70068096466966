import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalCatalogPatch } from "../../store/generalCatalog/actions";

export const useGeneralCatalogPatch = (
  trigger: boolean,
  body?: any,
  id?: string,
) => {
  const dispatch = useDispatch();

  const {
    responseGeneralCatalogPatch,
    errorGeneralCatalogPatch,
    loadingGeneralCatalogPatch,
  } = useSelector((state: any) => ({
    responseGeneralCatalogPatch:
      state.GeneralCatalog.responseGeneralCatalogPatch?.data,
    errorGeneralCatalogPatch: state.GeneralCatalog.errorGeneralCatalogPatch,
    loadingGeneralCatalogPatch: state.GeneralCatalog.loadingGeneralCatalogPatch,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        generalCatalogPatch(
          {
            clientId: body.data.clientId,
            parentCatalogId: null,
            type: body.data.type,
            code: body.data.code,
            description: body.data.description,
          },
          id,
        ),
      );
    }
  }, [trigger, body.data.clientId]);

  return {
    responseGeneralCatalogPatch,
    errorGeneralCatalogPatch,
    loadingGeneralCatalogPatch,
  };
};

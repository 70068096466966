import {
  iconsSvg,
  InsurancePageProps,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const insuranceDTO: InsurancePageProps = {
  title: "Seguro",
  filter: {
    backgroundColor: "",
    bgChipInsurers: "",
    bgChipInsurersSelected: "",
    bgColorButton: "",
    bgColorIconButton: "",
    bgColorIconButtonSelected: "",
    colorIconButton: "",
    colorIconButtonSelected: "",
    colorIconChip: "",
    colorText: "",
    insurers: [],
  },
  data: [
    {
      iconSvg: iconsSvg.Accidentes,
      id: "1",
      title: "Asistencia",
    },
    {
      iconSvg: iconsSvg.Prestamos,
      id: "2",
      title: "Accidentes Personales",
    },
    {
      iconSvg: iconsSvg.Vida,
      id: "3",
      title: "Vida",
    },
    {
      iconSvg: iconsSvg.Mascotas,
      id: "4",
      title: "Mascotas",
    },
    {
      iconSvg: iconsSvg.Prestamos,
      id: "5",
      title: "Préstamos",
    },
    {
      iconSvg: iconsSvg.Hogar,
      id: "6",
      title: "Hogar",
    },
  ],
};

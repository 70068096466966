import { call, put, takeEvery } from "redux-saga/effects";
import {
  pageActionFlagsError,
  pageActionFlagsSuccess,
  postActionFlagsError,
  postActionFlagsSuccess,
} from "./actions";
import {
  getPageActionFlags,
  postPageActionFlags,
} from "../../resources/helper/pageActionFlags";
import {
  PAGEACTIONFLAGS,
  PAGEACTIONFLAGS_CLEAR_DATA,
  POST_ACTION_FLAGS,
} from "./actionTypes";

function* pageActionFlags({ payload: { pageActionFlags } }) {
  try {
    const response = yield call(
      getPageActionFlags,
      pageActionFlags.projectId,
      pageActionFlags.clientId,
      pageActionFlags.enable,
      pageActionFlags.page,
      pageActionFlags.limit,
    );
    yield put(pageActionFlagsSuccess(response));
  } catch (error) {
    yield put(pageActionFlagsError(error));
  }
}

function* postActionFlags({ payload: { actionFlags } }) {
  try {
    const response = yield call(postPageActionFlags, actionFlags);
    yield put(postActionFlagsSuccess(response));
  } catch (error) {
    yield put(postActionFlagsError(error));
  }
}

function* pageActionFlagsClearData() {
  try {
    yield put(pageActionFlagsClearData(true));
  } catch (error) {
    yield put(pageActionFlagsError(error));
  }
}

function* pageActionFlagsSaga() {
  yield takeEvery(PAGEACTIONFLAGS, pageActionFlags);
  yield takeEvery(PAGEACTIONFLAGS_CLEAR_DATA, pageActionFlagsClearData);
  yield takeEvery(POST_ACTION_FLAGS, postActionFlags);
}

export default pageActionFlagsSaga;

import { buttonActionOptions } from "../salesCore/reportsPage/completedSales/completedDTO";
import { downloadGenerateQuoteAPI } from "../resources/helper/generateQuote";

export const downloadReports = async (
  item: buttonActionOptions,
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
  catalogStatusQuote: string,
  startDate: string,
  endDate: string,
  fields: string,
  labels: string,
) => {
  try {
    const blob = await downloadGenerateQuoteAPI(
      clientId,
      enable,
      page,
      limit,
      catalogStatusQuote,
      startDate,
      endDate,
      fields,
      labels,
      item.id === "1"
        ? "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "pdf",
    );
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download =
      item.id === "1" ? "nombre_del_archivo.xlsx" : "nombre_del_archivo.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
  }
};

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { patchQuote } from "../../store/updateQuote/actions";
import { quoteBodyPatch } from "../../apap/salesCore/planDetail/planDetailDto";

export const usePatchQuote = (
  trigger: boolean,
  body?: quoteBodyPatch,
  id?: string,
) => {
  const dispatch = useDispatch();

  const { responsePatchQuote, errorPatchQuote, loadingPatchQuote } =
    useSelector((state: any) => ({
      responsePatchQuote: state.UpdateQuote.responsePatchQuote?.data,
      errorPatchQuote: state.UpdateQuote.errorPatchQuote,
      loadingPatchQuote: state.UpdateQuote.loadingPatchQuote,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(patchQuote({ body }, id));
    }
  }, [trigger, body?.planId]);

  return {
    responsePatchQuote,
    errorPatchQuote,
    loadingPatchQuote,
  };
};
import {
  PERSON,
  PERSON_BY_DOCUMENT,
  PERSON_BY_DOCUMENT_CLEAR_DATA,
  PERSON_BY_DOCUMENT_ERROR,
  PERSON_BY_DOCUMENT_SUCCESS,
  PERSON_BY_ID,
  PERSON_BY_ID_CLEAR_DATA,
  PERSON_BY_ID_ERROR,
  PERSON_BY_ID_SUCCESS,
  PERSON_CLEAR_DATA,
  PERSON_ERROR,
  PERSON_SUCCESS,
  UPDATE_PERSON,
  UPDATE_PERSON_CLEAR_DATA,
  UPDATE_PERSON_ERROR,
  UPDATE_PERSON_SUCCESS,
} from "./actionTypes";

export const person = (person, history) => {
  return {
    type: PERSON,
    payload: { person, history },
  };
};

export const personSuccess = (success) => {
  return {
    type: PERSON_SUCCESS,
    payload: success,
  };
};

export const personError = (error) => {
  return {
    type: PERSON_ERROR,
    payload: error,
  };
};

export const personClearData = () => {
  return {
    type: PERSON_CLEAR_DATA,
    payload: {},
  };
};

export const personById = (personId) => {
  return {
    type: PERSON_BY_ID,
    payload: { personId },
  };
};

export const personByIdSuccess = (success) => {
  return {
    type: PERSON_BY_ID_SUCCESS,
    payload: success,
  };
};

export const personByIdError = (error) => {
  return {
    type: PERSON_BY_ID_ERROR,
    payload: error,
  };
};

export const personByIdClearData = () => {
  return {
    type: PERSON_BY_ID_CLEAR_DATA,
    payload: {},
  };
};

export const personByDocument = (document, history) => {
  return {
    type: PERSON_BY_DOCUMENT,
    payload: { document, history },
  };
};

export const personByDocumentSuccess = (success) => {
  return {
    type: PERSON_BY_DOCUMENT_SUCCESS,
    payload: success,
  };
};

export const personByDocumentError = (error) => {
  return {
    type: PERSON_BY_DOCUMENT_ERROR,
    payload: error,
  };
};

export const personByDocumentClearData = () => {
  return {
    type: PERSON_BY_DOCUMENT_CLEAR_DATA,
    payload: {},
  };
};

export const updatePerson = (person) => {
  return {
    type: UPDATE_PERSON,
    payload: { person },
  };
};

export const updatePersonSuccess = (success) => {
  return {
    type: UPDATE_PERSON_SUCCESS,
    payload: success,
  };
};

export const updatePersonError = (error) => {
  return {
    type: UPDATE_PERSON_ERROR,
    payload: error,
  };
};

export const updatePersonClearData = () => {
  return {
    type: UPDATE_PERSON_CLEAR_DATA,
    payload: {},
  };
};

import {
  PAGEACTIONFLAGS,
  PAGEACTIONFLAGS_CLEAR_DATA,
  PAGEACTIONFLAGS_ERROR,
  PAGEACTIONFLAGS_SUCCESS,
  POST_ACTION_FLAGS,
  POST_ACTION_FLAGS_CLEAR_DATA,
  POST_ACTION_FLAGS_ERROR,
  POST_ACTION_FLAGS_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPageActionFlags: null,
  responsePageActionFlags: null,
  loadingPageActionFlags: false,

  loadingPostActionFlags: false,
  responsePostActionFlags: null,
  errorPostActionFlags: null,
};

const pageActionFlagsApi = (state = initialState, action) => {
  switch (action.type) {
    case PAGEACTIONFLAGS:
      state = {
        ...state,
        loadingPageActionFlags: true,
        errorPageActionFlags: null,
        responsePageActionFlags: true,
      };
      break;
    case PAGEACTIONFLAGS_SUCCESS:
      state = {
        ...state,
        errorPageActionFlags: null,
        loadingPageActionFlags: false,
        responsePageActionFlags: action.payload,
      };
      break;
    case PAGEACTIONFLAGS_ERROR:
      state = {
        ...state,
        errorPageActionFlags: action.payload,
        loadingPageActionFlags: false,
        responsePageActionFlags: null,
      };
      break;
    case PAGEACTIONFLAGS_CLEAR_DATA:
      state = {
        ...state,
        errorPageActionFlags: null,
        loadingPageActionFlags: null,
        responsePageActionFlags: false,
      };
      break;
    //
    case POST_ACTION_FLAGS:
      state = {
        ...state,
        loadingPostActionFlags: true,
        errorPostActionFlags: null,
        responsePostActionFlags: true,
      };
      break;
    case POST_ACTION_FLAGS_SUCCESS:
      state = {
        ...state,
        errorPostActionFlags: null,
        loadingPostActionFlags: false,
        responsePostActionFlags: action.payload,
      };
      break;
    case POST_ACTION_FLAGS_ERROR:
      state = {
        ...state,
        errorPostActionFlags: action.payload,
        loadingPostActionFlags: false,
        responsePostActionFlags: null,
      };
      break;
    case POST_ACTION_FLAGS_CLEAR_DATA:
      state = {
        ...state,
        errorPostActionFlags: null,
        loadingPostActionFlags: null,
        responsePostActionFlags: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default pageActionFlagsApi;

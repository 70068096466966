import {
  icons,
  MenuItem,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const ItemMenu: MenuItem[] = [
  // {
  //   disabled: true,
  //   divider: true,
  //   icon: {
  //     color: "#006ac6",
  //     icon: icons.Settings,
  //     size: sizesIcon.standard,
  //   },
  //   id: "1",
  //   name: "Setting",
  // },
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.Logout,
      size: sizesIcon.standard,
    },
    id: "1",
    name: "Cerrar sesión",
  },
];

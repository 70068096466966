import { useParams } from "react-router-dom";
import {
  ContextMenuAFY,
  DataFormClient,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { SpanStatus } from "./style";

export const ColumnsPaymentRecurrence = (clickContextMenuRecurrence: any) => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {(!idProduct || (idProduct && isEdit === "true")) && (
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              onClickContextMenu={() => clickContextMenuRecurrence(params?.row)}
              sizeIcon={sizesIcon.standard}
            />
          )}
        </div>
      ),
    },
    {
      editable: false,
      field: "code",
      flex: 1,
      headerName: "Código",
      width: 150,
    },
    {
      editable: false,
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};

export const ColumnsPaymentMode = (clickContextMenuPaymentMode: any) => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {(!idProduct || (idProduct && isEdit === "true")) && (
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              onClickContextMenu={() =>
                clickContextMenuPaymentMode(params?.row)
              }
              sizeIcon={sizesIcon.standard}
            />
          )}
        </div>
      ),
    },

    {
      editable: false,
      field: "code",
      flex: 1,
      headerName: "Código",
      width: 150,
    },
    {
      editable: false,
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};

export const ColumnsPlans = (clickContextMenuPlans: any) => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {(!idProduct || (idProduct && isEdit === "true")) && (
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.Visibility,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Ver plan",
                },
              ]}
              onClickContextMenu={() => clickContextMenuPlans(params?.row)}
              sizeIcon={sizesIcon.standard}
            />
          )}
        </div>
      ),
    },

    {
      field: "code",
      headerName: "Código",
      width: 180,
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 180,
      flex: 1,
    },
    {
      field: "coverType",
      headerName: "Tipo de cobertura",
      width: 230,
      flex: 1,
    },
  ];
};

export const ColumnsActionsFlags = () => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            {(!idProduct || (idProduct && isEdit === "true")) && (
              <ContextMenuAFY
                colorButton="#fff"
                iconButton={icons.MoreVert}
                iconColor="#3A56A1"
                menuItem={[
                  params.row.value === "Activo"
                    ? {
                        disabled: false,
                        icon: {
                          color: "#ef2f24",
                          icon: icons.Unpublished,
                          size: sizesIcon.standard,
                        },
                        id: "1",
                        name: "Desactivar",
                      }
                    : {
                        disabled: false,
                        icon: {
                          color: "#46c51a",
                          icon: icons.CheckCircle,
                          size: sizesIcon.standard,
                        },
                        id: "2",
                        name: "Activar",
                      },
                ]}
                // onClickContextMenu={(item: any) =>
                //   // handleClickContextMenu(item, params)
                // }
                sizeIcon={sizesIcon.standard}
              />
            )}
          </div>
        );
      },
    },

    {
      editable: false,
      field: "value",
      headerName: "Estado",
      width: 180,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <SpanStatus
              style={{
                backgroundColor:
                  params.row.value === "Activo" ? "#46c51a" : "#ef2f24",
              }}
            ></SpanStatus>
            {params.value}
          </div>
        );
      },
    },
    {
      editable: false,
      field: "name",
      headerName: "Nombre",
      width: 180,
      flex: 1,
    },
  ];
};

export const dataProductCreationForm: DataFormClient = {
  IdSponsor: "",
  IdTypeInsurance: "",
  Description: "",
  Code: "",
  IdCurrency: "",
  CertifyNumber: "",
  QuotesNumber: 0,
  PolicyNumber: 0,
};

export const dataForm: DataFormClient = {
  CampaignName: "",
  CodeCampaign: "",
  StartDateCampaign: "",
  EndDateCampaign: "",
};

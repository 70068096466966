import {
  PROJECT_TYPE,
  PROJECT_TYPE__POST_ERROR,
  PROJECT_TYPE_CLEAR_DATA,
  PROJECT_TYPE_ERROR,
  PROJECT_TYPE_PATCH,
  PROJECT_TYPE_PATCH_CLEAR_DATA,
  PROJECT_TYPE_PATCH_ERROR,
  PROJECT_TYPE_PATCH_SUCCESS,
  PROJECT_TYPE_POST,
  PROJECT_TYPE_POST_CLEAR_DATA,
  PROJECT_TYPE_POST_SUCCESS,
  PROJECT_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorProjectType: null,
  responseProjectType: null,
  loadingProjectType: false,
  errorProjectTypePost: null,
  responseProjectTypePost: null,
  loadingProjectTypePost: false,
  errorProjectTypePatch: null,
  responseProjectTypePatch: null,
  loadingProjectTypePatch: false,
};

const ProjectType = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_TYPE:
      state = {
        ...state,
        loadingProjectType: true,
        errorProjectType: null,
        responseProjectType: null,
      };
      break;
    case PROJECT_TYPE_SUCCESS:
      state = {
        ...state,
        errorProjectType: null,
        loadingProjectType: false,
        responseProjectType: action.payload,
      };
      break;
    case PROJECT_TYPE_ERROR:
      state = {
        ...state,
        errorProjectType: action.payload,
        loadingProjectType: false,
        responseProjectType: null,
      };
      break;
    case PROJECT_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorProjectType: null,
        loadingProjectType: null,
        responseProjectType: false,
      };
      break;
    case PROJECT_TYPE_POST:
      state = {
        ...state,
        loadingProjectTypePost: true,
        errorProjectTypePost: null,
        responseProjectTypePost: null,
      };
      break;
    case PROJECT_TYPE_POST_SUCCESS:
      state = {
        ...state,
        errorProjectTypePost: null,
        loadingProjectTypePost: false,
        responseProjectTypePost: action.payload,
      };
      break;
    case PROJECT_TYPE__POST_ERROR:
      state = {
        ...state,
        errorProjectTypePost: action.payload,
        loadingProjectTypePost: false,
        responseProjectTypePost: null,
      };
      break;
    case PROJECT_TYPE_POST_CLEAR_DATA:
      state = {
        ...state,
        errorProjectTypePost: null,
        loadingProjectTypePost: null,
        responseProjectTypePost: false,
      };
      break;
    case PROJECT_TYPE_PATCH:
      state = {
        ...state,
        loadingProjectTypePatch: true,
        errorProjectTypePatch: null,
        responseProjectTypePatch: null,
      };
      break;
    case PROJECT_TYPE_PATCH_SUCCESS:
      state = {
        ...state,
        errorProjectTypePatch: null,
        loadingProjectTypePatch: false,
        responseProjectTypePatch: action.payload,
      };
      break;
    case PROJECT_TYPE_PATCH_ERROR:
      state = {
        ...state,
        errorProjectTypePatch: action.payload,
        loadingProjectTypePatch: false,
        responseProjectTypePatch: null,
      };
      break;
    case PROJECT_TYPE_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorProjectTypePatch: null,
        loadingProjectTypePatch: null,
        responseProjectTypePatch: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ProjectType;

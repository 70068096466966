export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_CLEAR_DATA = "LOGIN_USER_CLEAR_DATA";

export const USER_CREATE = "USER_CREATE";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_ERROR = "USER_CREATE_ERROR";
export const USER_CREATE_CLEAR_DATA = "USER_CREATE_CLEAR_DATA";

export const USER = "USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_CLEAR_DATA = "USER_CLEAR_DATA";

export const USER_PASSWORD_RESET = "USER_PASSWORD_RESET";
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS";
export const USER_PASSWORD_RESET_ERROR = "USER_PASSWORD_RESET_ERROR";
export const USER_PASSWORD_RESET_CLEAR_DATA = "USER_PASSWORD_RESET_CLEAR_DATA";

export const USER_CONFIRM_PASSWORD = "USER_CONFIRM_PASSWORD";
export const USER_CONFIRM_PASSWORD_SUCCESS = "USER_CONFIRM_PASSWORD_SUCCESS";
export const USER_CONFIRM_PASSWORD_ERROR = "USER_CONFIRM_PASSWORD_ERROR";
export const USER_CONFIRM_PASSWORD_CLEAR_DATA =
  "USER_CONFIRM_PASSWORD_CLEAR_DATA";

export const USER_CONFIRM_EMAIL = "USER_CONFIRM_EMAIL";
export const USER_CONFIRM_EMAIL_SUCCESS = "USER_CONFIRM_EMAIL_SUCCESS";
export const USER_CONFIRM_EMAIL_ERROR = "USER_CONFIRM_EMAIL_ERROR";
export const USER_CONFIRM_EMAIL_CLEAR_DATA = "USER_CONFIRM_EMAIL_CLEAR_DATA";

export const USER_PATCH = "USER_PATCH";
export const USER_PATCH_SUCCESS = "USER_PATCH_SUCCESS";
export const USER_PATCH_ERROR = "USER_PATCH_ERROR";
export const USER_PATCH_CLEAR_DATA = "USER_PATCH_CLEAR_DATA";

export const USER_PUT_ROL = "USER_PUT_ROL";
export const USER_PUT_ROL_SUCCESS = "USER_PUT_ROL_SUCCESS";
export const USER_PUT_ROL_ERROR = "USER_PUT_ROL_ERROR";
export const USER_PUT_ROL_CLEAR_DATA = "USER_PUT_ROL_CLEAR_DATA";
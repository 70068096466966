import { call, put, takeEvery } from "redux-saga/effects";
import { cancelQuoteError, cancelQuoteSuccess } from "./actions";
import { cancelQuoteAPI } from "../../resources/helper/cancelQuote";
import { CANCEL_QUOTE } from "./actionTypes";

function* cancelQuote({ payload: { quote } }) {
  try {
    const response = yield call(cancelQuoteAPI, quote.id);
    yield put(cancelQuoteSuccess(response));
  } catch (error) {
    yield put(cancelQuoteError(error));
  }
}

function* CancelQuoteSaga() {
  yield takeEvery(CANCEL_QUOTE, cancelQuote);
}

export default CancelQuoteSaga;

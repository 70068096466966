import {
  position,
  theme,
  ToastAFY,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

const showToast = (
  position: position | undefined,
  typeAlert: typeAlert,
  message: string,
  onClose:  () => void,

) => {
  return (
    <ToastAFY
      autoclose={5000}
      closeOnClick
      newstOnTop
      pauseOnHover
      position={position}
      theme={theme.colored}
      title={message}
      type={typeAlert}
      onClose={() => onClose && onClose()}
    />
  );
};
export default showToast;



import {
  CarCondition,
  FuelType,
  NumberCylinders,
} from "../../../enum/cardDetailsForm";
import { TFunction } from "i18next";
import { DataFormVehiclesNew } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const filtersData = (insurance: string, tl: TFunction) => {
  return [
    {
      label: insurance,
      name: tl(`carDetails.insuranceType`),
      type: "label",
    },
  ];
};

export const getNumberCylindersOption = [
  { id: NumberCylinders.DOS, name: "2" },
  { id: NumberCylinders.TRES, name: "3" },
  { id: NumberCylinders.CUATRO, name: "4" },
  { id: NumberCylinders.CINCO, name: "5" },
  { id: NumberCylinders.SEIS, name: "6" },
  { id: NumberCylinders.OCHO, name: "8" },
  { id: NumberCylinders.DIEZ, name: "10" },
  { id: NumberCylinders.DOCE, name: "12" },
];

export const getFuelTypeOption = (tl: TFunction) => [
  {
    id: FuelType.GASOLINE,
    name: tl(`carDetails.fullType.gasolina`),
  },
  {
    id: FuelType.DIESEL,
    name: tl(`carDetails.fullType.diesel`),
  },
  {
    id: FuelType.ELECTRIC,
    name: tl(`carDetails.fullType.eléctrico`),
  },
  {
    id: FuelType.GAS,
    name: tl(`carDetails.fullType.gas`),
  },
];

export const carConditionOption = (tl: TFunction) => [
  {
    id: CarCondition.NEW,
    name: tl(`carDetails.carCondition.nuevo`),
  },
  {
    id: CarCondition.USED,
    name: tl(`carDetails.carCondition.usado`),
  },
];

export const dataEditVehicleForm: DataFormVehiclesNew = {
  carDetails: null,
  year: "",
  carColor: null,
  chassisNumber: "",
  price: "",
  numberCylinders: null,
  fuelType: null,
  registrationNumber: "",
  carCondition: null,
};

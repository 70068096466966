import React, { useEffect } from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  ButtonAFY,
  icons,
  InformationAFY,
  InputAFY,
  ModalComponentAFY,
  PoliciesAFY,
  ReportFiltersAFY,
  SelectAFY,
  sizes,
  sizesIcon,
  sizesSelect,
  sizesTextField,
  variants,
  variantsAvatar,
  variantsTextField,
  BillingAFY,

} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions } from "./insurancePoliciesDTO";
import { themeCore } from "../../assets/themes/theme";
import { Title } from "../../components/layout/style";
import MetaTags from "react-meta-tags";


const View = (props: any) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState<any>(props.products);

  useEffect(() => {
    if (props.products && props.products.length > 0){
      setProducts(props.products);
    }
  }
  , [props.products]);

  return (
    <React.Fragment>
      <Title>Formulario</Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description="Seleccione los proyectos a facturar y configure las opciones de facturación."
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />


      <div className="containerPanel" style={{ marginTop: "15px" }}>
        
        <BillingAFY
          backgroundColorBack={themeCore.colors.primary}
          backgroundColorNext={themeCore.colors.primary}
          title={""}
          drawerTitle={"¡Listo!"}
          drawerText={"Se ha enviado la información de facturación a los proyectos seleccionados."}
          register={null}
          onClickDefaultValues={props.onClickDefaultValues}
          onClickNext={props.onClickNext}
          periodLabels={{
            title: "Periodo de facturación",
            billingMonthDateLabel: "Mes de facturación",
            billingMonthDateValue: "2021-09-01",
            primePeriodDateLabel: "Periodo de prima",
            primePeriodStartDateValue: "2021-09-01",
            primePeriodEndDateValue: "2021-09-30",
            primeEvaluationDateLabel: "Fecha de evaluación de prima",
            primeEvaluationDateValue: "2021-09-30",
            processDateLabel: "Fecha de proceso",
            processDateValue: "2021-09-30",
            newBillingDateLabel: "Fecha de nueva factura",
            newBillingDateValue: "2021-09-30",
            rebillingDateLabel: "Fecha de refacturación",
            rebillingDateValue: "2021-09-30",
            involunteerCancellationDateLabel: "Fecha de cancelación involuntaria",
            involunteerCancellationDateValue: "2021-09-30",
            defaultValuesLabel: "Restaurar valores predeterminados",
            exchangeRateLabel: "Convertir a moneda local",
            exchangeRateValue: "false",
            exchangeRateMultiplierLabel: "Multiplicador de tasa de cambio",
            exchangeRateMultiplierValue: 0,
            notesLabel: "Nota: Tasa de cambio aplica solamente a proyectos en dólares de EE.UU.",
          }}
          optionsLabels={{
            title: "Opciones de facturación",
            option1Label: "Cada día que se generen",
            option2Label: "Acumular todos y enviar el día",
            cancelAfterLabel: "Cancelar la póliza después de (meses)",
            maximumLimitLabel: "Límite máximo de refacturación",
            numberMaximumLabel: "No. Máximo de facturas por póliza",
            noRebillLabel: "No recobrar facturas después de",
            noCancelExpiredPolicyLabel: "No cancelar póliza vencida",
            noExpiredPolicyAgeLabel: "No expirar pólizas por edad",
            justRebillNoNewInvoiceLabel: "Solo refacturar -No nueva factura",
            justNewInvoiceNoRebillLabel: "Solo nuevas facturas - No refacturar",
            sixMonthsExistingPolicyLabel: "Crear facturas no emitidas de los últimos 6 meses - Póliza existente",
            sixMonthsNewPolicyLabel: "Crear facturas no emitidas de los últimos 6 meses - Póliza nueva",
            ignoreLabel: "Ignorar: no procesar controles de facturación de pólizas individuales"
          }}
          projectsLabels={{
            title: "Proyectos a facturar",
            billingSponsorsLabel: "Facturar todos los Sponsors y productos",
            selectSponsorLabel: "Seleccionar los Sponsors y productos a facturar",
            unselectSponsorLabel: "Deseleccionar todo",
            columns: [
              {
                field: 'type',
                headerName: 'Proyecto',
                width: 150,
                editable: true,
                flex: 1,
              },
              {
                field: 'sponsor',
                headerName: 'Sponsor',
                width: 150,
                editable: true,
                flex: 1,
              },
              {
                field: 'product',
                headerName: 'Producto',
                width: 150,
                editable: true,
                flex: 1,
              },
              {
                field: 'currency',
                headerName: 'Moneda',
                width: 150,
                editable: true,
                flex: 1,
              }],
            rows: products
          }}
          next={{
            nextLabel: "Siguiente",
            previousLabel: "Anterior",
            nextDisabled: false,
          }
          }
        />
      </div>
    </React.Fragment>
  );
};

export default View;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPasswordReset } from "../../store/auth/actions";

export const UseUserResetPassword = (
  trigger: boolean,
  email: string,
) => {
  const dispatch = useDispatch();

  const { responsePasswordReset, errorPasswordReset, loadingPasswordReset } = useSelector(
    (state: any) => ({
      responsePasswordReset: state.Login.responsePasswordReset?.data,
      errorPasswordReset: state.Login.errorPasswordReset,
      loadingPasswordReset: state.Login.loadingPasswordReset,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        userPasswordReset(email),
      );
    }
  }, [email, trigger]);

  return {
    responsePasswordReset,
    errorPasswordReset,
    loadingPasswordReset,
  };
};
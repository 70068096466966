import {
  COVER_TYPE_PLAN_CLEAR_DATA,
  COVER_TYPE_PLAN,
  COVER_TYPE_PLAN_ERROR,
  COVER_TYPE_PLAN_SUCCESS,
  COVER_TYPE_PLAN_GET_ONE,
  COVER_TYPE_PLAN_GET_ONE_SUCCESS,
  COVER_TYPE_PLAN_GET_ONE_ERROR,
  COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA,
  COVER_TYPE_PLAN_POST,
  COVER_TYPE_PLAN_POST_SUCCESS,
  COVER_TYPE_PLAN_POST_ERROR,
  COVER_TYPE_PLAN_POST_CLEAR_DATA,
  COVER_TYPE_PLAN_PATCH,
  COVER_TYPE_PLAN_PATCH_SUCCESS,
  COVER_TYPE_PLAN_PATCH_ERROR,
  COVER_TYPE_PLAN_PATCH_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorCoverTypePlan: null,
  responseCoverTypePlan: null,
  loadingCoverTypePlan: false,
  errorPostCoverTypePlan: null,
  responsePostCoverTypePlan: null,
  loadingPostCoverTypePlan: false,
  errorPatchCoverTypePlan: null,
  loadingPatchCoverTypePlan: null,
  responsePatchCoverTypePlan: false,
};

const coverTypePlan = (state = initialState, action) => {
  switch (action.type) {
    case COVER_TYPE_PLAN:
      state = {
        ...state,
        loadingCoverTypePlan: true,
        errorCoverTypePlan: null,
        responseCoverTypePlan: true,
      };
      break;
    case COVER_TYPE_PLAN_SUCCESS:
      state = {
        ...state,
        errorCoverTypePlan: null,
        loadingCoverTypePlan: false,
        responseCoverTypePlan: action.payload,
      };
      break;
    case COVER_TYPE_PLAN_ERROR:
      state = {
        ...state,
        errorCoverTypePlan: action.payload,
        loadingCoverTypePlan: false,
        responseCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_CLEAR_DATA:
      state = {
        ...state,
        errorCoverTypePlan: null,
        loadingCoverTypePlan: null,
        responseCoverTypePlan: false,
      };
      break;
      case COVER_TYPE_PLAN_GET_ONE:
        state = {
          ...state,
          loadingCoverTypePlan: true,
          errorCoverTypePlan: null,
          responseCoverTypePlan: null,
        };
        break;
      case COVER_TYPE_PLAN_GET_ONE_SUCCESS:
        state = {
          ...state,
          errorCoverTypePlan: null,
          loadingCoverTypePlan: false,
          responseCoverTypePlan: action.payload,
        };
        break;
      case COVER_TYPE_PLAN_GET_ONE_ERROR:
        state = {
          ...state,
          errorCoverTypePlan: action.payload,
          loadingCoverTypePlan: false,
          responseCoverTypePlan: null,
        };
        break;
      case COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA:
        state = {
          ...state,
          errorCoverTypePlan: null,
          loadingCoverTypePlan: null,
          responseCoverTypePlan: null,
        };
        break;      
    case COVER_TYPE_PLAN_POST:
      state = {
        ...state,
        loadingPostCoverTypePlan: true,
        errorPostCoverTypePlan: null,
        responsePostCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_POST_SUCCESS:
      state = {
        ...state,
        errorPostCoverTypePlan: null,
        loadingPostCoverTypePlan: false,
        responsePostCoverTypePlan: action.payload,
      };
      break;
    case COVER_TYPE_PLAN_POST_ERROR:
      state = {
        ...state,
        errorPostCoverTypePlan: action.payload,
        loadingPostCoverTypePlan: false,
        responsePostCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_POST_CLEAR_DATA:
      state = {
        ...state,
        errorPostCoverTypePlan: null,
        loadingPostCoverTypePlan: null,
        responsePostCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_PATCH:
      state = {
        ...state,
        loadingPatchCoverTypePlan: true,
        errorPatchCoverTypePlan: null,
        responsePatchCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_PATCH_SUCCESS:
      state = {
        ...state,
        errorPatchCoverTypePlan: null,
        loadingPatchCoverTypePlan: false,
        responsePatchCoverTypePlan: action.payload,
      };
      break;
    case COVER_TYPE_PLAN_PATCH_ERROR:
      state = {
        ...state,
        errorPatchCoverTypePlan: action.payload,
        loadingPatchCoverTypePlan: false,
        responsePatchCoverTypePlan: null,
      };
      break;
    case COVER_TYPE_PLAN_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorPatchCoverTypePlan: null,
        loadingPatchCoverTypePlan: null,
        responsePatchCoverTypePlan: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default coverTypePlan;

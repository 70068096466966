import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalCatalog } from "../../store/generalCatalog/actions";

export const useGeneralCatalogGetAll = (
  trigger: boolean,
  clientId?: string,
  type?: string,
  code?: string,
  description?: string,
  pageCatalog?: number,
  limitCatalog?: number,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { responseGeneralCatalog, errorGeneralCatalog, loadingGeneralCatalog } =
    useSelector((state: any) => ({
      responseGeneralCatalog: state.GeneralCatalog.responseGeneralCatalog,
      errorGeneralCatalog: state.GeneralCatalog.errorGeneralCatalog,
      loadingGeneralCatalog: state.GeneralCatalog.loadingGeneralCatalog,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        generalCatalog({
          clientId: clientId,
          enable: enable,
          page: pageCatalog,
          limit: limitCatalog,
          type: type,
          code: code,
          description: description,
        }),
      );
    }
  }, [trigger, clientId]);

  return {
    responseGeneralCatalog,
    errorGeneralCatalog,
    loadingGeneralCatalog,
    setEnable,
  };
};

import { all, fork } from "redux-saga/effects";
import InsuranceTypeSaga from "./insuranceType/saga";
import IdentificationTypeSaga from "./identificationType/saga";
import CountriesSaga from "./countries/saga";
import RegionSaga from "./region/saga";
import ProjectSaga from "./project/saga";
import sponsorSaga from "./sponsor/saga";
import ClientSaga from "./client/saga";
import PersonSaga from "./person/saga";
import PlanSaga from "./plan/saga";
import Insurance from "./insurance/saga";
import CampaignSaga from "./campaign/saga";
import CampaignProjectSaga from "./campaignProject/saga";
import QuoteSaga from "./generateQuote/saga";
import PlansSaga from "./plans/saga";
import InsuredSaga from "./insured/saga";
import PolicySaga from "./policy/saga";
import planBenefitSaga from "./planBenefit/saga";
import UpdateQuoteSaga from "./updateQuote/saga";
import CatalogSaga from "./catalog/saga";
import BeneficiarySaga from "./beneficiary/saga";
import insurancesSaga from "./insurances/saga";
import PageActionFlagsSaga from "./pageActionFlags/saga";
import DeleteEntitySaga from "./delete/saga";
import AvatarSaga from "./avatar/saga";
import AuthSaga from "./auth/saga";
import BranchesSaga from "./branches/saga";
import PaymentModeSaga from "./paymentModes/saga";
import AccountTypeSaga from "./accountType/saga";
import ProjectTypeSaga from "./projectType/saga";
import CoverType from "./coverType/saga";
import PremiumEvalOption from "./premiumEvalOption/saga";
import CoverTypePlan from "./coverTypePlan/saga";
import generalCatalogSaga from "./generalCatalog/saga";
import CancelQuoteSaga from "./cancelQuote/saga";
import CurrenciesSaga from "./currencies/saga";
import rolSaga from "./rol/saga";
import collectionSaga from "./collections/saga";
import permissionSaga from "./permissions/saga";
import vehiclesSaga from "./vehiclesApap/saga";
import uploadAttachSaga from "./apapUploadAttachments/saga";
import certificateQuoteSaga from "./certificates/saga";
import documentsStatisticsSaga from "./documentStatistics/saga";

export default function* rootSaga() {
  yield all([fork(InsuranceTypeSaga)]);
  yield all([fork(IdentificationTypeSaga)]);
  yield all([fork(CountriesSaga)]);
  yield all([fork(RegionSaga)]);
  yield all([fork(ProjectSaga)]);
  yield all([fork(sponsorSaga)]);
  yield all([fork(ClientSaga)]);
  yield all([fork(PersonSaga)]);
  yield all([fork(PlanSaga)]);
  yield all([fork(Insurance)]);
  yield all([fork(insurancesSaga)]);
  yield all([fork(AccountTypeSaga)]);
  yield all([fork(CampaignSaga)]);
  yield all([fork(CampaignProjectSaga)]);
  yield all([fork(QuoteSaga)]);
  yield all([fork(PlansSaga)]);
  yield all([fork(InsuredSaga)]);
  yield all([fork(PolicySaga)]);
  yield all([fork(planBenefitSaga)]);
  yield all([fork(UpdateQuoteSaga)]);
  yield all([fork(CatalogSaga)]);
  yield all([fork(BeneficiarySaga)]);
  yield all([fork(PageActionFlagsSaga)]);
  yield all([fork(DeleteEntitySaga)]);
  yield all([fork(AvatarSaga)]);
  yield all([fork(AuthSaga)]);
  yield all([fork(BranchesSaga)]);
  yield all([fork(PaymentModeSaga)]);
  yield all([fork(ProjectTypeSaga)]);
  yield all([fork(CoverType)]);
  yield all([fork(PremiumEvalOption)]);
  yield all([fork(CoverTypePlan)]);
  yield all([fork(generalCatalogSaga)]);
  yield all([fork(CancelQuoteSaga)]);
  yield all([fork(CurrenciesSaga)]);
  yield all([fork(rolSaga)]);
  yield all([fork(collectionSaga)]);
  yield all([fork(permissionSaga)]);
  yield all([fork(vehiclesSaga)]);
  yield all([fork(uploadAttachSaga)]);
  yield all([fork(certificateQuoteSaga)]);
  yield all([fork(documentsStatisticsSaga)]);
}

import {
  DOCUMENTS_STATISTICS,
  DOCUMENTS_STATISTICS_CLEAR_DATA,
  DOCUMENTS_STATISTICS_ERROR,
  DOCUMENTS_STATISTICS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingDocumentStatistics: false,
  responseDocumentStatistics: null,
  errorDocumentStatistics: null,
};

const DocumentStatistics = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENTS_STATISTICS:
      state = {
        ...state,
        loadingDocumentStatistics: true,
        responseDocumentStatistics: null,
        errorDocumentStatistics: null,
      };
      break;
    case DOCUMENTS_STATISTICS_SUCCESS:
      state = {
        ...state,
        loadingDocumentStatistics: false,
        responseDocumentStatistics: action.payload,
        errorDocumentStatistics: null,
      };
      break;
    case DOCUMENTS_STATISTICS_ERROR:
      state = {
        ...state,
        loadingDocumentStatistics: false,
        responseDocumentStatistics: null,
        errorDocumentStatistics: action.payload,
      };
      break;
    case DOCUMENTS_STATISTICS_CLEAR_DATA:
      state = {
        ...state,
        loadingDocumentStatistics: false,
        responseDocumentStatistics: null,
        errorDocumentStatistics: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DocumentStatistics;

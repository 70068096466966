import {
  PUT_AVATAR,
  PUT_AVATAR_SUCCESS,
  PUT_AVATAR_ERROR,
  PUT_AVATAR_CLEAR_DATA,
} from "./actionTypes";


export const putAvatar = (entity, id,image) => {
  return {
    type: PUT_AVATAR,
    payload: {entity,id,image},
  };
};

export const successPutAvatar = (success) => {
  return {
    type: PUT_AVATAR_SUCCESS,
    payload: success,
  };
};

export const errorPutAvatar = (error) => {
  return {
    type: PUT_AVATAR_ERROR,
    payload: error,
  };
};

export const clearDataPutAvatar = () => {
  return {
    type: PUT_AVATAR_CLEAR_DATA,
    payload: {},
  };
};

// Path: src/store/avatar/reducer.js
import React, { useEffect, useState } from "react";
import {
  ListDropdownMultipleProps,
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { CompletedView } from "./view";
import { useDispatch, useSelector } from "react-redux";
import {
  quoteAll,
  quoteAllClearData,
} from "../../../store/generateQuote/actions";
import LocalStorageService from "../../../services/localStorage";
import { useNavigate, useParams } from "react-router-dom";
import {
  RowsModel,
  StatusOptionsModel,
} from "../../../model/insurancePoliciesSummary";
import { formattedDate, formattedDateWithTime } from "../../../utils/date";
import { downloadReports } from "../../../fnx/reportsDowload";
import { buttonActionOptions } from "./completedDTO";
import showToast from "../../../services/toast";
import { Branches } from "../../../store/branches/actions";
import { useProjectsByCustomerId } from "../../../fnx/projectsByCustomerId";
import { showErrorToast } from "../../../fnx/showError";
import { useTranslation } from "react-i18next";
import {
  getCatalogStatusQuote,
  getCatalogStatusQuoteClearData,
} from "../../../store/catalog/actions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
export const Completed = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any>([]);
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [isLoadin, setIsLoading] = React.useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId
  );
  const [nameSearch, setNameSearch] = useState<string>("");
  const [description, setDescription] = useState("");
  const [branchesOptions, setBranchesOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [insuranceOptions, setInsuranceOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const fields =
    "policyId.code,code,productId.description,policyId.effectiveFrom,creationDate,firstNameA,firstNameB,lastNameA,lastNameB,numberId,customerAccount.accountType.description,customerAccount.accountNumber,customerAccount.amount,catalogStatusQuote.description,user.fullName,user.email,clientId.description,catalogCancellation.createdAt,catalogCancellation.description";
  const labelFields =
    "Póliza,Cotización,Producto,Fecha efectiva,Póliza creada el,Primer nombre,Segundo nombre,Primer apellido,Segundo apellido,No. Documento,Tipo de cuenta,No. de cuenta,Prima,Estado,Nombre del empleado,Usuario,Aseguradora,Fecha de cancelación,Motivo de cancelación";
  const idSalesCompleted = "99d9c7f3-e138-4a36-920b-1de59346ea10";
  const idSalesCanceled = "d40de8d2-f71a-42e7-98b2-b30efa6b153a";
  const [sponsorId, setSponsorId] = React.useState<string>("");
  const [trigger, setTrigger] = useState(false);
  const { responseInsurance } = useProjectsByCustomerId(
    clientId,
    trigger,
    "",
    "",
    sponsorId
  );

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    })
  );

  const { responseBranches, errorBranches, loadingBranches } = useSelector(
    (state: any) => ({
      responseBranches: state.Branches.responseBranches?.data,
      errorBranches: state.Branches.errorBranches,
      loadingBranches: state.Branches.loadingBranches,
    })
  );

  const {
    responseGetCatalogStatusQuote,
    errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote,
  } = useSelector((state: any) => ({
    responseGetCatalogStatusQuote:
      state.Catalog.responseGetCatalogStatusQuote?.data,
    errorGetCatalogStatusQuote: state.Catalog.errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote: state.Catalog.loadingGetCatalogStatusQuote,
  }));

  useEffect(() => {
    const description1 = t("salesReport.description1");
    const description2 = t("salesReport.description2");
    const description3 = t("salesReport.description3");
    const foundItem = responseGetCatalogStatusQuote?.find(
      (item: StatusOptionsModel) => item.id === id
    );

    if (foundItem && foundItem.description === "Emitida") {
      setDescription(description1);
    } else if (foundItem && foundItem.description === "Cancelada") {
      setDescription(description2);
    } else {
      setDescription(description3);
    }
  }, [responseGetCatalogStatusQuote, id]);

  useEffect(() => {
    setDescription("");
    if (!responseBranches) {
      dispatch(
        Branches({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        })
      );
    }
    if (!responseAllQuote) {
      getQuoteAll();
    }
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit })
      );
    }
    if (!responseAllQuote) {
      getQuoteAll();
    }
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit })
      );
    }
  }, []);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorGetCatalogStatusQuote) {
      showErrorToast(errorGetCatalogStatusQuote, setSeeToast, t);
    }
  }, [errorGetCatalogStatusQuote]);

  useEffect(() => {
    if (errorBranches) {
      showErrorToast(errorBranches, setSeeToast, t);
    }
  }, [errorBranches]);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      setDataReportStorybook(responseAllQuote);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseInsurance)) {
      setInsuranceOptionsStorybook(responseInsurance);
    }
  }, [responseInsurance]);

  useEffect(() => {
    if (nameSearch) {
      getQuoteAll();
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  useEffect(() => {
    if (Array.isArray(responseBranches)) {
      setBranchesOptionsStorybook(responseBranches);
    }
  }, [responseBranches]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  const onCloseSearch = () => {
    setNameSearch("");
    dispatch(
      quoteAll({
        clientId: clientId,
        enable: enable,
        catalogStatusQuote: id,
        page: page,
        limit: limit,
        search: "",
      })
    );
  };

  const getQuoteAll = () => {
    dispatch(
      quoteAll({
        clientId: clientId,
        enable: enable,
        catalogStatusQuote: id,
        page: page,
        limit: limit,
        search: nameSearch,
      })
    );
  };

  const setBranchesOptionsStorybook = (
    responseBranches: StatusOptionsModel[]
  ) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseBranches?.map((item: StatusOptionsModel) => {
      if (item.id && item.name) {
        dataBranches.push({
          id: item.id,
          name: item.name,
          active: false,
        });
      }
    });
    setBranchesOptions(dataBranches);
  };

  const setInsuranceOptionsStorybook = (
    responseProject: StatusOptionsModel[]
  ) => {
    let dataInsurance: ListDropdownMultipleProps[] = [];
    responseProject?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataInsurance.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setInsuranceOptions(dataInsurance);
  };

  const setDataReportStorybook = (responseAllQuote: RowsModel[]) => {
    const dataR = responseAllQuote?.map((item: RowsModel) => ({
      id: item?.id,
      certificate: item?.policyId ? item?.policyId?.code : item.code,
      product: item?.productId?.description,
      creationDate: item?.policyId
        ? formattedDate(item?.policyId?.effectiveFrom)
        : formattedDate(item?.creationDate),
      names: `${item?.firstNameA} ${item?.firstNameB}`,
      lastNames: `${item?.lastNameA} ${item?.lastNameB}`,
      identificationNumber: item?.numberId,
      paymentType: item?.customerAccount?.accountType.description,
      accountNumber: item?.customerAccount?.accountNumber,
      prime: `RD$ ${item?.customerAccount?.amount}`,
      status: item?.catalogStatusQuote?.description,
      userName: item?.user?.fullName,
      user: item?.user?.email,
      branch: item?.branchData?.name,
      cancellationDate:
        item?.catalogCancellation?.createdAt &&
        formattedDate(item?.catalogCancellation?.createdAt),
      reasonCancellation: item?.catalogCancellation?.description,
      insuranceCarrier: item?.sponsor?.name,
    }));
    setRows(dataR);
    dispatch(quoteAllClearData());
  };

  const onClickDownload = (item: buttonActionOptions) => {
    setIsLoading(true);
    downloadReports(
      item,
      clientId,
      enable,
      page,
      limit,
      id ? id : "",
      startDate,
      endDate,
      fields,
      labelFields
    )
      .then(() => {
        setSeeToast({
          show: true,
          message: t("salesReport.downloadFile"),
          type: typeAlert.success,
        });
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterStatus = (item: any) => {
    const idBranch = item?.branch.map((item: any) => item.id).join(",");
    const idInsurance = item?.insurance.map((item: any) => item.id).join(",");
    setStartDate(
      formattedDateWithTime(item?.dateRange?.startDate) === "undefined" ||
        formattedDateWithTime(item?.dateRange?.startDate) === "null" ||
        formattedDateWithTime(item?.dateRange?.startDate) === "NA"
        ? ""
        : formattedDateWithTime(item?.dateRange?.startDate)
    );
    setEndDate(
      formattedDateWithTime(item?.dateRange?.endDate) === "undefined" ||
        formattedDateWithTime(item?.dateRange?.endDate) === "null" ||
        formattedDateWithTime(item?.dateRange?.endDate) === "NA"
        ? ""
        : formattedDateWithTime(item?.dateRange?.endDate)
    );
    dispatch(
      quoteAll({
        clientId: clientId,
        enable: enable,
        catalogStatusQuote: id,
        creationDateStart:
          formattedDateWithTime(item?.dateRange?.startDate) === "undefined" ||
          formattedDateWithTime(item?.dateRange?.startDate) === "null" ||
          formattedDateWithTime(item?.dateRange?.startDate) === "NA"
            ? ""
            : formattedDateWithTime(item?.dateRange?.startDate),
        creationDateEnd:
          formattedDateWithTime(item?.dateRange?.endDate) === "undefined" ||
          formattedDateWithTime(item?.dateRange?.endDate) === "null" ||
          formattedDateWithTime(item?.dateRange?.endDate) === "NA"
            ? ""
            : formattedDateWithTime(item?.dateRange?.endDate),
        branches: idBranch,
        projectIds: idInsurance,
        page: page,
        limit: limit,
      })
    );
  };

  const onClickBack = () => {
    dispatch(getCatalogStatusQuoteClearData());
    navigate("/report");
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}

      <LoadingAFY
        loading={
          loadingAllQuote ||
          isLoadin ||
          loadingGetCatalogStatusQuote ||
          loadingBranches
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={isLoadin ? "Descargando archivo" : "Cargando Pólizas."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <CompletedView
        idSalesCompleted={idSalesCompleted}
        idSalesCanceled={idSalesCanceled}
        searchFilter={searchFilter}
        onCloseSearch={onCloseSearch}
        branchOptions={branchesOptions}
        rows={rows}
        filterStatus={filterStatus}
        id={id}
        downloadFile={onClickDownload}
        insuranceOptions={insuranceOptions}
        onClickBack={onClickBack}
        description={description}
      />
    </React.Fragment>
  );
};

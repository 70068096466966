import { URLs } from "../url";
import { buildQueryString } from "../../utils/queryParam";
import { PaymentModeResponse } from "../../model/paymentMode";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getPaymentMode = async (paymentMode: any) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${
    URLs.paymentMode
  }?${buildQueryString(paymentMode)}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PaymentModeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postPaymentModeAPI = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.paymentMode;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PaymentModeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getPaymentMode, postPaymentModeAPI };

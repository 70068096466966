import { ModalComponentAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { Alert, useStyles } from "../style";

export const ModalClone = (props: any) => {
  const classes = useStyles();
  return (
    <ModalComponentAFY
      openModal={props.openModalConfirmClone}
      backgroundColor="#ffffff"
      onClose={() => {
        props.setOpenModalConfirmClone(false);
      }}
      border={0}
      minWidthModal={"500px"}
      padding={30}
    >
      <Alert>esta función aun no esta disponible</Alert>
      {/*<div style={{ marginTop: "10px" }}>*/}
      {/*  <Title>Clonar {props.modalTitle}</Title>*/}
      {/*  <Description>*/}
      {/*    {`¿Está seguro que desea Clonar ${props.modalTitle}?`}*/}
      {/*  </Description>*/}
      {/*  <ButtonsContainer>*/}
      {/*    <div className={classes.button}>*/}
      {/*      <ButtonAFY*/}
      {/*        variant={variants.contained}*/}
      {/*        size={sizes.large}*/}
      {/*        label={"No"}*/}
      {/*        onClick={() => {}}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className={classes.button}>*/}
      {/*      <ButtonAFY*/}
      {/*        variant={variants.contained}*/}
      {/*        size={sizes.large}*/}
      {/*        label={"Si, Clonar"}*/}
      {/*        onClick={(e) => {*/}
      {/*          e.stopPropagation();*/}
      {/*          props.onSubmitClone();*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </ButtonsContainer>*/}
      {/*</div>*/}
    </ModalComponentAFY>
  );
};

import React from "react";
import {
  AlertAFY,
  ButtonAFY,
  DataFormClient,
  FormsInsurancesAFY,
  IconAFY,
  icons,
  select,
  severity,
  sizes,
  sizesIcon,
  variants,
  variantsAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../../components/layout/style";
import {
  Container,
  ContainerAlert,
  ContainerIcon,
  ContainerTitle,
} from "./style";
import { RowsModel } from "../../../model/insurancePoliciesSummary";
import { themeCore } from "../../../assets/themes/theme";
import { GridColDef } from "@mui/x-data-grid";
import { ModalConfirmationDelete } from "./resources/modalConfirmationDelete";

export const ProductCreationView = (props: {
  permissions?: any;
  disableCampaign: boolean;
  disableRecurrence: boolean;
  disablePaymentMode: boolean;
  disablePlans: boolean;
  disableFlags: boolean;
  insuranceTypeList: select[];
  sponsorList: select[];
  currencyList: select[];
  paymentModeList: select[];
  accountTypeList: select[];
  dataProductCreation: DataFormClient;
  handleSubmitProduct: (data: any, file: any) => void;
  showAlert?: boolean;
  setDescriptionAlert?: React.Dispatch<React.SetStateAction<string>>;
  descriptionAlert?: string;
  activateEditProduct?: boolean;
  activateEditCampaign?: boolean;
  dataCampaignCreation?: DataFormClient;
  setShowAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitCampaign: (data: any) => void;
  panelActive?: number;
  autoClose?: number;
  autoCloseActive?: boolean;
  handleSubmitPaymentRecurrence: (data: any) => void;
  handleSubmitAccountType: (data: any) => void;
  rowsPaymentRecurrence: RowsModel[];
  rowsAccountType: RowsModel[];
  rowsPlans: RowsModel[];
  rowsFlags: RowsModel[];
  seeButtonAddPlans?: boolean;
  activateEditMode?: boolean;
  showButtonsActions?: boolean;
  onClickBack?: () => void;
  onClickAddPlans?: () => void;
  showButtonAddRecurrence?: boolean;
  showButtonAddPaymentMode?: boolean;
  showAddButtonPlans: boolean;
  showCancelButton?: boolean;
  idProduct?: string;
  isEdit?: string;
  setIsUpdate: (value: boolean | (() => boolean)) => void;
  setShowCancelButton: (value: boolean | (() => boolean)) => void;
  showButtonAddCampaign?: boolean;
  columnsRecurrence?: GridColDef<any, any, any>[];
  columnsPaymentMode?: GridColDef<any, any, any>[];
  columnsPlans?: GridColDef<any, any, any>[];
  ColumnsFlags?: GridColDef<any, any, any>[];
  openModal?: boolean;
  setOpenModal: (value: boolean | (() => boolean)) => void;
  titleDelete?: string;
  subTitleDelete?: string;
  deleteRecurrence?: () => void;
  deletePaymentMode?: () => void;
  showUploadButton: boolean;
  imgProject?: string;
  openDrawerPaymentR?: boolean;
  setOpenDrawerPaymentR: (value: boolean | (() => boolean)) => void;
  openDrawerPaymentM?: boolean;
  setOpenDrawerPaymentM: (value: boolean | (() => boolean)) => void;
}) => {
  return (
    <React.Fragment>
      <ModalConfirmationDelete
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        titleDelete={props.titleDelete}
        subTitleDelete={props.subTitleDelete}
        deleteRecurrence={props.deleteRecurrence}
        deletePaymentMode={props.deletePaymentMode}
      />
      <ContainerAlert>
        <Container>
          <ButtonAFY
            variant={variants.text}
            label={"Regresar"}
            icon={icons.ArrowBackIosNew}
            size={sizes.small}
            color={themeCore.colors.primaryText}
            onClick={props.onClickBack}
          />
        </Container>
        <div style={{ marginTop: "15px", marginBottom: "-15px" }}>
          <AlertAFY
            title=""
            isOpen={props.showAlert}
            setAutoClose={props.autoCloseActive}
            autoClose={props.autoClose}
            description={props.descriptionAlert ? props.descriptionAlert : ""}
            iconClose={icons.Close}
            iconCloseColor="#fff"
            onClose={() => {
              props.setDescriptionAlert?.("");
              props.setShowAlert?.(false);
            }}
            severity={severity.info}
            variant={variantsAlert.filled}
            backgroundColor={"#637985"}
            iconColor={"#FFF"}
            spacing={0}
            textColor={"#fff"}
            width={"100%"}
          />
        </div>
      </ContainerAlert>
      <ContainerTitle>
        <Title>Productos</Title>
        {props.idProduct && props.isEdit !== "true" && (
          <ContainerIcon>
            <IconAFY
              icon={icons.Visibility}
              size={sizesIcon.medium}
              color={"#3A56A1"}
            />
          </ContainerIcon>
        )}
      </ContainerTitle>

      <FormsInsurancesAFY
      permissions = {props.permissions}
        disableCampaign={props.disableCampaign}
        disablePriority={props.disableRecurrence}
        disablePaymentMode={props.disablePaymentMode}
        disablePlans={props.disablePlans}
        disableFlags={props.disableFlags}
        dataProductCreationForm={props.dataProductCreation}
        dataForm={props.dataCampaignCreation}
        showButtonAddRecurrence={props.showButtonAddRecurrence}
        showButtonAddPaymentMode={props.showButtonAddPaymentMode}
        showCancelButton={props.showCancelButton}
        showButtonAddPlans={props.showAddButtonPlans}
        formsCompleted={[true]}
        panelActive={props.panelActive}
        showButtonsActions={props.showButtonsActions}
        titleButtonAddPlans={"Agregar Planes"}
        labelButtonAddCampaign={
          props.isEdit ? "Actualizar campaña" : "Crear campaña"
        }
        PlansTitle="Planes"
        campaignTitle={"Campaña"}
        paymentModeTitle={"Modos de pago"}
        priorityTitle={"Recurrencias de pago"}
        titleButtonAdd={"Agregar"}
        titleButtonAddNewMode={"Agregar modo de pago"}
        titleButtonAddNewRecurrence={"Agregar recurrencia de pago"}
        titleButtonCancel={"Cancelar"}
        titleNewMode={"Nuevo modo de pago"}
        titleNewRecurrence={"Nueva recurrencia de pago"}
        labelButtonAdd={props.isEdit ? "Actualizar producto" : "Crear producto"}
        textLegend={"Para crear una campaña, primero debe crear un producto."}
        flagsTitle={"Actions flags"}
        titleDrawer={"Agregar recurrencia de pago"}
        descriptionDrawer={
          "Si desea agregar una nueva recurrencia de pago seleccione a continuación."
        }
        titleDrawerII={"Nuevo modo de pago"}
        descriptionDrawerII={
          "Si desea agregar un nuevo modo de pago seleccione a continuación."
        }
        widthFormViewType={"35%"}
        showUploadP={props.showUploadButton}
        topUpload={"418px"}
        rightUpload={"20px"}
        topImg={"470px"}
        rightImg={"260px"}
        textLegendColor={"#bcbcbc"}
        bgColorButtonAddPlans={"#018749"}
        columnsPayment={props.columnsRecurrence}
        rowsPayment={props.rowsPaymentRecurrence}
        paymentRecurrenceOption={props.paymentModeList}
        columnsPaymentMode={props.columnsPaymentMode}
        rowsPaymentMode={props.rowsAccountType}
        columnsPlans={props.columnsPlans}
        rowsPlans={props.rowsPlans}
        columnsFlags={props.ColumnsFlags}
        rowsFlags={props.rowsFlags}
        paymentModeOption={props.accountTypeList}
        activateEditModeProduct={props.activateEditProduct}
        activateEditModeCampaign={props.activateEditCampaign}
        openDrawerPaymentR={props.openDrawerPaymentR}
        openDrawerPaymentM={props.openDrawerPaymentM}
        typeList={props.insuranceTypeList}
        imgProject={props.imgProject}
        sponsorList={props.sponsorList}
        currencyList={props.currencyList}
        onNext={props.handleSubmitCampaign}
        onSendPaymentMode={props.handleSubmitAccountType}
        onSendPaymentRecurrence={props.handleSubmitPaymentRecurrence}
        onSendProductForm={props.handleSubmitProduct}
        showButtonsActionsCampaign={props.showButtonAddCampaign}
        onClickEdit={() => {
          props.setIsUpdate(true);
          props.setShowCancelButton(true);
        }}
        onClickEditCampaign={() => {
          props.setIsUpdate(true);
          props.setShowCancelButton(true);
        }}
        onClickAddPlans={props.onClickAddPlans}
        onClickOpenDrawer={() => {
          props.setOpenDrawerPaymentR(true);
        }}
        onCloseDrawer={() => {
          props.setOpenDrawerPaymentR(false);
        }}
        onClickOpenDrawerPaymentMode={() => {
          props.setOpenDrawerPaymentM(true);
        }}
        onCloseDrawerPaymentMode={() => {
          props.setOpenDrawerPaymentM(false);
        }}
      />
    </React.Fragment>
  );
};

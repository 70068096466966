import {
  DELETE_ENTITY,
  DELETE_ENTITY_CLEAR_DATA,
  DELETE_ENTITY_ERROR,
  DELETE_ENTITY_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorDeleteEntity: null,
  responseDeleteEntity: null,
  loadingDeleteEntity: false,
};

const DeleteEntity = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ENTITY:
      state = {
        ...state,
        loadingDeleteEntity: true,
        errorDeleteEntity: null,
        responseDeleteEntity: null,
      };
      break;
    case DELETE_ENTITY_SUCCESS:
      state = {
        ...state,
        errorDeleteEntity: null,
        loadingDeleteEntity: false,
        responseDeleteEntity: action.payload,
      };
      break;
    case DELETE_ENTITY_ERROR:
      state = {
        ...state,
        errorDeleteEntity: action.payload,
        loadingDeleteEntity: false,
        responseDeleteEntity: null,
      };
      break;
    case DELETE_ENTITY_CLEAR_DATA:
      state = {
        ...state,
        errorDeleteEntity: null,
        loadingDeleteEntity: null,
        responseDeleteEntity: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DeleteEntity;

import {
  INSURANCE_TYPE,
  INSURANCE_TYPE_CLEAR_DATA,
  INSURANCE_TYPE_ERROR,
  INSURANCE_TYPE_ONE,
  INSURANCE_TYPE_ONE_SUCCESS,
  INSURANCE_TYPE_SUCCESS,
  POST_INSURANCE_TYPE,
  POST_INSURANCE_TYPE_CLEAR_DATA,
  POST_INSURANCE_TYPE_ERROR,
  POST_INSURANCE_TYPE_SUCCESS,
  PATCH_INSURANCE_TYPE,
  PATCH_INSURANCE_TYPE_CLEAR_DATA,
  PATCH_INSURANCE_TYPE_ERROR,
  PATCH_INSURANCE_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorInsuranceType: null,
  responseInsuranceType: null,
  loadingInsuranceType: false,
  errorPostInsuranceType: null,
  responsePostInsuranceType: null,
  loadingPostInsuranceType: false,
  errorPatchInsuranceType: null,
  loadingPatchInsuranceType: null,
  responsePatchInsuranceType: false,
};

const insuranceType = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_TYPE:
      state = {
        ...state,
        loadingInsuranceType: true,
        errorInsuranceType: null,
        responseInsuranceType: true,
      };
      break;
	case INSURANCE_TYPE_ONE:
      state = {
        ...state,
        loadingInsuranceType: true,
        errorIloadingInsuranceType: null,
        responseOneIloadingInsuranceType: true,
      };
      break;
    case INSURANCE_TYPE_SUCCESS:
      state = {
        ...state,
        errorInsuranceType: null,
        loadingInsuranceType: false,
        responseInsuranceType: action.payload,
      };
      break;
	case INSURANCE_TYPE_ONE_SUCCESS:
      state = {
        ...state,
        errorInsuranceType: null,
        loadingInerrorInsuranceType: false,
        responseOneInerrorInsuranceType: action.payload,
      };
      break;
    case INSURANCE_TYPE_ERROR:
      state = {
        ...state,
        errorInsuranceType: action.payload,
        loadingInsuranceType: false,
        responseInsuranceType: null,
      };
      break;
    case INSURANCE_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorInsuranceType: null,
        loadingInsuranceType: null,
        responseInsuranceType: false,
      };
      break;
      case POST_INSURANCE_TYPE:
      state = {
        ...state,
        loadingPostInsuranceType: true,
        errorPostIloadingPostInsuranceType: null,
        responsePostIloadingPostInsuranceType: true,
      };
      break;
    case POST_INSURANCE_TYPE_SUCCESS:
      state = {
        ...state,
        errorPostInsuranceType: null,
        loadingPostInsuranceType: false,
        responsePostInsuranceType: action.payload,
      };
      break;
    case POST_INSURANCE_TYPE_ERROR:
      state = {
        ...state,
        errorPostInsuranceType: action.payload,
        loadingPostInsuranceType: false,
        responsePostInsuranceType: null,
      };
      break;
    case POST_INSURANCE_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorPostInsuranceType: null,
        loadingPostInsuranceType: null,
        responsePostInsuranceType: false,
      };
      break;
      case PATCH_INSURANCE_TYPE:
      state = {
        ...state,
        loadingPatchInsuranceType: true,
        errorPatchInsuranceType: null,
        responsePatchInsuranceType: true,
      };
      break;
    case PATCH_INSURANCE_TYPE_SUCCESS:
      state = {
        ...state,
        errorPatchInsuranceType: null,
        loadingPatchInsuranceType: false,
        responsePatchInsuranceType: action.payload,
      };
      break;
    case PATCH_INSURANCE_TYPE_ERROR:
      state = {
        ...state,
        errorPatchInsuranceType: action.payload,
        loadingPatchInsuranceType: false,
        responsePatchInsuranceType: null,
      };
      break;
    case PATCH_INSURANCE_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorPatchInsuranceType: null,
        loadingPatchInsuranceType: null,
        responsePatchInsuranceType: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default insuranceType;

import React from "react";
import {
  ContainerTitles,
  Title_II,
} from "../../salesCore/agentDashboard/style";
import { Title } from "../../components/layout/style";
import {
  BranchDashboardAFY,
  icons,
  sizesIcon,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const BranchDashboardView = (props: {}) => {
  return (
    <React.Fragment>
      <ContainerTitles>
        <Title>Reportes |</Title>
        <Title_II> Historial Sucursales</Title_II>
      </ContainerTitles>
      <BranchDashboardAFY
        avatarVariant={variantsAvatar.rounded}
        backgroundColorEvenRow="#EBEEF6"
        backgroundColorOddRow="#fff"
        backgroundColorSearch="#F6F7FB"
        bgChipSelected="#EBEEF6"
        bgChips="#fff"
        categories={[
          "cancer",
          "vehiculos",
          "medico",
          "dental",
          "vida",
          "funerario",
          "veterinario",
          "casa",
        ]}
        columns={[
          {
            field: "id",
            flex: 1,
            headerName: "ID",
          },
          {
            field: "agent",
            flex: 1,
            headerName: "Agentes",
          },
          {
            field: "sales",
            flex: 1,
            headerName: "Ventas",
          },
        ]}
        columnsBranchs={[
          {
            field: "id",
            flex: 1,
            headerName: "ID",
          },
          {
            field: "branch",
            flex: 1,
            headerName: "Sucursales",
          },
          {
            field: "sales",
            flex: 1,
            headerName: "Ventas",
          },
        ]}
        dataChips={[
          {
            active: true,
            id: "2",
            name: "Mes actual",
          },
          {
            active: false,
            id: "3",
            name: "Mes anterior",
          },
          {
            active: false,
            id: "4",
            name: "Trimestre actual",
          },
          {
            active: false,
            id: "5",
            name: "Trimestre anterior",
          },
          {
            active: false,
            id: "6",
            name: "Semestre actual",
          },
        ]}
        downData={[
          {
            id: "1",
            numericalTitle: "356",
            title: "Ventas netas",
          },
          {
            id: "2",
            numericalTitle: "97",
            title: "Pólizas canceladas",
          },
          {
            id: "3",
            numericalTitle: "41",
            title: "Nuevos asegurados",
          },
          {
            id: "4",
            numericalTitle: "82",
            title: "Nuevos clientes",
          },
        ]}
        iconAvatar={icons.Search}
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        iconSearch={icons.Search}
        innerSize="50%"
        onBlurSearch={() => {}}
        onBlurTextSearch={function noRefCheck() {}}
        onChangeData={function noRefCheck() {}}
        onChangeSearch={function noRefCheck() {}}
        onChangeTextSearch={function noRefCheck() {}}
        onClickActionButton={function noRefCheck() {}}
        onClickBarChart={function noRefCheck() {}}
        onClickBranchRows={function noRefCheck() {}}
        onClickChip={function noRefCheck() {}}
        onClickRows={function noRefCheck() {}}
        onClickSemiCircleChart={function noRefCheck() {}}
        onCloseCalendar={function noRefCheck() {}}
        onCloseSearch={function noRefCheck() {}}
        optionList={[
          {
            id: "1",
            name: "Previsualizar",
          },
          {
            id: "2",
            name: "Descargar",
          },
        ]}
        rowHeight={30}
        rows={[
          {
            agent: "Jon",
            id: 1,
            sales: 35,
          },
          {
            agent: "Cersei",
            id: 2,
            sales: 42,
          },
          {
            agent: "Jaime",
            id: 3,
            sales: 45,
          },
          {
            agent: "Arya",
            id: 4,
            sales: 16,
          },
          {
            agent: "Daenerys",
            id: 5,
            sales: 65,
          },
        ]}
        rowsBranchs={[
          {
            branch: "17 - Oficina Principal",
            id: 1,
            sales: 10,
          },
          {
            branch: "96 - Contact Center",
            id: 2,
            sales: 5,
          },
          {
            branch: "Catolica",
            id: 3,
            sales: 1,
          },
          {
            branch: "Arya",
            id: 4,
            sales: 2,
          },
          {
            branch: "Daenerys",
            id: 5,
            sales: 15,
          },
        ]}
        series={[
          {
            data: [
              {
                id: "1",
                y: 25,
              },
              {
                id: "2",
                y: 125,
              },
              {
                id: "3",
                y: 200,
              },
              {
                id: "4",
                y: 135,
              },
              {
                id: "5",
                y: 102,
              },
              {
                id: "6",
                y: 75,
              },
              {
                id: "7",
                y: 225,
              },
              {
                id: "8",
                y: 95,
              },
            ],
            name: "Seguro",
          },
        ]}
        seriesLineChart={[
          {
            data: [5.2, 5.7, 8.7, 13.9, 18.2, 21.4, 25, 22.8, 17.5, 12.1, 7.6],
            marker: {
              symbol: "square",
            },
            name: "Ventas",
          },
          {
            data: [1.6, 3.3, 5.9, 10.5, 13.5, 14.5, 14.4, 11.5, 8.7, 4.7, 2.6],
            marker: {
              symbol: "diamond",
            },
            name: "Cancelaciones",
          },
        ]}
        seriesSemiCircleChart={[
          {
            color: "#7cb5ec",
            id: "1",
            name: "cancer",
            y: 61.41,
          },
          {
            color: "#434348",
            id: "2",
            name: "autos",
            y: 11.84,
          },
          {
            color: "#90ed7d",
            id: "3",
            name: "accidentes personales",
            y: 10.85,
          },
          {
            color: "#f7a35c",
            id: "4",
            name: "daños a terceros",
            y: 4.67,
          },
          {
            color: "#f15c80",
            id: "5",
            name: "dental",
            y: 2.61,
          },
        ]}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        tableHeight="400px"
        tableWidth="100%"
        titleBarChart="Producción por producto"
        titleClearButton="Limpiar filtros"
        titleLineChart="Ventas y cancelaciones"
        titlePercentage="Seguro"
        titleSemiCircleChart="Top productos<br>más<br>vendidos"
        xAxisLineChart={[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]}
        yAxisTitle="Ventas totales"
      />
    </React.Fragment>
  );
};
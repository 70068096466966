import { call, put, takeEvery } from "redux-saga/effects";
import { 
  coverTypePlanSuccess, coverTypePlanError,  
  coverTypePlanGetOneSuccess, coverTypePlanGetOneError,
  coverTypePlanPostSuccess, coverTypePlanPostError,
  coverTypePlanPatchSuccess, coverTypePlanPatchError
} from "./actions";
import { coverTypePlanGet, coverTypePlanGetOne, coverTypePlanPost, coverTypePlanPatch } from "../../resources/helper/coverTypePlan";
import { COVER_TYPE_PLAN, COVER_TYPE_PLAN_POST, COVER_TYPE_PLAN_PATCH, COVER_TYPE_PLAN_GET_ONE  } from "./actionTypes";

function* coverTypePlanApi({ payload: { coverTypePlan } }) {
  try {
    const response = yield call(
      coverTypePlanGet,
      coverTypePlan.enable,
      coverTypePlan.page,
      coverTypePlan.limit,
    );
    yield put(coverTypePlanSuccess(response));
  } catch (error) {
    yield put(coverTypePlanError(error));
  }
}

function* coverTypePlanGetOneApi({ payload: { coverTypePlan } }) {
  try {
    const response = yield call(coverTypePlanGetOne, coverTypePlan.id);
    yield put(coverTypePlanGetOneSuccess(response));
  } catch (error) {
    yield put(coverTypePlanGetOneError(error));
  }
}

function* coverTypePlanPostApi({ payload: { data } }) {
  try {
    const response = yield call(coverTypePlanPost, data);
    yield put(coverTypePlanPostSuccess(response));
  } catch (error) {
    yield put(coverTypePlanPostError(error));
  }
}

function* coverTypePlanPatchApi({ payload: { coverTypePlan, id } }) {
  try {
    const response = yield call(coverTypePlanPatch, coverTypePlan, id);
    yield put(coverTypePlanPatchSuccess(response));
  } catch (error) {
    yield put(coverTypePlanPatchError(error));
  }
}

function* coverTypePlanSaga() {
  yield takeEvery(COVER_TYPE_PLAN, coverTypePlanApi);
  yield takeEvery(COVER_TYPE_PLAN_GET_ONE, coverTypePlanGetOneApi);
  yield takeEvery(COVER_TYPE_PLAN_POST, coverTypePlanPostApi);
  yield takeEvery(COVER_TYPE_PLAN_PATCH, coverTypePlanPatchApi);
}

export default coverTypePlanSaga;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceApi } from "../store/insurance/actions";

export const useGetInsurance = (
  trigger: boolean,
  sponsorId?: string,
  descriptionInsurance?: string,
  projectType?: string,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);

  const dispatch = useDispatch();

  const { responseInsurance, errorInsurance, loadingInsurance } = useSelector(
    (state: any) => ({
      responseInsurance: state.Insurance.responseInsurance?.data,
      errorInsurance: state.Insurance.errorInsurance,
      loadingInsurance: state.Insurance.loadingInsurance,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        insuranceApi({
          enable: enable,
          page: page,
          limit: limit,
          sponsorId: sponsorId,
          descriptionInsurance: descriptionInsurance,
          // projectType: projectType,
        }),
      );
    }
  }, [sponsorId, descriptionInsurance, projectType, trigger]);

  return {
    responseInsurance,
    errorInsurance,
    loadingInsurance,
    setEnable,
    setPage,
    setLimit,
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { region } from "../../store/region/actions";
import { Level } from "../../enum/region";

export const useRegionGetAll = (
  trigger: boolean,
  name?: string,
  zipcode?: string,
  code?: string,
  type?: Level,
  parentRegionId?: string,
  clientId?: string,
  page?: number,
  limit?: number,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  
  const dispatch = useDispatch();

  const { responseRegion, errorRegion, loadingRegion } = useSelector(
    (state: any) => ({
      responseRegion: state.Region.responseRegion,
      errorRegion: state.Region.errorRegion,
      loadingRegion: state.Region.loadingRegion,
    }),
  );

  useEffect(() => {
    if (trigger === true) {
   
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          name: name,
          zipcode: zipcode,
          code: code,
          level: type,
          parentRegionId: parentRegionId,
          clientId: clientId,
        }),
      );
    }
  }, [name,zipcode,code,type,parentRegionId,clientId,trigger]);

  return {
    responseRegion,
    errorRegion,
    loadingRegion,
  };
};

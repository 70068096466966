import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postInsuranceType } from "../../store/insuranceType/actions";

export const useInsuranceTypeCreate = (
  trigger: boolean,
  body: any,
) => {
  
  const dispatch = useDispatch();

  const { responsePostInsuranceType, errorPostInsuranceType, loadingPostInsuranceType } =
    useSelector((state: any) => ({
      responsePostInsuranceType: state.InsuranceType.responsePostInsuranceType,
      errorPostInsuranceType: state.InsuranceType.errorPostInsuranceType,
      loadingPostInsuranceType: state.InsuranceType.loadingPostInsuranceType,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(postInsuranceType(body));
  }}, [trigger]);

  return {
    responsePostInsuranceType,
    errorPostInsuranceType,
    loadingPostInsuranceType,
  };
};

import React, { useEffect, useState } from "react";
import View from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getColumnsHistory } from "./insurancePoliciesDTO";
import {
  RowsModel,
  StatusOptionsModel,
} from "../../model/insurancePoliciesSummary";
import {
  quoteAll,
  quoteAllClearData,
  quoteOne,
} from "../../store/generateQuote/actions";
import { useNavigate } from "react-router-dom";
import {
  breadcrumb,
  ContextMenuAFY,
  icons,
  ListDropdownMultipleProps,
  LoadingAFY,
  MenuItem,
  position,
  sizesIcon,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { hasPermission } from "../../services/getNavigationPages";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { breadcrumDTO } from "../../dataBase/layout";
import LocalStorageService from "../../services/localStorage";
import * as catalogConst from "../../const/catalog";
import { plan } from "../../store/plan/actions";
import { PolicyRequest } from "../../model/policy";
import { deleteEntityClearData } from "../../store/delete/actions";
import {
  cancelQuote,
  cancelQuoteClearData,
} from "../../store/cancelQuote/actions";
import {
  policyCancel,
  policyCancelClearData,
} from "../../store/policy/actions";

import { useStyles } from "./style";
import { getCatalogStatusQuote } from "../../store/catalog/actions";
import { formattedDate, formattedDateWithTime } from "../../utils/date";
import { sponsor } from "../../store/sponsor/actions";
import { buttonActionOptions } from "../reportsPage/completedSales/completedDTO";
import { downloadReports } from "../../fnx/reportsDowload";
import { useProjectsByCustomerId } from "../../fnx/projectsByCustomerId";
import { Branches } from "../../store/branches/actions";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { Steps } from "../../enum/steps";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const InsurancePoliciesSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [dataHistory, setDataHistory] = React.useState<any[]>([]);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalHistorial, setOpenModalHistorial] =
    React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>("Póliza");
  const [openModalConfirm, setOpenModalConfirm] =
    React.useState<boolean>(false);
  const [reasonCancellation, setReasonCancellation] = React.useState<any>([]);
  const [policyId, setPolicyId] = React.useState<string>("");
  const [type, setType] = React.useState<boolean>(true); //cotizacion = false o poliza = true
  const [row, setRow] = React.useState<any>({});
  const [statusOptions, setStatusOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [insuranceOptions, setInsuranceOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [branchesOptions, setBranchesOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [sponsorId, setSponsorId] = React.useState<string>("");
  const [idCatalogStatusQuote, setIdCatalogStatusQuote] =
    React.useState<string>("");
  const [trigger, setTrigger] = useState(false);
  const [dateStart, setDateStart] = React.useState<string>("");
  const [dateEnd, setDateEnd] = React.useState<string>("");
  const [isLoadin, setIsLoading] = React.useState<boolean>(false);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [nameSearch, setNameSearch] = useState<string>("");

  const fields =
    "numberId,code,policyId.code,productId.description,clientId.description,firstNameA,firstNameB,lastNameA,lastNameB,catalogStatusQuote.description,catalogCancellation.createdAt,catalogCancellation.description";

  const labelFields =
    "Número documento,Cotización,Póliza,Producto,Aseguradora,Primer nombre,Segundo nombre,Primer apellido,Segundo apellido,Estatus,Fecha de cancelación,Motivo de cancelación";

  const {
    responseSponsor,
    errorSponsor,
    loadingSponsor,
    responseInsurance,
    loadingInsurance,
    errorInsurance,
  } = useProjectsByCustomerId(clientId, trigger, "", "", sponsorId);

  const { responseCancelPolicy, loadingCancelPolicy, errorCancelPolicy } =
    useSelector((state: any) => ({
      responseCancelPolicy: state.Policy.responseCancelPolicy,
      loadingCancelPolicy: state.Policy.loadingCancelPolicy,
      errorCancelPolicy: state.Policy.errorCancelPolicy,
    }));

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const { responseCancelQuote, loadingCancelQuote, errorCancelQuote } =
    useSelector((state: any) => ({
      responseCancelQuote: state.CancelQuote.responseCancelQuote,
      loadingCancelQuote: state.CancelQuote.loadingDeleteEntity,
      errorCancelQuote: state.CancelQuote.errorDeleteEntity,
    }));

  const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
    responsePlan: state.Plan.responsePlan,
    errorPlan: state.Plan.errorPlan,
    loading: state.Plan.loadingPlan,
  }));

  const { responseProject, errorProject, loadingProject } = useSelector(
    (state: any) => ({
      responseProject: state.Project.responseProject?.data,
      errorProject: state.Project.errorProject,
      loadingProject: state.Project.loadingProject,
    })
  );

  const {
    responseGetCatalogStatusQuote,
    errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote,
  } = useSelector((state: any) => ({
    responseGetCatalogStatusQuote:
      state.Catalog.responseGetCatalogStatusQuote?.data,
    errorGetCatalogStatusQuote: state.Catalog.errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote: state.Catalog.loadingGetCatalogStatusQuote,
  }));

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    })
  );

  const { responseBranches, errorBranches, loadingBranches } = useSelector(
    (state: any) => ({
      responseBranches: state.Branches.responseBranches?.data,
      errorBranches: state.Branches.errorBranches,
      loadingBranches: state.Branches.loadingBranches,
    })
  );

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    })
  );

  const callQuoteOne = (quoteId: string) => {
    if (quoteId !== "") {
      dispatch(
        quoteOne({
          id: quoteId,
          log: true,
        })
      );
    }
  };

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      if (Array.isArray(responseOneQuote?.data)) {
        const formattedResponse = responseOneQuote?.data.map(
          (hItem: { fechaCarga: string | undefined }) => {
            return {
              ...hItem,
              fechaCarga: formattedDate(hItem.fechaCarga),
            };
          }
        );
        setDataHistory(formattedResponse);
        setOpenModalHistorial(true);
      }
    }
  }, [responseOneQuote]);

  useEffect(() => {
    setBreadCrumbs(breadcrumDTO());
    if (!responseAllQuote) {
      getQuoteAll();
    }
    if (!responseBranches) {
      dispatch(
        Branches({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        })
      );
    }
    if (!responseSponsor) {
      dispatch(
        sponsor({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        })
      );
    }
    if (!responsePlan) {
      dispatch(plan({ enable: enable, page: page, limit: limit }));
    }
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit })
      );
    }
    dispatch(deleteEntityClearData());
  }, []);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      if (Array.isArray(responseOneQuote?.data)) {
        const formattedResponse = responseOneQuote?.data?.map(
          (hItem: { fechaCarga: string | undefined }) => {
            return {
              ...hItem,
              fechaCarga: formattedDate(hItem.fechaCarga),
            };
          }
        );

        setDataHistory(formattedResponse);
        setOpenModalHistorial(true);
      }
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (errorCancelPolicy) {
      showErrorToast(errorCancelPolicy, setSeeToast, t);
    }
  }, [errorCancelPolicy]);

  useEffect(() => {
    if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [errorDeleteEntity]);

  useEffect(() => {
    if (errorSponsor) {
      showErrorToast(errorSponsor, setSeeToast, t);
    }
  }, [errorSponsor]);

  useEffect(() => {
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [errorPlan]);

  useEffect(() => {
    if (errorProject) {
      showErrorToast(errorProject, setSeeToast, t);
    }
  }, [errorProject]);

  useEffect(() => {
    if (errorGetCatalogStatusQuote) {
      showErrorToast(errorGetCatalogStatusQuote, setSeeToast, t);
    }
  }, [errorGetCatalogStatusQuote]);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorBranches) {
      showErrorToast(errorBranches, setSeeToast, t);
    }
  }, [errorBranches]);

  useEffect(() => {
    if (responseCancelQuote) {
      showToastSuccessMessage(
        "Cotizacion eliminada correctamente",
        typeAlert.success,
        setSeeToast
      );
      dispatch(
        quoteAll({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
        })
      );
      dispatch(quoteAllClearData());
      dispatch(cancelQuoteClearData());
    }
  }, [responseCancelQuote]);

  useEffect(() => {
    if (responseCancelPolicy && responseCancelPolicy.data) {
      showToastSuccessMessage(
        "Póliza cancelada correctamente",
        typeAlert.success,
        setSeeToast
      );
      dispatch(quoteAllClearData());
      dispatch(
        quoteAll({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
        })
      );
      dispatch(policyCancelClearData());
    }
  }, [responseCancelPolicy]);

  useEffect(() => {
    let listSponsorFilter = "";
    responseSponsor?.map((sponsor: MenuItem, idx: number) => {
      if (sponsor.id) {
        listSponsorFilter += `&sponsorId=${sponsor.id}`;
      }
    });
    setSponsorId(listSponsorFilter);
  }, [responseSponsor]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      /* Filtrar por beneficiary type */
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_REASON_CANCELLATION
      );
      if (result.length > 0) {
        setReasonCancellation(result);
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      setDataQuoteStorybook(responseAllQuote);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseGetCatalogStatusQuote)) {
      setStatusOptionsStorybook(responseGetCatalogStatusQuote);
    }
  }, [responseGetCatalogStatusQuote]);

  useEffect(() => {
    if (Array.isArray(responseInsurance)) {
      setInsuranceOptionsStorybook(responseInsurance);
    }
  }, [responseInsurance]);

  useEffect(() => {
    if (Array.isArray(responseBranches)) {
      setBranchesOptionsStorybook(responseBranches);
    }
  }, [responseBranches]);

  useEffect(() => {
    setBreadCrumbs(breadcrumDTO());
    if (!responseProject) {
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (nameSearch) {
      getQuoteAll();
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const handleOpenModal = () => {
    setOpenModal(true);
    setOpenModalConfirm(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
    setOpenModalHistorial(false);
    dispatch(quoteAllClearData());
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
    setOpenModal(false);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleConfirm = () => {
    setOpenModalConfirm(true);
    setOpenModal(false);
  };

  const handleDelete = (event: any) => {
    if (event.row.status === "Cotizada" || event.row.status === "cotizada") {
      setModalTitle("cotización");
      setPolicyId(event.row.id);
      setType(false);
    }
    if (event.row.status === "Emitida" || event.row.status === "emitida") {
      setModalTitle("póliza");
      setPolicyId(event.row.policyId);
      setType(true);
    }
    setRow(event.row);

    setOpenModal(true);
  };

  const handleClickContextMenu = (item: any, params: any) => {
    switch (item.id) {
      case "1":
        handleDelete(params);
        break;
      case "2":
        if (
          params.row.status === "Cotizada" ||
          params.row.status === "cotizada"
        ) {
          navigate(
            `/confirmationForm/${params.row.id}/true/false/${params.row.productId}`
          );
        } else {
          navigate(
            `/confirmationForm/${params.row.id}/false/false/${params.row.productId}`
          );
        }
        break;
      case "3":
        setLocalStorage(params);
        if (params.row.code === "ALE") {
          showToastSuccessMessage(
            "Operación no permitida!",
            typeAlert.warning,
            setSeeToast
          );
        } else {
          navigate("/fiscalizacion-apap-auto/" + params.row.id);
        }
        break;
      case "4":
        callQuoteOne(params.row.id);
        break;
      default:
        break;
    }
  };

  const setLocalStorage = (params: any) => {
    let product = params.row.product.replace(/\s+/g, "");
    switch (product) {
      case "AutoFull":
        localStorageService.setStepStored({
          id: "",
          name: "",
          step: Steps.insurance,
          completed: true,
          campaign: responseInsurance[0].campaign,
          flow: responseInsurance[0].salesFlow,
        });
        localStorageService.setStepStored({
          id: "",
          name: "",
          step: Steps.customer,
          completed: true,
          quoteId: params.row.id,
          campaign: "",
          coverType: { id: "", name: "" },
          paymentModeSelected: { id: "", name: "" },
          campaignSelected: { id: "", name: "" },
        });
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 120,
      renderCell: (params: any) => {
        const isSinDocument = params.row.code !== "AFU";
        const isStatusEmitida =
          params.row.status === "Emitida" || isSinDocument;
        let menuContext: any = [
          {
            disabled: isStatusEmitida,
            icon: {
              color: "#006ac6",
              icon: icons.UploadFile,
              size: sizesIcon.standard,
            },
            id: "3",
            name: "Fiscalizar",
          },
          {
            disabled: !hasPermission("sale-policies-sumary-see-detail"),
            icon: {
              color: "#006ac6",
              icon: icons.Search,
              size: sizesIcon.standard,
            },
            id: "2",
            name: "Ver detalles",
          },

          {
            disabled:
              !hasPermission("sale-policies-sumary-cancel") ||
              params.row.status === "Cancelada",
            icon: {
              color: "#006ac6",
              icon: icons.Edit,
              size: sizesIcon.standard,
            },
            id: "1",
            name: "Cancelar",
          },
          {
            disabled: false,
            icon: {
              color: "#006ac6",
              icon: icons.GridView,
              size: sizesIcon.standard,
            },
            id: "4",
            name: "Historial",
          },
        ];
        const menuContextV = isSinDocument ? menuContext.slice(1) : menuContext;

        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={menuContextV}
              onClickContextMenu={(item: any) =>
                handleClickContextMenu(item, params)
              }
              sizeIcon={sizesIcon.standard}
            />
          </div>
        );
      },
    },
    {
      editable: false,
      field: "status",
      headerName: "Estado",
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <span className={params.value}></span>
          {params.value}
        </div>
      ),
      width: 100,
    },
    {
      field: "quotation",
      headerName: "Cotización",
      width: 170,
    },
    {
      field: "certificate",
      headerName: "No. Póliza",
      width: 170,
    },
    {
      field: "firstNameA",
      headerName: "Nombres",
      width: 250,
    },
    {
      field: "lastNameA",
      headerName: "Primer apellido",
      width: 170,
    },
    {
      field: "lastNameB",
      headerName: "Segundo apellido",
      width: 170,
    },
    {
      field: "identification",
      headerName: "Identificación",
      width: 150,
    },

    {
      field: "branch",
      headerName: "Sucursal",
      width: 150,
    },
    {
      field: "user",
      headerName: "Usuario",
      width: 175,
    },
    {
      field: "product",
      headerName: "Producto",
      width: 175,
    },
    {
      field: "insuranceCarrier",
      headerName: "Aseguradora",
      width: 175,
    },
    {
      field: "cancellationDate",
      headerName: "Fecha de cancelación",
      width: 170,
    },
    {
      field: "reasonCancellation",
      headerName: "Motivo de cancelación",
      width: 170,
    },
  ];

  const handleCancelPolicy = (reasonId: any) => {
    setOpenModalConfirm(false);
    setOpenModal(false);
    //get current date and time
    let currentDateAndTime = new Date();
    let currentDate = currentDateAndTime.toISOString();
    if (type) {
      const dataSubmit: PolicyRequest = {
        data: {
          date: currentDate,
          userId: "d2bd577e-c8c6-4519-b30a-b8e674c42952",
          policyId: policyId,
          catalogReasonCancellation: reasonId,
        },
      };
      dispatch(policyCancel({ ...dataSubmit }));
    } else {
      dispatch(
        cancelQuote({
          id: policyId,
        })
      );
    }
  };

  const filterData = (array: any, key: string, value: string) => {
    let temp: any = [];
    temp = array.filter(function (e: any) {
      return e[key] == value;
    });
    let result: any = [];
    temp.map((item: any) => {
      result.push({ id: item.id, value: item.id, name: item.description });
    });
    return result;
  };

  const setDataQuoteStorybook = (responseAllQuote: RowsModel[]) => {
    const dataR = responseAllQuote?.map((item: RowsModel) => ({
      id: item?.id,
      quotation: item?.code,
      certificate: item?.policyId && item?.policyId?.code,
      identification: item?.numberId,
      firstNameA: `${item?.firstNameA} ${item?.firstNameB}`,
      lastNameA: item?.lastNameA,
      lastNameB: item?.lastNameB,
      status: item?.catalogStatusQuote?.description,
      branch: item?.branchData?.name,
      user: item?.user?.fullName,
      product: item?.productId?.description,
      code: item?.productId?.code,
      insuranceCarrier: item?.sponsor?.name,
      cancellationDate:
        item?.catalogCancellation?.createdAt &&
        formattedDate(item?.catalogCancellation?.createdAt),
      reasonCancellation: item?.catalogCancellation?.description,
      policyId: item?.policyId?.id,
      brandVehicle: "",
      modelVehicle: "",
      productId: item?.productId?.id,
      // brandVehicle: item.contentMap.fkCatalogBrandVeh.value,
      // modelVehicle: item.contentMap.fkCatalogModelVeh.value,
    }));

    setRows(dataR);
    dispatch(quoteAllClearData());
  };

  const setStatusOptionsStorybook = (
    responseGetCatalogStatusQuote: StatusOptionsModel[]
  ) => {
    let dataStatus: ListDropdownMultipleProps[] = [];
    responseGetCatalogStatusQuote?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataStatus.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setStatusOptions(dataStatus);
  };

  const setInsuranceOptionsStorybook = (
    responseProject: StatusOptionsModel[]
  ) => {
    let dataInsurance: ListDropdownMultipleProps[] = [];
    responseProject?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataInsurance.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setInsuranceOptions(dataInsurance);
  };

  const setBranchesOptionsStorybook = (
    responseBranches: StatusOptionsModel[]
  ) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseBranches?.map((item: StatusOptionsModel) => {
      if (item.id && item.name) {
        dataBranches.push({
          id: item.id,
          name: item.name,
          active: false,
        });
      }
    });
    setBranchesOptions(dataBranches);
  };

  const onCloseSearch = () => {
    setNameSearch("");
    dispatch(
      quoteAll({
        clientId: clientId,
        creationDateStart: null,
        creationDateEnd: null,
        enable: enable,
        page: page,
        limit: limit,
        search: "",
      })
    );
  };

  useEffect(() => {
    if (nameSearch) {
      getQuoteAll();
    }
  }, [nameSearch]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  const getQuoteAll = () => {
    dispatch(
      quoteAll({
        clientId: clientId,
        creationDateStart: null,
        creationDateEnd: null,
        enable: enable,
        page: page,
        limit: limit,
        search: nameSearch,
      })
    );
  };

  const onClickDownload = (item: buttonActionOptions) => {
    setIsLoading(true);
    downloadReports(
      item,
      clientId,
      enable,
      page,
      limit,
      idCatalogStatusQuote,
      dateStart,
      dateEnd,
      fields,
      labelFields
    )
      .then(() => {
        showToastSuccessMessage(
          "Archivo descargado con éxito",
          typeAlert.success,
          setSeeToast
        );
      })
      .catch((error) => {
        showToastSuccessMessage(
          "Ocurrio un error al descargar el archivo",
          typeAlert.error,
          setSeeToast
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterStatus = (item: any) => {
    const idStatus = item?.status.map((item: any) => item.id).join(",");
    const idBranch = item?.branch.map((item: any) => item.id).join(",");
    const idInsurance = item?.insurance.map((item: any) => item.id).join(",");
    setIdCatalogStatusQuote(idStatus);

    setDateStart(
      formattedDateWithTime(item?.dateRange?.startDate) === "undefined" ||
        formattedDateWithTime(item?.dateRange?.startDate) === "null" ||
        formattedDateWithTime(item?.dateRange?.startDate) === "NA"
        ? ""
        : formattedDateWithTime(item?.dateRange?.startDate)
    );
    setDateEnd(
      formattedDateWithTime(item?.dateRange?.endDate) === "undefined" ||
        formattedDateWithTime(item?.dateRange?.endDate) === "null" ||
        formattedDateWithTime(item?.dateRange?.endDate) === "NA"
        ? ""
        : formattedDateWithTime(item?.dateRange?.endDate)
    );
    dispatch(
      quoteAll({
        clientId: clientId,
        enable: enable,
        statuses: idStatus,
        creationDateStart:
          formattedDateWithTime(item?.dateRange?.startDate) === "undefined" ||
          formattedDateWithTime(item?.dateRange?.startDate) === "null" ||
          formattedDateWithTime(item?.dateRange?.startDate) === "NA"
            ? ""
            : formattedDateWithTime(item?.dateRange?.startDate),
        creationDateEnd:
          formattedDateWithTime(item?.dateRange?.endDate) === "undefined" ||
          formattedDateWithTime(item?.dateRange?.endDate) === "null" ||
          formattedDateWithTime(item?.dateRange?.endDate) === "NA"
            ? ""
            : formattedDateWithTime(item?.dateRange?.endDate),
        branches: idBranch,
        projectIds: idInsurance,
        page: page,
        limit: limit,
      })
    );
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <LoadingAFY
        loading={
          loadingAllQuote ||
          loadingCancelQuote ||
          loadingCancelPolicy ||
          isLoadin
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={isLoadin ? "Descargando archivo" : "Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />

      <View
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        columns={columns}
        getQuoteAll={getQuoteAll}
        getColumnsHistory={getColumnsHistory}
        filterStatus={filterStatus}
        rows={rows}
        insuranceOptions={insuranceOptions}
        statusOptions={statusOptions}
        branchOptions={branchesOptions}
        handleOpenModal={handleOpenModal}
        OpenModalHistorial={openModalHistorial}
        rowHistory={dataHistory}
        onClose={handleCloseModal}
        openModal={openModal}
        onConfirm={handleConfirm}
        reasonCancellation={reasonCancellation}
        handleOpenModalConfirm={handleOpenModalConfirm}
        onCloseConfirm={handleCloseModalConfirm}
        openModalConfirm={openModalConfirm}
        breadCrumbs={breadCrumbs}
        modalTitle={modalTitle}
        title={"Resumen pólizas"}
        onSubmit={handleCancelPolicy}
        row={row}
        onClickDownload={onClickDownload}
      />
    </React.Fragment>
  );
};

export default InsurancePoliciesSummary;

import React from "react";
import {
  DocumentProps,
  LoadingAFY,
  PDFViewerAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { StatusAuditDocument } from "../../../enum/statusAuditDocument";
import { themeCore } from "../../../assets/themes/theme";

export const PDFViewerView = (props: {
  documents?: DocumentProps;
  onBack: () => void;
  approveDocument: (status: StatusAuditDocument, comment: string) => void;
  onRejectedDocument: () => void;
  url: string;
  t: any;
  loading: boolean;
  titleDrawer: string;
  txtLegendDrawer: string;
  titleButtonDrawer: string;
  placeHolderDrawer: string;
  subTitleDrawer: string;
  onValueApply?: (value: any) => void;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}) => {
  return (
    <React.Fragment>
      <LoadingAFY
        loading={props.loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <PDFViewerAFY
        view={2}
        title={props.documents?.name ?? ""}
        description=""
        titleButtonBack="Regresar"
        titleButtonTopLeft="Aprobar"
        titleButtonTopRight="Rechazar"
        titlePagination1="Página"
        titlePagination2="de"
        bgColorButtonTopLeft="#3A56A1"
        bgColorButtonTopRight="#ebebeb"
        textColorButtonTopLeft="#fff"
        textColorButtonTopRight="#3A56A1"
        padding={30}
        onBack={props.onBack}
        onClickButtonTopLeft={() => {
          props.approveDocument(
            StatusAuditDocument.APPROVED,
            props.t("pdfViewer.messageDefault"),
          );
        }}
        onClickButtonTopRight={props.onRejectedDocument}
        onClose={function noRefCheck() {}}
        url={props.url}
        openModal={false}
        setOpenModal={() => {}}
        /*Drawer Properties*/
        titleDrawer={props.titleDrawer}
        textLegendDrawer={props.txtLegendDrawer}
        titleBtnDrawer={props.titleButtonDrawer}
        placeHolderDrawer={props.placeHolderDrawer}
        subTitleDrawer={props.subTitleDrawer}
        openDrawer={props.openDrawer}
        onValueApply={props.onValueApply}
        onCloseDrawer={props.onCloseDrawer}
      />
    </React.Fragment>
  );
};

import {
  COUNTRIES,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  COUNTRIES_CLEAR_DATA,
} from "./actionTypes";

export const countries = (countries, history) => {
  return {
    type: COUNTRIES,
    payload: { countries, history },
  };
};

export const countriesSuccess = (success) => {
  return {
    type: COUNTRIES_SUCCESS,
    payload: success,
  };
};

export const countriesError = (error) => {
  return {
    type: COUNTRIES_ERROR,
    payload: error,
  };
};

export const countriesClearData = () => {
  return {
    type: COUNTRIES_CLEAR_DATA,
    payload: {},
  };
};

import {
  QUOTE_UPLOAD_ATTACH,
  QUOTE_UPLOAD_ATTACH_CLEAR_DATA,
  QUOTE_UPLOAD_ATTACH_ERROR,
  QUOTE_UPLOAD_ATTACH_SUCCESS,
  QUOTE_UPLOAD_DOCUMENT,
  QUOTE_UPLOAD_DOCUMENT_CLEAR_DATA,
  QUOTE_UPLOAD_DOCUMENT_ERROR,
  QUOTE_UPLOAD_DOCUMENT_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingUploadAttach: false,
  responseUploadAttach: null,
  errorUploadAttach: null,
  ///
  loadingUploadDocuments: false,
  responseUploadDocuments: null,
  errorUploadDocuments: null,
};

const UploadAttach = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_UPLOAD_ATTACH:
      state = {
        ...state,
        loadingUploadAttach: true,
        errorUploadAttach: null,
        responseUploadAttach: null,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_SUCCESS:
      state = {
        ...state,
        errorUploadAttach: null,
        loadingUploadAttach: false,
        responseUploadAttach: action.payload,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_ERROR:
      state = {
        ...state,
        errorUploadAttach: action.payload,
        loadingUploadAttach: false,
        responseUploadAttach: null,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_CLEAR_DATA:
      state = {
        ...state,
        errorUploadAttach: null,
        loadingUploadAttach: null,
        responseUploadAttach: false,
      };
      break;
    //UPLOAD_DOCUMENTS
    case QUOTE_UPLOAD_DOCUMENT:
      state = {
        ...state,
        loadingUploadDocuments: true,
        errorUploadDocuments: null,
        responseUploadDocuments: null,
      };
      break;
    case QUOTE_UPLOAD_DOCUMENT_SUCCESS:
      state = {
        ...state,
        errorUploadDocuments: null,
        loadingUploadDocuments: false,
        responseUploadDocuments: action.payload,
      };
      break;
    case QUOTE_UPLOAD_DOCUMENT_ERROR:
      state = {
        ...state,
        errorUploadDocuments: action.payload,
        loadingUploadDocuments: false,
        responseUploadDocuments: null,
      };
      break;
    case QUOTE_UPLOAD_DOCUMENT_CLEAR_DATA:
      state = {
        ...state,
        errorUploadDocuments: null,
        loadingUploadDocuments: null,
        responseUploadDocuments: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UploadAttach;

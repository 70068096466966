import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postSponsor } from "../../store/sponsor/actions";

export const useSponsorCreate = (
  trigger: boolean,
  body: any,
) => {
  
  const dispatch = useDispatch();

  const { responsePostSponsor, errorPostSponsor, loadingPostSponsor } =
    useSelector((state: any) => ({
      responsePostSponsor: state.Sponsor.responsePostSponsor,
      errorPostSponsor: state.Sponsor.errorPostSponsor,
      loadingPostSponsor: state.Sponsor.loadingPostSponsor,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(postSponsor(body));
  }}, [trigger]);

  return {
    responsePostSponsor,
    errorPostSponsor,
    loadingPostSponsor,
  };
};

import {
  BRANCHES,
  BRANCHES_CLEAR_DATA,
  BRANCHES_ERROR,
  BRANCHES_PATCH,
  BRANCHES_PATCH_CLEAR_DATA,
  BRANCHES_PATCH_ERROR,
  BRANCHES_PATCH_SUCCESS,
  BRANCHES_POST,
  BRANCHES_POST_CLEAR_DATA,
  BRANCHES_POST_ERROR,
  BRANCHES_POST_SUCCESS,
  BRANCHES_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorBranches: null,
  responseBranches: null,
  loadingBranches: false,
};

const Branches = (state = initialState, action) => {
  switch (action.type) {
    case BRANCHES:
      state = {
        ...state,
        loadingBranches: true,
        errorBranches: null,
        responseBranches: true,
      };
      break;
    case BRANCHES_SUCCESS:
      state = {
        ...state,
        errorBranches: null,
        loadingBranches: false,
        responseBranches: action.payload,
      };
      break;
    case BRANCHES_ERROR:
      state = {
        ...state,
        errorBranches: action.payload,
        loadingBranches: false,
        responseBranches: null,
      };
      break;
    case BRANCHES_CLEAR_DATA:
      state = {
        ...state,
        errorBranches: null,
        loadingBranches: null,
        responseBranches: false,
      };
      break;
    // aqui inicia el POST
    case BRANCHES_POST:
      state = {
        ...state,
        loadingBranchesPost: true,
        errorBranchesPost: null,
        responseBranchesPost: null,
      };
      break;
    case BRANCHES_POST_SUCCESS:
      state = {
        ...state,
        errorBranchesPost: null,
        loadingBranchesPost: false,
        responseBranchesPost: action.payload,
      };
      break;
    case BRANCHES_POST_ERROR:
      state = {
        ...state,
        errorBranchesPost: action.payload,
        loadingBranchesPost: false,
        responseBranchesPost: null,
      };
      break;
    case BRANCHES_POST_CLEAR_DATA:
      state = {
        ...state,
        errorBranchesPost: null,
        loadingBranchesPost: null,
        responseBranchesPost: false,
      };
      break;
    // aqui inicia el PATCH
    case BRANCHES_PATCH:
      state = {
        ...state,
        loadingBranchesPatch: true,
        errorBranchesPatch: null,
        responseBranchesPatch: null,
      };
      break;
    case BRANCHES_PATCH_SUCCESS:
      state = {
        ...state,
        errorBranchesPatch: null,
        loadingBranchesPatch: false,
        responseBranchesPatch: action.payload,
      };
      break;
    case BRANCHES_PATCH_ERROR:
      state = {
        ...state,
        errorBranchesPatch: action.payload,
        loadingBranchesPatch: false,
        responseBranchesPatch: null,
      };
      break;
    case BRANCHES_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorBranchesPatch: null,
        loadingBranchesPatch: null,
        responseBranchesPatch: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Branches;

// import {
//   BRANCHES_GET_ONE,
//   BRANCHES_GET_ONE_CLEAR_DATA,
//   BRANCHES_GET_ONE_ERROR,
//   BRANCHES_GET_ONE_SUCCESS,
//   BRANCHES_PATCH,
//   BRANCHES_PATCH_CLEAR_DATA,
//   BRANCHES_PATCH_ERROR,
//   BRANCHES_PATCH_SUCCESS,
//   BRANCHES_POST,
//   BRANCHES_POST_CLEAR_DATA,
//   BRANCHES_POST_ERROR,
//   BRANCHES_POST_SUCCESS,
//   GET_BRANCHES,
//   GET_BRANCHES_CLEAR_DATA,
//   GET_BRANCHES_ERROR,
//   GET_BRANCHES_SUCCESS,
// } from "./actionTypes";
//
// const initialState = {
//   errorBranches: null,
//   responseBranches: null,
//   loadingBranches: false,
//   errorPostBranches: null,
//   responsePostBranches: null,
//   loadingPostBranches: false,
//   errorPatchBranches: null,
//   loadingPatchBranches: null,
//   responsePatchBranches: false,
// };
//
// const Branches = (state = initialState, action) => {
//   switch (action.type) {
//     case GET_BRANCHES:
//       state = {
//         ...state,
//         loadingBranches: true,
//         errorBranches: null,
//         responseBranches: true,
//       };
//       break;
//     case GET_BRANCHES_SUCCESS:
//       state = {
//         ...state,
//         errorBranches: null,
//         loadingBranches: false,
//         responseBranches: action.payload,
//       };
//       break;
//     case GET_BRANCHES_ERROR:
//       state = {
//         ...state,
//         errorBranches: action.payload,
//         loadingBranches: false,
//         responseBranches: null,
//       };
//       break;
//     case GET_BRANCHES_CLEAR_DATA:
//       state = {
//         ...state,
//         errorBranches: null,
//         loadingBranches: null,
//         responseBranches: false,
//       };
//       break;
//     case BRANCHES_GET_ONE:
//       state = {
//         ...state,
//         loadingBranches: true,
//         errorBranches: null,
//         responseBranches: null,
//       };
//       break;
//     case BRANCHES_GET_ONE_SUCCESS:
//       state = {
//         ...state,
//         errorBranches: null,
//         loadingBranches: false,
//         responseBranches: action.payload,
//       };
//       break;
//     case BRANCHES_GET_ONE_ERROR:
//       state = {
//         ...state,
//         errorBranches: action.payload,
//         loadingBranches: false,
//         responseBranches: null,
//       };
//       break;
//     case BRANCHES_GET_ONE_CLEAR_DATA:
//       state = {
//         ...state,
//         errorBranches: null,
//         loadingBranches: null,
//         responseBranches: null,
//       };
//       break;
//     case BRANCHES_POST:
//       state = {
//         ...state,
//         loadingPostBranches: true,
//         errorPostBranches: null,
//         responsePostBranches: null,
//       };
//       break;
//     case BRANCHES_POST_SUCCESS:
//       state = {
//         ...state,
//         errorPostBranches: null,
//         loadingPostBranches: false,
//         responsePostBranches: action.payload,
//       };
//       break;
//     case BRANCHES_POST_ERROR:
//       state = {
//         ...state,
//         errorPostBranches: action.payload,
//         loadingPostBranches: false,
//         responsePostBranches: null,
//       };
//       break;
//     case BRANCHES_POST_CLEAR_DATA:
//       state = {
//         ...state,
//         errorPostBranches: null,
//         loadingPostBranches: null,
//         responsePostBranches: null,
//       };
//       break;
//     case BRANCHES_PATCH:
//       state = {
//         ...state,
//         loadingPatchBranches: true,
//         errorPatchBranches: null,
//         responsePatchBranches: null,
//       };
//       break;
//     case BRANCHES_PATCH_SUCCESS:
//       state = {
//         ...state,
//         errorPatchBranches: null,
//         loadingPatchBranches: false,
//         responsePatchBranches: action.payload,
//       };
//       break;
//     case BRANCHES_PATCH_ERROR:
//       state = {
//         ...state,
//         errorPatchBranches: action.payload,
//         loadingPatchBranches: false,
//         responsePatchBranches: null,
//       };
//       break;
//     case BRANCHES_PATCH_CLEAR_DATA:
//       state = {
//         ...state,
//         errorPatchBranches: null,
//         loadingPatchBranches: null,
//         responsePatchBranches: null,
//       };
//       break;
//     default:
//       state = { ...state };
//       break;
//   }
//   return state;
// };
// export default Branches;

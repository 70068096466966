import {
  ContextMenuAFY,
  icons,
  MenuItem,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { SpanStatus } from "./style";
import { hasPermission } from "../../services/getNavigationPages";
import { useParams } from "react-router-dom";
import React from "react";
import { Rol } from "../../model/rol";

export const userDataForm = {
  id: "",
  name: "",
  lastName: "",
  birthDate: "",
  identificationType: "",
  identificationNumber: "",
  genderType: "",
  userName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  companyType: "",
  branchType: "",
  roleType: "",
  intermediaryType: "",
};
export const ColumnsUser = (handleClickContextMenu: any) => {
  return [
    {
      field: "actions",
      headerName: "Acciones",
      width: 80,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: hasPermission("admin-users-edit") ? false : true,
                  icon: {
                    color: "#006ac6",
                    icon: icons.Edit,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Modificar",
                },
                {
                  icon: {
                    color: "#006ac6",
                    icon: icons.Delete,
                    size: sizesIcon.standard,
                  },
                  disabled: hasPermission("admin-users-delete") ? false : true,
                  id: "2",
                  name: "Eliminar",
                },
                {
                  icon: {
                    color: "#006ac6",
                    icon: icons.TaskAlt,
                    size: sizesIcon.standard,
                  },
                  disabled: false,
                  id: "4",
                  name: "Roles",
                },
              ]}
              onClickContextMenu={(item: any) =>
                handleClickContextMenu(item, params)
              }
              sizeIcon={sizesIcon.standard}
            />
          </div>
        );
      },
    },
    {
      editable: false,
      field: "Enable",
      headerName: "Estado",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <SpanStatus
              style={{
                backgroundColor:
                  params.row.Enable === "true" ? "#46c51a" : "#ef2f24",
              }}
            ></SpanStatus>
            {params.row.Enable === "true" ? "Activo" : "Inactivo"}
          </div>
        );
      },
    },
    {
      field: "UserName",
      headerName: "User Name",
      width: 300,
    },
    {
      field: "Name",
      headerName: "Nombre",
      width: 180,
    },
    {
      field: "LastNames",
      headerName: "Apellidos",
      width: 180,
    },
    {
      field: "BranchName",
      headerName: "Sucursal",
      width: 180,
    },
    {
      field: "RoleName",
      headerName: "Rol",
      width: 180,
    },
  ];
};
export const ColumnsRols = (clickContextMenuPaymentMode: any) => {
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  return [
    {
      field: "type",
      headerName: "",
      width: 80,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {
            <ContextMenuAFY
              colorButton="#fff"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={[
                {
                  disabled: false,
                  icon: {
                    color: "#006ac6",
                    icon: icons.DeleteForever,
                    size: sizesIcon.standard,
                  },
                  id: "1",
                  name: "Eliminar",
                },
              ]}
              onClickContextMenu={() =>
                clickContextMenuPaymentMode(params?.row)
              }
              sizeIcon={sizesIcon.standard}
            />
          }
        </div>
      ),
    },

    {
      editable: false,
      field: "name",
      flex: 1,
      headerName: "Rol",
      width: 150,
    },
    {
      editable: false,
      field: "description",
      flex: 1,
      headerName: "Descripción",
      width: 160,
    },
  ];
};
export interface CUserModel {
  id?: string;
  userName?: string;
  name?: string;
  lastName?: string;
  email?: string;
  avatarUrl?: string;
  enabled?: boolean;
  roles?: Rol[];
  codeLabel?: string;
  code?: string;
  typeLabel?: string;
  type?: string;
  description?: string;
  fontSizeCode?: number;
  fontSizeType?: number;
  fontSizeDescription?: number;
  backgroundColor?: string;
  textDetails?: string;
  height?: string;
  heightList?: string;
  isList?: boolean;
  menuItem?: MenuItem[];
  onClickMenu?: (event: MenuItem) => void;
}
export const COVER_TYPE_PLAN = "COVER_TYPE_PLAN";
export const COVER_TYPE_PLAN_SUCCESS = "COVER_TYPE_PLAN_SUCCESS";
export const COVER_TYPE_PLAN_ERROR = "COVER_TYPE_PLAN_ERROR";
export const COVER_TYPE_PLAN_CLEAR_DATA = "COVER_TYPE_PLAN_CLEAR_DATA";

export const COVER_TYPE_PLAN_GET_ONE = "COVER_TYPE_PLAN_GET_ONE";
export const COVER_TYPE_PLAN_GET_ONE_SUCCESS = "COVER_TYPE_PLAN_GET_ONE_SUCCESS";
export const COVER_TYPE_PLAN_GET_ONE_ERROR = "COVER_TYPE_PLAN_GET_ONE_ERROR";
export const COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA = "COVER_TYPE_PLAN_GET_ONE_CLEAR_DATA";

export const COVER_TYPE_PLAN_POST = "COVER_TYPE_PLAN_POST";
export const COVER_TYPE_PLAN_POST_SUCCESS = "COVER_TYPE_PLAN_POST_SUCCESS";
export const COVER_TYPE_PLAN_POST_ERROR = "COVER_TYPE_PLAN_POST_ERROR";
export const COVER_TYPE_PLAN_POST_CLEAR_DATA = "COVER_TYPE_PLAN_POST_CLEAR_DATA";

export const COVER_TYPE_PLAN_PATCH = "COVER_TYPE_PLAN_PATCH";
export const COVER_TYPE_PLAN_PATCH_SUCCESS = "COVER_TYPE_PLAN_PATCH_SUCCESS";
export const COVER_TYPE_PLAN_PATCH_ERROR = "COVER_TYPE_PLAN_PATCH_ERROR";
export const COVER_TYPE_PLAN_PATCH_CLEAR_DATA = "COVER_TYPE_PLAN_PATCH_CLEAR_DATA";
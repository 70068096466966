import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { rolPatch } from "../../store/rol/actions";

export const useRolPatch = (trigger: boolean, body?: any, id?: string) => {
  const dispatch = useDispatch();

  const { responseRolPatch, errorRolPatch, loadingRolPatch } = useSelector(
    (state: any) => ({
      responseRolPatch: state.Rol.responseRolPatch,
      errorRolPatch: state.Rol.errorGeneralCatalogPatch,
      loadingRolPatch: state.Rol.loadingGeneralCatalogPatch,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        rolPatch(
          {
            name: body.RolName,
            description: body.RolDescription,
            permissions: body.Permissions,
          },
          body.id,
        ),
      );
    }
  }, [trigger]);

  return {
    responseRolPatch,
    errorRolPatch,
    loadingRolPatch,
  };
};
import {
  INSURANCE,
  INSURANCE_CLEAR_DATA,
  INSURANCE_ERROR,
  INSURANCE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorInsurance: null,
  responseInsurance: null,
  loadingInsurance: false,
};

const insuranceApi = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE:
      state = {
        ...state,
        loadingInsurance: true,
        errorInsurance: null,
        responseInsurance: true,
      };
      break;
    case INSURANCE_SUCCESS:
      state = {
        ...state,
        errorInsurance: null,
        loadingInsurance: false,
        responseInsurance: action.payload,
      };
      break;
    case INSURANCE_ERROR:
      state = {
        ...state,
        errorInsurance: action.payload,
        loadingInsurance: false,
        responseInsurance: null,
      };
      break;
    case INSURANCE_CLEAR_DATA:
      state = {
        ...state,
        errorInsurance: null,
        loadingInsurance: null,
        responseInsurance: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default insuranceApi;

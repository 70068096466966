import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchPlan } from "../../store/plans/actions";
import {  PlanRequest } from "../../model/plan";

export const usePlansPatch = (
  trigger: boolean,
  bodyRequest: PlanRequest,
) => {
  const dispatch = useDispatch();

  const { responsePatchPlans, errorPatchPlans, loadingPatchPlans } = useSelector(
    (state: any) => ({
      responsePatchPlans: state.Plans.responsePatchPlans?.data,
      errorPatchPlans: state.Plans.errorPatchPlans,
      loadingPatchPlans: state.Plans.loadingPatchPlans,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        patchPlan({bodyRequest}),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responsePatchPlans,
    errorPatchPlans,
    loadingPatchPlans,
  };
};

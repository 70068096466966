export const buildQueryString = (params: any): string => {
  if (!params) return "";
  // @ts-ignore
  return Object.entries(params)
    .filter(
      ([key, value]) => value !== undefined && value !== null && value !== "",
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((item) => `${key}=${encodeURIComponent(item as string)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(value as string)}`;
    })
    .join("&");
};

import {
  INSURANCES,
  INSURANCES_CLEAR_DATA,
  INSURANCES_ERROR,
  INSURANCES_ONE,
  INSURANCES_ONE_SUCCESS,
  INSURANCES_SUCCESS,
  POST_INSURANCES,
  POST_INSURANCES_CLEAR_DATA,
  POST_INSURANCES_ERROR,
  POST_INSURANCES_SUCCESS,
  PATCH_INSURANCES,
  PATCH_INSURANCES_CLEAR_DATA,
  PATCH_INSURANCES_ERROR,
  PATCH_INSURANCES_SUCCESS,
} from "./actionTypes";

export const insurances = (insurances, history) => {
  return {
    type: INSURANCES,
    payload: { insurances, history },
  };
};

export const insurancesOne = (insurances, history) => {
  return {
    type: INSURANCES_ONE,
    payload: { insurances, history },
  };
};

export const insurancesSuccess = (success) => {
  return {
    type: INSURANCES_SUCCESS,
    payload: success,
  };
};

export const insurancesError = (error) => {
  return {
    type: INSURANCES_ERROR,
    payload: error,
  };
};

export const insurancesOneSuccess = (success) => {
  return {
    type: INSURANCES_ONE_SUCCESS,
    payload: { ...success },
  };
};

export const insurancesClearData = () => {
  return {
    type: INSURANCES_CLEAR_DATA,
    payload: {},
  };
};

export const postInsurances = (insurancesBody, history) => {
  return {
    type: POST_INSURANCES,
    payload: { insurancesBody, history },
  };
};

export const postInsurancesSuccess = (success) => {
  return {
    type: POST_INSURANCES_SUCCESS,
    payload: success,
  };
};

export const postInsurancesError = (error) => {
  return {
    type: POST_INSURANCES_ERROR,
    payload: error,
  };
};

export const postInsurancesClearData = () => {
  return {
    type: POST_INSURANCES_CLEAR_DATA,
    payload: {},
  };
};

export const patchInsurances = (insurancesBody, history) => {
  return {
    type: PATCH_INSURANCES,
    payload: { insurancesBody, history },
  };
};

export const patchInsurancesSuccess = (success) => {
  return {
    type: PATCH_INSURANCES_SUCCESS,
    payload: success,
  };
};

export const patchInsurancesError = (error) => {
  return {
    type: PATCH_INSURANCES_ERROR,
    payload: error,
  };
};

export const patchInsurancesClearData = () => {
  return {
    type: PATCH_INSURANCES_CLEAR_DATA,
    payload: {},
  };
};

import { PolicyOneResponse, PolicyRequest } from "../../model/policy";
import { URLs } from "../url";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const postPolicy = async (policy: PolicyRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.policy;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(policy),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PolicyOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postPolicyConfirm = async (policy: PolicyRequest, id: string) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.policy + `/${id}/Confirm`;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(policy),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PolicyOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPolicyById = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.policy + `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PolicyOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const cancelPolicy = async (policy: PolicyRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.cancelPolicy;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(policy),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PolicyOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { postPolicy, getPolicyById, cancelPolicy, postPolicyConfirm };

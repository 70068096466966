import {
  LOGIN_USER,
  LOGIN_USER_CLEAR_DATA,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  USER,
  USER_CLEAR_DATA,
  USER_CONFIRM_EMAIL,
  USER_CONFIRM_EMAIL_CLEAR_DATA,
  USER_CONFIRM_EMAIL_ERROR,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_PASSWORD,
  USER_CONFIRM_PASSWORD_CLEAR_DATA,
  USER_CONFIRM_PASSWORD_ERROR,
  USER_CONFIRM_PASSWORD_SUCCESS,
  USER_CREATE,
  USER_CREATE_CLEAR_DATA,
  USER_CREATE_ERROR,
  USER_CREATE_SUCCESS,
  USER_ERROR,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_CLEAR_DATA,
  USER_PASSWORD_RESET_ERROR,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PATCH,
  USER_PATCH_CLEAR_DATA,
  USER_PATCH_ERROR,
  USER_PATCH_SUCCESS,
  USER_PUT_ROL,
  USER_PUT_ROL_CLEAR_DATA,
  USER_PUT_ROL_ERROR,
  USER_PUT_ROL_SUCCESS,
  USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorLogin: null,
  responseLogin: null,
  loadingLogin: false,
  errorUserCreate: null,
  loadingUserCreate: null,
  responseUserCreate: false,
  errorUser: null,
  loadingUser: null,
  responseUser: false,
  errorPasswordReset: null,
  loadingPasswordReset: null,
  responsePasswordReset: false,
  errorConfirmPassword: null,
  loadingConfirmPassword: null,
  responseConfirmPassword: false,
  errorConfirmEmail: null,
  loadingConfirmEmail: null,
  responseConfirmEmail: false,
  errorUserPatch: null,
  loadingUserPatch: null,
  responseUserPatch: false,
  errorUserPutRol: null,
  loadingUserPutRol: null,
  responseUserPutRol: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loadingLogin: true,
        errorLogin: null,
        responseLogin: null,
      };
      break;
    case LOGIN_USER_SUCCESS:
      state = {
        ...state,
        errorLogin: null,
        loadingLogin: false,
        responseLogin: action.payload,
      };
      break;
    case LOGIN_USER_ERROR:
      state = {
        ...state,
        errorLogin: action.payload,
        loadingLogin: false,
        responseLogin: null,
      };
      break;
    case LOGIN_USER_CLEAR_DATA:
      state = {
        ...state,
        errorLogin: null,
        loadingLogin: null,
        responseLogin: false,
      };
      break;
    case USER_CREATE:
      state = {
        ...state,
        loadingUserCreate: true,
        errorUserCreate: null,
        responseUserCreate: null,
      };
      break;
    case USER_CREATE_SUCCESS:
      state = {
        ...state,
        errorUserCreate: null,
        loadingUserCreate: false,
        responseUserCreate: action.payload,
      };
      break;
    case USER_CREATE_ERROR:
      state = {
        ...state,
        errorUserCreate: action.payload,
        loadingUserCreate: false,
        responseUserCreate: null,
      };
      break;
    case USER_CREATE_CLEAR_DATA:
      state = {
        ...state,
        errorUserCreate: null,
        loadingUserCreate: null,
        responseUserCreate: false,
      };
      break;
    case USER:
      state = {
        ...state,
        loadingUser: true,
        errorUser: null,
        responseUser: null,
      };
      break;
    case USER_SUCCESS:
      state = {
        ...state,
        errorUser: null,
        loadingUser: false,
        responseUser: action.payload,
      };
      break;
    case USER_ERROR:
      state = {
        ...state,
        errorUser: action.payload,
        loadingUser: false,
        responseUser: null,
      };
      break;
    case USER_CLEAR_DATA:
      state = {
        ...state,
        errorUser: null,
        loadingUser: null,
        responseUser: false,
      };
      break;
    case USER_PASSWORD_RESET:
      state = {
        ...state,
        loadingPasswordReset: true,
        errorPasswordReset: null,
        responsePasswordReset: null,
      };
      break;
    case USER_PASSWORD_RESET_SUCCESS:
      state = {
        ...state,
        errorPasswordReset: null,
        loadingPasswordReset: false,
        responsePasswordReset: action.payload,
      };
      break;
    case USER_PASSWORD_RESET_ERROR:
      state = {
        ...state,
        errorPasswordReset: action.payload,
        loadingPasswordReset: false,
        responsePasswordReset: null,
      };
      break;
    case USER_PASSWORD_RESET_CLEAR_DATA:
      state = {
        ...state,
        errorPasswordReset: null,
        loadingPasswordReset: null,
        responsePasswordReset: false,
      };
      break;
    case USER_CONFIRM_PASSWORD:
      state = {
        ...state,
        loadingConfirmPassword: true,
        errorConfirmPassword: null,
        responseConfirmPassword: null,
      };
      break;
    case USER_CONFIRM_PASSWORD_SUCCESS:
      state = {
        ...state,
        errorConfirmPassword: null,
        loadingConfirmPassword: false,
        responseConfirmPassword: action.payload,
      };
      break;
    case USER_CONFIRM_PASSWORD_ERROR:
      state = {
        ...state,
        errorConfirmPassword: action.payload,
        loadingConfirmPassword: false,
        responseConfirmPassword: null,
      };
      break;
    case USER_CONFIRM_PASSWORD_CLEAR_DATA:
      state = {
        ...state,
        errorConfirmPassword: null,
        loadingConfirmPassword: null,
        responseConfirmPassword: false,
      };
      break;
    case USER_CONFIRM_EMAIL:
      state = {
        ...state,
        loadingConfirmEmail: true,
        errorConfirmEmail: null,
        responseConfirmEmail: null,
      };
      break;
    case USER_CONFIRM_EMAIL_SUCCESS:
      state = {
        ...state,
        errorConfirmEmail: null,
        loadingConfirmEmail: false,
        responseConfirmEmail: action.payload,
      };
      break;
    case USER_CONFIRM_EMAIL_ERROR:
      state = {
        ...state,
        errorConfirmEmail: action.payload,
        loadingConfirmEmail: false,
        responseConfirmEmail: null,
      };
      break;
    case USER_CONFIRM_EMAIL_CLEAR_DATA:
      state = {
        ...state,
        errorConfirmEmail: null,
        loadingConfirmEmail: null,
        responseConfirmEmail: false,
      };
      break;
    case USER_PATCH:
      state = {
        ...state,
        loadingUserPatch: true,
        errorUserPatch: null,
        responseUserPatch: null,
      };
      break;
    case USER_PATCH_SUCCESS:
      state = {
        ...state,
        errorUserPatch: null,
        loadingUserPatch: false,
        responseUserPatch: action.payload,
      };
      break;
    case USER_PATCH_ERROR:
      state = {
        ...state,
        errorUserPatch: action.payload,
        loadingUserPatch: false,
        responseUserPatch: null,
      };
      break;
    case USER_PATCH_CLEAR_DATA:
      state = {
        ...state,
        errorUserPatch: null,
        loadingUserPatch: null,
        responseUserPatch: false,
      };
      break;

    case USER_PUT_ROL:
      state = {
        ...state,
        loadingUserPutRol: true,
        errorUserPutRol: null,
        responseUserPutRol: null,
      };
      break;
    case USER_PUT_ROL_SUCCESS:
      state = {
        ...state,
        errorUserPutRol: null,
        loadingUserPutRol: false,
        responseUserPutRol: action.payload,
      };
      break;
    case USER_PUT_ROL_ERROR:
      state = {
        ...state,
        errorUserPutRol: action.payload,
        loadingUserPutRol: false,
        responseUserPutRol: null,
      };
      break;
    case USER_PUT_ROL_CLEAR_DATA:
      state = {
        ...state,
        errorUserPutRol: null,
        loadingUserPutRol: null,
        responseUserPutRol: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
import { call, put, takeEvery } from "redux-saga/effects";
import { DELETE_ENTITY } from "./actionTypes";
import { deleteEntityError, deleteEntitySuccess } from "./actions";
import { deleteEntityApi } from "../../resources/helper/deleteEntity";

function* deleteEntity({ payload: { deleteEntity } }) {
  try {
    const response = yield call(
      deleteEntityApi,
      deleteEntity.id,
      deleteEntity.entity,
      deleteEntity.idUser,
      deleteEntity.body,
    );
    yield put(deleteEntitySuccess(response));
  } catch (error) {
    yield put(deleteEntityError(error));
  }
}

function* DeleteEntitySaga() {
  yield takeEvery(DELETE_ENTITY, deleteEntity);
}

export default DeleteEntitySaga;

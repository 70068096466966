import {
  POST_COLLECTION,
  POST_COLLECTION_CLEAR_DATA,
  POST_COLLECTION_ERROR,
  POST_COLLECTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPostCollection: null,
  responsePostCollection: null,
  loadingPostCollection: false,
};

const collectionApi = (state = initialState, action) => {
  switch (action.type) {
   
    case POST_COLLECTION:
      state = {
        ...state,
        loadingPostCollection: true,
        errorPostCollection: null,
        responsePostCollection: true,
      };
      break;
    case POST_COLLECTION_SUCCESS:
      state = {
        ...state,
        errorPostCollection: null,
        loadingPostCollection: false,
        responsePostCollection: action.payload,
      };
      break;
    case POST_COLLECTION_ERROR:
      state = {
        ...state,
        errorPostCollection: action.payload,
        loadingPostCollection: false,
        responsePostCollection: null,
      };
      break;
    case POST_COLLECTION_CLEAR_DATA:
      state = {
        ...state,
        errorPostCollection: null,
        loadingPostCollection: null,
        responsePostCollection: false,
      };
      break;
       default:
      state = { ...state };
      break;
  }
  return state;
};

export default collectionApi;

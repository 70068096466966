import { call, put, takeEvery } from "redux-saga/effects";
import { certificateQuoteError, certificateQuoteSuccess } from "./actions";
import { getCertificateQuote } from "../../resources/helperAPAP/certificatesQuote";
import { CERTIFICATE_QUOTE } from "./actionTypes";

function* certificateQuote({ payload: { certificateQuote } }) {
  try {
    const response = yield call(
      getCertificateQuote,
      certificateQuote.title,
      certificateQuote.quoteId,
      certificateQuote.enable,
      certificateQuote.page,
      certificateQuote.limit,
    );
    yield put(certificateQuoteSuccess(response));
  } catch (error) {
    yield put(certificateQuoteError(error));
  }
}

function* certificateQuoteSaga() {
  yield takeEvery(CERTIFICATE_QUOTE, certificateQuote);
}

export default certificateQuoteSaga;
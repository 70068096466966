import React from "react";
import "./App.css";
import { Layout } from "./components/layout/layout";
import { AppRouter } from "./routes/appRouter";
import { PublicRoutes } from "./routes/publicRoutes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./authContext";
import i18Next from "i18next";
function useIdleTimer() {
  let timer: NodeJS.Timeout;
  const startTimer = () => {
    timer = setTimeout(() => {
      localStorage.removeItem("afy-login");
      window.location.reload();
    }, 60000 * 1000); // 1 minute
  };

  const resetTimer = () => {
    clearTimeout(timer);
    startTimer();
  };

  startTimer();

  window.addEventListener("mousemove", resetTimer);
  window.addEventListener("keydown", resetTimer);

  return () => {
    clearTimeout(timer);
    window.removeEventListener("mousemove", resetTimer);
    window.removeEventListener("keydown", resetTimer);
  };
}

function App() {
  const { status } = useAuth();
  useIdleTimer(); // Add this line

        
    let lng = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "es";
    let ns = localStorage.getItem("i18nextDefaultNamespace") ? localStorage.getItem("i18nextDefaultNamespace") : "default";

     i18Next.loadNamespaces(ns as string, () => {
      if (ns) {
        i18Next.setDefaultNamespace(ns);
      }
      i18Next.changeLanguage(lng as string);
    });
   
  

  return (
    <BrowserRouter>
      {status !== "no-authenticated" ? (
        <Layout>
          <AppRouter />
        </Layout>
      ) : (
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;

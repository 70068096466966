import React, { useEffect, useState } from "react";
import LocalStorageService from "../services/localStorage";

interface Permission {
  enabled: string;
  name: string;
}

interface Role {
  clientId: string;
  description: string;
  id: string;
  name: string;
  permissions: Permission[];
}

export const useGetPermissionList = (trigger: boolean) => {
  const storageKey2 = "afy-login";
  const lsLogin = new LocalStorageService(storageKey2);

  const [permissionList, setPermissionList] = useState<Permission[]>([]);
  const [userRoles] = React.useState<any>(lsLogin.getUserLoginStored().roles);

  const getAllPermissions = (roles: Role[]): Permission[] => {
    return roles.reduce((acc, role) => {
      return acc.concat(role.permissions);
    }, [] as Permission[]);
  };

  useEffect(() => {
    if (trigger) {
      setPermissionList(getAllPermissions(userRoles));
    }
  }, [userRoles, trigger]);

  return {
    permissionList,
  };
};

import { URLs } from "../url";
import { CATALOG_SEXO, CATALOG_STATUS_QUOTE } from "../../const/catalog";
import { PlanResponse } from "../../model/plan";
import { CatalogResponse } from "../../model/catalog";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getAccountTypeCatalog = async (
  enable: boolean, 
  page: number,
  limit: number,
  projectId: string,
) => {
 const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.accountType +
    `?enable=${enable}&page=${page}&limit=${limit}&projectId=${projectId}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getGenderCatalog = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.catalog +
    `?enable=${enable}&page=${page}&limit=${limit}&type=${CATALOG_SEXO}` +
    (clientId ? `&clientId=${clientId}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getCatalogByIdApi = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.catalog + `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CatalogResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getStatusQuoteCatalog = async (
  enable: boolean,
  page: number,
  limit: number,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.catalog +
    `?enable=${enable}&page=${page}&limit=${limit}&type=${CATALOG_STATUS_QUOTE}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getCatalogsApi = async (
  enable: boolean,
  page: number,
  limit: number,
  type: string,
  clientId: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.catalog +
    `?enable=${enable}&page=${page}&limit=${limit}&type=${type}` +
    (clientId ? `&clientId=${clientId}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  getAccountTypeCatalog,
  getGenderCatalog,
  getCatalogByIdApi,
  getStatusQuoteCatalog,
  getCatalogsApi,
};

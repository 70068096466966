import React from "react";
import { PDFViewerAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const PDFViewer = (props: {
  openModal: boolean;
  setOpenModal: (value: boolean | (() => boolean)) => void;
  worker?: string;
  documentName?: string;
  urlDocument?: string;
  showTopButtons?: boolean;
  titleButtonTopLeft?: string;
  titleButtonTopRight?: string;
  bgColorButtonTopLeft?: string;
  bgColorButtonTopRight?: string;
  textColorButtonTopLeft?: string;
  textColorButtonTopRight?: string;
  onClickButtonTopLeft?: () => void;
  onClickButtonTopRight?: () => void;
}) => {
  return (
    <React.Fragment>
      <PDFViewerAFY
        openModal={props.openModal}
        description="PDF Viewer"
        onClose={() => {
          props.setOpenModal(false);
        }}
        setOpenModal={() => {}}
        width={"50%"}
        height={"470"}
        padding={30}
        title={props.documentName || ""}
        url={props.urlDocument || ""}
        worker={props.worker}
        showTopButtons={props.showTopButtons}
        titleButtonTopLeft={props.titleButtonTopLeft}
        titleButtonTopRight={props.titleButtonTopRight}
        bgColorButtonTopLeft={props.bgColorButtonTopLeft}
        bgColorButtonTopRight={props.bgColorButtonTopRight}
        textColorButtonTopLeft={props.textColorButtonTopLeft}
        textColorButtonTopRight={props.textColorButtonTopRight}
        onClickButtonTopLeft={props.onClickButtonTopLeft}
        onClickButtonTopRight={props.onClickButtonTopRight}
      />
    </React.Fragment>
  );
};

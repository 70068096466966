import {
  IDENTIFICATION_TYPE,
  IDENTIFICATION_TYPE_CLEAR_DATA,
  IDENTIFICATION_TYPE_ERROR,
  IDENTIFICATION_TYPE_SUCCESS,
  IDENTIFICATION_TYPE_GET_ONE,
  IDENTIFICATION_TYPE_GET_ONE_SUCCESS,
  IDENTIFICATION_TYPE_GET_ONE_ERROR,
  IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA,
  IDENTIFICATION_TYPE_POST,
  IDENTIFICATION_TYPE_POST_SUCCESS,
  IDENTIFICATION_TYPE_POST_ERROR,
  IDENTIFICATION_TYPE_POST_CLEAR_DATA,
  IDENTIFICATION_TYPE_PATCH,
  IDENTIFICATION_TYPE_PATCH_SUCCESS,
  IDENTIFICATION_TYPE_PATCH_ERROR,
  IDENTIFICATION_TYPE_PATCH_CLEAR_DATA,
} from "./actionTypes";

export const identificationType = (identificationType, history) => {
  return {
    type: IDENTIFICATION_TYPE,
    payload: { identificationType, history },
  };
};

export const identificationTypeSuccess = (success) => {
  return {
    type: IDENTIFICATION_TYPE_SUCCESS,
    payload: success,
  };
};

export const identificationTypeError = (error) => {
  return {
    type: IDENTIFICATION_TYPE_ERROR,
    payload: error,
  };
};

export const identificationTypeClearData = () => {
  return {
    type: IDENTIFICATION_TYPE_CLEAR_DATA,
    payload: {},
  };
};

export const identificationTypeGetOne = (id) => {
  return {
    type: IDENTIFICATION_TYPE_GET_ONE,
    payload: id,
  };
};

export const identificationTypeGetOneSuccess = (success) => {
  return {
    type: IDENTIFICATION_TYPE_GET_ONE_SUCCESS,
    payload: success,
  };
};

export const identificationTypeGetOneError = (error) => {
  return {
    type: IDENTIFICATION_TYPE_GET_ONE_ERROR,
    payload: error,
  };
};

export const identificationTypeGetOneClearData = () => {
  return {
    type: IDENTIFICATION_TYPE_GET_ONE_CLEAR_DATA,
    payload: {},
  };
};

export const identificationTypePost = (data) => {
  return {
    type: IDENTIFICATION_TYPE_POST,
    payload: {data},
  };
};

export const identificationTypePostSuccess = (success) => {
  return {
    type: IDENTIFICATION_TYPE_POST_SUCCESS,
    payload: success,
  };
};

export const identificationTypePostError = (error) => {
  return {
    type: IDENTIFICATION_TYPE_POST_ERROR,
    payload: error,
  };
};

export const identificationTypePostClearData = () => {
  return {
    type: IDENTIFICATION_TYPE_POST_CLEAR_DATA,
    payload: {},
  };
};

export const identificationTypePatch = (id, data) => {
  return {
    type: IDENTIFICATION_TYPE_PATCH,
    payload: { id, data },
  };
};

export const identificationTypePatchSuccess = (success) => {
  return {
    type: IDENTIFICATION_TYPE_PATCH_SUCCESS,
    payload: success,
  };
};

export const identificationTypePatchError = (error) => {
  return {
    type: IDENTIFICATION_TYPE_PATCH_ERROR,
    payload: error,
  };
};

export const identificationTypePatchClearData = () => {
  return {
    type: IDENTIFICATION_TYPE_PATCH_CLEAR_DATA,
    payload: {},
  };
};
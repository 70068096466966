import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { person } from "../../store/person/actions";

export const GetAllPerson = (trigger: boolean) => {
  const dispatch = useDispatch();
  const [enable] = React.useState<boolean>(true);
  const [page] = React.useState<number>(1);
  const [limit] = React.useState<number>(100000);

  const { responsePerson, errorPerson, loadingPerson } = useSelector(
    (state: any) => ({
      responsePerson: state.Person.responsePerson?.data,
      errorPerson: state.Person.errorPerson,
      loadingPerson: state.Person.loadingPerson,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        person({
          enable,
          page,
          limit,
        }),
      );
    }
  }, [trigger]);

  return {
    responsePerson,
    errorPerson,
    loadingPerson,
  };
};

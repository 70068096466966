import {
  INSURED,
  INSURED_CLEAR_DATA,
  INSURED_ERROR,
  INSURED_SUCCESS,
  PATCH_INSURED,
  PATCH_INSURED_CLEAR_DATA,
  PATCH_INSURED_ERROR,
  PATCH_INSURED_SUCCESS,
  POST_INSURED,
  POST_INSURED_CLEAR_DATA,
  POST_INSURED_ERROR,
  POST_INSURED_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorInsured: null,
  responseInsured: null,
  loadingInsured: false,
  errorPatchInsured: null,
  responsePatchInsured: null,
  loadingPatchInsured: false,
  errorPostInsured: null,
  responsePostInsured: null,
  loadingPostInsured: false,
};

const Insured = (state = initialState, action) => {
  switch (action.type) {
    case INSURED:
      state = {
        ...state,
        loadingInsured: true,
        errorInsured: null,
        responseInsured: true,
      };
      break;
    case INSURED_SUCCESS:
      state = {
        ...state,
        errorInsured: null,
        loadingInsured: false,
        responseInsured: action.payload,
      };
      break;
    case INSURED_ERROR:
      state = {
        ...state,
        errorInsured: action.payload,
        loadingInsured: false,
        responseInsured: null,
      };
      break;
    case INSURED_CLEAR_DATA:
      state = {
        ...state,
        errorInsured: null,
        loadingInsured: null,
        responseInsured: false,
      };
      break;
    case PATCH_INSURED:
      state = {
        ...state,
        loadingPatchInsured: true,
        errorPatchInsured: null,
        responsePatchInsured: true,
      };
      break;
    case PATCH_INSURED_SUCCESS:
      state = {
        ...state,
        errorPatchInsured: null,
        loadingPatchInsured: false,
        responsePatchInsured: action.payload,
      };
      break;
    case PATCH_INSURED_ERROR:
      state = {
        ...state,
        errorPatchInsured: action.payload,
        loadingPatchInsured: false,
        responsePatchInsured: null,
      };
      break;
    case PATCH_INSURED_CLEAR_DATA:
      state = {
        ...state,
        errorPatchInsured: null,
        loadingPatchInsured: null,
        responsePatchInsured: false,
      };
      break;
    case POST_INSURED:
      state = {
        ...state,
        loadingPostInsured: true,
        errorPostInsured: null,
        responsePostInsured: null,
      };
      break;
    case POST_INSURED_SUCCESS:
      state = {
        ...state,
        errorPostInsured: null,
        loadingPostInsured: false,
        responsePostInsured: action.payload,
      };
      break;
    case POST_INSURED_ERROR:
      state = {
        ...state,
        errorPostInsured: action.payload,
        loadingPostInsured: false,
        responsePostInsured: null,
      };
      break;
    case POST_INSURED_CLEAR_DATA:
      state = {
        ...state,
        errorPostInsured: null,
        loadingPostInsured: null,
        responsePostInsured: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Insured;

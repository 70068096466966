import { URLs } from "../url";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";


const postCalculationsAPI = async (data: any) => {
    let newURL = process.env.REACT_APP_HOST_API + URLs.calculations;
    
    const requestOptions = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(data),
    };
    
    try {
        const response = await fetch(newURL, requestOptions);
        if (!response.ok) {
        handleUnauthorized(response.status);
        throw await response.json();
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
    };
export { postCalculationsAPI };

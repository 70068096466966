import { call, put, takeEvery } from "redux-saga/effects";
import { GET_CAR_COLOR, GET_VEHICLES } from "./actionTypes";
import {
  getCarColorAPI,
  getVehiclesAPI,
} from "../../resources/helperAPAP/vehicles";
import {
  getCarColorError,
  getCarColorSuccess,
  getVehiclesError,
  getVehiclesSuccess,
} from "./actions";

function* getVehicles({ payload: { vehicle } }) {
  try {
    const response = yield call(
      getVehiclesAPI,
      vehicle.brand,
      // vehicle.orderBy,
      // vehicle.orderDir,
      // vehicle.page,
      // vehicle.limit,
    );
    yield put(getVehiclesSuccess(response));
  } catch (error) {
    yield put(getVehiclesError(error));
  }
}

function* getCarColor({ payload: { carColor } }) {
  try {
    const response = yield call(
      getCarColorAPI,
      carColor.name,
      carColor.orderBy,
      carColor.orderDir,
      carColor.page,
      carColor.limit,
    );
    yield put(getCarColorSuccess(response));
  } catch (error) {
    yield put(getCarColorError(error));
  }
}

function* vehiclesSaga() {
  yield takeEvery(GET_VEHICLES, getVehicles);
  yield takeEvery(GET_CAR_COLOR, getCarColor);
}

export default vehiclesSaga;

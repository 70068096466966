import { URLs } from "../url";
import { CampaignResponse } from "../../model/campaign";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const postCampaignApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.campaign;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CampaignResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchCampaignApi = async (campaignBody: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.campaign + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: campaignBody }),
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CampaignResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getCampaignById = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.campaign + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CampaignResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { postCampaignApi, patchCampaignApi, getCampaignById };

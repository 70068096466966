import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPatch } from "../../store/auth/actions";
import { UserRequest } from "../../model/user";

export const useUserPatch = (
  trigger: boolean,
  bodyRequest: UserRequest,
) => {
  const dispatch = useDispatch();

  const { responseUserPatch, errorUserPatch, loadingUserPatch } = useSelector(
    (state: any) => ({
      responseUserPatch: state.Login.responseUserPatch?.data,
      errorUserPatch: state.Login.errorUserPatch,
      loadingUserPatch: state.Login.loadingUserPatch,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        userPatch(bodyRequest),
      );
    }
  }, [bodyRequest, trigger]);

  return {
    responseUserPatch,
    errorUserPatch,
    loadingUserPatch
  };
};

import {
  GENERAL_CATALOG,
  GENERAL_CATALOG_CLEAR_DATA,
  GENERAL_CATALOG_ERROR,
  GENERAL_CATALOG_PATCH,
  GENERAL_CATALOG_PATCH_CLEAR_DATA,
  GENERAL_CATALOG_PATCH_ERROR,
  GENERAL_CATALOG_PATCH_SUCCESS,
  GENERAL_CATALOG_POST,
  GENERAL_CATALOG_POST_CLEAR_DATA,
  GENERAL_CATALOG_POST_ERROR,
  GENERAL_CATALOG_POST_SUCCESS,
  GENERAL_CATALOG_SUCCESS,
} from "./actionTypes";

export const generalCatalog = (generalCatalog, history) => {
  return {
    type: GENERAL_CATALOG,
    payload: { generalCatalog, history },
  };
};

export const generalCatalogSuccess = (success) => {
  return {
    type: GENERAL_CATALOG_SUCCESS,
    payload: success,
  };
};

export const generalCatalogError = (error) => {
  return {
    type: GENERAL_CATALOG_ERROR,
    payload: error,
  };
};

export const generalCatalogClearData = () => {
  return {
    type: GENERAL_CATALOG_CLEAR_DATA,
    payload: {},
  };
};

export const generalCatalogPost = (generalCatalogBody, history) => {
  return {
    type: GENERAL_CATALOG_POST,
    payload: { generalCatalogBody, history },
  };
};

export const generalCatalogPostSuccess = (success) => {
  return {
    type: GENERAL_CATALOG_POST_SUCCESS,
    payload: success,
  };
};

export const generalCatalogPostError = (error) => {
  return {
    type: GENERAL_CATALOG_POST_ERROR,
    payload: error,
  };
};

export const generalCatalogPostClearData = () => {
  return {
    type: GENERAL_CATALOG_POST_CLEAR_DATA,
    payload: {},
  };
};

export const generalCatalogPatch = (generalCatalogPatchBody, id) => {
  return {
    type: GENERAL_CATALOG_PATCH,
    payload: { generalCatalogPatchBody, id },
  };
};

export const generalCatalogPatchSuccess = (success) => {
  return {
    type: GENERAL_CATALOG_PATCH_SUCCESS,
    payload: success,
  };
};

export const generalCatalogPatchError = (error) => {
  return {
    type: GENERAL_CATALOG_PATCH_ERROR,
    payload: error,
  };
};

export const generalCatalogPatchClearData = () => {
  return {
    type: GENERAL_CATALOG_PATCH_CLEAR_DATA,
    payload: {},
  };
};

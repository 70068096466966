import { call, put, takeEvery } from "redux-saga/effects";
import { 
  identificationTypeSuccess, identificationTypeError,  
  identificationTypeGetOneSuccess, identificationTypeGetOneError,
  identificationTypePostSuccess, identificationTypePostError,
  identificationTypePatchSuccess, identificationTypePatchError
} from "./actions";
import { identificationTypeGet, identificationTypeGetOne, identificationTypePost, identificationTypePatch } from "../../resources/helper/identificationType";
import { IDENTIFICATION_TYPE, IDENTIFICATION_TYPE_POST, IDENTIFICATION_TYPE_PATCH, IDENTIFICATION_TYPE_GET_ONE  } from "./actionTypes";

function* identificationTypeApi({ payload: { identificationType } }) {
  try {
    const response = yield call(
      identificationTypeGet,
      identificationType.enable,
      identificationType.page,
      identificationType.limit,
      identificationType.description
    );
    yield put(identificationTypeSuccess(response));
  } catch (error) {
    yield put(identificationTypeError(error));
  }
}

function* identificationTypeGetOneApi({ payload: { identificationType } }) {
  try {
    const response = yield call(identificationTypeGetOne, identificationType.id);
    yield put(identificationTypeGetOneSuccess(response));
  } catch (error) {
    yield put(identificationTypeGetOneError(error));
  }
}

function* identificationTypePostApi({ payload: { data } }) {
  try {
    const response = yield call(identificationTypePost, data);
    yield put(identificationTypePostSuccess(response));
  } catch (error) {
    yield put(identificationTypePostError(error));
  }
}

function* identificationTypePatchApi({ payload: {id, data} }) {
  try {
    const response = yield call(identificationTypePatch, data, id);
    yield put(identificationTypePatchSuccess(response));
  } catch (error) {
    yield put(identificationTypePatchError(error));
  }
}

function* identificationTypeSaga() {
  yield takeEvery(IDENTIFICATION_TYPE, identificationTypeApi);
  yield takeEvery(IDENTIFICATION_TYPE_GET_ONE, identificationTypeGetOneApi);
  yield takeEvery(IDENTIFICATION_TYPE_POST, identificationTypePostApi);
  yield takeEvery(IDENTIFICATION_TYPE_PATCH, identificationTypePatchApi);
}

export default identificationTypeSaga;

import { call, put, takeEvery } from "redux-saga/effects";
import {
  patchQuoteError,
  patchQuoteSuccess,
  updateQuoteError,
  updateQuoteSuccess,
} from "./actions";
import {
  patchQuoteAPI,
  updateQuoteAPI,
} from "../../resources/helper/updateQuote";
import { PATCH_QUOTE, UPDATE_QUOTE } from "./actionTypes";

function* updateQuote({ payload: { quote } }) {
  try {
    const response = yield call(updateQuoteAPI, quote.body, quote.id);
    yield put(updateQuoteSuccess(response));
  } catch (error) {
    yield put(updateQuoteError(error));
  }
}

function* patchQuote({ payload: { quote, id } }) {
  try {
    const response = yield call(patchQuoteAPI, quote.body, id);
    yield put(patchQuoteSuccess(response));
  } catch (error) {
    yield put(patchQuoteError(error));
  }
}

function* UpdateQuoteSaga() {
  yield takeEvery(UPDATE_QUOTE, updateQuote);
  yield takeEvery(PATCH_QUOTE, patchQuote);
}

export default UpdateQuoteSaga;
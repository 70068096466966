import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencies } from "../../store/currencies/actions";

export const GetCurrencies = (
  trigger: boolean,
  enable: boolean,
  orderBy: string,
  orderDir: string,
  page: number,
  limit: number,
) => {
  const dispatch = useDispatch();

  const { responseCurrencies, errorCurrencies, loadingCurrencies } =
    useSelector((state: any) => ({
      responseCurrencies: state.Currencies.responseCurrencies?.data,
      errorCurrencies: state.Currencies.errorCurrencies,
      loadingCurrencies: state.Currencies.loadingCurrencies,
    }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        currencies({
          enable: enable,
          orderBy: orderBy,
          orderDir: orderDir,
          page: page,
          limit: limit,
        }),
      );
    }
  }, [trigger]);
  return {
    responseCurrencies,
    errorCurrencies,
    loadingCurrencies,
  };
};

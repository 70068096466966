import {
  BreadcrumbAFY,
  icons,
  StepProgressAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../layout/style";
import React from "react";
import { Icon } from "./iconOrLogo";
import { ContainerStepProgressAFY } from "./style";

export const HeaderTitlePage = (props: {
  showIcon?: boolean;
  breadCrumbs: any;
  titlePage?: string;
  logoImg?: string;
  iconPage?: icons;
  steps: any;
  hideStep?: boolean;
  stepsTop?: string;
  completed?: boolean;
}) => {
  return (
    <>
      <BreadcrumbAFY options={props.breadCrumbs} />
      <Title>
        {props.titlePage}
        <Icon
          logoImg={props.logoImg ? props.logoImg : ""}
          showIcon={props.showIcon ? props.showIcon : false}
          iconPage={props.iconPage ? props.iconPage : icons.Search}
        />
        {!props.hideStep && (
          <ContainerStepProgressAFY
            style={{ top: props.stepsTop ? props.stepsTop : "-4px" }}
          >
            <StepProgressAFY
              onClickStepProgress={() => {}}
              stepProgress={props.steps}
              collapsed
              sticky
              completed={props.completed}
            />
          </ContainerStepProgressAFY>
        )}
      </Title>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  icons,
  LoadingAFY,
  ReportScreenAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useDispatch, useSelector } from "react-redux";
import { quoteAll, quoteAllClearData } from "../../store/generateQuote/actions";
import { RowsModel } from "../../model/insurancePoliciesSummary";
import LocalStorageService from "../../services/localStorage";
import { dataColum } from "./data";
import { themeCore } from "../../assets/themes/theme";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
export const PolicyReport = () => {
  const dispatch = useDispatch();
  const [policyRows, setPolicyRows] = useState<any>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(100);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    }),
  );

  useEffect(() => {
    dispatch(quoteAllClearData());
    setPolicyRows([]);
    // window.location.reload();
  }, []);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      setDataQuoteStorybook(responseAllQuote);
    }
  }, [responseAllQuote]);

  const callApi = () => {
    dispatch(
      quoteAll({
        clientId: clientId,
        catalogStatusQuote: "427c885d-005e-4eab-8ad3-ed1a5228e702",
        enable: enable,
        page: page,
        limit: limit,
      }),
    );
  };

  const setDataQuoteStorybook = (responseAllQuote: RowsModel[]) => {
    const dataR = responseAllQuote?.map((item: RowsModel) => ({
      id: item.id,
      certificate: item.code,
      identification: `${item.identificationTypeId?.description} / ${item.numberId}`,
      name: item.firstNameA,
      firstSurname: item.lastNameA,
      secondSurname: item.firstNameB,
      marriedName: item.lastNameB,
      quote: item.code,
      status: item.catalogStatusQuote?.description,
      branch: "Principal",
      user: "User 1",
    }));
    setPolicyRows(dataR);
  };

  const viewReport = () => {
    callApi();
  };
  return (
    <React.Fragment>
      <LoadingAFY
        loading={loadingAllQuote}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando Pólizas."
        bgColor={themeCore.colors.backgroundLoader}
      />
      <ReportScreenAFY
        bGColorButton={themeCore.colors.primaryText}
        bGColorDescriptionHelp={themeCore.colors.third}
        buttonTitle="Visualizar reporte"
        buttonTitle2="Generar excel"
        colorTitle={themeCore.colors.primaryText}
        columns={dataColum}
        descriptionHelp="Insertar todos los campo necesarios para generar el reporte solicitado"
        disableButton
        heightGrid={575}
        iconColor={themeCore.colors.primaryText}
        iconDescription={icons.Help}
        onClick={() => {
          viewReport();
        }}
        onClickExcel={() => {}}
        onClickRow={function noRefCheck() {}}
        rows={policyRows}
        title="REPORTE DE VENTAS"
        titleDate1="Fecha inicio:"
        titleDate2="Fecha fin:"
      />
    </React.Fragment>
  );
};

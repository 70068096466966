import {
  SPONSOR,
  SPONSOR_CLEAR_DATA,
  SPONSOR_ERROR,
  SPONSOR_ONE,
  SPONSOR_ONE_SUCCESS,
  SPONSOR_SUCCESS,
  POST_SPONSOR,
  POST_SPONSOR_CLEAR_DATA,
  POST_SPONSOR_ERROR,
  POST_SPONSOR_SUCCESS,
  PATCH_SPONSOR,
  PATCH_SPONSOR_CLEAR_DATA,
  PATCH_SPONSOR_ERROR,
  PATCH_SPONSOR_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorSponsor: null,
  responseSponsor: null,
  loadingSponsor: false,
  errorPostSponsor: null,
  responsePostSponsor: null,
  loadingPostSponsor: false,
  errorPatchSponsor: null,
  loadingPatchSponsor: null,
  responsePatchSponsor: false,
};

const sponsorApi = (state = initialState, action) => {
  switch (action.type) {
    case SPONSOR:
      state = {
        ...state,
        loadingSponsor: true,
        errorSponsor: null,
        responseSponsor: true,
      };
      break;
    case SPONSOR_ONE:
      state = {
        ...state,
        loadingSponsor: true,
        errorSponsor: null,
        responseOneSponsor: true,
      };
      break;
    case SPONSOR_SUCCESS:
      state = {
        ...state,
        errorSponsor: null,
        loadingSponsor: false,
        responseSponsor: action.payload,
      };
      break;
    case SPONSOR_ONE_SUCCESS:
      state = {
        ...state,
        errorSponsor: null,
        loadingSponsor: false,
        responseOneSponsor: action.payload,
      };
      break;
    case SPONSOR_ERROR:
      state = {
        ...state,
        errorSponsor: action.payload,
        loadingSponsor: false,
        responseSponsor: null,
      };
      break;
    case SPONSOR_CLEAR_DATA:
      state = {
        ...state,
        errorSponsor: null,
        loadingSponsor: null,
        responseSponsor: false,
      };
      break;
    case POST_SPONSOR:
      state = {
        ...state,
        loadingPostSponsor: true,
        errorPostSponsor: null,
        responsePostSponsor: true,
      };
      break;
    case POST_SPONSOR_SUCCESS:
      state = {
        ...state,
        errorPostSponsor: null,
        loadingPostSponsor: false,
        responsePostSponsor: action.payload,
      };
      break;
    case POST_SPONSOR_ERROR:
      state = {
        ...state,
        errorPostSponsor: action.payload,
        loadingPostSponsor: false,
        responsePostSponsor: null,
      };
      break;
    case POST_SPONSOR_CLEAR_DATA:
      state = {
        ...state,
        errorPostSponsor: null,
        loadingPostSponsor: null,
        responsePostSponsor: false,
      };
      break;
      case PATCH_SPONSOR:
      state = {
        ...state,
        loadingPatchSponsor: true,
        errorPatchSponsor: null,
        responsePatchSponsor: true,
      };
      break;
    case PATCH_SPONSOR_SUCCESS:
      state = {
        ...state,
        errorPatchSponsor: null,
        loadingPatchSponsor: false,
        responsePatchSponsor: action.payload,
      };
      break;
    case PATCH_SPONSOR_ERROR:
      state = {
        ...state,
        errorPatchSponsor: action.payload,
        loadingPatchSponsor: false,
        responsePatchSponsor: null,
      };
      break;
    case PATCH_SPONSOR_CLEAR_DATA:
      state = {
        ...state,
        errorPatchSponsor: null,
        loadingPatchSponsor: null,
        responsePatchSponsor: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default sponsorApi;
